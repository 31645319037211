import { useQuery } from 'react-query';
import { BrandName } from '../classes/Brand';
import {
  IGetBrands,
  getBrands,
  getBrandsNames,
  getBrandsReviews,
  getBrandsWithDealsNames,
} from '../apis/brand.apis';
import { IBrand } from '../../types/resources/cars';
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';

export type TBrandParams = {
  type?: 'new' | 'used';
  dealer?: number;
};

interface IUseGetBrands {
  params?: TBrandParams;
  enabled?: boolean;
}

export const useBrands = ({ params, enabled }: IUseGetBrands) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['brand-names', params, enabled, lang],
    queryFn: () => getBrands(params),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: enabled,
  });
};

export const useBrandsReviews = ({ language }: { language: string }) => {
  return useQuery({
    queryKey: ['brand-reviews', language],
    queryFn: (): Promise<IBrand[]> => getBrandsReviews(language),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};

export const useGetBrands = (data?: IGetBrands) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['brands', data?.dealer, data?.type, lang],
    queryFn: () => getBrands(data),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useBrandNames = () => {
  return useQuery({
    queryKey: ['brand-names'],
    queryFn: (): Promise<BrandName[]> => getBrandsNames(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useBrandWithDealsNames = () => {
  return useQuery({
    queryKey: ['brand-names-with-deals'],
    queryFn: (): Promise<BrandName[]> => getBrandsWithDealsNames(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
