export const AndroidAutoAppleCarPlayIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="23"
      height="41"
      viewBox="0 0 23 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.55303 27.0093C7.99357 27.4498 8.70785 27.4498 9.14836 27.0093C9.58888 26.5687 9.58888 25.8545 9.14836 25.4139L7.58409 23.8496H17.8346C18.4577 23.8496 18.9628 23.3445 18.9628 22.7215C18.9628 22.0984 18.4577 21.5933 17.8346 21.5933H4.86057C4.57185 21.5933 4.28309 21.7035 4.06285 21.9237C3.62228 22.3643 3.62228 23.0786 4.06285 23.5192L7.55303 27.0093Z"
        fill={color}
      />
      <path
        d="M15.1422 14.0618C14.7016 13.6213 13.9873 13.6213 13.5468 14.0618C13.1063 14.5024 13.1063 15.2166 13.5468 15.6572L15.1111 17.2214H4.86061C4.23753 17.2214 3.73242 17.7266 3.73242 18.3496C3.73242 18.9727 4.23753 19.4778 4.86061 19.4778H17.8346C18.1233 19.4778 18.4121 19.3676 18.6324 19.1474C19.0729 18.7068 19.0729 17.9925 18.6324 17.5519L15.1422 14.0618Z"
        fill={color}
      />
      <path
        d="M21.6955 36.6813H1.00029C0.507178 36.6813 0.107422 36.2816 0.107422 35.7885V5.28251C0.107422 4.7894 0.507178 4.38965 1.00029 4.38965H21.6955C22.1886 4.38965 22.5884 4.7894 22.5884 5.28251V35.7885C22.5884 36.2816 22.1886 36.6813 21.6955 36.6813ZM1.89315 36.3241H20.8027V4.74677H1.89315V36.3241Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.5879 35.7883H0.10696L0.106934 37.6092C0.106934 39.2186 1.42373 40.5354 3.03322 40.5354H19.6617C21.2711 40.5354 22.588 39.2186 22.588 37.6092L22.5879 35.7883ZM11.3474 37.1976C11.88 37.1976 12.3117 37.6293 12.3117 38.1618C12.3117 38.6944 11.88 39.1261 11.3474 39.1261C10.8149 39.1261 10.3832 38.6944 10.3832 38.1618C10.3832 37.6293 10.8149 37.1976 11.3474 37.1976Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6617 0.5354H3.03322C1.42373 0.5354 0.106934 1.85222 0.106934 3.46169L0.10696 5.28246H22.5879L22.588 3.46169C22.588 1.85222 21.2711 0.5354 19.6617 0.5354ZM16.6667 2.90897C16.6667 3.1062 16.5067 3.26612 16.3095 3.26612H6.38542C6.18819 3.26612 6.02827 3.1062 6.02827 2.90897C6.02827 2.71174 6.18819 2.55182 6.38542 2.55182H16.3095C16.5067 2.55182 16.6667 2.71174 16.6667 2.90897Z"
        fill={color}
      />
    </svg>
  );
};
