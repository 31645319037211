import { useContext } from 'react';
import { IUsedBike } from '../../../types/bikes';
import { AppContext } from '../../../contexts/AppContext';
import { CarListCardSkeleton } from '../../UsedCars/UsedCarsSkeletons';
import { Link } from 'react-router-dom';
import {
  AutoImgBox,
  CarListInfo,
  CarListInfoDd,
  CarListInfoDt,
  DealsAdvantages,
  DealsAdvantagesDealTitle,
  DealsAdvantagesH3,
  DealsAdvantagesSmallLogos,
  DealsAdvantagesSmallLogosImg,
  DealsInfoRow,
  DealsLeft,
  DealsNav,
  DealsNavLi,
  DealsRight,
  DealsRightBox,
  DealsRightBtn,
  DealsRightFrame,
  DealsRightFrameQarSpan,
  DealsRightFrameStrong,
  ImgBox,
} from '../../../common/styles/CommonStyles';
import { CarListImageCard } from '../../Listings/common/CarGridCard/CarListImageCard';
import { formatPrice } from '../../../helpers/formatHelper';
import { timeSince } from '../../../helpers/timesince';
import '../../User/AccountWishList/Card-styles.css';

type BikeListCardProps = {
  usedBike: IUsedBike;
  isFavourite: boolean;
  onFavoriteClick: (e: any, id: number) => void;
  redirectionLink: string;
  isLoading: boolean;
};

export const BikeListCard = ({
  isFavourite,
  isLoading,
  onFavoriteClick,
  redirectionLink,
  usedBike,
}: BikeListCardProps) => {
  const { trans, darkImageLoadingPlaceholder, lang } = useContext(AppContext);

  if (isLoading) {
    return <CarListCardSkeleton />;
  }

  return (
    <Link style={{ color: '#000' }} to={redirectionLink}>
      <DealsInfoRow key={usedBike.id} className="usedcars test">
        <DealsLeft className="deals-left">
          <ImgBox>
            <AutoImgBox>
              <CarListImageCard
                src={usedBike.images?.[0]}
                defaultSrc={darkImageLoadingPlaceholder}
              />
              <button
                className={`wishlist-icon loginpopupload ${
                  isFavourite ? 'active' : ''
                }`}
                onClick={(e: any) => {
                  e.preventDefault();
                  onFavoriteClick(e, usedBike.id);
                }}
              >
                <i className="fa fa-heart-o"></i>
                <i className="fa fa-heart"></i>
              </button>
            </AutoImgBox>
          </ImgBox>
          <DealsAdvantages className="deals-advantages">
            <DealsAdvantagesSmallLogos className="small-logos">
              <DealsAdvantagesSmallLogosImg
                src={usedBike.bike_model?.brand.original_logo}
                alt="Brand Logo"
              />
            </DealsAdvantagesSmallLogos>
            <DealsAdvantagesDealTitle>
              {usedBike.bike_model?.brand.name ?? ''}
            </DealsAdvantagesDealTitle>
            <DealsAdvantagesH3>
              {usedBike.bike_model?.name ?? ''}{' '}
              {usedBike && usedBike.bike_trim != null
                ? usedBike.bike_trim.name
                : ''}
            </DealsAdvantagesH3>
            <DealsNav className="deals-nav">
              <DealsNavLi>
                {timeSince(usedBike.status_changed_at, trans, lang)}
              </DealsNavLi>
            </DealsNav>
          </DealsAdvantages>
        </DealsLeft>
        <DealsRight className="deals-right">
          <DealsRightBox className="box">
            <CarListInfo>
              <CarListInfoDt>{trans.year}:</CarListInfoDt>
              <CarListInfoDd>{usedBike.year}</CarListInfoDd>
              <CarListInfoDt>{trans.mileage}:</CarListInfoDt>
              <CarListInfoDd>
                {usedBike.mileage || 0} {trans.kms}
              </CarListInfoDd>
              <CarListInfoDt>{trans.seller}:</CarListInfoDt>
              <CarListInfoDd>
                {usedBike.bike_dealer?.name || trans.private}
              </CarListInfoDd>
            </CarListInfo>
          </DealsRightBox>
          <DealsRightFrame>
            <DealsRightFrameStrong>
              <DealsRightFrameQarSpan>{trans.qar}</DealsRightFrameQarSpan>{' '}
              <span>{formatPrice(usedBike.price)}</span>
            </DealsRightFrameStrong>
            <DealsRightBtn className="btn btn-secondary" to={redirectionLink}>
              {trans.view_bike}
            </DealsRightBtn>
          </DealsRightFrame>
        </DealsRight>
      </DealsInfoRow>
    </Link>
  );
};
