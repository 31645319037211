import { useContext, useEffect, useMemo, useState } from 'react';
import { IGetUsedBikes } from '../apis';
import { AppContext } from '../../../contexts/AppContext';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IUsedBike } from '../../../types/bikes';
import {
  TOrder,
  TSortBy,
  useHandleFavouriteCarClick,
  useInsertBanners,
  useSortCars,
} from '../../UsedCars/hooks/useUsedCars';
import ListingFilterBar, {
  TFilters,
} from '../../Listings/common/ListingFiltersBar';
import {
  useAddFavoriteUsedBike,
  useDeleteFavoriteUsedBike,
  useGetFavoriteUsedBikes,
  useGetUsedBikes,
} from '../hooks';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import { UsedCarsContainer } from '../../UsedCars/components/UsedCarStyles';
import {
  UsedCarsFilter,
  UsedCarsFilterContainer,
} from '../../../common/styles/CommonStyles';
import { UsedBikeBodyComponent } from './UsedBikeBodyComponent';
import SignIn from '../../User/SignIn/SignInComponent';
import { useBikeBrands } from '../../../common/hooks/bikes';
import { useModelsForUsedBikes } from '../../../common/hooks/bikes/useBikeModels';
import { AdvancedBikeSearchComponent } from './AdvancedBikeSearchComponent';
import { useDealers } from '../../Dealers/hooks/useDealersApis';

export type TUsedBikesFilter = Omit<IGetUsedBikes, 'page' | 'perPage'>;

const generateBikeRedirectionLink = (bike: IUsedBike, lang: string) => {
  const country = localStorage.getItem('country');
  return `/${country}/${lang}/used-bikes/${bike.bike_model?.brand?.slug}/${bike.bike_model?.slug}/${bike.year}/${bike.id}`;
};

const UsedBikeComponent = () => {
  const responsiveFeatured = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };
  const { lang, trans, userData, country } = useContext(AppContext);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [usedBikes, setUsedBikes] = useState<any>([]);
  const [usedBikesWithBanners, setUsedBikesWithBanners] = useState<
    Array<IUsedBike | string>
  >([]);

  const [isGrid, setIsGrid] = useState(false);

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const [showSignIn, setShowSignIn] = useState(false);
  const pageSize = 16;
  const [pagesCount, setPagesCount] = useState(0);
  const [featuredPagesCount, setFeaturedPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0,
  );
  const [sort, setSort] = useState<{ sortBy: TSortBy; order: TOrder }>();
  const [filtersNumber, setFiltersNumber] = useState<number>(0);
  const brandIds = searchParams.get('brand');
  const modelIds = searchParams.get('model');
  const types = searchParams.get('type');

  const [usedBikeQueryParams, setUsedBikeQueryParams] = useState<
    TUsedBikesFilter | undefined
  >(
    brandIds && modelIds && types
      ? {
          brandIds: searchParams.get('brand')
            ? [Number(searchParams.get('brand'))]
            : undefined,
          modelIds: searchParams.get('model')
            ? [Number(searchParams.get('model'))]
            : undefined,
          types: searchParams.get('type')
            ? [Number(searchParams.get('type'))]
            : undefined,
        }
      : undefined,
  );

  const [filters, setFilters] = useState<TFilters>({
    brandIds: searchParams.get('brand')
      ? [Number(searchParams.get('brand'))]
      : [],
    modelIds: searchParams.get('model')
      ? [Number(searchParams.get('model'))]
      : [],
    types: searchParams.get('type') ? [Number(searchParams.get('type'))] : [],
    maxPrice: -1,
    minPrice: -1,
    minMileage: -1,
    maxMileage: -1,
    minYear: -1,
    maxYear: -1,
    optionalFeatures: [],
    sellerType: '',
  });

  useEffect(() => {
    let count = 0;
    if (!usedBikeQueryParams) return setFiltersNumber(0);
    Object.keys(usedBikeQueryParams).forEach(key => {
      if (
        usedBikeQueryParams[key] &&
        key !== 'minYear' &&
        key !== 'dealerIds'
      ) {
        if (Array.isArray(usedBikeQueryParams[key])) {
          if (usedBikeQueryParams[key]?.length > 0) {
            count++;
          }
        } else {
          if (usedBikeQueryParams[key] !== -1) {
            count++;
          }
        }
      }
    });
    setFiltersNumber(count);
  }, [usedBikeQueryParams]);

  const { insertBanners } = useInsertBanners();

  const toggleGridView = (isGrid: boolean) => {
    setIsGrid(isGrid);
  };

  const { data: bikeBrands } = useBikeBrands({});

  const { data: bikeModels, isFetching: isFetchingModels } =
    useModelsForUsedBikes({
      params: {
        brand: filters.brandIds,
      },
    });

  const { data: dealers } = useDealers();

  const { data: usedBikesData, isFetching: isFetchingUsedBikes } =
    useGetUsedBikes({
      query: {
        ...usedBikeQueryParams,
        page: currentPage + 1,
        perPage: pageSize,
        is_featured: false,
        sortBy: sort?.sortBy,
        status: 'approved',
        sortDirection: sort?.order,
      },
      enabled: true,
      searchQuery:
        !usedBikeQueryParams && searchParams.get('q')
          ? searchParams.get('q') || undefined
          : undefined,
    });

  const {
    data: usedFeaturedBikesData,
    isFetching: isFetchingFeaturedUsedBikes,
  } = useGetUsedBikes({
    query: {
      page: (currentPage % featuredPagesCount) + 1 || 1,
      perPage: 5,
      is_featured: true,
      status: 'approved',
    },
    enabled: true,
  });

  const { data: favoriteBikes, isLoading: isLoadingFavoriteBikes } =
    useGetFavoriteUsedBikes({
      enabled: !!userData,
      userData,
    });

  const toggleSignInDialog = (show: any) => {
    setShowSignIn(show);
  };

  const { sortCars } = useSortCars(setSort);

  const toggleAdvancedSearch = (show: boolean) => {
    setShowAdvancedSearch(show);
  };

  const { mutateAsync: addBikeFavorite } = useAddFavoriteUsedBike();
  const { mutateAsync: deleteBikeFavorite } = useDeleteFavoriteUsedBike();

  const { handleFavouriteCarClick } = useHandleFavouriteCarClick(
    userData,
    addBikeFavorite,
    deleteBikeFavorite,
    setShowSignIn,
  );

  useEffect(() => {
    if (!usedBikesData) return;
    setUsedBikes(usedBikesData.data);
    if (usedBikesData.meta.lastPage < currentPage) {
      setCurrentPage(usedBikesData?.meta?.lastPage - 1);
    }
    setPagesCount(usedBikesData?.meta?.lastPage);
    setShowAdvancedSearch(false);
  }, [usedBikesData]);

  useEffect(() => {
    if (!usedFeaturedBikesData) return;
    setFeaturedPagesCount(usedFeaturedBikesData?.meta.lastPage);
  }, [usedFeaturedBikesData]);

  useEffect(() => {
    if (!isFetchingUsedBikes) {
      const interval = isGrid ? 12 : 5;
      setUsedBikesWithBanners(insertBanners(interval, usedBikes));
    }
  }, [isGrid, usedBikes]);

  const handleReset = () => {
    setFilters({
      brandIds: [],
      modelIds: [],
      types: [],
      maxPrice: -1,
      minPrice: -1,
      minMileage: -1,
      maxMileage: -1,
      minYear: -1,
      maxYear: -1,
      optionalFeatures: [],
      sellerType: '',
    });
    setUsedBikeQueryParams({});
    setFiltersNumber(0);
  };

  const handleSearch = () => {
    const filteredFilters = getFilteredFilters(filters);
    setUsedBikeQueryParams(filteredFilters);
    setShowAdvancedSearch(false);
  };

  const getFilteredFilters = (filters: TFilters) => {
    const filteredFilters = Object.keys(filters).reduce((acc, key) => {
      if (filters[key as keyof TFilters] !== -1) {
        acc[key] = filters[key as keyof TFilters];
      }

      return acc;
    }, {});
    return filteredFilters;
  };

  const addPage = useMemo(() => {
    return currentPage + 1 === 1 ? '' : `?page=${currentPage + 1}`;
  }, [currentPage]);

  const noResults = useMemo(() => {
    return usedBikesWithBanners.length === 0 && !isFetchingUsedBikes;
  }, [usedBikesWithBanners]);

  const isLoading = useMemo(() => {
    return (
      isFetchingUsedBikes ||
      isFetchingFeaturedUsedBikes ||
      isLoadingFavoriteBikes ||
      (usedBikesWithBanners.length === 0 && !noResults)
    );
  }, [
    isFetchingUsedBikes,
    isFetchingFeaturedUsedBikes,
    isLoadingFavoriteBikes,
    usedBikesWithBanners,
    noResults,
  ]);
  return (
    <>
      <MetaTag
        metaKey={'usedBikes'}
        href={`/${country}/${lang}/used-bikes${addPage}`}
      />
      <UsedCarsContainer>
        <UsedCarsFilterContainer>
          <UsedCarsFilter className="container">
            <ListingFilterBar
              brands={bikeBrands}
              dealers={dealers}
              models={bikeModels}
              isFetchingModels={isFetchingModels}
              setShowAdvancedFilter={setShowAdvancedSearch}
              filters={filters}
              setFilters={setFilters}
              getFilteredFilters={getFilteredFilters}
              setUsedCarQueryParams={setUsedBikeQueryParams}
              filtersNumber={filtersNumber}
              setFiltersNumber={setFiltersNumber}
            />
          </UsedCarsFilter>
        </UsedCarsFilterContainer>
        <UsedBikeBodyComponent
          trans1={trans.used_bikes_for_sale}
          trans2={trans.p_of}
          responsiveFeatured={responsiveFeatured}
          currentPage={currentPage}
          favoriteBikes={favoriteBikes}
          generateRedirectionLink={generateBikeRedirectionLink}
          handleFavouriteBikeClick={handleFavouriteCarClick}
          isGrid={isGrid}
          isLoading={isLoading}
          lang={lang}
          noResults={noResults}
          pageSize={pageSize}
          pagesCount={pagesCount}
          setCurrentPage={setCurrentPage}
          sortBikes={sortCars}
          toggleGridView={toggleGridView}
          trans={trans}
          usedBikesWithBanners={usedBikesWithBanners}
          usedFeaturedBikesData={usedFeaturedBikesData}
          country={country}
        />
      </UsedCarsContainer>
      {showAdvancedSearch && (
        <AdvancedBikeSearchComponent
          showAdvancedSearch={showAdvancedSearch}
          toggleAdvancedSearch={toggleAdvancedSearch}
          handleSearch={handleSearch}
          filters={filters}
          setFilters={setFilters}
          handleClear={handleReset}
        />
      )}
      {showSignIn && (
        <SignIn
          toggleSignInDialog={toggleSignInDialog}
          showSignIn={showSignIn}
          location={location.pathname}
        />
      )}
    </>
  );
};

export default UsedBikeComponent;
