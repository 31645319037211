import { TextField, styled } from '@mui/material';
import { FormControlStyled } from '../../../../common/styles/CommonStyles';

const TextFieldStyled = styled(TextField)(() => ({
  height: '48px',
  width: '438px',
  borderRadius: '8px',
  background: '#F7F7F7',
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '8px',
    height: '52px',
    display: 'none',
  },
  input: {
    ':disabled': {
      color: 'black',
      WebkitTextFillColor: 'unset',
    },
  },
  '@media screen and (max-width: 1200px)': {
    width: '100%',
  },
}));

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '70px',
  gap: '20px',
}));

const StyledH4 = styled('h4')(() => ({
  height: '10px',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '28px',
}));

export const CarDetailField = ({
  header,
  value,
}: {
  header: string;
  value: string;
}) => (
  <StyledDiv>
    <StyledH4>{header}</StyledH4>
    <FormControlStyled fullWidth required>
      <TextFieldStyled
        className="sell-your-car-vin-search-bar"
        variant="outlined"
        value={value}
        fullWidth
        disabled
      />
    </FormControlStyled>
  </StyledDiv>
);
