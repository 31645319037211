import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { IReview } from '../../../../types/reviews';
import { ReviewCardImage } from './ReviewCardImage';
import { ReviewsCardSkeletons } from '../ReviewsSkeletons';
import { ReviewCardReviewLogo } from './ReviewCardReviewLogo';
import { SponsoredBadge } from '../../../../common/styles/CommonStyles';
import {
  ReviewCardContent,
  ReviewsCardP1,
  ReviewsCardP2,
  ReviewsCardTextContainer,
} from '../ReviewsStyles';

interface ReviewCardProps {
  review: IReview;
  lang: string;
  trans: any;
  index: number;
  className?: string;
  onClick?: any;
  style: CSSProperties;
  isLoading?: boolean;
}

export const ReviewCard = ({
  review,
  trans,
  index,
  className,
  onClick,
  style,
  isLoading,
}: ReviewCardProps) => {
  if (isLoading) {
    return <ReviewsCardSkeletons />;
  }
  return (
    <div key={index} className={className} onClick={onClick}>
      {/* Tag */}
      {review.is_featured && (
        <SponsoredBadge className="reviews-badge">
          {trans.sponsored}
        </SponsoredBadge>
      )}

      <SponsoredBadge className="reviews-badge-lang">
        {review.lang === 'en' ? trans.en : trans.ar}
      </SponsoredBadge>

      {/* Video Content */}
      <ReviewCardImage
        src={review.thumbnail}
        alt={`Thumbnail for ${review.title}`}
        style={style}
      />
      <ReviewCardContent>
        <ReviewCardReviewLogo
          src={review.reviewer.reviewer_images}
          alt={`Profile Picture for ${review.reviewer.name}`}
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            marginRight: '10px',
          }}
        />
        <ReviewsCardTextContainer>
          <ReviewsCardP1>{review.title}</ReviewsCardP1>
          <ReviewsCardP2>{review.reviewer.name}</ReviewsCardP2>
        </ReviewsCardTextContainer>
      </ReviewCardContent>
    </div>
  );
};
