import PropTypes from 'prop-types';
import { NewCarsDetailsPageSilderSkeleton } from '../NewCarsCardsSkeletons';
import { NewCarDetailPageImage } from './NewCarDetailPageImage';
import { NewCarDetailPageImages } from './NewCarDetailPageImages';
import {
  AutoImgBox,
  SliderHolder,
} from '../../../../common/styles/CommonStyles';
import { NewCarImageSlideContent, NewCarImageStyled } from '../NewCarsStyled';
import '../../../Listings/common/ListingDetailsImageGallery/ListingDetailsImageGallery-styles.css';
export const SliderSection = ({
  images,
  coverImageSrc,
  setActive,
  darkImageLoadingPlaceholder,
  style,
  isLoading,
  selectedModel,
}) => {
  if (isLoading) {
    return <NewCarsDetailsPageSilderSkeleton />;
  }

  return (
    <SliderHolder style={{ ...style }}>
      <div className="slick-slider-big slick-initialized slick-slider">
        <div className="slick-list draggable">
          <div className="slick-track" style={{ opacity: 1 }}>
            <NewCarImageStyled>
              <NewCarImageSlideContent>
                <AutoImgBox>
                  <NewCarDetailPageImage
                    src={
                      coverImageSrc
                        ? coverImageSrc
                        : darkImageLoadingPlaceholder
                    }
                    defaultSrc={darkImageLoadingPlaceholder}
                    alt={`${selectedModel.slug}-cover-image`}
                  />
                </AutoImgBox>
              </NewCarImageSlideContent>
            </NewCarImageStyled>
          </div>
        </div>
      </div>
      <div className="slider-nav-thumbnails slick-initialized slick-slider">
        <div className="slick-list draggable">
          <div
            className="slick-track"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            {images?.map((imageSrc: string, index: number) => {
              return (
                <div
                  key={index}
                  className={`item slick-slide ${
                    index === 0 && imageSrc !== darkImageLoadingPlaceholder
                      ? 'slick-current slick-active'
                      : ''
                  }`}
                  style={{ cursor: 'pointer' }}
                >
                  <AutoImgBox
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <NewCarDetailPageImages
                      src={imageSrc ? imageSrc : darkImageLoadingPlaceholder}
                      onClick={setActive}
                      defaultSrc={darkImageLoadingPlaceholder}
                      alt={`${selectedModel.slug}-cover-image-${index}`}
                      style={{
                        margin: '0',
                        height: '100%',
                        maxWidth: '85px',
                        maxHeight: '82px',
                        borderRadius: '8px',
                      }}
                    />
                  </AutoImgBox>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </SliderHolder>
  );
};

SliderSection.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  coverImageSrc: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  darkImageLoadingPlaceholder: PropTypes.string.isRequired,
  style: PropTypes.object,
  isLoading: PropTypes.bool,
};

SliderSection.defaultProps = {
  style: {},
};
