import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useBikeBrands } from '../../../common/hooks/bikes';
import MetaTag from '../../../common/meta/MetaTag';
import { Container } from 'reactstrap';
import { Grid } from '@mui/material';
import BrandCard from '../../Listings/brands/components/BrandCard';
import { NewBikesBrandsSkeleton } from './NewBikesSkeletons';
import {
  BrowseBrandsSection,
  MainHeadingH1,
} from '../../../common/styles/CommonStyles';

const NewBikesBrands = () => {
  const { trans, lang, country } = useContext(AppContext);

  const { data: brands, isLoading: isLoadingBrands } = useBikeBrands({
    params: { type: 'new' },
  });

  if (isLoadingBrands) {
    return (
      <>
        <MetaTag metaKey="bikeBrands" href={`/${country}/${lang}/new-bikes`} />
        <NewBikesBrandsSkeleton trans={trans} />
      </>
    );
  }

  return (
    <>
      <MetaTag metaKey="bikeBrands" href={`/${country}/${lang}/new-bikes`} />
      <main id="main">
        <div className="container newcars" style={{ paddingTop: '32px' }}>
          <BrowseBrandsSection className="container">
            <MainHeadingH1>{trans.browse_bikes_by_brands}</MainHeadingH1>
            <Container
              style={{
                paddingBottom: '16px',
              }}
            >
              <Grid container spacing={2}>
                {brands &&
                  brands.length > 0 &&
                  brands.map(brand => (
                    <Grid
                      key={brand.id}
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      lg={2}
                      xl={2}
                    >
                      <BrandCard
                        key={brand.id}
                        redirectionUrl={`/${country}/${lang}/new-bikes/${brand.slug}`}
                        brand={brand}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Container>
          </BrowseBrandsSection>
        </div>
      </main>
    </>
  );
};

export default NewBikesBrands;
