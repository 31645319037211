import authenticatedApi from '../../../instance/ApiInstance';
import { IBikeTrim } from '../../../types/resources/bikes/trims';

const bikeTrimsEndpoint = '/v2/resources/bikes/trims';

export interface IGetBikeTrims {
  enabled?: boolean;
  model?: number;
}

export const getBikeTrims = async ({
  model,
}: IGetBikeTrims): Promise<IBikeTrim[]> => {
  const response = await authenticatedApi.get(bikeTrimsEndpoint, {
    params: {
      bike_model_id: model,
    },
  });
  return response.data;
};
