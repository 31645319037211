import { useContext } from 'react';
import { IUsedBike } from '../../../types/bikes';
import { AppContext } from '../../../contexts/AppContext';
import { CarGridCarouselSkeleton } from '../../UsedCars/UsedCarsSkeletons';
import { BikeGridCard } from './BikeGridCard';
import Carousel from 'react-multi-carousel';

type FeaturedBikesCarouselProps = {
  featuredBikes: Array<IUsedBike>;
  favorite: Array<number>;
  onFavouriteClick: (e: any, bikeId: number) => void;
  isLoading: boolean;
};

export const FeaturedBikesCarousel = ({
  favorite,
  featuredBikes,
  isLoading,
  onFavouriteClick,
}: FeaturedBikesCarouselProps) => {
  const { lang } = useContext(AppContext);

  const rtl = lang == 'ar' ? true : false;

  const responsiveFeatured = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  const generateBikeRedirectionLink = (usedCar: IUsedBike) =>
    `/${lang}/used-bikes/${usedCar.bike_model?.brand.slug}/${usedCar.bike_model?.slug}/${usedCar.year}/${usedCar.id}`;

  if (isLoading && !featuredBikes) {
    return (
      <CarGridCarouselSkeleton responsive={responsiveFeatured} rtl={rtl} />
    );
  }

  return (
    <Carousel
      autoPlay
      responsive={responsiveFeatured}
      swipeable={true}
      draggable={true}
      infinite={true}
      rtl={rtl}
      customLeftArrow={<div className="swiper-button-prev"></div>}
      customRightArrow={<div className="swiper-button-next"></div>}
    >
      {featuredBikes.map((featuredCar: IUsedBike) => (
        <BikeGridCard
          key={Math.random()}
          bike={featuredCar}
          isFavorite={
            favorite &&
            favorite.find((f: any) => f === featuredCar.id) !== undefined
          }
          onFavoriteClick={onFavouriteClick}
          redirectionLink={generateBikeRedirectionLink(featuredCar)}
          isLoading={isLoading}
        />
      ))}
    </Carousel>
  );
};
