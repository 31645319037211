import { useContext } from 'react';
import CarouselTypeFilters from '../../Listings/common/CarouselTypeFilters';
import { AppContext } from '../../../contexts/AppContext';
import FeaturedRentalsCarousel from './FeaturedRentalsCarousel';
import { IRentalCar } from '../../../types/cars/rental_car';
import {
  BtnViewHolderView,
  CarsSliderBlock,
  HeaderHolder,
  HomeUsedCars,
  InfoBlock,
  InfoBlockBtnViewHolder,
  InfoBlockH2,
  ViewCarsBox,
  ViewCarsBoxBtn,
  ViewCarsBoxBtnImg,
  ViewCarsBoxTitle,
  ViewCarsBoxWrap,
} from '../../../common/styles/CommonStyles';

interface IRentalCarouselProps {
  rentals: Array<IRentalCar> | undefined;
  carouselTitle: string;
  goToRentalsTitle: string;
  allRentalsPageUrl: string;
  selectedFilter: any;
  setSelectedFilter: (filter: any) => void;
  isLoading: boolean;
}

export const CarouselWithTypeFilter = ({
  rentals,
  carouselTitle,
  goToRentalsTitle,
  allRentalsPageUrl,
  selectedFilter,
  setSelectedFilter,
  isLoading,
}: IRentalCarouselProps) => {
  const { trans } = useContext(AppContext);

  const responsiveBreakPoints = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <InfoBlock className="tabs">
        <HeaderHolder className="style">
          <InfoBlockH2>{carouselTitle}</InfoBlockH2>
          <CarouselTypeFilters
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        </HeaderHolder>
        <ViewCarsBox>
          <ViewCarsBoxWrap>
            <ViewCarsBoxTitle>{goToRentalsTitle}</ViewCarsBoxTitle>
            <ViewCarsBoxBtn to={allRentalsPageUrl}>
              <ViewCarsBoxBtnImg src="/images/chevron-right.png" alt="img" />
            </ViewCarsBoxBtn>
          </ViewCarsBoxWrap>
        </ViewCarsBox>
        <CarsSliderBlock className="slider-holder.style">
          <HomeUsedCars>
            <FeaturedRentalsCarousel
              featuredRentals={rentals}
              isLoading={isLoading}
              responsiveBreakPoints={responsiveBreakPoints}
            />
          </HomeUsedCars>
        </CarsSliderBlock>
        <InfoBlockBtnViewHolder>
          <BtnViewHolderView to={allRentalsPageUrl}>
            {trans.view_all}
          </BtnViewHolderView>
        </InfoBlockBtnViewHolder>
      </InfoBlock>
    </>
  );
};
