import authenticatedApi from '../../../instance/ApiInstance';
import { IUsedCar } from '../../../types/cars/used_car';
import { IMetadata } from '../../../types/common/metadata';
import { TFilters } from '../../Listings/common/ListingFiltersBar';

const usedCarsEndpoint = 'v2/cars/used-cars';
const usedCarsAnalyticsEndpoint = 'v2/analytics/used-car-analytics';
const vinDecoderEndPoint = 'v2/cars/used-cars/vin';
const priceValuationEndPoint = 'v2/cars/used-cars/PriceValuation';

export interface IGetUsedCars extends Partial<TFilters> {
  page: number;
  perPage: number;
  is_featured?: boolean;
  sortBy?: string;
  sortDirection?: string;
  status?: string;
  city?: number[];
  lang?: string;
  search?: string;
}

export interface ICreateUsedCar {
  year: number;
  description: string;
  whatsapp_number: string;
  mobile_number: string;
  call_to_action: string;
  mileage: number;
  price: number;
  doors: number;
  model_id: number;
  city_id: number;
  type_id: number;
  transmission_id: number;
  engine_type_id: number;
  engine_size_id: number;
  battery_capacity_id: number;
  cylinder_id: number;
  battery_range_id: number;
  exterior_color_id: number;
  interior_color_id: number;
  condition_id: number;
  seat_material_id: number;
  variant_id: number;
  optional_features_ids: number[];
  add_images: Array<{ id: number }>;
}

export interface IUpdateUsedCar {
  year?: number;
  description?: string;
  whatsapp_number?: string;
  mobile_number?: string;
  call_to_action?: string;
  mileage?: number;
  price?: number;
  doors?: number;
  model_id?: number;
  city_id?: number;
  type_id?: number;
  transmission_id?: number;
  engine_type_id?: number;
  engine_size_id?: number;
  battery_capacity_id?: number;
  cylinder_id?: number;
  battery_range_id?: number;
  exterior_color_id?: number;
  interior_color_id?: number;
  condition_id?: number;
  seat_material_id?: number;
  variant_id?: number;
  optional_features_ids?: number[];
  add_images?: Array<{ id: number }>;
  remove_images?: Array<{ url: string }>;
}

export interface IPriceValuation {
  market: string;
  vin: string;
  km?: number;
}

export const getUsedCars = async (
  params: IGetUsedCars,
): Promise<{ data: IUsedCar[]; meta: IMetadata }> => {
  const response = await authenticatedApi.get(usedCarsEndpoint, {
    params,
  });
  return response.data;
};

export const getFavoriteUsedCars = async (): Promise<number[]> => {
  try {
    const response = await authenticatedApi.get('v2/favorites');
    const data = response.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const uploadUsedCarImage = async ({
  file,
  onProgress,
}: {
  file: File;
  onProgress?: (progress: { percent: number }) => void;
}) => {
  const response = await authenticatedApi.post(
    `v2/image/used-car-image`,
    {
      image: file,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: ({ total, loaded }) => {
        if (onProgress && total)
          onProgress({
            percent: (loaded / total) * 100,
          });
      },
    },
  );
  return response.data;
};

export const createUsedCar = async (data: ICreateUsedCar) => {
  try {
    const response = await authenticatedApi.post(usedCarsEndpoint, data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateUsedCar = async (id: number, data: IUpdateUsedCar) => {
  try {
    const response = await authenticatedApi.put(
      `${usedCarsEndpoint}/${id}`,
      data,
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const UsedCarsPageView = async (id: number) => {
  try {
    const response = await authenticatedApi.post(usedCarsAnalyticsEndpoint, {
      used_car_id: id,
      event: 'view',
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const UsedCarsPageClick = async (id: number, event: string) => {
  try {
    const response = await authenticatedApi.post(usedCarsAnalyticsEndpoint, {
      used_car_id: id,
      event: event,
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const VinDecoder = async (vin: string) => {
  try {
    const response = await authenticatedApi.get(`${vinDecoderEndPoint}/${vin}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadVinDecoderIamge = async (file: File) => {
  try {
    const response = await authenticatedApi.post(
      `v2/document-ai/ocr`,
      {
        image: file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const PriceValuation = async (priceValuation: IPriceValuation) => {
  try {
    const response = await authenticatedApi.post(
      priceValuationEndPoint,
      priceValuation,
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
