import { useQuery } from 'react-query';
import { TRentalFeatured, getFeaturedRentals } from '../apis';
import { AppContext } from '../../../contexts/AppContext';
import { useContext } from 'react';

export const useFeaturedRentals = ({
  searchQueryParams,
  page,
  perPage,
}: TRentalFeatured) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['featured-rentals', searchQueryParams, page, perPage, lang],
    queryFn: () => getFeaturedRentals({ searchQueryParams, page, perPage }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};
