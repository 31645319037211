import { useQuery } from 'react-query';
import { RentalDealerName } from '../classes/RentalDealer';
import { getRentalDealerNames } from '../apis/rentalDealer.api';

export const useRentalDealerNames = () => {
  return useQuery({
    queryKey: ['rental-dealer-names'],
    queryFn: (): Promise<RentalDealerName[]> => getRentalDealerNames(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
