import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';

type HamburgerButtonProps = {
  onChangeLanguage: () => void;
};

const HamburgerButton = ({ onChangeLanguage }: HamburgerButtonProps) => {
  const { lang, trans } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageButtonClick = () => {
    handleClose();
    onChangeLanguage();
  };

  return (
    <div className="burger-menu-button">
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          width: 'fit-content',
          backgroundColor: 'transparent',
          '&:focus': { outline: 'none' },
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0,0,255.99998,255.99998"
          width="30px"
          height="30px"
          fill-rule="nonzero"
        >
          <g
            fill="#ffffff"
            fillRule="nonzero"
            stroke="none"
            strokeWidth="1"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="10"
            strokeDasharray=""
            strokeDashoffset="0"
            fontFamily="none"
            fontWeight="none"
            fontSize="none"
            textAnchor="none"
            style={{ mixBlendMode: 'normal' }}
          >
            <g transform="scale(8.53333,8.53333)">
              <path d="M3,7c-0.36064,-0.0051 -0.69608,0.18438 -0.87789,0.49587c-0.18181,0.3115 -0.18181,0.69676 0,1.00825c0.18181,0.3115 0.51725,0.50097 0.87789,0.49587h24c0.36064,0.0051 0.69608,-0.18438 0.87789,-0.49587c0.18181,-0.3115 0.18181,-0.69676 0,-1.00825c-0.18181,-0.3115 -0.51725,-0.50097 -0.87789,-0.49587zM3,14c-0.36064,-0.0051 -0.69608,0.18438 -0.87789,0.49587c-0.18181,0.3115 -0.18181,0.69676 0,1.00825c0.18181,0.3115 0.51725,0.50097 0.87789,0.49587h24c0.36064,0.0051 0.69608,-0.18438 0.87789,-0.49587c0.18181,-0.3115 0.18181,-0.69676 0,-1.00825c-0.18181,-0.3115 -0.51725,-0.50097 -0.87789,-0.49587zM3,21c-0.36064,-0.0051 -0.69608,0.18438 -0.87789,0.49587c-0.18181,0.3115 -0.18181,0.69676 0,1.00825c0.18181,0.3115 0.51725,0.50097 0.87789,0.49587h24c0.36064,0.0051 0.69608,-0.18438 0.87789,-0.49587c0.18181,-0.3115 0.18181,-0.69676 0,-1.00825c-0.18181,-0.3115 -0.51725,-0.50097 -0.87789,-0.49587z"></path>
            </g>
          </g>
        </svg>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ '& ul': { padding: 0 } }}
      >
        {lang == 'en' ? (
          <MenuItem
            sx={{
              backgroundColor: '#151515',
              color: '#ffffff',
            }}
            onClick={handleLanguageButtonClick}
          >
            {trans.arabic}
          </MenuItem>
        ) : (
          <MenuItem
            sx={{
              backgroundColor: '#151515',
              color: '#ffffff',
            }}
            onClick={handleLanguageButtonClick}
          >
            {trans.english}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default HamburgerButton;
