import authenticatedApi from '../../../instance/ApiInstance';
import { IOptionalFeature } from '../../../types/resources/cars';

const optionalFeaturesEndpoint = '/v2/resources/bikes/optional-features';

export const getBikeOptionalFeatures = async (): Promise<
  IOptionalFeature[] | undefined
> => {
  try {
    const response = await authenticatedApi.get(optionalFeaturesEndpoint);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
