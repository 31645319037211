import authenticatedApi from '../../../instance/ApiInstance';
import { IArticle } from '../../../types/articles/article';
import { IMetadata } from '../../../types/common/metadata';

const brandsEndpoint = '/v2/articles';

export interface IGetArticles {
  searchQueryParams?: Record<string, string>;
  page?: number;
  perPage?: number;
}

export const getArticles = async ({
  searchQueryParams,
  page,
  perPage,
}: IGetArticles): Promise<{ data: IArticle[]; meta: IMetadata }> => {
  searchQueryParams = {
    ...searchQueryParams,
    is_active: 'true',
  };

  const response = await authenticatedApi.get(brandsEndpoint, {
    params: {
      ...searchQueryParams,
      page,
      per_page: perPage,
    },
  });

  return response.data;
};

export const getArticlesById = async (id: number) => {
  const response = await authenticatedApi.get(`${brandsEndpoint}/${id}`);

  return response.data;
};

export const getArticlesBySlug = async (slug: string) => {
  const response = await authenticatedApi.get(`${brandsEndpoint}/${slug}`);

  return response.data;
};

export const getLast4CreatedArticles = async (skip?: string) => {
  const response = await authenticatedApi.get(
    `${brandsEndpoint}/more-articles`,
    {
      params: {
        skip,
        limit: 4,
      },
    },
  );

  return response.data;
};
