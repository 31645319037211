import authenticatedApi from '../../../instance/ApiInstance';
import { INewCar } from '../../../types/cars/new_car';

const newCarsEndpoint = '/v2/cars/new-cars';

export interface IGetModelDealsParams {
  model_id?: number;
}

export const getModelDeals = async (
  params?: IGetModelDealsParams,
): Promise<INewCar[]> => {
  const response = await authenticatedApi.get(newCarsEndpoint, {
    params,
  });
  const data = response.data;
  return data;
};
