import { useNavigate } from 'react-router';
import { ReviewCard } from './ReviewCard/ReviewCard';
import PagingComponent from '../../Other/PagingComponent';
import { ResultsNotFound } from '../../common';
import { ReviewsSkeletons } from './ReviewsSkeletons';
import {
  ColLg12,
  FeaturedDetailsPage,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  MainHeadingH1,
  Row,
} from '../../../common/styles/CommonStyles';
import { ReviewCardStyled, ReviewsContainer } from './ReviewsStyles';
import { IReview } from '../../../types/reviews';

export const ReviewsBodyComponent = ({
  trans,
  reviews,
  lang,
  isLoading,
  noResults,
  setCurrentPage,
  setReviewsPage,
  reviewsPage,
  country,
}: {
  trans: any;
  reviews: any;
  lang: string;
  isLoading: boolean;
  noResults: boolean | undefined;
  setCurrentPage: (page: number) => void;
  setReviewsPage: (page: number) => void;
  reviewsPage: number;
  country: string | null;
}) => {
  const navigate = useNavigate();

  if (isLoading) {
    return <ReviewsSkeletons trans={trans} />;
  }

  return (
    <>
      <MainHead>
        <MainHeadContainer className="container">
          <Row>
            <ColLg12>
              <h1>
                <MainHeadH1>{trans.reviews}</MainHeadH1>
              </h1>
            </ColLg12>
          </Row>
        </MainHeadContainer>
      </MainHead>
      <FeaturedDetailsPage className="container reviews">
        <ReviewsContainer>
          {reviews?.data.map((review: IReview, index: number) => (
            <ReviewCardStyled>
              <ReviewCard
                key={review.id}
                lang={lang}
                trans={trans}
                review={review}
                index={index}
                onClick={() => {
                  navigate(`/${country}/${lang}/review/${review.slug}`);
                }}
                style={{
                  width: '100%',
                  minHeight: '150px',
                  maxHeight: '200px',
                  objectFit: 'cover',
                  borderRadius: '5px',
                }}
                isLoading={isLoading}
              />
            </ReviewCardStyled>
          ))}
        </ReviewsContainer>
        {reviews && reviews?.meta && (
          <span className="pagination-middle">
            <PagingComponent
              total={reviews?.meta?.total}
              pagesCount={reviews?.meta?.lastPage}
              pageSize={reviews?.meta?.perPage}
              pageChangeHandler={(page: number) => {
                setCurrentPage(page + 1);
                setReviewsPage(page);
                navigate(`/${lang}/reviews?page=${page}`);
              }}
              currentPage={reviewsPage}
            />
          </span>
        )}
        {noResults && (
          <ResultsNotFound lang={lang} trans={trans} country={country} />
        )}
      </FeaturedDetailsPage>
    </>
  );
};
