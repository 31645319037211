import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useDeactivateAccount } from '../../../services/hooks/useApiService';
import '../AccountDetails/styles.css';

interface AccountDeactivationConfirmationModelProps {
  hideModal: () => void;
}

export const AccountDeactivationConfirmationModel = (
  props: AccountDeactivationConfirmationModelProps,
) => {
  const { lang, trans, country } = useContext(AppContext);
  const { mutateAsync: deactivateMe } = useDeactivateAccount();

  const deactivateAccount = async () => {
    await deactivateMe();
    localStorage.clear();
    window.location.href = `/${country}/${lang}`;
  };

  return (
    <>
      <div
        className="modal fade modal-center advance-search-modal close-modal show"
        id="removelisting"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="usedcar"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-center-style">
          <div className="modal-content">
            <div className="modal-body">
              <p>{trans.deactivateAccountConfirmationMessage}</p>
            </div>
            <div className="modal-footer d-flex flex-row justify-content-center modal-action-buttons">
              <button
                className="btn btn-primary"
                type="submit"
                onClick={deactivateAccount}
              >
                {trans.confirm}
              </button>
              <button
                className="btn btn-primary cancel"
                aria-label="Close"
                onClick={props.hideModal}
              >
                {trans.cancel}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};
