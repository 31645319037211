import parse from 'html-react-parser';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useBrands } from '../../../common/hooks/brand.hooks.';
import { useModelsForUsedCars } from '../../../common/hooks/model.hooks';
import { useGetCarTypes } from '../../../common/hooks/type.hooks';
import { useTransmissionNames } from '../../../common/hooks/transmission.hooks';
import { useRentalDealerNames } from '../../../common/hooks/rentalDealer.hooks';
import { IModel } from '../../../types/resources/cars';
import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { IRentalSearch } from '../apis';
import '../../User/SellYourCar/sellYourCar-styles.css';
import '../../../styles/css/rentalFilter-styles.css';
import { CarsFormHolder } from '../../Dealers/components';
import { Row } from '../../../common/styles/CommonStyles';
import '../../../styles/css/filters-styles.css';

const ITEM_HEIGHT = 48;
export const MenuProps = {
  PaperProps: {
    sx: {
      color: '#4E4E4E',
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + 8,
      width: 200,
    },
  },
};

export const smallMenuProps = {
  PaperProps: {
    sx: {
      color: '#4E4E4E',
    },
    style: {
      maxHeight: ITEM_HEIGHT * 10 + 8,
      width: 80,
    },
  },
  MenuListProps: {
    style: {
      padding: 0,
    },
    sx: {
      '& li': {
        fontSize: '12px',
      },
    },
  },
};

export const selectStyle = {
  color: '#4E4E4E',
};

export const smallSelectStyle = {
  fontSize: '11px',
  padding: 0,
};

export type TRenatlCarsFilter = Omit<IRentalSearch, 'page' | 'perPage'>;

export type TRentalFilters = {
  brandIds: number[];
  modelIds: number[];
  types: number[];
  maxPrice: number;
  minPrice: number;
  companies?: number[];
  transmissions?: number;
  seats?: number[];
  largeBaggageSizes?: number[];
  smallBaggageSizes?: number[];
};

export function generateOptions(highestNumber: number): {
  id: number;
  value: string;
}[] {
  const options: any = [];
  for (let i: number = 10000; i <= highestNumber; i += 10000) {
    options.push({ id: i, value: i.toLocaleString() });
  }

  return options;
}

interface IRentalFilterInterface {
  setShowAdvancedFilter: Dispatch<SetStateAction<boolean>>;
  filters: TRentalFilters;
  setFilters: Dispatch<SetStateAction<TRentalFilters>>;
  handleOnSearch: () => void;
  handleReset: () => void;
}

function RentalsFilter({
  setShowAdvancedFilter,
  filters,
  setFilters,
  handleOnSearch,
  handleReset,
}: IRentalFilterInterface) {
  //#region States
  const { trans, lang } = React.useContext(AppContext);

  const { data: brands } = useBrands({});

  const { data: models, isFetching: isFetchingModels } = useModelsForUsedCars({
    brandIds: filters.brandIds,
  });

  const { data: types } = useGetCarTypes();

  const { data: transmissions } = useTransmissionNames();

  const { data: rentalDealersNames } = useRentalDealerNames();

  const availableTypes = useMemo(() => {
    // Get selected models
    const selectedModels = models?.filter(model =>
      filters.modelIds?.includes(model.id),
    );

    // Get types of selected models
    const types = selectedModels?.map(model => model.types);

    // Get available types
    const availableTypes: IModel['types'] = [];
    types?.forEach(type => {
      type.forEach(t => {
        if (!availableTypes.find(availableType => availableType.id === t.id)) {
          availableTypes.push(t);
        }
      });
    });

    return availableTypes;
  }, [filters.modelIds]);

  useEffect(() => {
    setFilters({
      ...filters,
      types: filters.types?.filter(typeId =>
        availableTypes.find(type => type.id === typeId),
      ),
    });
  }, [availableTypes]);

  //#endregion

  const handleBrandChange = (
    event: SelectChangeEvent<typeof filters.brandIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        brandIds: value,
        modelIds: filters.modelIds?.filter(modelId => {
          const model = models?.find(model => model.id === modelId);
          return model ? value.includes(model.brand_id) : false;
        }),
      });
    }
  };

  const handleModelChange = (
    event: SelectChangeEvent<typeof filters.modelIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        modelIds: value,
      });
    }
  };

  const handleTypeChange = (event: SelectChangeEvent<typeof filters.types>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        types: value,
      });
    }
  };

  const handleTransmissionChange = (
    event: SelectChangeEvent<typeof filters.transmissions>,
  ) => {
    const {
      target: { value },
    } = event;

    setFilters({
      ...filters,
      transmissions: value as number,
    });
  };

  const handleCompanyChange = (
    event: SelectChangeEvent<typeof filters.companies>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        companies: value,
      });
    }
  };

  const renderBrandValue = (selected: Array<string | number>) => {
    if (!selected || selected.length === 0) {
      return trans.any;
    }

    return selected
      .map(id => {
        const brand = brands?.find(brand => brand.id === id);
        return brand?.name;
      })
      .join(', ');
  };

  const renderModelValue = (selected: Array<string | number>) => {
    if (isFetchingModels) return <p>Loading...</p>;
    if (selected.length === 0) {
      return trans.any;
    }

    const filteredModels = models?.filter(model => selected.includes(model.id));
    return filteredModels?.map(model => model.name).join(', ');
  };

  const typesToRender = useMemo(() => {
    if (availableTypes.length === 0 && !types) return [];

    return availableTypes.length > 0 ? availableTypes : types;
  }, [availableTypes, types]);

  const renderTypeValue = (selected: Array<string | number>) => {
    if (selected.length === 0) {
      return trans.any;
    }

    return selected
      .map(id => {
        const type = typesToRender?.find(type => type.id === id);
        return type?.name;
      })
      .join(', ');
  };

  const renderTransmissionValue = (selected: string | number) => {
    const transmission = transmissions?.find(
      transmission => transmission.id === selected,
    );
    return transmission?.name || trans.any;
  };

  const renderCompanyValue = (selected: Array<string | number>) => {
    if (!selected || selected.length === 0) {
      return trans.any;
    }

    return selected
      .map(id => {
        const dealer = rentalDealersNames?.find(dealer => dealer.id === id);
        return dealer?.name;
      })
      .join(', ');
  };

  return (
    <div className="row">
      <div className="col-12">
        <CarsFormHolder className="searchcar">
          <Row className="cars-form-holder">
            <div className="col-xl-5 col-md-12 form-title search">
              <h2>{parse(trans.usedcar_search)}</h2>
            </div>
            <div className="col-xl-7 col-md-12">
              <form
                style={{ alignItems: 'center' }}
                className="row cars-selection-form"
              >
                {/* BRAND */}
                <div className="col-xl-4 col-md-4 form-group">
                  <label
                    htmlFor="brands"
                    style={{
                      fontSize: '15px',
                    }}
                  >
                    {trans.brand}
                  </label>
                  <Select
                    id="brands"
                    multiple
                    value={filters?.brandIds}
                    onChange={handleBrandChange}
                    className="custom-select"
                    renderValue={renderBrandValue}
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                    disabled={!brands}
                  >
                    {brands?.map(brand => (
                      <MenuItem key={brand.id} value={brand.id}>
                        <ListItemText primary={brand.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* MODEL */}
                <div className="col-xl-4 col-md-4 form-group">
                  <label
                    htmlFor="brands"
                    style={{
                      fontSize: '15px',
                    }}
                  >
                    {trans.model}
                  </label>
                  <Select
                    id="models"
                    multiple
                    value={filters.modelIds}
                    onChange={handleModelChange}
                    className="custom-select mui-fixed"
                    renderValue={renderModelValue}
                    MenuProps={MenuProps}
                    displayEmpty
                    disabled={!models}
                    style={selectStyle}
                  >
                    {models?.map(model => (
                      <MenuItem key={model.id} value={model.id}>
                        <ListItemText primary={model.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* TYPE */}
                <div className="col-xl-4 col-md-4 form-group">
                  <label
                    htmlFor="brands"
                    style={{
                      fontSize: '15px',
                    }}
                  >
                    {trans.type}
                  </label>
                  <Select
                    id="types"
                    multiple
                    value={filters.types}
                    onChange={handleTypeChange}
                    className="custom-select"
                    renderValue={renderTypeValue}
                    disabled={!typesToRender || typesToRender.length === 0}
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                  >
                    {typesToRender?.map(type => (
                      <MenuItem key={type.id} value={type.id}>
                        <ListItemText primary={type.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* Transmission */}
                <div className="col-md-4 col-sm-6 form-group">
                  <label
                    htmlFor="transmissions"
                    style={{
                      fontSize: '15px',
                    }}
                  >
                    {trans.transmission}
                  </label>
                  <Select
                    id="transmissions"
                    value={filters.transmissions}
                    onChange={handleTransmissionChange}
                    className="custom-select"
                    renderValue={value => {
                      return renderTransmissionValue(
                        value as unknown as number,
                      );
                    }}
                    disabled={!transmissions || transmissions.length === 0}
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                  >
                    {transmissions?.map(transmission => (
                      <MenuItem key={transmission.id} value={transmission.id}>
                        <ListItemText primary={transmission.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* DEALERS */}
                <div className="col-xl-4 col-md-4 form-group">
                  <label
                    htmlFor="companies"
                    style={{
                      fontSize: '15px',
                    }}
                  >
                    {trans.company}
                  </label>
                  <Select
                    id="companies"
                    multiple
                    value={filters.companies}
                    onChange={handleCompanyChange}
                    className="custom-select"
                    renderValue={renderCompanyValue}
                    disabled={
                      !rentalDealersNames || rentalDealersNames.length === 0
                    }
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                  >
                    {rentalDealersNames?.map(dealer => (
                      <MenuItem key={dealer.id} value={dealer.id}>
                        <ListItemText primary={dealer.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* SEARCH */}
                <div
                  className="col-xl-12 form-group buttons d-flex justify-content-end flex-column align-items-end"
                  style={{
                    maxHeight: '50px',
                  }}
                >
                  <div className="d-flex flex-column flex-nowrap justify-content-center align-items-center">
                    <button
                      onClick={handleOnSearch}
                      type="button"
                      className="btn btn-primary"
                      style={{
                        marginRight: 0,
                        marginBottom:
                          window.innerWidth < 768
                            ? lang === 'en'
                              ? '-5px'
                              : lang === 'ar'
                              ? '-10px'
                              : '0'
                            : '0',
                      }}
                    >
                      {trans.search}
                    </button>

                    <button
                      type="button"
                      className="btn btn-advance adv-search"
                    ></button>
                    {/* ADVANCED SEARCH */}
                    <button
                      type="button"
                      className="btn btn-advance adv-search"
                      onClick={() => setShowAdvancedFilter(true)}
                    >
                      {trans.advanced_search}
                    </button>
                  </div>
                </div>
                {/* RESET */}
                <button
                  onClick={handleReset}
                  className={
                    lang == 'en' ? 'btn-reset' : 'btn-reset arabic-reset-filter'
                  }
                  type="button"
                >
                  <i className="fa fa-repeat"></i>
                </button>
              </form>
            </div>
          </Row>
        </CarsFormHolder>
      </div>
    </div>
  );
}

export default RentalsFilter;
