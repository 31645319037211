import { Button, TextField, Typography, styled } from '@mui/material';
import PhoneInput from 'react-phone-input-2';

const commonStyles = {
  height: '50px',
  borderRadius: '8px',
  border: '1px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '8px',
    height: '52px',
    width: '100%',
  },
  '@media screen and (max-width: 992px)': {
    width: '100%',
  },
};

export const StyledTextField = styled(TextField)(() => ({
  ...commonStyles,
  '& .MuiFormHelperText-root': {
    color: 'green',
    '.lang-au &': {
      alignSelf: 'flex-start',
    },
  },
}));

export const StyledPhoneInput = styled(PhoneInput)(() => ({
  ...commonStyles,
  input: {
    height: '50px !important',
  },
}));

export const StyledH4 = styled('h4')(() => ({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '28px',
}));

export const StyledH3 = styled('h3')(() => ({
  fontSize: '24px',
  lineHeight: '28px',
  fontWeight: '500',
  height: '17px',
}));

export const StyledH2 = styled('h2')(() => ({
  fontSize: '25.5px',
  fontWeight: '700',
  lineHeight: '30.6px',
}));

export const StyledUl = styled('ul')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gridRowGap: '25.5px',
  alignSelf: 'center',
  gap: '25.5px',
  '@media screen and (max-width: 992px)': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  '@media screen and (max-width: 680px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@media screen and (max-width: 552px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

export const StyledDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
}));

export const StyledDivColumn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const UploadPhotoContainer = styled('div')(() => ({
  padding: '35px 110px 35px 110px',
  gap: ' 8px',
  borderRadius: '9px',
  border: '1px solid var(--stroke, #D1D1D1)',
  justifyContent: 'center',
  display: 'flex',
}));

export const UploadPhotoContainer2 = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '258px',
  height: '113px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2px',
}));

export const UploadPhotoContainer2Label = styled('label')(() => ({
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
  width: '200px',
  justifyContent: 'center',
}));

export const UploadPhotoImg = styled('img')(() => ({
  width: '74px',
  height: '64px',
  left: '92px',
}));

export const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '18px',
  '@media screen and (max-width: 992px)': {
    fontSize: '10px',
  },
}));

export const StyledSpan1 = styled('span')(() => ({
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '18px',
  cursor: 'pointer',
  color: '#1F4690',
  '@media screen and (max-width: 992px)': {
    fontSize: '10px',
  },
}));

export const StyledSpan2 = styled('span')(() => ({
  fontSize: '8px',
  fontWeight: '400',
  lineHeight: '12px',
  color: '#969DB2',
}));

export const UploadInProgressContainer = styled('div')(() => ({
  padding: '9px 12px 9px 12px',
  gap: '12px',
  borderRadius: '6px',
  border: '1px solid #D1D1D1',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '235px',
  overflowY: 'auto',
  overflowX: 'hidden',
}));

export const UploadInProgressContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '1520px',
  justifyContent: 'space-between',
  '@media screen and (max-width: 1200px)': {
    width: '1480px',
  },
  '@media screen and (max-width: 992px)': {
    width: '1000px',
  },
  '@media screen and (max-width: 722px)': {
    width: '950px',
  },
  '@media screen and (max-width: 795px)': {
    width: '900px',
  },
  '@media screen and (max-width: 680px)': {
    width: '800px',
  },
  '@media screen and (max-width: 630px)': {
    width: '750px',
  },
  '@media screen and (max-width: 600px)': {
    width: '700px',
  },
  '@media screen and (max-width: 575px)': {
    width: '600px',
  },
  '@media screen and (max-width: 530px)': {
    width: '500px',
  },
  '@media screen and (max-width: 480px)': {
    width: '450px',
  },
  '@media screen and (max-width: 454px)': {
    width: '400px',
  },
  '@media screen and (max-width: 400px)': {
    width: '100%',
  },
}));

export const UploadInProgressLeft = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
}));

export const UploadInProgressLeftImg = styled('img')(() => ({
  width: '64px',
  height: '64px',
  borderRadius: '8px',
}));

export const UploadInProgressLeftContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const UploadInProgressLeftContentImgName = styled('span')(() => ({
  fontSize: '14px',
  fontWeight: '500',
  color: '#132A60',
  lineHeight: '21px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '104px',
}));

export const UploadInProgressLeftContentImgSize = styled('span')(() => ({
  fontSize: '14px',
  fontWeight: '400',
  color: '#969DB2',
  lineHeight: '21px',
}));

export const UploadInProgressRemoveImg = styled('img')(() => ({
  width: '20px',
  height: '20px',
  borderRadius: '33px',
  cursor: 'pointer',
}));

export const ProgressBarContainer = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
}));

export const ProgressBar = styled('div')(() => ({
  height: '8px',
  width: '100%',
  backgroundColor: '#E2E8F0',
  borderRadius: '8px',
  marginTop: '16px',
}));

export const ProgressBarFill = styled('div')<{
  progress: number;
}>(({ progress }) => ({
  height: '8px',
  width: `${progress}%`,
  backgroundColor: '#00D6BC',
  borderRadius: '8px',
}));

export const ProgressPercentage = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '8px',
}));

export const ProgressPercentageSpan = styled('span')(() => ({
  fontSize: '12px',
  fontWeight: '400',
  color: '#132A60',
}));

export const UploadedImagesContaier = styled('div')(() => ({
  borderRadius: '6px',
  border: '1px solid  #D1D1D1',
  padding: '9px 12px 9px 12px',
  gap: '12px',
  height: '240px',
  display: 'flex',
  overflowY: 'hidden',
  overflowX: 'auto',
}));

export const UploadedImagesContent = styled('div')(() => ({
  width: '120px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const UploadedImagesContaierImg = styled('img')(() => ({
  width: '120px',
  height: '120px',
  borderRadius: '6px',
  objectFit: 'cover',
}));

export const UploadInProgressRemoveImg2 = styled('img')(() => ({
  width: '20px',
  height: '20px',
  borderRadius: '33px',
  cursor: 'pointer',
  position: 'relative',
  top: '25px',
  left: '40px',
  '@media screen and (max-width: 600px)': {
    left: '35%',
  },
}));

export const AdditionalInfoHeaderContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const AdditionalInfoHeaderP = styled('p')(() => ({
  fontSize: '12px',
  fontWeight: '400',
  color: ' #161616',
  lineHeight: '18px',
  opacity: '56%',
}));

export const AdditionalInfoContent = styled(TextField)(() => ({
  height: '191px',
  gap: '8px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '9px',
  },
}));

export const ConfirmCancelContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left',
  gap: '16px',
  alignItems: 'center',
  '@media screen and (max-width: 992px)': {
    flexDirection: 'column',
  },
  '.lang-au &': {
    justifyContent: 'right',
  },
}));

export const SellYourCarButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'center',
  height: '64px',
  borderRadius: '12px',
  backgroundColor: '#00D6BC',
  color: 'white',
  padding: '6px, 18px, 6px, 18px',
  width: '841px',
  border: '1px solid #00A187',
  ':hover': {
    backgroundColor: '#00A187',
  },
  '@media screen and (max-width: 992px)': {
    width: '100%',
  },
}));

export const CancelButton = styled(Button)(() => ({
  width: '232px',
  height: '64px',
  borderRadius: '12px',
  padding: '6px, 18px, 6px, 18px',
  backgroundColor: '#FFFFFF',
  color: 'black',
  border: '1px solid #00A187',
  ':hover': {
    backgroundColor: '#00A187',
  },
}));
