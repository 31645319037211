import authenticatedApi from '../../../instance/ApiInstance';

const usedCarsInquiriesEndPoint = 'v2/inquiries/Used-car-inquiries';

export interface ICreateUsedCarInquiry {
  name: string;
  email: string;
  mobile_number: string;
  used_car_id: number;
}

export const createUsedCarInquiry = async (data: ICreateUsedCarInquiry) => {
  const response = await authenticatedApi.post(usedCarsInquiriesEndPoint, data);
  return response.data;
};
