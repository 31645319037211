import { useEffect, useState } from 'react';

export const useScrollPosition = () => {
  const [top, setTop] = useState('unset');

  const handleScroll = () => {
    const scroll = window.scrollY;
    if (scroll > 0) {
      setTop('70px');
    } else {
      setTop('unset');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return top;
};
