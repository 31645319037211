import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { AppContext } from '../../../../contexts/AppContext';
import {
  useGetChatMessagesByConversation,
  useMarkMessagesAsRead,
  useSendMessage,
} from '../hooks/useChats';
import {
  EChatConversationType,
  IChatConversation,
} from '../../../../types/chats/chat_conversation';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { Socket } from 'socket.io-client';
import { IChatMessage } from '../../../../types/chats/chat_message';
import { getChatMessagesByConversation } from '../utils/chatApis';
import { useScrollToBottom } from '../../../../hooks/useScrollToBottom';
import { IUsedCar } from '../../../../types/cars/used_car';
import { User } from '../../../../types';
import { IUsedBike } from '../../../../types/bikes';
import { usedVehiclesUrlTemplates } from '../../../../constants/UrlTemplates';
import './AccountChatRoom-styles.css';
import {
  AutoImgBox,
  AutoImgBoxImg,
} from '../../../../common/styles/CommonStyles';

interface IAccountChatRoomProps {
  chatConversation?: IChatConversation | undefined;
  userParticipantId: number | null;
  setUserParticipantId?: React.Dispatch<React.SetStateAction<number | null>>;
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
  setUnreadChatMessagesCount: React.Dispatch<React.SetStateAction<number>>;
  newChatState?: {
    new: boolean;
    car: IUsedCar;
    loggedInUser: User;
    bike: IUsedBike;
    chat_conversation_type: EChatConversationType;
  };
  chatConversationIdRef: React.MutableRefObject<number | undefined>;
}

const AccountChatRoom = ({
  chatConversation,
  userParticipantId,
  setUserParticipantId,
  socket,
  setUnreadChatMessagesCount,
  newChatState,
  chatConversationIdRef,
}: IAccountChatRoomProps) => {
  const [message, setMessage] = useState('');
  const [currentMessages, setCurrentMessages] = useState<IChatMessage[]>([]);
  const { lang, trans, userData } = useContext(AppContext);

  const { data: existingChatMessages, isLoading: isLoadingChatMessages } =
    useGetChatMessagesByConversation({
      enabled: !!chatConversation?.id,
      chat_conversation_id: chatConversation?.id,
      chat_conversation_type: chatConversation?.chat_conversation_type!,
    });

  const [firstMessage, setFirstMessage] = useState<IChatMessage | null>(null);

  const { mutateAsync: sendMessage } = useSendMessage();
  const { mutateAsync: markAsRead } = useMarkMessagesAsRead();

  const ref = useScrollToBottom(currentMessages);

  const getUsedVehicleImg = useCallback(() => {
    const fallbackImage = '/images/picture-icon.png';
    if (chatConversation) {
      if (chatConversation.chat_conversation_type === EChatConversationType.Car)
        return chatConversation.used_car.images[0] ?? fallbackImage;
      else return chatConversation.used_bike.images[0] ?? fallbackImage;
    } else {
      if (newChatState?.chat_conversation_type === EChatConversationType.Car)
        return newChatState!.car.images[0] ?? fallbackImage;
      else {
        return newChatState!.bike.images[0] ?? fallbackImage;
      }
    }
  }, [chatConversation, newChatState]);

  const getUsedVehicleBrandModelNames = useCallback(() => {
    if (
      (chatConversation &&
        chatConversation.chat_conversation_type ===
          EChatConversationType.Car) ||
      (newChatState &&
        newChatState.chat_conversation_type === EChatConversationType.Car)
    ) {
      return (
        <>
          {(chatConversation && chatConversation.used_car?.model?.brand
            ? chatConversation.used_car?.model?.brand.name
            : '') || newChatState?.car?.model?.brand.name}{' '}
          {(chatConversation && chatConversation.used_car?.model
            ? chatConversation.used_car?.model.name
            : '') || newChatState?.car?.model?.name}
        </>
      );
    }
    if (
      (chatConversation &&
        chatConversation.chat_conversation_type ===
          EChatConversationType.Bike) ||
      (newChatState &&
        newChatState.chat_conversation_type === EChatConversationType.Bike)
    ) {
      return (
        <>
          {(chatConversation && chatConversation.used_bike?.bike_model?.brand
            ? chatConversation.used_bike?.bike_model?.brand.name
            : '') || newChatState?.bike?.bike_model?.brand.name}{' '}
          {(chatConversation && chatConversation.used_bike?.bike_model
            ? chatConversation.used_bike?.bike_model.name
            : '') || newChatState?.bike?.bike_model?.name}
        </>
      );
    }
  }, [chatConversation, newChatState]);

  const getUsedVehiclePrice = useCallback(() => {
    // it may be a used_bike or a used_car
    if (chatConversation) {
      return chatConversation.chat_conversation_type ===
        EChatConversationType.Car
        ? chatConversation.used_car.price
        : chatConversation.used_bike.price;
    } else {
      return newChatState?.chat_conversation_type === EChatConversationType.Car
        ? newChatState!.car.price
        : newChatState!.bike.price;
    }
  }, [chatConversation, newChatState]);

  const getRedirectLink = useCallback(() => {
    const usedVehicleType = chatConversation
      ? chatConversation.chat_conversation_type
      : newChatState!.chat_conversation_type;
    const templateLink = usedVehiclesUrlTemplates[usedVehicleType];
    if (usedVehicleType === EChatConversationType.Car) {
      const usedCar = chatConversation
        ? chatConversation.used_car
        : newChatState!.car;
      return templateLink
        .replace('%LANG%', lang)
        .replace('%BRAND_SLUG%', usedCar?.model?.brand?.slug || '')
        .replace('%MODEL_SLUG%', usedCar?.model?.slug || '')
        .replace('%YEAR%', usedCar?.year?.toString())
        .replace('%ID%', usedCar?.id?.toString());
    } else {
      const usedBike = chatConversation
        ? chatConversation.used_bike
        : newChatState!.bike;
      return templateLink
        .replace('%LANG%', lang)
        .replace('%BRAND_SLUG%', usedBike?.bike_model?.brand?.slug || '')
        .replace('%MODEL_SLUG%', usedBike?.bike_model?.slug || '')
        .replace('%YEAR%', usedBike?.year?.toString())
        .replace('%ID%', usedBike?.id?.toString());
    }
  }, [chatConversation, lang, newChatState]);

  const send = async (e: any) => {
    e.preventDefault();
    setMessage('');
    const recipient = chatConversation?.chat_participants?.filter(
      participant => participant.id !== userParticipantId,
    )[0];

    if (recipient?.user || (newChatState && newChatState.new)) {
      const usedCarId = chatConversation
        ? chatConversation.chat_conversation_type === EChatConversationType.Car
          ? chatConversation.used_car.id
          : undefined
        : newChatState?.chat_conversation_type === EChatConversationType.Car
        ? newChatState!.car.id
        : undefined;

      const usedBikeId = chatConversation
        ? chatConversation.chat_conversation_type === EChatConversationType.Bike
          ? chatConversation.used_bike.id
          : undefined
        : newChatState?.chat_conversation_type === EChatConversationType.Bike
        ? newChatState!.bike.id
        : undefined;

      const recipientId = chatConversation
        ? recipient!.user.id
        : newChatState?.chat_conversation_type === EChatConversationType.Car
        ? newChatState!.car.user_id!
        : newChatState!.bike.user_id!;

      const messageSent = await sendMessage({
        chat_conversation_id: chatConversation
          ? chatConversation.id
          : chatConversationIdRef.current,
        recipient_id: recipientId,
        recipient_type: 'Users',
        used_car_id: usedCarId,
        used_bike_id: usedBikeId,
        message,
        chat_conversation_type: chatConversation
          ? chatConversation.chat_conversation_type
          : newChatState!.chat_conversation_type,
      });
      if (newChatState && newChatState.new) {
        chatConversationIdRef.current = messageSent.chat_conversation_id;
      }
      if (setUserParticipantId && !userParticipantId) {
        setUserParticipantId(messageSent.chat_participant_id);
      }
      setCurrentMessages(prevMessages => [...prevMessages, messageSent]);
    } else {
      const messageSent = await sendMessage({
        chat_conversation_id: chatConversation!.id,
        recipient_id: recipient!.dealer.id,
        recipient_type: 'Automotive',
        used_car_id: chatConversation!.used_car.id,
        message,
        chat_conversation_type: chatConversation!.chat_conversation_type,
      });
      setCurrentMessages(prevMessages => [...prevMessages, messageSent]);
    }
  };

  const fetchMessages = useCallback(async () => {
    if (userData && chatConversation) {
      const response = await getChatMessagesByConversation({
        chat_conversation_id: chatConversation!.id,
        chat_conversation_type: chatConversation!.chat_conversation_type,
      });
      if (response) {
        setCurrentMessages(response.data);
        await markAsRead({
          chat_conversation_id: chatConversation!.id,
          chat_conversation_type: chatConversation!.chat_conversation_type,
        });

        setUnreadChatMessagesCount(
          prev => prev - response.unread_messages_count,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatConversation, userData]);

  useEffect(() => {
    if (
      currentMessages.length > 0 &&
      currentMessages[0].id !== firstMessage?.id
    ) {
      setFirstMessage(currentMessages[0]);
    }
  }, [currentMessages]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  useEffect(() => {
    if (socket) {
      socket.on('msgToClient', data => {
        const dataChatConversationId =
          data.chat_conversation_type === EChatConversationType.Car
            ? data.car_chat_conversation_id
            : data.bike_chat_conversation_id;

        if (
          dataChatConversationId === chatConversation?.id ||
          dataChatConversationId === chatConversationIdRef.current
        ) {
          setCurrentMessages(prevMessages => [...prevMessages, data]);
        }
      });
    }
    return () => {
      if (socket) socket.off('msgToClient');
    };
  }, [chatConversation?.id, chatConversationIdRef, socket, firstMessage]);

  return (
    <>
      {socket &&
        !isLoadingChatMessages &&
        (chatConversation ? existingChatMessages : true) && (
          <article className="user-chat-section">
            <div className="chat-listing-info">
              <div className="text-box">
                <div className="img-holder">
                  <AutoImgBox>
                    <AutoImgBoxImg src={getUsedVehicleImg()} alt="img" />
                  </AutoImgBox>
                </div>
                <div className="box">
                  <h2>{getUsedVehicleBrandModelNames()}</h2>
                  <p>
                    {getUsedVehiclePrice()} <sub>{trans.qar}</sub>
                  </p>
                </div>
              </div>
              <Link to={getRedirectLink()} className="btn btn-secondary">
                {trans.view_listing}
              </Link>
            </div>
            <div ref={ref} className="user-chat-holder customscroll">
              {currentMessages.length > 0 &&
                currentMessages.map((message, index: number) => {
                  const messageChatParticipantId =
                    message.chat_participant_id ||
                    message.bike_chat_participant_id;
                  return (
                    <div
                      key={index}
                      className={`user-chat-row ${
                        messageChatParticipantId !== userParticipantId
                          ? 'admin-chat-row'
                          : ''
                      }`}
                    >
                      <div className="user-chat-box">{message.message}</div>
                    </div>
                  );
                })}
            </div>

            <form className="user-chat-form" onSubmit={send}>
              <div className="btn-box"></div>
              <div className="chat-form-row">
                <input
                  className="form-control"
                  id="msg"
                  type="text"
                  placeholder=""
                  value={message}
                  onChange={e => {
                    setMessage(e.target.value);
                  }}
                />
                <button
                  className={`form-btn send-msg ${
                    message.length > 0 ? 'active' : ''
                  }`}
                  type="submit"
                >
                  <SendIcon color="disabled" fontSize="large" />
                </button>
              </div>
            </form>
          </article>
        )}
    </>
  );
};

export default AccountChatRoom;
