export const formatPrice = (price: string | number) => {
  if (typeof price === 'string') {
    price = Number(price.replace(/,/g, ''));
  }

  return Intl.NumberFormat().format(price);
};

export function processTranslations(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (obj.hasOwnProperty('t')) {
      const translations = obj.t;
      for (const key in translations) {
        if (translations.hasOwnProperty(key)) {
          obj[key] = translations[key];
        }
      }
      delete obj.t;
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
        obj[key] = processTranslations(obj[key]);
      }
    }
  }

  return obj;
}
