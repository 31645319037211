import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { Link } from 'react-router-dom';
import { formatPrice } from '../../../helpers/formatHelper';
import { IRentalCar } from '../../../types/cars/rental_car';
import { CarListCardSkeleton } from '../../UsedCars/UsedCarsSkeletons';
import { CarListImageCard } from '../../Listings/common/CarGridCard/CarListImageCard';
import {
  AutoImgBox,
  CarListInfo,
  CarListInfoDd,
  CarListInfoDt,
  DealsAdvantages,
  DealsAdvantagesDealTitle,
  DealsAdvantagesH3,
  DealsAdvantagesSmallLogos,
  DealsAdvantagesSmallLogosImg,
  DealsInfoRow,
  DealsLeft,
  DealsRightFrame,
  DealsRightFrameStrong,
  DealsRight,
  DealsRightBox,
  ImgBox,
  DealsRightFrameQarSpan,
  DealsRightFrameEveryDaySpan,
  DealsRightBtn,
} from '../../../common/styles/CommonStyles';

type CarListCardProps = {
  car: IRentalCar;
  isLoading?: boolean;
};

export const RentCarListCard = ({ car, isLoading }: CarListCardProps) => {
  const { lang, trans, darkImageLoadingPlaceholder, country } =
    useContext(AppContext);
  const redirectionUrl = `/${country}/${lang}/rent-cars/${car.model?.brand?.slug}/${car.model?.slug}/${car.year}/${car.id}`;

  if (isLoading) {
    return <CarListCardSkeleton />;
  }

  return (
    <Link className="" style={{ color: '#000' }} to={redirectionUrl}>
      <DealsInfoRow key={car.id} className="usedcars test">
        <DealsLeft className="deals-left">
          <ImgBox>
            <AutoImgBox>
              <CarListImageCard
                src={car.cover ? car.cover : darkImageLoadingPlaceholder}
                defaultSrc={darkImageLoadingPlaceholder}
                alt=""
              />
            </AutoImgBox>
          </ImgBox>
          <DealsAdvantages className="deals-advantages">
            <DealsAdvantagesSmallLogos className="small-logos">
              <DealsAdvantagesSmallLogosImg
                src={
                  car.rental_dealer?.logo
                    ? car.rental_dealer?.logo
                    : darkImageLoadingPlaceholder
                }
                alt="Logo"
              />
            </DealsAdvantagesSmallLogos>
            <DealsAdvantagesDealTitle>
              {car.model?.brand?.name ?? ''}
            </DealsAdvantagesDealTitle>
            <DealsAdvantagesH3>{car.model?.name ?? ''}</DealsAdvantagesH3>
          </DealsAdvantages>
        </DealsLeft>
        <DealsRight className="deals-rights">
          <DealsRightBox className="box">
            <CarListInfo>
              <CarListInfoDt>{trans.year}:</CarListInfoDt>
              <CarListInfoDd>{car?.year}</CarListInfoDd>
              <CarListInfoDt>{trans.type}:</CarListInfoDt>
              <CarListInfoDd>{car?.type?.name}</CarListInfoDd>
              <CarListInfoDt>{trans.company}:</CarListInfoDt>
              <CarListInfoDd>
                {car?.rental_dealer?.name || trans.private}
              </CarListInfoDd>
            </CarListInfo>
          </DealsRightBox>
          <DealsRightFrame>
            <DealsRightFrameStrong>
              <DealsRightFrameQarSpan>
                {
                  car.location_rental_car[0].locations.countries.cities[0]
                    .currencies.name
                }
              </DealsRightFrameQarSpan>{' '}
              <span>{formatPrice(car?.price_per_day)}</span>
              <DealsRightFrameEveryDaySpan>
                {trans.per_day}
              </DealsRightFrameEveryDaySpan>
            </DealsRightFrameStrong>
            <DealsRightBtn className="btn btn-secondary" to={redirectionUrl}>
              {trans.rent_now}
            </DealsRightBtn>
          </DealsRightFrame>
        </DealsRight>
      </DealsInfoRow>
    </Link>
  );
};
