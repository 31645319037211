import React from 'react';

const BluetoothIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="28"
      height="41"
      viewBox="0 0 28 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.885 17.3821L2.65572 10.3282C2.01111 9.77591 1.03879 9.84976 0.486484 10.4944C-0.0658241 11.139 0.00802469 12.1113 0.65264 12.6636L10.0588 20.7267L0.65264 28.7898C0.00802469 29.3421 -0.0658241 30.3144 0.486484 30.959C1.03879 31.6036 2.01111 31.6775 2.65572 31.1252L10.885 24.0713V39.1882C10.885 39.7329 11.1726 40.2375 11.6434 40.5144C12.1126 40.7913 12.6942 40.7975 13.1711 40.5329L27.0173 32.8405C27.468 32.5898 27.7634 32.1298 27.8034 31.6144C27.8434 31.099 27.6219 30.599 27.2142 30.2821L15.205 20.9405L27.0173 14.379C27.468 14.1282 27.7634 13.6682 27.8034 13.1528C27.8434 12.6375 27.6219 12.1375 27.2142 11.8205L13.368 1.05129C12.9034 0.689754 12.2757 0.625137 11.748 0.883599C11.2203 1.14052 10.885 1.67745 10.885 2.26514V17.3821ZM13.9619 23.8728L23.488 31.2821L13.9619 36.5729V23.8728ZM13.9619 5.41129L23.488 12.8205L13.9619 18.1113V5.41129Z"
        fill={color}
      />
    </svg>
  );
};

export default BluetoothIcon;
