import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { BannerModel, ImageBannerInput } from '../../models/BannerModel';
import { ImageBannerComponent } from './ImageBannerImage';

export default function ImageBanner(props: ImageBannerInput) {
  const { lang } = useContext(AppContext);

  const imageBanner: BannerModel = props.bannerModel;

  const bannerImages = imageBanner?.images;

  const banner =
    bannerImages?.find(banner => banner.lang === lang) ||
    bannerImages?.find(banner => banner.lang === 'en');
  const redirectLink = banner?.call_to_action;
  const imageUrl = banner?.url;

  if (
    imageBanner &&
    imageBanner.banners?.length === 0 &&
    imageBanner?.show_default_image === true &&
    imageBanner?.banner_resource_images === null
  ) {
    return <></>;
  }

  if (
    imageBanner &&
    imageBanner.banners?.length === 0 &&
    imageBanner?.show_default_image === false &&
    imageBanner?.banner_resource_images !== null
  ) {
    return <></>;
  }

  if (!imageBanner) {
    return <></>;
  }

  return (
    <div
      className={props.className ? props.className : 'default-image-banner'}
      onClick={() => props.onClick(imageBanner?.id)}
      ref={props.innerRef}
    >
      <a target={'_blank'} href={redirectLink}>
        <ImageBannerComponent
          src={
            imageUrl
              ? imageUrl
              : imageBanner &&
                imageBanner.banners.length === 0 &&
                imageBanner?.show_default_image === true &&
                imageBanner?.banner_resource_images !== null
              ? imageBanner?.banner_resource_images
              : ''
          }
        />
      </a>
    </div>
  );
}
