import { Skeleton } from '@mui/material';
import {
  CarsFormHolder,
  FinancingColumn,
  FinancingColumnBox,
  FinancingFrame,
  FinancingFrameH2,
  FinancingSection,
} from '../DealerStyledComponent';
import {
  AutoImgBox,
  BrowseBrands,
  BrowseBrandsCarInfoColumn,
  CarInfoColumnImgHolder,
  CarInfoColumnLogoImg,
  CarInfoColumnPriceHolder,
  CarInfoColumnPriceHolderPrice,
  CarInfoColumnTextBox,
  CarInfoColumnTextHolder,
  CarInfoColumnTitleCar,
  CarInfoColumnTitleCategory,
  ColLg12,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  MainHeadP,
  Row,
  SellYourCar,
} from '../../../../common/styles/CommonStyles';
import { CarGridCardImageSkeleton } from '../../../UsedCars/UsedCarsSkeletons';
import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import {
  CarInfoColumnDl,
  CarInfoColumnDlDd,
  CarInfoColumnDlDt,
} from '../../../UsedCars/components/UsedCarStyles';
import {
  LogoBox,
  LogoBoxText,
  LogoHold,
  WrapBox,
} from '../../../Listings/brands/components/BrandCardStyledComponent';
import '../../components/ShowRoomUsedCarsComponent/ShowRoomUsedCars-styles.css';

export const DealerCardImageSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      width={'100%'}
      height={150}
      className="lazy"
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const DealerCardSkeleton = () => {
  return (
    <>
      {[...Array(20)].map((_, i) => (
        <FinancingColumn key={i}>
          <FinancingColumnBox to={''}>
            <DealerCardImageSkeleton />
          </FinancingColumnBox>
        </FinancingColumn>
      ))}
    </>
  );
};

export const DealerCardLogoSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      width={40}
      height={40}
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const DealerListSkeleton = ({ trans }: { trans: any }) => {
  return (
    <main id="main" className="dealers">
      <MainHead>
        <MainHeadContainer className="container">
          <Row>
            <ColLg12>
              <h1>
                <MainHeadH1>{trans.car_dealers} </MainHeadH1>
                <MainHeadP>{trans.in_qatar}</MainHeadP>
              </h1>
            </ColLg12>
          </Row>
        </MainHeadContainer>
      </MainHead>
      <SellYourCar className="container financing-block">
        <FinancingFrame>
          <FinancingFrameH2>{trans.list_of_dealers}</FinancingFrameH2>
          <FinancingSection>
            <DealerCardSkeleton />
          </FinancingSection>
        </FinancingFrame>
      </SellYourCar>
    </main>
  );
};

export const NewBikeBrandImageShowroomSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      width={'100%'}
      height={'100%'}
      className="lazy"
      style={{
        display: 'inline-block',
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const NewBikeDealerCarBrandSkeleton = () => {
  return (
    <article style={{ height: '100%', width: '100%' }}>
      <LogoBox>
        <WrapBox>
          <LogoHold>
            <NewBikeBrandImageShowroomSkeleton />
          </LogoHold>
          <LogoBoxText className="text">
            <Skeleton variant="text" width={'100%'} />
          </LogoBoxText>
        </WrapBox>
      </LogoBox>
    </article>
  );
};

export const NewBikeDealerImageShowroomSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      width={230}
      height={50}
      style={{
        display: 'inline-block',
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const NewBikeDealerShowroomSkeleton = ({ trans }: { trans: any }) => {
  return (
    <main id="main" className="online_showroom used_cars">
      <MainHead>
        <MainHeadContainer className="container">
          <Row>
            <ColLg12>
              <MainHeadH1>{trans.online_showroom}</MainHeadH1>
              <MainHeadP>{trans.new_bikes}</MainHeadP>
            </ColLg12>
          </Row>
        </MainHeadContainer>
      </MainHead>
      <div className="container featured-details-page">
        <Row className="featured-logo">
          <div className="col-12">
            <NewBikeDealerImageShowroomSkeleton />
          </div>
        </Row>
        <div className="row">
          <div className="col-12">
            <CarsFormHolder className="cars-form-holder">
              <BrowseBrands className="dealers">
                {[...Array(5)].map((_, i) => (
                  <div
                    key={i}
                    style={{ width: '150px', height: '150px', margin: 0 }}
                  >
                    <NewBikeDealerCarBrandSkeleton />
                  </div>
                ))}
              </BrowseBrands>
            </CarsFormHolder>
          </div>
        </div>
      </div>
    </main>
  );
};

export const DealUsedBikeCardSkeleton = () => {
  return (
    <BrowseBrands>
      {[...Array(8)].map((_, i) => (
        <BrowseBrandsCarInfoColumn key={i}>
          <CarInfoColumnImgHolder>
            <AutoImgBox>
              <CarGridCardImageSkeleton />
            </AutoImgBox>
          </CarInfoColumnImgHolder>
          <CarInfoColumnTextHolder>
            <CarInfoColumnTextBox>
              <CarInfoColumnLogoImg>
                <DealerCardLogoSkeleton />
              </CarInfoColumnLogoImg>
              <CarInfoColumnTitleCar>
                <Skeleton variant="text" width={100} />
              </CarInfoColumnTitleCar>
              <CarInfoColumnTitleCategory>
                <Skeleton variant="text" width={100} />
              </CarInfoColumnTitleCategory>
              <CarInfoColumnDl>
                <CarInfoColumnDlDt>
                  <Skeleton variant="text" width={50} />
                </CarInfoColumnDlDt>
                <CarInfoColumnDlDd>
                  <Skeleton variant="text" width={100} />
                </CarInfoColumnDlDd>
                <CarInfoColumnDlDt>
                  <Skeleton variant="text" width={50} />
                </CarInfoColumnDlDt>
                <CarInfoColumnDlDd>
                  <Skeleton variant="text" width={100} />
                </CarInfoColumnDlDd>
                <CarInfoColumnDlDt>
                  <Skeleton variant="text" width={50} />
                </CarInfoColumnDlDt>
                <CarInfoColumnDlDd>
                  <Skeleton variant="text" width={100} />
                </CarInfoColumnDlDd>
              </CarInfoColumnDl>
            </CarInfoColumnTextBox>
            <CarInfoColumnPriceHolder>
              <CarInfoColumnPriceHolderPrice>
                <Skeleton variant="text" width={70} />
              </CarInfoColumnPriceHolderPrice>
              <Skeleton variant="rounded" height={28} width={100} />
            </CarInfoColumnPriceHolder>
          </CarInfoColumnTextHolder>
        </BrowseBrandsCarInfoColumn>
      ))}
    </BrowseBrands>
  );
};

export const UsedBikeDealerShowroomSkeleton = ({ trans }: { trans: any }) => {
  return (
    <main id="main" className="online_showroom used_cars">
      <MainHead>
        <MainHeadContainer className="container">
          <Row>
            <ColLg12>
              <MainHeadH1>{trans.online_showroom}</MainHeadH1>
              <MainHeadP>{trans.used_bikes}</MainHeadP>
            </ColLg12>
          </Row>
        </MainHeadContainer>
      </MainHead>
      <div className="container featured-details-page">
        <Row className="featured-logo">
          <div className="col-12">
            <NewBikeDealerImageShowroomSkeleton />
          </div>
        </Row>
        <div style={{ height: '143px', width: '100%' }}></div>
        <DealUsedBikeCardSkeleton />
        <span className="pagination-middle">
          <div
            style={{
              height: '65px',
              margin: '0 auto',
              width: '200px',
            }}
          />
        </span>
      </div>
    </main>
  );
};
