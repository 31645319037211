export interface LazyImageProps {
  defaultSrc?: string;
  src: string | undefined;
  alt?: string;
  class?: string;
  onClick?: any;
  style?: React.CSSProperties;
  onLoad?: any;
  width?: number;
  height?: number;
}

export default function LazyImage(props: LazyImageProps) {
  return (
    <img
      src={props.src}
      className={props.class}
      onClick={props.onClick}
      alt={props.alt}
      style={props.style}
      onLoad={props.onLoad}
      width={props.width}
      height={props.height}
      loading="lazy"
    />
  );
}
