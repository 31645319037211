import { IGetUsedCars } from '../../Components/UsedCars/apis/usedCarsApi';
import authenticatedApi from '../../instance/ApiInstance';
import { IUsedCar } from '../../types/cars/used_car';
import { IMetadata } from '../../types/common/metadata';

export const enum ListingStatus {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected',
}

export interface UserListing {
  status: string[];
  lang: string;
}

export const logout = async () => {
  try {
    const response = await authenticatedApi.post('/v2/auth/logout', {
      refresh_token: localStorage.getItem('refreshToken'),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMyListings = async ({ status, lang }: UserListing) => {
  try {
    const result = await authenticatedApi.get(`/v2/users/listings`, {
      params: {
        status,
        lang,
      },
    });

    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteListings = async (carId: number) => {
  try {
    const result = await authenticatedApi.delete(`/v2/cars/used-cars/${carId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteBikeListing = async (bikeId: number) => {
  try {
    const result = await authenticatedApi.delete(
      `/v2/bikes/used-bikes/${bikeId}`,
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getFavorites = async (
  params?: IGetUsedCars,
): Promise<{
  data: IUsedCar['id'][];
  meta: IMetadata;
}> => {
  try {
    const result = await authenticatedApi.get(`/v2/users/favorites`, {
      params: {
        ...params,
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFavorites = async (id: number, type: string) => {
  try {
    const result = await authenticatedApi.delete(
      `/v2/users/favorite/${id}?type=${type}`,
    );

    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const getNotifications = async () => {
  try {
    const result = await authenticatedApi.get(`v2/notifications`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const markNotificationsAsRead = async () => {
  try {
    const result = await authenticatedApi.put(
      'v2/notifications/mark-all-as-read',
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};
