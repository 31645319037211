import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import AccountLeftComponent from '../AccountLeftComponent';
import MetaTag from '../../../common/meta/MetaTag';
import './LanguageCountry.css';

export const LanguageCountry = () => {
  const { trans, lang, country } = useContext(AppContext);
  const location = useLocation();
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [languageSwitchUrl, setLanguageSwitchUrl] = useState({
    ar: '',
    en: '',
  });

  const pathSegments = location.pathname.split('/');
  const currentCountryCode = pathSegments[1];
  const currentPath = pathSegments.slice(3).join('/');

  const handleSelectCountry = (country: string) => {
    const countryCode = country === 'Qatar' ? 'qa' : 'sa';
    localStorage.setItem('country', countryCode);
    window.location.pathname = `/${countryCode}/${lang}`;
  };

  useEffect(() => {
    setLanguageSwitchUrl({
      ar: `/${currentCountryCode}/ar/${currentPath}`,
      en: `/${currentCountryCode}/en/${currentPath}`,
    });
  }, [currentCountryCode, currentPath]);

  useEffect(() => {
    setSelectedCountry(country === 'qa' ? 'Qatar' : 'Saudi Arabia');
  }, [country]);

  return (
    <>
      <MetaTag
        href={`/${country}/${lang}/account-details`}
        metaKey="accountDetails"
        index={false}
      />
      <main id="main">
        <div className="accounts-container">
          <div className="accounts-frame">
            <AccountLeftComponent country={country} />
            <section className="accounts-content">
              <header className="accounts-header details">
                <h2>{trans.languagecountry}</h2>
              </header>
              <div className="accounts-language">
                <h3 className="accounts-language-title">
                  {trans.ChooseyourPreferredLanguage}
                </h3>
                <div className="accounts-language-list">
                  <div
                    className={`accounts-language-item ${
                      lang === 'ar' ? 'accounts-language-item-2' : ''
                    }`}
                  >
                    <Link
                      to={languageSwitchUrl.ar}
                      className="accounts-language-item-title"
                    >
                      العربية
                    </Link>
                  </div>
                  <div
                    className={`accounts-language-item ${
                      lang === 'en' ? 'accounts-language-item-2' : ''
                    }`}
                  >
                    <Link
                      to={languageSwitchUrl.en}
                      className="accounts-language-item-title"
                    >
                      English
                    </Link>
                  </div>
                </div>
              </div>
              <div className="accounts-language">
                <h3 className="accounts-language-title">
                  {trans.ChooseyourCountry}
                </h3>
                <div className="accounts-language-list">
                  <div
                    className={`accounts-country-item ${
                      selectedCountry === 'Qatar' ? 'selected' : ''
                    }`}
                    onClick={() => handleSelectCountry('Qatar')}
                  >
                    <span className="modal-dropdown-item-text">
                      {trans.qatar}
                    </span>
                    <img
                      className="modal-dropdown-item-flag"
                      src={`/images/language1.png`}
                      alt="Flag"
                    />
                  </div>
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                    className={`accounts-country-item ${
                      selectedCountry === 'Saudi Arabia' ? 'selected' : ''
                    }`}
                    onClick={() => handleSelectCountry('Saudi Arabia')}
                  >
                    <span className="modal-dropdown-item-text">
                      {trans.saudiArabia}
                    </span>
                    <img
                      className="modal-dropdown-item-flag"
                      src={`/images/saudi-flag.svg`}
                      alt="Flag"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};
