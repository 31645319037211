import { useContext, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { successNotification } from '../../Other/ToastMessage';
import { FaCar } from 'react-icons/fa';
import { formatPrice } from '../../../helpers/formatHelper';
import { IRentalCar } from '../../../types/cars/rental_car';
import '../../Listings/common/ListingDetailsFeaturesList/ListingDetailsFeaturesList-styles.css';

type RentalListingDetailsFeaturesListProps = {
  car: IRentalCar;
  handleRentNowClick: () => void;
};

export const RentalListingDetailsFeaturesList = (
  props: RentalListingDetailsFeaturesListProps,
) => {
  const { trans, lang } = useContext(AppContext);
  const [showNumber, setShowNumber] = useState(false);

  const generateContactMessage = () => {
    if (!props.car) return '';
    const message = `Hi, I am interested in your car. Please let me know if it is still available.\n\n${
      props.car?.model?.slug
    }\n\nCar Details:\n${props.car?.model?.brand?.name} ${
      props.car.model?.name
    }\nModel: ${props.car?.model?.slug}\nPrice: ${formatPrice(
      props.car?.price_per_day,
    )}`;
    return message;
  };

  const handleShowNumber = () => {
    showNumber
      ? handleCall()
      : successNotification(
          trans.number_copied_to_clipboard,
          'info',
          '',
          lang === 'en' ? 'top-right' : 'top-left',
        );
    navigator.clipboard.writeText(props.car?.mobile_number || '');
    setShowNumber(true);
  };

  const handleCall = () => {
    if (props.car?.mobile_number?.includes('974')) {
      window.open(`tel:${props.car?.mobile_number}`, '_blank');
    } else {
      window.open(`tel:${'974' + props.car?.mobile_number}`, '_blank');
    }
  };

  const handleWhatsapp = () => {
    //remove the + if found
    const phoneNumber = props.car?.whatsapp_number?.includes('+')
      ? props.car?.whatsapp_number.split('+')[1]
      : props.car?.whatsapp_number;
    const phoneNumberWithoutSpaces = phoneNumber?.replace(/\s/g, '');
    const phoneNumberWithCountryCode = phoneNumberWithoutSpaces?.includes('974')
      ? phoneNumberWithoutSpaces
      : '974' + phoneNumberWithoutSpaces;
    window.open(
      `https://wa.me/${phoneNumberWithCountryCode}?text=${encodeURIComponent(
        generateContactMessage(),
      )}`,
      '_blank',
    );
  };

  return (
    <>
      <section className="deals-detail-info">
        <div className="deals-detail">
          <header className="details-head">
            <div className="box">
              <h1>{props.car.model?.brand?.name}</h1>
              <h2>{props.car.model?.name}</h2>
            </div>
            <div className="box2">
              <strong className="price-qar">
                <span>{formatPrice(props.car.price_per_day)}</span>{' '}
                <sub>{trans.qar}</sub>{' '}
                <sub
                  style={{
                    fontSize: '12px',
                  }}
                >
                  {trans.per_day}
                </sub>
              </strong>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '260px',
                }}
              >
                {props.car && props.car?.mobile_number ? (
                  <Button
                    onClick={handleShowNumber}
                    style={{
                      outline: 'none',
                      color: showNumber ? '#000' : '',
                      width: props.car?.whatsapp_number ? '' : '100%',
                      fontSize: '17px',
                    }}
                    variant="outlined"
                    startIcon={lang === 'en' ? <PhoneIcon /> : ''}
                    className="show-number-used-car"
                  >
                    {showNumber ? props.car?.mobile_number : trans.show_number}
                  </Button>
                ) : (
                  <div>
                    <span>{''}</span>
                  </div>
                )}
                {props.car && props.car?.whatsapp_number ? (
                  <Button
                    onClick={handleWhatsapp}
                    variant="contained"
                    style={{
                      width: props.car?.mobile_number ? '' : '100%',
                      backgroundColor: '#25D366',
                      color: '#fff',
                      fontSize: props.car?.mobile_number ? '0px' : '17px',
                      outline: 'none',
                    }}
                    className="whatsapp-btn-used-car"
                  >
                    <WhatsAppIcon
                      style={{
                        backgroundColor: '#25D366',
                        color: '#FFF',
                        outline: 'none',
                      }}
                    />
                    WhatsApp
                  </Button>
                ) : (
                  <div>
                    <span>{''}</span>
                  </div>
                )}
              </div>
              <div className="buttons-holder-rent">
                <div className="holder">
                  <div className="sharenav-rent">
                    {props.car && (
                      <>
                        <button
                          onClick={props.handleRentNowClick}
                          className="btn btn-secondary showroom-rent"
                        >
                          {window.innerWidth > 992 ? <FaCar size={25} /> : ''}
                          {trans.rent_now}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </header>
          <table className="table table-striped details-table">
            <tbody>
              {props.car ? (
                <>
                  <tr>
                    <td>{trans.price_per_day}</td>
                    <td>
                      {formatPrice(props.car.price_per_day) + ' ' + trans.qar ||
                        trans.n_a}
                    </td>
                  </tr>
                  {props.car.price_per_week ? (
                    <tr>
                      <td>{trans.price_per_week}</td>
                      <td>
                        {formatPrice(props.car.price_per_week) +
                          ' ' +
                          trans.qar || trans.n_a}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {props.car.price_per_month ? (
                    <tr>
                      <td>{trans.price_per_month}</td>
                      <td>
                        {formatPrice(props.car.price_per_month) +
                          ' ' +
                          trans.qar || trans.n_a}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {props.car.price_per_year ? (
                    <tr>
                      <td>{trans.price_per_year}</td>
                      <td>
                        {formatPrice(props.car.price_per_year) +
                          ' ' +
                          trans.qar || trans.n_a}
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  <tr>
                    <td>{trans.year} </td>
                    <td>{props.car.year || trans.n_a}</td>
                  </tr>
                  <tr>
                    <td>{trans.type}</td>
                    <td>{props.car.type?.name || trans.n_a}</td>
                  </tr>
                  <tr>
                    <td>{trans.large_baggage_capacity}</td>
                    <td>{props.car.large_baggage_size || trans.n_a}</td>
                  </tr>
                  <tr>
                    <td>{trans.small_baggage_capacity}</td>
                    <td>{props.car.small_baggage_size || trans.n_a}</td>
                  </tr>
                  <tr>
                    <td>{trans.transmission}</td>
                    <td>{props.car.transmission?.name || trans.n_a}</td>
                  </tr>
                  <tr>
                    <td>{trans.dealer}</td>
                    <td>{props.car?.rental_dealer?.name || trans.n_a}</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>{trans.not_available}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};
