import { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AppContext } from './contexts/AppContext';
import { ReactNotifications } from 'react-notifications-component';
import { DataProvider } from './contexts/DataContext';
import ScrollToTopOnLocationChange from './services/ScrollToTop';
import { FloatingButton } from './Components/Other/hooks/FloatingButton/FloatingButton';
import { QueryClient } from 'react-query';
import ImageZoomModal from './Components/Modals/ImageModal';
import {
  getDeviceType,
  getPlatform,
} from './Components/Other/PlatformDetection';
import WebsiteLayout from './Components/WebsiteLayout';
import Header from './Components/Other/HeaderComponent';
import Footer from './Components/Other/FooterComponent';
import ThankYouPage from './Components/Other/ThankYouPage/ThankYouPage';
import LandingPageLayout from './Components/LandingPage/LandingPageLayout';
import PageNotFound from './Components/Other/PageNotFoundComponent';
import Home from './Components/Home/HomeComponent';
import Dealers from './Components/Dealers/components/DealersComponent';
import DealerDetail from './Components/Dealers/components/DealerDetailComponent';
import ShowroomUsedCars from './Components/Dealers/components/ShowRoomUsedCarsComponent/ShowroomUsedCarsComponent';
import ShowroomUsedBikesComponent from './Components/Dealers/components/ShowroomUsedBikesComponent';
import ShowroomNewCars from './Components/Dealers/components/ShowroomNewCarsComponent';
import ShowroomNewBikesComponent from './Components/Dealers/components/ShowroomNewBikesComponent';
import Articles from './Components/Articles/ArticlesLayout/ArticlesComponent';
import ArticleDetail from './Components/Articles/ArticleDetailComponent/ArticleDetailComponent';
import ReviewsLayout from './Components/Reviews/ReviewsLayout';
import ReviewDetails from './Components/Reviews/components/ReviewsDetailsLayout/ReviewDetailsLayout';
import WhyWeRock from './Components/Other/WhyWeRockComponent';
import PrivacyPolicy from './Components/Other/PrivacyPolicyComponent';
import TermsAndConditions from './Components/Other/TermsAndConditionsComponent';
import CarBrands from './Components/NewCars/components/NewCarsBrands';
import NewCars from './Components/NewCars/components/NewCarsComponent';
import NewCarDetail from './Components/NewCars/components/NewCarDetail';
import NewBikesBrands from './Components/NewBikes/components/NewBikesBrands';
import NewBikesModel from './Components/NewBikes/components/NewBikesModels';
import NewBikeDetails from './Components/NewBikes/components/NewBikeDetails';
import RamadanOfferListPage from './Components/RamadanOffers/RamadanOfferListPage/RamadanOfferListPage';
import UsedCars from './Components/UsedCars/UsedCarsComponent';
import UsedBikeComponent from './Components/UsedBikes/components/UsedBikeComponent';
import UsedCarDetail from './Components/UsedCars/UsedCarDetailComponent';
import UsedBikeDetailComponent from './Components/UsedBikes/components/UsedBikeDetailComponent';
import RamadanOffersDetailsPage from './Components/RamadanOffers/RamadanOffersDetails/RamadanOffersDetailsPage';
import RentalCarsLayout from './Components/RentalCars/RentalCarLayout/RentalCarsLayout';
import RentalCarsDetails from './Components/RentalCars/RentalCarsDetail';
import AccountDetails from './Components/User/AccountDetails';
import SellYourCar from './Components/User/SellYourCar/SellYourCarComponent';
import SellYourBikeComponent from './Components/User/SellYourVehicle/SellYourBikeComponent';
import Login from './Components/User/LogIn/LoginComponent';
import ForgotPassword from './Components/User/ForgotPasswordComponent';
import SignUp from './Components/User/SignUp/SignUpComponent';
import VerifyPhone from './Components/User/VerifyPhoneComponent';
import AccountChats from './Components/User/AccountChats';
import AccountNotifications from './Components/User/AccountNotifications';
import AccountSupport from './Components/User/AccountSupport/AccountSupport';
import AccountListings from './Components/User/AccountListings';
import AccountWishList from './Components/User/AccountWishList';
import ContactUs from './Components/Other/ContactUs/ContactUsComponent';
import { SellYourCarFirstPage } from './Components/User/SellYourCar/SellYourCarFirstPage/SellYourCarFirstPage';
import { SellYourCarSecondPage } from './Components/User/SellYourCar/SellYourCarSecondPage';
import { SellYourCarThirdPage } from './Components/User/SellYourCar/SellYourCarThirdPage';
import { LanguageCountry } from './Components/User/LanguageCountry/LanguageCountry';

export const queryClient = new QueryClient();

// Google Tag Manager
// const tagManagerArgs = {
//   gtmId: 'GTM-KC7BCFK',
// };
// TagManager.initialize(tagManagerArgs);

const SCROLL_THRESHOLD_FOR_STICKY_NAVBAR = 30;

export default function App() {
  const platform = getPlatform();
  const deviceType = getDeviceType();
  const [showBanner, hideBanner] = useState(
    deviceType === 'Mobile' && (platform === 'Android' || platform === 'iOS'),
  );
  const [addStickyNavbar, setAddStickyNavbar] = useState(false);
  const location = useLocation();

  const { lang, userData, country } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setAddStickyNavbar(currentPosition > SCROLL_THRESHOLD_FOR_STICKY_NAVBAR);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const shouldShowHeaderFooter = (pathname: string) => {
    const routesWithoutHeaderFooter = ['thank-you', 'landing-page'];
    return !routesWithoutHeaderFooter.some(route => pathname.includes(route));
  };

  return (
    <DataProvider>
      <ImageZoomModal
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        imageSrc={imageSrc}
      />
      <ReactNotifications />
      <div className={`${addStickyNavbar ? 'sticky-header' : ''}`} id="navbar">
        <div
          className={`d-flex flex-column justify-content-center wrapper ${
            showBanner ? 'wrapper-with-download-banner' : ''
          }`}
        >
          <FloatingButton />
          {shouldShowHeaderFooter(location.pathname) && (
            <Header showBanner={showBanner} hideBanner={hideBanner} />
          )}
          <Routes>
            <Route element={<WebsiteLayout />}>
              <Route
                path=":lang/:sourcePage/thank-you"
                element={<ThankYouPage />}
              />
              <Route
                path=":lang/landing-page"
                element={<LandingPageLayout />}
              />
              <Route
                path="/landing"
                element={
                  <Navigate to={`/${lang}/landing-page`} replace={true} />
                }
              />
              <Route
                path={`/${country}/${lang}/404`}
                element={<PageNotFound />}
              />
              <Route
                path="/"
                element={<Navigate to={`/${country}/en`} replace={true} />}
              />
              <Route path={`/${country}/${lang}`} element={<Home />} />
              <Route path="/:country/:lang/dealers" element={<Dealers />} />
              <Route
                path="/:country/:lang/dealer/:name"
                element={<DealerDetail />}
              />
              <Route
                path="/:country/:lang/dealer/:name/used-cars"
                element={<ShowroomUsedCars />}
              />
              <Route
                path="/:country/:lang/dealer/:name/used-bikes"
                element={<ShowroomUsedBikesComponent />}
              />
              <Route
                path="/:country/:lang/dealer/:name/new-cars"
                element={<ShowroomNewCars />}
              />
              <Route
                path="/:country/:lang/dealer/:name/new-bikes"
                element={<ShowroomNewBikesComponent />}
              />
              <Route path="/:country/:lang/articles" element={<Articles />} />
              <Route
                path="/:country/:lang/article/:name"
                element={<ArticleDetail />}
              />
              <Route
                path="/:country/:lang/reviews"
                element={<ReviewsLayout />}
              />
              <Route
                path="/:country/:lang/review/:name"
                element={<ReviewDetails />}
              />
              <Route
                path="/:country/:lang/why-we-rock"
                element={<WhyWeRock />}
              />
              <Route
                path="/:country/:lang/privacy-policy"
                element={<PrivacyPolicy />}
              />
              <Route
                path="/:country/:lang/terms-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/:country/:lang/new-cars" element={<CarBrands />} />
              <Route
                path="/:country/:lang/new-cars/:brand"
                element={<NewCars />}
              />
              <Route
                path="/:country/:lang/new-cars/:brand/:model"
                element={<NewCarDetail />}
              />
              <Route
                path="/:country/:lang/new-bikes"
                element={<NewBikesBrands />}
              />
              <Route
                path="/:country/:lang/new-bikes/:brand"
                element={<NewBikesModel />}
              />
              <Route
                path="/:country/:lang/new-bikes/:brand/:model"
                element={<NewBikeDetails />}
              />
              <Route
                path="/:country/:lang/ramadan-offers"
                element={<RamadanOfferListPage />}
              />
              <Route path="/:country/:lang/used-cars" element={<UsedCars />} />
              <Route
                path="/:country/:lang/used-bikes"
                element={<UsedBikeComponent />}
              />
              <Route
                path="/:country/:lang/used-cars/:brand"
                element={<UsedCars />}
              />
              <Route
                path="/:country/:lang/used-cars/:brand/:model"
                element={<UsedCars />}
              />
              <Route
                path="/:country/:lang/used-cars/:brand/:model/:year/:id"
                element={
                  <UsedCarDetail
                    handleOpen={handleOpen}
                    setImageSrc={setImageSrc}
                  />
                }
              />
              <Route
                path="/:country/:lang/used-bikes/:brand/:model/:year/:id"
                element={
                  <UsedBikeDetailComponent
                    handleOpen={handleOpen}
                    setImageSrc={setImageSrc}
                  />
                }
              />
              <Route
                path="/:country/:lang/ramadan-offers/:OfferSlug"
                element={<RamadanOffersDetailsPage />}
              />
              <Route
                path="/:country/:lang/rent-cars"
                element={<RentalCarsLayout />}
              />
              <Route
                path="/:country/:lang/rent-cars/:brand/:model/:year/:id"
                element={<RentalCarsDetails />}
              />
              <Route
                path="/:country/:lang/account-details"
                element={
                  userData ? (
                    <AccountDetails />
                  ) : (
                    <Navigate replace to={`/${lang}`} />
                  )
                }
              />
              <Route
                path="/:country/:lang/sell-your-car"
                element={
                  userData ? (
                    <SellYourCarFirstPage />
                  ) : (
                    <Navigate replace to={`/${lang}`} />
                  )
                }
              />
              <Route
                path="/:country/:lang/sell-your-car/step-two"
                element={
                  userData ? (
                    <SellYourCarSecondPage />
                  ) : (
                    <Navigate replace to={`/${lang}`} />
                  )
                }
              />
              <Route
                path="/:country/:lang/sell-your-car/step-three"
                element={
                  userData ? (
                    <SellYourCarThirdPage />
                  ) : (
                    <Navigate replace to={`/${lang}`} />
                  )
                }
              />
              <Route
                path="/:country/:lang/sell-your-car/car-details-form"
                element={
                  userData ? (
                    <SellYourCar />
                  ) : (
                    <Navigate replace to={`/${lang}`} />
                  )
                }
              />
              <Route
                path="/:country/:lang/sell-your-bike"
                element={
                  userData ? (
                    <SellYourBikeComponent />
                  ) : (
                    <Navigate replace to={`/${lang}`} />
                  )
                }
              />
              <Route path="/:country/:lang/login" element={<Login />} />
              <Route
                path="/:country/:lang/forgot-password"
                element={<ForgotPassword />}
              />
              <Route path="/:country/:lang/register" element={<SignUp />} />
              <Route
                path="/:country/:lang/verify-phone"
                element={<VerifyPhone />}
              />
              <Route
                path="/:country/:lang/account-chat"
                element={<AccountChats />}
              />
              <Route
                path="/:country/:lang/account-chat/:type/:id"
                element={<AccountChats />}
              />
              <Route
                path="/:country/:lang/account-notifications"
                element={<AccountNotifications />}
              />
              <Route
                path="/:country/:lang/account-support"
                element={<AccountSupport />}
              />
              <Route
                path="/:country/:lang/account-listings"
                element={<AccountListings />}
              />
              <Route
                path="/:country/:lang/language-country"
                element={<LanguageCountry />}
              />
              <Route
                path="/:country/:lang/account-wishlist"
                element={<AccountWishList />}
              />
              <Route
                path="/:country/:lang/contact-us"
                element={<ContactUs />}
              />
            </Route>
          </Routes>
          {shouldShowHeaderFooter(location.pathname) && <Footer />}
          <ScrollToTopOnLocationChange />
        </div>
      </div>
    </DataProvider>
  );
}
