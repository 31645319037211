import { Divider, styled } from '@mui/material';

const DividerStyled = styled(Divider)(() => ({
  width: '64px',
  height: '1.97px',
  background: '#00D6BC',
  '@media screen and (max-width: 992px)': {
    width: '32px',
  },
}));

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '24px',
}));

const StyledH3 = styled('h3')(() => ({
  fontWeight: '700',
  fontSize: '18px',
  lineHeight: '28px',
  '@media screen and (max-width: 992px)': {
    fontSize: '16px',
  },
}));

export const TitleDivider = ({ header }) => {
  return (
    <StyledDiv>
      <DividerStyled />
      <StyledH3>{header}</StyledH3>
    </StyledDiv>
  );
};
