import { useContext } from 'react';
import { IUsedBike } from '../../../types/bikes';
import { AppContext } from '../../../contexts/AppContext';
import { CarGridCardSkeleton } from '../../UsedCars/UsedCarsSkeletons';
import { Link } from 'react-router-dom';
import {
  AutoImgBox,
  CarInfoColumn,
  CarInfoColumnBtn,
  CarInfoColumnImgHolder,
  CarInfoColumnLogoImg,
  CarInfoColumnPriceHolder,
  CarInfoColumnPriceHolderPrice,
  CarInfoColumnTextBox,
  CarInfoColumnTextHolder,
  CarInfoColumnTitleCar,
  CarInfoColumnTitleCategory,
  SponsoredBadge,
} from '../../../common/styles/CommonStyles';
import { CarGridImageCard } from '../../Listings/common/CarGridCard/CarGridImageCard';
import LazyImage from '../../Other/LazyImageComponent';
import {
  CarInfoColumnDl,
  CarInfoColumnDlDd,
  CarInfoColumnDlDt,
} from '../../UsedCars/components/UsedCarStyles';
import { formatPrice } from '../../../helpers/formatHelper';
import '../../User/AccountWishList/Card-styles.css';

type BikeGridCardProps = {
  bike: IUsedBike;
  redirectionLink: string;
  isFavorite: boolean;
  onFavoriteClick: (e: any, bikeId: number) => void;
  isLoading: boolean;
};

export const BikeGridCard = ({
  bike,
  isFavorite,
  isLoading,
  onFavoriteClick,
  redirectionLink,
}: BikeGridCardProps) => {
  const { trans, darkImageLoadingPlaceholder, lang, country } =
    useContext(AppContext);

  if (isLoading) {
    return <CarGridCardSkeleton />;
  }

  return (
    <div key={bike.id} className="swiper-slide">
      <div className="slide-holder">
        <button
          className={`wishlist-icon loginpopupload ${isFavorite && 'active'}`}
          onClick={(e: any) => onFavoriteClick(e, bike.id)}
        >
          <i className="fa fa-heart-o"></i>
          <i className="fa fa-heart"></i>
        </button>
        <Link to={redirectionLink}>
          <CarInfoColumn>
            <CarInfoColumnImgHolder>
              <AutoImgBox>
                {bike.is_featured ? (
                  <SponsoredBadge className="badge">
                    {trans.featured}
                  </SponsoredBadge>
                ) : null}
                <CarGridImageCard
                  src={
                    bike?.images
                      ? bike?.images?.[0]
                      : darkImageLoadingPlaceholder
                  }
                  defaultSrc={darkImageLoadingPlaceholder}
                  alt="img"
                  width={300}
                  height={200}
                />
              </AutoImgBox>
            </CarInfoColumnImgHolder>
            <CarInfoColumnTextHolder>
              <CarInfoColumnTextBox>
                <CarInfoColumnLogoImg>
                  <LazyImage
                    src={bike.bike_model?.brand.original_logo ?? ''}
                    defaultSrc={darkImageLoadingPlaceholder}
                    alt="img"
                    width={40}
                    height={40}
                  />
                </CarInfoColumnLogoImg>
                <CarInfoColumnTitleCar>
                  {bike.bike_model?.brand?.name}
                </CarInfoColumnTitleCar>
                <CarInfoColumnTitleCategory>
                  {bike.bike_model?.name}{' '}
                  {bike && bike.bike_trim != null ? bike.bike_trim.name : ''}
                </CarInfoColumnTitleCategory>
                <CarInfoColumnDl>
                  <CarInfoColumnDlDt>{trans.year}:</CarInfoColumnDlDt>
                  <CarInfoColumnDlDd>{bike.year}</CarInfoColumnDlDd>
                  <CarInfoColumnDlDt>{trans.km_driven}:</CarInfoColumnDlDt>
                  <CarInfoColumnDlDd>
                    {bike.mileage || 0} {trans.km}
                  </CarInfoColumnDlDd>
                  <CarInfoColumnDlDt>{trans.seller}:</CarInfoColumnDlDt>
                  <CarInfoColumnDlDd>
                    {bike.bike_dealer?.name ?? trans.private}
                  </CarInfoColumnDlDd>
                </CarInfoColumnDl>
              </CarInfoColumnTextBox>
              <CarInfoColumnPriceHolder>
                <CarInfoColumnPriceHolderPrice>
                  <span className="qar">{trans.qar}</span>{' '}
                  <span>{formatPrice(bike.price)}</span>
                </CarInfoColumnPriceHolderPrice>
                <CarInfoColumnBtn
                  className="btn"
                  to={`/${country}/${lang}/used-bikes/${bike.bike_model?.brand?.slug}/${bike.bike_model?.slug}/${bike.year}/${bike.id}`}
                >
                  {trans.view_bike}
                </CarInfoColumnBtn>
              </CarInfoColumnPriceHolder>
            </CarInfoColumnTextHolder>
          </CarInfoColumn>
        </Link>
      </div>
    </div>
  );
};
