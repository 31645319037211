import authenticatedApi from '../../../instance/ApiInstance';
import CarBrand from '../../../models/listings/CarBrand';
import { IBrand, IModel } from '../../../types/resources/cars';

const modelsEndpoint = 'v2/resources/cars/models';

const modelsAnalytics = '/v2/analytics/new-car-analytics/model/analytics';

interface IBrandModelWithDeals {
  brandId: IBrand['id'];
}

export interface IBrandModelWithDealsResponse {
  arabic_description: string;
  arabic_name: string;
  brand: CarBrand;
  brand_id: number;
  country_id: number;
  created_at: string;
  description: string;
  id: number;
  image: string;
  image1: string;
  image2: string;
  image3: string;
  image4: string;
  image5: string;
  name: string;
  thumbnail: string;
  type_id: string[];
  updated_at: string;
  url: string;
}

export const getBrandModelsWithDeals = async ({
  brandId,
}: IBrandModelWithDeals): Promise<IModel[]> => {
  try {
    const response = await authenticatedApi.get(
      `${modelsEndpoint}/brand/${brandId}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const NewCarModelPageClick = async (id: number) => {
  try {
    const response = await authenticatedApi.post(modelsAnalytics, {
      new_car_id: id,
      event: 'click',
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const NewcarModelPageView = async (id: number) => {
  try {
    const response = await authenticatedApi.post(modelsAnalytics, {
      new_car_id: id,
      event: 'view',
    });

    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};
