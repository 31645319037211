import { format } from 'date-fns';
import parse from 'html-react-parser';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../contexts/AppContext';
import MetaTag, {
  metaDataDescription,
  metaDataTitle,
} from '../../../../common/meta/MetaTag';
import PageLoader from '../../../Other/PageLoader/PageLoaderComponent';
import Banner from '../../../Banners/BannerComponent';
import { useGetMoreReviews, useGetReviewBySlug } from '../../hooks/useReview';
import { Avatar } from '@mui/material';
import { MoreReviews } from '../MoreReviews';
import VideoPlayer from '../VideoPlayer';
import {
  ArticleDateNavLi,
  ArticleDateNavUl,
  ArticleDetailsInfo,
  ArticleDetailsInfoH2,
  ArticleDetailsInfoP,
} from '../../../Articles/ArticleDetailStyledComponent';
import {
  ColLg12,
  FeaturedDetailsPage,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  Row,
} from '../../../../common/styles/CommonStyles';
import {
  ReviewBox,
  ReviewDescription,
  ReviewerInfo,
  ReviewerInfoContainer,
  ReviewerName,
  VideopWrapper,
} from '../ReviewsStyles';
import './ReviewDetailsLayout-styles.css';

export default function ReviewDetails() {
  const { lang, trans, country } = useContext(AppContext);
  const params = useParams();

  const { data: selectedReview, isLoading } = useGetReviewBySlug(
    params.name ?? '',
  );

  const { data: reviews } = useGetMoreReviews(selectedReview?.slug);

  const handleReviewClick = (clickedSlug: any) => {
    window.location.href = `/${country}/${lang}/review/${clickedSlug}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.name]);

  return (
    <>
      <MetaTag
        title={metaDataTitle(selectedReview)}
        description={metaDataDescription(selectedReview)}
        metaKey="reviews"
        href={`/${lang}/review/${selectedReview?.slug}`}
        params={{ '%NAME%': selectedReview?.title }}
      />
      {isLoading && <PageLoader />}
      <main id="main">
        <MainHead>
          <MainHeadContainer className="container">
            <Row>
              <ColLg12>
                <MainHeadH1>{trans.reviews}</MainHeadH1>
              </ColLg12>
            </Row>
          </MainHeadContainer>
        </MainHead>
        {selectedReview && selectedReview?.id && (
          <>
            <FeaturedDetailsPage className="container reviews">
              <ArticleDetailsInfo className="reviews-details-info">
                <div>
                  <div>
                    <ArticleDetailsInfoH2>
                      {selectedReview.title}
                    </ArticleDetailsInfoH2>
                    <ArticleDateNavUl>
                      <ArticleDateNavLi>
                        {trans.by} ONE AUTOCAR
                      </ArticleDateNavLi>
                      <ArticleDateNavLi>
                        {format(
                          new Date(selectedReview?.created_at),
                          'MMM d, yyyy',
                        )}
                      </ArticleDateNavLi>
                    </ArticleDateNavUl>
                  </div>
                  <ReviewBox>
                    <VideopWrapper>
                      <VideoPlayer
                        videoSrc={selectedReview?.video}
                        thumbnail={selectedReview?.thumbnail}
                      />
                    </VideopWrapper>
                  </ReviewBox>
                </div>
                <Banner
                  section_name="F1"
                  className="default-image-banner"
                ></Banner>
                <div>
                  <ReviewDescription>{trans.description}</ReviewDescription>
                  <ArticleDetailsInfoP
                    style={{ marginBottom: '10px', color: '#000' }}
                  >
                    {parse(selectedReview?.description)}
                  </ArticleDetailsInfoP>
                  <ReviewerInfo>
                    <ReviewerInfoContainer>
                      <Avatar
                        alt="Image"
                        src={selectedReview?.reviewer?.reviewer_images}
                        style={{ width: '60px', height: '60px' }}
                      />
                      <ReviewerName>
                        {selectedReview?.reviewer.name}
                      </ReviewerName>
                    </ReviewerInfoContainer>
                    <ArticleDetailsInfoP style={{ color: '#000' }}>
                      {parse(selectedReview?.reviewer?.autobiography)}
                    </ArticleDetailsInfoP>
                  </ReviewerInfo>
                </div>
              </ArticleDetailsInfo>
              {reviews && reviews.length > 0 && (
                <MoreReviews
                  className="more-review-card-container"
                  lang={lang}
                  reviews={reviews}
                  trans={trans}
                  key={Math.random()}
                  onClick={handleReviewClick}
                />
              )}
            </FeaturedDetailsPage>
          </>
        )}
      </main>
    </>
  );
}
