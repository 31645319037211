import {
  useState,
  useContext,
  SetStateAction,
  Dispatch,
  useMemo,
  useEffect,
} from 'react';
import Slider from '@mui/material/Slider';
import {
  ListItemText,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { AppContext } from '../../../contexts/AppContext';
import { useBrands } from '../../../common/hooks/brand.hooks.';
import { useModelsForUsedCars } from '../../../common/hooks/model.hooks';
import { useGetCarTypes } from '../../../common/hooks/type.hooks';
import { useTransmissionNames } from '../../../common/hooks/transmission.hooks';
import { useRentalDealerNames } from '../../../common/hooks/rentalDealer.hooks';
import {
  smallBaggageSizes,
  largeBaggageSizes,
  seats,
} from '../constants/filterConstants';
import { MenuProps, TRentalFilters, selectStyle } from './RentalsFilter';
import { IModel } from '../../../types/resources/cars';
import '../../../styles/css/filters-styles.css';

interface IRentalsAdvancedFilterProps {
  showAdvancedSearch: boolean;
  filters: TRentalFilters;
  setFilters: Dispatch<SetStateAction<TRentalFilters>>;
  toggleAdvancedSearch: (show: any, isSearchNewCars?: any) => void;
  handleSearch: () => void;
  handleClear: () => void;
}

function RentalsAdvancedFilter({
  showAdvancedSearch,
  filters,
  setFilters,
  toggleAdvancedSearch,
  handleClear,
  handleSearch,
}: IRentalsAdvancedFilterProps) {
  const { trans } = useContext(AppContext);

  const [priceRange, setPriceRange] = useState<number[]>([0, 1000000]);

  const handlePriceRangeChange = (
    event: Event,
    newValue: number | number[],
  ) => {
    setFilters({
      ...filters,
      minPrice: newValue[0],
      maxPrice: newValue[1],
    });
    setPriceRange(newValue as number[]);
  };

  const sliderTheme = createTheme({
    palette: {
      primary: {
        light: '#00d5bb',
        main: '#00d5bb',
        dark: '#00d5bb',
        contrastText: '#00d5bb',
      },
    },
  });

  const { data: brands } = useBrands({});

  const { data: models, isFetching: isFetchingModels } = useModelsForUsedCars({
    brandIds: filters.brandIds,
  });

  const { data: types } = useGetCarTypes();

  const { data: transmissions } = useTransmissionNames();

  const { data: rentalDealersNames } = useRentalDealerNames();

  const availableTypes = useMemo(() => {
    // Get selected models
    const selectedModels = models?.filter(model =>
      filters.modelIds?.includes(model.id),
    );

    // Get types of selected models
    const types = selectedModels?.map(model => model.types);

    // Get available types
    const availableTypes: IModel['types'] = [];
    types?.forEach(type => {
      type.forEach(t => {
        if (!availableTypes.find(availableType => availableType.id === t.id)) {
          availableTypes.push(t);
        }
      });
    });

    return availableTypes;
  }, [filters.modelIds]);

  useEffect(() => {
    setFilters({
      ...filters,
      types: filters.types?.filter(typeId =>
        availableTypes.find(type => type.id === typeId),
      ),
    });
  }, [availableTypes]);

  //#endregion

  const handleBrandChange = (
    event: SelectChangeEvent<typeof filters.brandIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        brandIds: value,
        modelIds: filters.modelIds?.filter(modelId => {
          const model = models?.find(model => model.id === modelId);
          return model ? value.includes(model.brand_id) : false;
        }),
      });
    }
  };

  const handleModelChange = (
    event: SelectChangeEvent<typeof filters.modelIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        modelIds: value,
      });
    }
  };

  const handleTypeChange = (event: SelectChangeEvent<typeof filters.types>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        types: value,
      });
    }
  };

  const handleTransmissionChange = (
    event: SelectChangeEvent<typeof filters.transmissions>,
  ) => {
    const {
      target: { value },
    } = event;

    setFilters({
      ...filters,
      transmissions: value as number,
    });
  };

  const handleCompanyChange = (
    event: SelectChangeEvent<typeof filters.companies>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        companies: value,
      });
    }
  };

  const renderBrandValue = (selected: Array<string | number>) => {
    if (!selected || selected.length === 0) {
      return trans.any;
    }

    return selected
      .map(id => {
        const brand = brands?.find(brand => brand.id === id);
        return brand?.name;
      })
      .join(', ');
  };

  const renderModelValue = (selected: Array<string | number>) => {
    if (isFetchingModels) return <p>Loading...</p>;
    if (selected.length === 0) {
      return trans.any;
    }

    const filteredModels = models?.filter(model => selected.includes(model.id));
    return filteredModels?.map(model => model.name).join(', ');
  };

  const typesToRender = useMemo(() => {
    if (availableTypes.length === 0 && !types) return [];

    return availableTypes.length > 0 ? availableTypes : types;
  }, [availableTypes, types]);

  const renderTypeValue = (selected: Array<string | number>) => {
    if (selected.length === 0) {
      return trans.any;
    }

    return selected
      .map(id => {
        const type = typesToRender?.find(type => type.id === id);
        return type?.name;
      })
      .join(', ');
  };

  const renderTransmissionValue = (selected: string | number) => {
    const transmission = transmissions?.find(
      transmission => transmission.id === selected,
    );
    return transmission?.name || trans.any;
  };

  const renderCompanyValue = (selected: Array<string | number>) => {
    if (!selected || selected.length === 0) {
      return trans.any;
    }

    return selected
      .map(id => {
        const dealer = rentalDealersNames?.find(dealer => dealer.id === id);
        return dealer?.name;
      })
      .join(', ');
  };

  const handleFilterChange = (e: any) => {
    const {
      target: { name, value },
    } = e;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const renderFilterValue = (selected: Array<string | number>) => {
    if (!selected || selected.length === 0) {
      return trans.any;
    }

    return selected;
  };

  //#endregion
  return (
    <Modal
      className="modal fade modal-center advance-search-modal show"
      open={showAdvancedSearch}
      style={{
        top: '40px',
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{trans.advanced_search}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => toggleAdvancedSearch(false)}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <form>
              <div className="row generic-form">
                <div className="col-lg-12">
                  <h3>{trans.car_details}</h3>
                </div>
                {/* BRAND */}
                <div className="col-md-4 col-sm-6 form-group">
                  <label
                    htmlFor="brands"
                    style={{
                      fontSize: '15px',
                    }}
                  >
                    {trans.brand}
                  </label>
                  <Select
                    id="brands"
                    multiple
                    value={filters?.brandIds}
                    onChange={handleBrandChange}
                    className="custom-select"
                    renderValue={renderBrandValue}
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                    disabled={!brands}
                  >
                    {brands?.map(brand => (
                      <MenuItem key={brand.id} value={brand.id}>
                        <ListItemText primary={brand.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* MODEL */}
                <div className="col-md-4 col-sm-6 form-group">
                  <label
                    htmlFor="models"
                    style={{
                      fontSize: '15px',
                    }}
                  >
                    {trans.model}
                  </label>
                  <Select
                    id="models"
                    multiple
                    value={filters.modelIds}
                    onChange={handleModelChange}
                    className="custom-select mui-fixed"
                    renderValue={renderModelValue}
                    MenuProps={MenuProps}
                    displayEmpty
                    disabled={!models}
                    style={selectStyle}
                  >
                    {models?.map(model => (
                      <MenuItem key={model.id} value={model.id}>
                        <ListItemText primary={model.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* TYPE */}
                <div className="col-md-4 col-sm-6 form-group">
                  <label
                    htmlFor="types"
                    style={{
                      fontSize: '15px',
                    }}
                  >
                    {trans.type}
                  </label>
                  <Select
                    id="types"
                    multiple
                    value={filters.types}
                    onChange={handleTypeChange}
                    className="custom-select"
                    renderValue={renderTypeValue}
                    disabled={!typesToRender || typesToRender.length === 0}
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                  >
                    {typesToRender?.map(type => (
                      <MenuItem key={type.id} value={type.id}>
                        <ListItemText primary={type.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="col-lg-12">
                  <hr className="hr-lines" />
                </div>
                {/* COMPANY */}
                <div className="col-md-4 col-sm-6 form-group">
                  <label
                    htmlFor="companies"
                    style={{
                      fontSize: '15px',
                    }}
                  >
                    {trans.company}
                  </label>
                  <Select
                    id="companies"
                    multiple
                    value={filters.companies}
                    onChange={handleCompanyChange}
                    className="custom-select"
                    renderValue={renderCompanyValue}
                    disabled={
                      !rentalDealersNames || rentalDealersNames.length === 0
                    }
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                  >
                    {rentalDealersNames?.map(dealer => (
                      <MenuItem key={dealer.id} value={dealer.id}>
                        <ListItemText primary={dealer.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* TRANSMISSION */}
                <div className="col-md-4 col-sm-6 form-group">
                  <label
                    htmlFor="transmissions"
                    style={{
                      fontSize: '15px',
                    }}
                  >
                    {trans.transmission}
                  </label>
                  <Select
                    id="transmissions"
                    value={filters.transmissions}
                    onChange={handleTransmissionChange}
                    className="custom-select"
                    renderValue={value => {
                      return renderTransmissionValue(
                        value as unknown as number,
                      );
                    }}
                    disabled={!transmissions || transmissions.length === 0}
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                  >
                    {transmissions?.map(transmission => (
                      <MenuItem key={transmission.id} value={transmission.id}>
                        <ListItemText primary={transmission.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* SEATS */}
                <div className="col-md-4 col-sm-6 form-group">
                  <label htmlFor="seats" style={{ fontSize: '15px' }}>
                    {trans.seats}
                  </label>
                  <Select
                    id="seats"
                    value={filters.seats}
                    onChange={e =>
                      handleFilterChange({
                        target: {
                          name: 'seats',
                          value: e.target.value,
                        },
                      })
                    }
                    renderValue={renderFilterValue}
                    className="custom-select"
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                  >
                    {seats.map((seat: any) => (
                      <MenuItem key={seat.id} value={seat.id}>
                        <ListItemText primary={seat.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="col-lg-12">
                  <hr className="hr-lines" />
                </div>
                {/* LARGE BAGAGGE */}
                <div className="col-md-4 col-sm-6 form-group">
                  <label
                    htmlFor="largeBaggageSizes"
                    style={{ fontSize: '15px' }}
                  >
                    {trans.large_baggage_capacity}
                  </label>
                  <Select
                    id="largeBaggageSizes"
                    value={filters.largeBaggageSizes}
                    onChange={e =>
                      handleFilterChange({
                        target: {
                          name: 'largeBaggageSizes',
                          value: e.target.value,
                        },
                      })
                    }
                    renderValue={renderFilterValue}
                    className="custom-select"
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                  >
                    {largeBaggageSizes.map((size: any) => (
                      <MenuItem key={size.id} value={size.id}>
                        <ListItemText primary={size.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* SMALL BAGGAGE */}
                <div className="col-md-4 col-sm-6 form-group">
                  <label
                    htmlFor="smallBaggageSizes"
                    style={{ fontSize: '15px' }}
                  >
                    {trans.small_baggage_capacity}
                  </label>
                  <Select
                    id="smallBaggageSizes"
                    value={filters.smallBaggageSizes}
                    onChange={e =>
                      handleFilterChange({
                        target: {
                          name: 'smallBaggageSizes',
                          value: e.target.value,
                        },
                      })
                    }
                    renderValue={renderFilterValue}
                    className="custom-select"
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                  >
                    {smallBaggageSizes.map((size: any) => (
                      <MenuItem key={size.id} value={size.id}>
                        <ListItemText primary={size.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/* PRICE */}
                <div className="col-md-4 col-sm-6 form-group">
                  <div>
                    <label htmlFor="year-from">
                      {trans.price} ({trans.qar})
                    </label>
                    <ThemeProvider theme={sliderTheme}>
                      <Slider
                        color={'primary'}
                        min={0}
                        max={10000}
                        step={50}
                        value={priceRange}
                        onChange={handlePriceRangeChange}
                      />
                    </ThemeProvider>
                    <div className="slider-labels">
                      <div className="caption">
                        <span id="slider-range-value1">{priceRange[0]}</span>
                      </div>
                      <div
                        className={`right caption ${
                          priceRange[1] === 1000000 ? 'addplus' : ''
                        }`}
                      >
                        <span id="slider-range-value2">{priceRange[1]}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <hr className="hr-lines" />
              </div>
            </form>
          </div>
          <div
            style={{ marginTop: '20px', justifyContent: 'end' }}
            className="modal-footer"
          >
            <button className="btn btn-primary btn-clear" onClick={handleClear}>
              {trans.clear}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-adv-search"
              onClick={handleSearch}
            >
              {trans.search}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default RentalsAdvancedFilter;
