import { styled } from '@mui/material';

export const UsedCarsContainer = styled('main')(() => ({
  position: 'relative',
}));

export const ListingsFiltersBarContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
}));

export const UsedCarsFilterButton = styled('button')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '230px',
  alignItems: 'center',
  border: '1px solid #c4c4c4',
  borderRadius: '8px',
  height: '38px',
  padding: '0.375rem 1.75rem 0.375rem 0.75rem',
  color: '#4e4e4e',
  backgroundColor: '#fff',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: 700,
  ':hover': {
    backgroundColor: '#fff',
    borderColor: '#171717',
  },
}));

export const CarInfoColumnDl = styled('dl')(() => ({
  margin: '0 -55px 0 0',
  fontSize: '12px',
  lineHeight: '15px',
  color: '#4e4e4e',
  '.lang-au &': {
    margin: '0 0 0 -55px',
  },
}));

export const CarInfoColumnDlDt = styled('dt')(() => ({
  float: 'left',
  width: '80px',
  fontWeight: 400,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  paddingRight: '8px',
  '.lang-au &': {
    float: 'right',
    paddingRight: 0,
    paddingLeft: '8px',
    direction: 'rtl',
  },
}));

export const CarInfoColumnDlDd = styled('dd')(() => ({
  margin: 0,
  fontWeight: 700,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '.lang-au &': {
    textAlign: 'right',
  },
}));
