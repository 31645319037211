import React, { useState } from 'react';
import { BikeDealerLogoSkeleton } from '../BikeDetailsSkeletons';

export interface BikeDealerLogoProps {
  defaultSrc?: string;
  src: string | undefined;
  alt?: string;
  class?: string;
  onClick?: any;
  style?: React.CSSProperties;
  onLoad?: any;
  width?: number;
  height?: number;
}

export const BikeDealerLogo = ({
  src,
  alt,
  class: className,
  height,
  onClick,
  style,
  width,
}: BikeDealerLogoProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  return (
    <>
      {isImageLoading && <BikeDealerLogoSkeleton />}
      <img
        src={src}
        className={className}
        onClick={onClick}
        alt={alt}
        style={{ ...style, display: isImageLoading ? 'none' : 'block' }}
        onLoad={() => {
          setIsImageLoading(false);
        }}
        width={width}
        height={height}
      />
    </>
  );
};
