import styled from '@emotion/styled';

export const VisualContainer = styled('section')(() => ({
  overflow: 'hidden',
  padding: 0,
  margin: '0 auto 20px',
  maxWidth: '1440px',
  width: '100%',
  '@media (max-width: 1460px)': {
    maxWidth: '100%',
  },
  '@media (max-width: 1399px)': {
    padding: 0,
  },
}));

export const Visual = styled('div')(() => ({
  overflow: 'hidden',
  background: '#20282C',
  '@media (max-width: 1460px)': {
    paddingInline: '2%',
  },
}));

export const VisualH1 = styled('h1')(() => ({
  textTransform: 'uppercase',
  margin: '0 0 8px',
  fontSize: '24px',
  lineHeight: '28px',
  fontWeight: '900',
  color: '#1C2628',
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const VisualCarFindHolder = styled('div')(() => ({
  top: '50%',
  left: '18px',
  position: 'absolute',
  transform: 'translateY(-50%)',
  width: '360px',
  flexShrink: 0,
  background: '#FFF',
  borderRadius: '5px',
  padding: '29px 27px 28px 20px',
  margin: '4px 0 0',
  zIndex: 1,
  '@media (max-width: 991px)': {
    position: 'static',
    width: '100%',
    margin: '0 0 50px',
    transform: 'translateY(0)',
  },
  '.lang-au &': {
    left: 'auto',
    right: '18px',
  },
}));

export const VisualCarFindHolderH2 = styled('h2')(() => ({
  margin: '0 0 16px',
  fontSize: '16px',
  lineHeight: '1.4',
  fontWeight: 400,
  color: '#1C2628',
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const CarOptions = styled('ul')(() => ({
  listStyle: 'none',
  margin: '0 -3px 22px',
  padding: '0',
  display: 'flex',
}));

export const CarOptionsLi = styled('li')(() => ({
  padding: '0 3px',
  width: '50%',
}));

export const CarOptionsLabel = styled('label')(() => ({
  display: 'block',
  margin: 0,
}));

export const FakeInput = styled('span')(() => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: '25px',
  position: 'relative',
  width: '100%',
  ':before': {
    border: '1px solid #000',
    borderRadius: '100%',
    color: '#fff',
    fontSize: '12px',
    content: '" "',
    height: '17px',
    left: '0',
    lineHeight: '14px',
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '17px',
  },

  '.lang-au &': {
    paddingLeft: '0',
    ':before': {
      left: 'auto',
      right: '0',
    },
    '@media (max-width: 820px)': {
      paddingLeft: '0',
      paddingRight: '25px',
    },
  },
}));

export const Input = styled('input')(() => ({
  '&:checked + span:before': {
    backgroundColor: '#00d6bc',
    borderColor: '#00d6bc',
    content: '"\\2713"',
  },
}));

export const FakeLabel = styled('span')(() => ({
  color: '#000',
  fontSize: '18px',
  fontWeight: 900,
  lineHeight: '22px',
  textTransform: 'uppercase',
  '@media (max-width: 991px)': {
    fontSize: '16px',
  },
  '@media (min-width: 821px)': {
    paddingRight: '23px',
  },
  '.lang-au &': {
    fontSize: '15px',
    '@media (max-width: 479px)': {
      fontSize: '14px',
      paddingRight: '5px',
    },
    '@media (max-width: 375px)': {
      fontSize: '12px',
    },
    '@media (max-width: 344px)': {
      fontSize: '11px',
    },
  },
}));

export const SelectLists = styled('ul')(() => ({
  border: '1px solid #606060',
  borderRadius: '3px',
  listStyle: 'none',
  margin: '0 0 23px',
  padding: '0',
}));

export const SelectListsLi = styled('li')(() => ({
  borderBottom: '1px solid #606060',
  ':last-child': {
    borderBottom: 'none',
  },
}));

export const FakeSelect = styled('span')(() => ({
  display: 'block',
  position: 'relative',
  ':after': {
    borderColor: '#747474 #0000 #0000',
    borderStyle: 'solid',
    borderWidth: '9px 8px 0',
    content: '"  "',
    pointerEvents: 'none',
    position: 'absolute',
    right: '18px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: '1',
  },
  '.lang-au &': {
    ':after': {
      left: '18px',
      right: 'auto',
    },
  },
}));

export const FakeSelectSelect = styled('select')(() => ({
  backgroundColor: 'initial',
  fontSize: '17px',
  height: '49px',
  width: '100%',
  '.lang-au &': {
    padding: '4px 15px 4px 43px',
  },
}));

export const VisualBtnSearch = styled('button')(() => ({
  alignItems: 'center',
  background: '#00D9BB',
  border: 'none',
  borderRadius: '3px',
  color: '#FFF',
  display: 'flex',
  fontSize: '22px',
  fontWeight: '900',
  height: '54px',
  justifyContent: 'center',
  lineHeight: '25px',
  margin: '0 0 24px',
  transition: 'all .3s ease',
  width: '100%',
  ':hover': {
    backgroundColor: '#04e4cc',
  },
}));
