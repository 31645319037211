import { TFilters } from '../Listings/common/ListingFiltersBar';

export const clearSearchParams = (searchParams: URLSearchParams) => {
  const keysToDelete = [
    'dealer',
    'brand',
    'model',
    'maxYear',
    'minYear',
    'type',
    'sellerType',
    'maxPrice',
    'minPrice',
    'minMileage',
    'maxMileage',
    'engineSizes',
    'engineTypes',
    'cylinders',
    'batteryCapacities',
    'batteryRanges',
    'transmissions',
    'seatMaterials',
    'conditions',
    'exteriorColors',
    'interiorColors',
    'optionalFeatures',
  ];
  keysToDelete.map(key => searchParams.delete(key));
};

export const appendSearchParams = (
  searchParams: URLSearchParams,
  filteredFilters: TFilters,
) => {
  const appendArrayParams = (key: string, values: number[]) => {
    if (values && values.length > 0) {
      values.map(value => searchParams.append(key, String(value)));
    }
  };

  const appendSingleParam = (key: string, value: number) => {
    if (value && value > 0) {
      searchParams.append(key, String(value));
    }
  };

  appendArrayParams('brand', filteredFilters.brandIds || []);
  appendArrayParams('model', filteredFilters.modelIds || []);
  appendSingleParam('maxYear', filteredFilters.maxYear);
  appendSingleParam('minYear', filteredFilters.minYear);

  if (filteredFilters.sellerType === 'dealer') {
    appendArrayParams('dealer', filteredFilters.dealerIds || []);
  }
  if (filteredFilters.sellerType) {
    searchParams.append('sellerType', filteredFilters.sellerType);
  }

  appendArrayParams('type', filteredFilters.types || []);
  appendSingleParam('maxPrice', filteredFilters.maxPrice);
  appendSingleParam('minPrice', filteredFilters.minPrice);
  appendSingleParam('minMileage', filteredFilters.minMileage);
  appendSingleParam('maxMileage', filteredFilters.maxMileage);
  appendArrayParams('engineSizes', filteredFilters.engineSizes || []);
  appendArrayParams('engineTypes', filteredFilters.engineTypes || []);
  appendArrayParams('cylinders', filteredFilters.cylinders || []);
  appendArrayParams(
    'batteryCapacities',
    filteredFilters.batteryCapacities || [],
  );
  appendArrayParams('batteryRanges', filteredFilters.batteryRanges || []);
  appendArrayParams('transmissions', filteredFilters.transmissions || []);
  appendArrayParams('seatMaterials', filteredFilters.seatMaterials || []);
  appendArrayParams('conditions', filteredFilters.conditions || []);
  appendArrayParams('exteriorColors', filteredFilters.exteriorColors || []);
  appendArrayParams('interiorColors', filteredFilters.interiorColors || []);
  appendArrayParams('optionalFeatures', filteredFilters.optionalFeatures || []);
};
