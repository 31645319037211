import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import {
  AirbagsIcon,
  AndroidAutoAppleCarPlayIcon,
  CameraIcon,
  CruiseControlIcon,
  InfotainmentSystemIcon,
  KeylessStartIcon,
  NavigationSystemIcon,
  SensorsIcon,
  SunroofIcon,
  USBChargerIcon,
  WarrantyIcon,
  WirelessChargerIcon,
} from '../../Icons/OptionalFeaturesIcons';
import BluetoothIcon from './BluetoothIcon';
import GPSIcon from './GPSIcon';
import ServicePackageIcon from './ServicePackageIcon';
import ThreeSixtyDegreeCameraIcon from './ThreeSixtyDegreeCameraIcon';
import '../../../styles/css/common.css';
interface IOptionalFeaturesListItemProps {
  name: string;
  onClick?: () => void;
  checked?: boolean;
  iconColor?: string;
}

const OptionalFeatureIcon = ({
  checked = false,
  name,
  iconColor,
}: IOptionalFeaturesListItemProps) => {
  const { lang } = useContext(AppContext);
  const defaultOutlineColor = '#191E29';
  const selectedOutlineColor = iconColor;

  const optionalFeaturesIcons = [
    {
      name: {
        en: 'Airbags',
        ar: 'وسائد هوائية',
      },
      icon: (
        <AirbagsIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'Android Auto & Apple CarPlay',
        ar: 'أندرويد وأبل كاربلاي',
      },
      icon: (
        <AndroidAutoAppleCarPlayIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'Bluetooth',
        ar: 'بلوتوث',
      },
      icon: (
        <BluetoothIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'Camera',
        ar: 'كاميرا',
      },
      icon: (
        <CameraIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'Cruise Control',
        ar: 'نظام تثبيت السرعة',
      },
      icon: (
        <CruiseControlIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'GPS',
        ar: 'نظام تحديد المواقع',
      },
      icon: (
        <GPSIcon color={checked ? selectedOutlineColor : defaultOutlineColor} />
      ),
    },
    {
      name: {
        en: 'Infotainment system',
        ar: 'نظام المعلومات والترفيه',
      },
      icon: (
        <InfotainmentSystemIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'Keyless Start',
        ar: 'مفتاح إلكتروني',
      },
      icon: (
        <KeylessStartIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'Navigation system',
        ar: 'نظام ملاحة',
      },
      icon: (
        <NavigationSystemIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'Sensors',
        ar: 'أجهزة الاستشعار',
      },
      icon: (
        <SensorsIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'Service Package',
        ar: 'خدمة صيانة',
      },
      icon: (
        <ServicePackageIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'Sunroof',
        ar: 'فتحة في السقف',
      },
      icon: (
        <SunroofIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: '360 Degree Camera',
        ar: 'كاميرا 360 درجة',
      },
      icon: (
        <ThreeSixtyDegreeCameraIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'USB Charger',
        ar: 'شاحن USB',
      },
      icon: (
        <USBChargerIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'Warranty',
        ar: 'ضمان',
      },
      icon: (
        <WarrantyIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
    {
      name: {
        en: 'Wireless Charger',
        ar: 'شاحن لاسلكي',
      },
      icon: (
        <WirelessChargerIcon
          color={checked ? selectedOutlineColor : defaultOutlineColor}
        />
      ),
    },
  ];

  return (
    <div className="optional-feature-card-icon">
      {optionalFeaturesIcons.find(icon => icon.name[lang] === name)?.icon ?? ''}
    </div>
  );
};

export default OptionalFeatureIcon;
