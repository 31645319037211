import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import './header-styles.css';

const Header = ({ edit, setEdit, updateAccount }: any) => {
  const { trans, lang } = useContext(AppContext);
  const toggleEditMode = () => setEdit(true);

  return (
    <header className="accounts-header details">
      <h2>{trans.account_details}</h2>
      {!edit ? (
        <div className="accounts-edit" onClick={toggleEditMode}>
          <i className="fa fa-edit"></i> {trans.edit}
        </div>
      ) : (
        <button
          className={
            lang == 'ar' ? 'float-left btn-primary' : 'float-right btn-primary'
          }
          style={{
            minWidth: '60px',
            position: lang == 'ar' ? 'relative' : 'absolute',
            right: lang == 'ar' ? '0' : '15px',
            top: '50%',
            display: 'flex',
            alignItems: 'center',
            transform: lang == 'ar' ? 'translateY(-75%)' : 'translateY(-50%)',
            justifyContent: 'center',
          }}
          onClick={updateAccount}
        >
          {trans.save}
        </button>
      )}
    </header>
  );
};

export default Header;
