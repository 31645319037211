export const KeylessStartIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6311 8.36111C22.9738 8.05087 22.1914 8.33219 21.8811 8.98842C21.5709 9.64466 21.8522 10.4281 22.5084 10.7384C25.4804 12.1434 27.3908 15.1753 27.3755 18.4623C27.3634 20.7292 26.4685 22.8556 24.8573 24.452C23.2451 26.0469 21.0777 26.9171 18.8413 26.9066C14.1614 26.8829 10.3728 23.0554 10.3959 18.374C10.4133 15.1007 12.2568 12.1844 15.2062 10.7636C15.8604 10.4481 16.1348 9.66254 15.8193 9.00841C15.5049 8.35532 14.7193 8.07979 14.0652 8.39424C10.2024 10.2551 7.78887 14.0737 7.76626 18.3603C7.73523 24.4915 12.697 29.5042 18.8271 29.5352C18.8476 29.5352 18.8671 29.5352 18.8876 29.5352C21.8348 29.5352 24.6091 28.3952 26.7061 26.3198C28.8168 24.2307 29.9873 21.4448 30.0036 18.4755C30.0252 14.1705 27.5233 10.2015 23.6311 8.36111Z"
        fill={color}
      />
      <path
        d="M18.8839 19.9341C19.6101 19.9341 20.1985 19.3457 20.1985 18.6196V6.27307C20.1985 5.5469 19.6101 4.9585 18.8839 4.9585C18.1588 4.9585 17.5693 5.5469 17.5693 6.27307V18.6196C17.5693 19.3457 18.1588 19.9341 18.8839 19.9341Z"
        fill={color}
      />
      <path
        d="M18.8835 0.322998C8.94743 0.322998 0.862793 8.40764 0.862793 18.3437C0.862793 28.2809 8.94743 36.3655 18.8835 36.3655C28.8207 36.3655 36.9053 28.2809 36.9053 18.3437C36.9058 8.40764 28.8212 0.322998 18.8835 0.322998ZM18.8835 33.7364C10.3961 33.7364 3.49194 26.8317 3.49194 18.3437C3.49194 9.8563 10.3966 2.95215 18.8835 2.95215C27.3704 2.95215 34.2761 9.85682 34.2761 18.3437C34.2767 26.8322 27.3709 33.7364 18.8835 33.7364Z"
        fill={color}
      />
    </svg>
  );
};
