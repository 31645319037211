import {
  createTheme,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Switch,
  ThemeProvider,
} from '@mui/material';
import Slider from '@mui/material/Slider';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AppContext } from '../../contexts/AppContext';
import { MenuProps, TFilters } from '../Listings/common/ListingFiltersBar';
import { useBrands } from '../../common/hooks/brand.hooks.';
import { useModelsForUsedCars } from '../../common/hooks/model.hooks';
import {
  IBatteryCapacity,
  IBatteryRange,
  ICylinder,
  IEngineSize,
  IModel,
  ITransmission,
} from '../../types/resources/cars';
import { useGetCarTypes } from '../../common/hooks/type.hooks';
import { useEngineTypesWithResources } from '../../common/hooks/engine_type.hooks';
import { useDealers } from '../Dealers/hooks/useDealersApis';
import {
  IEngineTypeResources,
  IEngineTypeWithResources,
} from '../../common/apis/engine_type.apis';
import { useGetSeatMaterials } from '../../common/hooks/seat_material.hooks';
import { useGetInteriorColors } from '../../common/hooks/interior_color.hooks';
import { useGetExteriorColors } from '../../common/hooks/exterior_color.hooks';
import { useGetConditions } from '../../common/hooks/condition.hooks';
import { useGetOptionalFeatures } from '../../common/hooks/optional_feature.hooks';
import PageLoader from './PageLoader/PageLoaderComponent';
import OptionalFeaturesListItem from '../Listings/common/OptionalFeaturesListItem';
import { formatPrice } from '../../helpers/formatHelper';
import '../../styles/css/common.css';
import '../../styles/css/filters-styles.css';

interface IAdvancedSearchComponentProps {
  showAdvancedSearch: boolean;
  filters: TFilters;
  setFilters: Dispatch<SetStateAction<TFilters>>;
  toggleAdvancedSearch: (show: any, isSearchNewCars?: any) => void;
  handleSearch: () => void;
  handleClear: () => void;
}

type TKey = keyof IEngineTypeResources;

const getUniqueObjectsById = (array: IEngineTypeWithResources[], key: TKey) => {
  const uniqueObjects: any = [];
  array.forEach(object => {
    object[key].forEach(transmission => {
      if (!uniqueObjects.find(o => o.id === transmission.id)) {
        uniqueObjects.push(transmission);
      }
    });
  });

  return uniqueObjects;
};

const selectStyle = {
  color: '#4E4E4E',
};

export default function AdvancedSearch({
  showAdvancedSearch,
  filters,
  setFilters,
  toggleAdvancedSearch,
  handleSearch,
  handleClear,
}: IAdvancedSearchComponentProps) {
  const { trans, lang } = useContext(AppContext);

  const maxPrice = 1000000;

  const [priceRange, setPriceRange] = useState<number[]>([
    filters?.minPrice !== -1 ? filters?.minPrice : 0,
    filters?.maxPrice !== -1 ? filters?.maxPrice : maxPrice,
  ]);

  const handlePriceRangeChange = (
    event: Event,
    newValue: number | number[],
  ) => {
    setFilters({
      ...filters,
      minPrice: newValue[0],
      maxPrice: newValue[1],
    });
    setPriceRange(newValue as number[]);
  };

  const [mileageRange, setMileageRange] = useState<number[]>([
    filters?.minMileage !== -1 ? filters?.minMileage : 0,
    filters?.maxMileage !== -1 ? filters?.maxMileage : 200000,
  ]);

  const handleMileageRangeChange = (
    event: Event,
    newValue: number | number[],
  ) => {
    setFilters({
      ...filters,
      minMileage: newValue[0],
      maxMileage: newValue[1],
    });
    setMileageRange(newValue as number[]);
  };

  const [usedYearRange, setUsedYearRange] = useState<number[]>([
    filters?.minYear !== -1 ? filters?.minYear : 1940,
    filters?.maxYear !== -1 ? filters?.maxYear : new Date().getFullYear() + 1,
  ]);

  const handleUsedYearRangeChange = (
    event: Event,
    newValue: number | number[],
  ) => {
    setFilters({
      ...filters,
      minYear: newValue[0],
      maxYear: newValue[1],
    });
    setUsedYearRange(newValue as number[]);
  };

  const sliderTheme = createTheme({
    palette: {
      primary: {
        light: '#00d5bb',
        main: '#00d5bb',
        dark: '#00d5bb',
        contrastText: '#00d5bb',
      },
    },
  });

  const handleSellerChange = (e: SelectChangeEvent) => {
    setFilters({
      ...filters,
      sellerType: e.target.value,
      dealerIds: [],
    });
  };

  // API Calls

  const { data: brands } = useBrands({});

  const { data: models, isFetching: isFetchingModels } = useModelsForUsedCars({
    brandIds: filters.brandIds,
  });

  const { data: types } = useGetCarTypes();

  const { data: engineTypes } = useEngineTypesWithResources();

  const { data: dealers } = useDealers();

  const usedCarsDealers = useMemo(() => {
    return dealers?.filter(dealer => dealer._count.used_cars > 0);
  }, [dealers]);

  const { data: seatMaterials } = useGetSeatMaterials();

  const { data: interiorColors } = useGetInteriorColors();

  const { data: exteriorColors } = useGetExteriorColors();

  const { data: conditions } = useGetConditions();

  const { data: optionalFeatures, isLoading: loadingOptionalFeatures } =
    useGetOptionalFeatures();

  // Memos for Engine Types //
  const selectedEngineTypes = useMemo(() => {
    if (!engineTypes) return [];

    return engineTypes.filter(engineType =>
      filters.engineTypes?.includes(engineType.id),
    );
  }, [filters.engineTypes]);

  const engineSizes: IEngineSize[] = useMemo(() => {
    if (!engineTypes) return [];

    if (selectedEngineTypes.length === 0)
      return getUniqueObjectsById(engineTypes, 'engine_sizes');

    const engineSizes: IEngineSize[] = [];

    selectedEngineTypes.forEach(engineType => {
      engineType.engine_sizes.forEach(engineSize => {
        if (!engineSizes.find(size => size.id === engineSize.id)) {
          engineSizes.push(engineSize);
        }
      });
    });

    return engineSizes;
  }, [selectedEngineTypes, engineTypes]);

  useEffect(() => {
    setFilters({
      ...filters,
      engineSizes: filters.engineSizes?.filter(engineSize =>
        engineSizes.find(size => size.id === engineSize),
      ),
    });
  }, [engineSizes]);

  const cylinders: ICylinder[] = useMemo(() => {
    if (!engineTypes) return [];

    if (selectedEngineTypes.length === 0)
      return getUniqueObjectsById(engineTypes, 'cylinders');

    const cylinders: ICylinder[] = [];

    selectedEngineTypes.forEach(engineType => {
      engineType.cylinders.forEach(cylinder => {
        if (!cylinders.find(c => c.id === cylinder.id)) {
          cylinders.push(cylinder);
        }
      });
    });

    return cylinders;
  }, [selectedEngineTypes, engineTypes]);

  useEffect(() => {
    setFilters({
      ...filters,
      cylinders: filters.cylinders?.filter(cylinder =>
        cylinders.find(c => c.id === cylinder),
      ),
    });
  }, [cylinders]);

  const transmissions: ITransmission[] = useMemo(() => {
    if (!engineTypes) return [];

    if (selectedEngineTypes.length === 0) {
      return getUniqueObjectsById(engineTypes, 'transmissions');
    }

    const transmissions: ITransmission[] = [];
    selectedEngineTypes.forEach(engineType => {
      engineType.transmissions.forEach(transmission => {
        if (!transmissions.find(t => t.id === transmission.id)) {
          transmissions.push(transmission);
        }
      });
    });

    return transmissions;
  }, [selectedEngineTypes, engineTypes]);

  useEffect(() => {
    setFilters({
      ...filters,
      transmissions: filters.transmissions?.filter(transmission =>
        transmissions.find(t => t.id === transmission),
      ),
    });
  }, [transmissions]);

  const batteryCapacities: IBatteryCapacity[] = useMemo(() => {
    if (!engineTypes) return [];

    if (selectedEngineTypes.length === 0)
      return getUniqueObjectsById(engineTypes, 'battery_capacities');

    const batteryCapacities: IBatteryCapacity[] = [];
    selectedEngineTypes.forEach(engineType => {
      engineType.battery_capacities.forEach(capacity => {
        if (!batteryCapacities.find(c => c.id === capacity.id)) {
          batteryCapacities.push(capacity);
        }
      });
    });

    return batteryCapacities;
  }, [selectedEngineTypes, engineTypes]);

  useEffect(() => {
    setFilters({
      ...filters,
      batteryCapacities: filters.batteryCapacities?.filter(capacity =>
        batteryCapacities.find(c => c.id === capacity),
      ),
    });
  }, [batteryCapacities]);

  const batteryRanges: IBatteryRange[] = useMemo(() => {
    if (!engineTypes) return [];

    if (selectedEngineTypes.length === 0)
      return getUniqueObjectsById(engineTypes, 'battery_ranges');

    const batteryRanges: IBatteryRange[] = [];

    selectedEngineTypes.forEach(engineType => {
      engineType.battery_ranges.forEach(range => {
        if (!batteryRanges.find(r => r.id === range.id)) {
          batteryRanges.push(range);
        }
      });
    });

    return batteryRanges;
  }, [selectedEngineTypes, engineTypes]);

  useEffect(() => {
    setFilters({
      ...filters,
      batteryRanges: filters.batteryRanges?.filter(range =>
        batteryRanges.find(r => r.id === range),
      ),
    });
  }, [batteryRanges]);

  const handleBrandChange = (
    event: SelectChangeEvent<typeof filters.brandIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        brandIds: value,
        modelIds: filters.modelIds?.filter(modelId => {
          const model = models?.find(model => model.id === modelId);
          return model ? value.includes(model.brand_id) : false;
        }),
      });
    }
  };

  const renderBrandValue = (selected: Array<string | number>) => {
    if (!selected || selected.length === 0) {
      return trans.brand;
    }

    return selected
      .map(id => {
        const brand = brands?.find(brand => brand.id === id);
        return brand?.name;
      })
      .join(', ');
  };

  const handleModelChange = (
    event: SelectChangeEvent<typeof filters.modelIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        modelIds: value,
      });
    }
  };

  const renderModelValue = (selected: Array<string | number>) => {
    if (isFetchingModels) return <p>Loading...</p>;
    if (selected.length === 0) {
      return trans.model;
    }

    const filteredModels = models?.filter(model => selected.includes(model.id));
    return filteredModels?.map(model => model.name).join(', ');
  };

  const handleTypeChange = (event: SelectChangeEvent<typeof filters.types>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        types: value,
      });
    }
  };

  const availableTypes = useMemo(() => {
    // Get selected models
    const selectedModels = models?.filter(model =>
      filters.modelIds?.includes(model.id),
    );

    // Get types of selected models
    const types = selectedModels?.map(model => model.types);

    // Get available types
    const availableTypes: IModel['types'] = [];
    types?.forEach(type => {
      type.forEach(t => {
        if (!availableTypes.find(availableType => availableType.id === t.id)) {
          availableTypes.push(t);
        }
      });
    });

    return availableTypes;
  }, [filters.modelIds]);

  useEffect(() => {
    setFilters({
      ...filters,
      types: filters.types?.filter(typeId =>
        availableTypes.find(type => type.id === typeId),
      ),
    });
  }, [availableTypes]);

  const typesToRender = useMemo(() => {
    if (availableTypes.length === 0 && !types) return [];

    return availableTypes.length > 0 ? availableTypes : types;
  }, [availableTypes, types]);

  const renderTypeValue = (selected: Array<string | number>) => {
    if (selected.length === 0) {
      return trans.type;
    }

    return selected
      .map(id => {
        const type = typesToRender?.find(type => type.id === id);
        return type?.name;
      })
      .join(', ');
  };

  const handleDealerChange = (
    event: SelectChangeEvent<typeof filters.dealerIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        dealerIds: value,
      });
    }
  };

  const renderDealerValue = (selected: Array<string | number>) => {
    if (selected.length === 0) {
      return trans.any_dealer;
    }

    return selected
      .map(id => {
        const dealer = dealers?.find(dealer => dealer.id === id);
        return dealer?.name;
      })
      .join(', ');
  };

  const handleEngineTypeChange = (
    event: SelectChangeEvent<typeof filters.engineTypes>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        engineTypes: value,
      });
    }
  };

  const renderEngineTypeValue = (selected: Array<string | number>) => {
    if (selected.length === 0) return trans.engine_type;

    return selected
      .map(id => {
        const engineType = engineTypes?.find(
          engineType => engineType.id === id,
        );
        return engineType?.name;
      })
      .join(', ');
  };

  const handleEngineSizeChange = (
    event: SelectChangeEvent<typeof filters.engineSizes>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        engineSizes: value,
      });
    }
  };

  const renderEngineSizeValue = (selected: Array<string | number>) => {
    if (selected.length === 0) return trans.engine_size;

    return (
      selected
        .map(id => {
          const engineSize = engineSizes?.find(
            engineSize => engineSize.id === id,
          );
          return engineSize?.name;
        })
        .filter(Boolean)
        .join(', ') || trans.engine_size
    );
  };

  const handleCylindersChange = (
    event: SelectChangeEvent<typeof filters.cylinders>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        cylinders: value,
      });
    }
  };

  const renderCylindersValue = (selected: Array<string | number>) => {
    if (selected.length === 0) return trans.cylinders;

    return (
      selected
        .map(id => {
          const cylinder = cylinders?.find(cylinder => cylinder.id === id);
          return cylinder?.name;
        })
        .filter(Boolean)
        .join(', ') || trans.cylinders
    );
  };

  const handleTransmissionChange = (
    event: SelectChangeEvent<typeof filters.transmissions>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        transmissions: value,
      });
    }
  };

  const renderTransmissionValue = (selected: Array<string | number>) => {
    if (selected.length === 0) return trans.transmission;

    return (
      selected
        .map(id => {
          const transmission = transmissions?.find(
            transmission => transmission.id === id,
          );
          return transmission?.name;
        })
        .filter(Boolean)
        .join(', ') || []
    );
  };

  const handleBatteryCapacityChange = (
    event: SelectChangeEvent<typeof filters.batteryCapacities>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        batteryCapacities: value,
      });
    }
  };

  const renderBatteryCapacityValue = (selected: Array<string | number>) => {
    if (selected.length === 0) return trans.battery_capacity;

    return (
      selected
        .map(id => {
          const batteryCapacity = batteryCapacities.find(
            batteryCapacity => batteryCapacity.id === id,
          );
          return batteryCapacity?.name;
        })
        .filter(Boolean)
        .join(', ') || []
    );
  };

  const handleBatteryRangeChange = (
    event: SelectChangeEvent<typeof filters.batteryRanges>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        batteryRanges: value,
      });
    }
  };

  const renderBatteryRangeValue = (selected: Array<string | number>) => {
    if (selected.length === 0) return trans.battery_range;

    return (
      selected
        .map(id => {
          const batteryRange = batteryRanges?.find(
            batteryRange => batteryRange.id === id,
          );
          return batteryRange?.name;
        })
        .filter(Boolean)
        .join(', ') || []
    );
  };

  const handleSeatMaterialChange = (
    event: SelectChangeEvent<typeof filters.seatMaterials>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        seatMaterials: value,
      });
    }
  };

  const renderSeatMaterialValue = (selected: Array<string | number>) => {
    if (selected.length === 0) return trans.seat_material;

    return (
      selected
        .map(id => {
          const seatMaterial = seatMaterials?.find(
            seatMaterial => seatMaterial.id === id,
          );
          return seatMaterial?.name;
        })
        .filter(Boolean)
        .join(', ') || []
    );
  };

  const handleConditionChange = (
    event: SelectChangeEvent<typeof filters.conditions>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        conditions: value,
      });
    }
  };

  const renderConditionValue = (selected: Array<string | number>) => {
    if (selected.length === 0) return trans.car_condition;

    return (
      selected
        .map(id => {
          const condition = conditions?.find(condition => condition.id === id);
          return condition?.name;
        })
        .filter(Boolean)
        .join(', ') || []
    );
  };

  const handleExteriorColorChange = (
    event: SelectChangeEvent<typeof filters.exteriorColors>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({ ...filters, exteriorColors: value });
    }
  };

  const renderExteriorColorValue = (selected: Array<string | number>) => {
    if (selected.length === 0) return trans.exterior_color;

    return (
      selected
        .map(id => {
          const exteriorColor = exteriorColors?.find(
            exteriorColor => exteriorColor.id === id,
          );
          return exteriorColor?.name;
        })
        .filter(Boolean)
        .join(', ') || []
    );
  };

  const handleInteriorColorChange = (
    event: SelectChangeEvent<typeof filters.interiorColors>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({ ...filters, interiorColors: value });
    }
  };

  const renderInteriorColorValue = (selected: Array<string | number>) => {
    if (selected.length === 0) return trans.interior_color;

    return (
      selected
        .map(id => {
          const interiorColor = interiorColors?.find(
            interiorColor => interiorColor.id === id,
          );
          return interiorColor?.name;
        })
        .filter(Boolean)
        .join(', ') || []
    );
  };

  const handleOptionalFeatureChange = (id: number) => {
    if (filters.optionalFeatures?.includes(id)) {
      setFilters({
        ...filters,
        optionalFeatures: filters.optionalFeatures.filter(
          feature => feature !== id,
        ),
      });
    } else {
      setFilters({
        ...filters,
        optionalFeatures: [...filters.optionalFeatures, id],
      });
    }
  };

  if (loadingOptionalFeatures) return <PageLoader />;

  return (
    <Modal
      className="modal fade modal-center advance-search-modal show"
      open={showAdvancedSearch}
      style={{
        top: '40px',
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{trans.advanced_search}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => toggleAdvancedSearch(false)}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <form>
              <div className="row generic-form">
                <div className="col-lg-12">
                  <h3>{trans.car_details}</h3>
                </div>
                <input
                  type="hidden"
                  name="category"
                  id="category"
                  value="new"
                />
                <div className="col-md-4 col-sm-6 form-group">
                  <Select
                    id="brand_id"
                    multiple
                    value={filters?.brandIds || []}
                    onChange={handleBrandChange}
                    className="custom-select"
                    renderValue={renderBrandValue}
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                    disabled={!brands}
                  >
                    {brands?.map(brand => (
                      <MenuItem key={brand.id} value={brand.id}>
                        <ListItemText primary={brand.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="col-md-4 col-sm-6 form-group">
                  <Select
                    id="model_id"
                    multiple
                    value={filters.modelIds || []}
                    onChange={handleModelChange}
                    className="custom-select mui-fixed"
                    renderValue={renderModelValue}
                    MenuProps={MenuProps}
                    displayEmpty
                    disabled={!models}
                    style={selectStyle}
                  >
                    {models?.map(model => (
                      <MenuItem key={model.id} value={model.id}>
                        <ListItemText primary={model.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="col-md-4 col-sm-6 form-group">
                  <Select
                    id="type_id"
                    multiple
                    value={filters.types || []}
                    onChange={handleTypeChange}
                    className="custom-select"
                    renderValue={renderTypeValue}
                    disabled={!typesToRender || typesToRender.length === 0}
                    MenuProps={MenuProps}
                    displayEmpty
                    style={selectStyle}
                  >
                    {typesToRender?.map(type => (
                      <MenuItem key={type.id} value={type.id}>
                        <ListItemText primary={type.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="col-md-4 col-sm-6 form-group search-parm">
                  <Select
                    className="custom-select"
                    name="seller"
                    value={filters.sellerType}
                    onChange={handleSellerChange}
                    displayEmpty
                    style={selectStyle}
                  >
                    <MenuItem value="">{trans.seller}</MenuItem>
                    <MenuItem value="dealer" data-slug="dealer">
                      {trans.dealer}
                    </MenuItem>
                    <MenuItem value="private" data-slug="private">
                      {trans.private}
                    </MenuItem>
                  </Select>
                </div>

                {filters.sellerType === 'dealer' && (
                  <div className="col-md-4 col-sm-6 form-group search-parm">
                    <Select
                      className="custom-select"
                      name="dealer_id"
                      id="dealer-id"
                      value={filters.dealerIds || []}
                      displayEmpty
                      renderValue={renderDealerValue}
                      multiple
                      onChange={handleDealerChange}
                      disabled={!dealers || dealers.length === 0}
                      MenuProps={MenuProps}
                      style={selectStyle}
                    >
                      {usedCarsDealers?.map(dealer => (
                        <MenuItem key={dealer.id} value={dealer.id}>
                          {dealer.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}

                <div className="col-md-4 col-sm-6 form-group  search-parm">
                  <Select
                    name="enginetype"
                    id="enginetype"
                    className="custom-select"
                    value={filters.engineTypes || []}
                    style={selectStyle}
                    onChange={handleEngineTypeChange}
                    renderValue={renderEngineTypeValue}
                    MenuProps={MenuProps}
                    multiple
                    displayEmpty
                  >
                    {engineTypes?.map(engineType => (
                      <MenuItem key={engineType.id} value={engineType.id}>
                        {engineType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="col-md-4 col-sm-6 form-group fuel-cars  search-parm">
                  <Select
                    name="engine_size"
                    id="engine_size"
                    className="custom-select engine_size applyenginetype"
                    style={selectStyle}
                    onChange={handleEngineSizeChange}
                    value={filters.engineSizes || []}
                    renderValue={renderEngineSizeValue}
                    multiple
                    displayEmpty
                    MenuProps={MenuProps}
                    disabled={!engineSizes || engineSizes.length === 0}
                  >
                    {engineSizes.map((engineSize: any) => (
                      <MenuItem key={engineSize.id} value={engineSize.id}>
                        {engineSize.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="col-md-4 col-sm-6 form-group fuel-cars  search-parm">
                  <Select
                    className="custom-select applyenginetype"
                    name="cylinders"
                    id="cylinders"
                    style={selectStyle}
                    onChange={handleCylindersChange}
                    value={filters.cylinders || []}
                    renderValue={renderCylindersValue}
                    multiple
                    displayEmpty
                    MenuProps={MenuProps}
                    disabled={!cylinders || cylinders.length === 0}
                  >
                    {cylinders.map((cylinder: any) => (
                      <MenuItem key={cylinder.id} value={cylinder.id}>
                        {cylinder.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="col-md-4 col-sm-6 form-group electric-car">
                  <Select
                    className="custom-select applyenginetype battery_capacity"
                    name="battery_capacity"
                    id="battery_capacity"
                    value={filters.batteryCapacities || []}
                    onChange={handleBatteryCapacityChange}
                    renderValue={renderBatteryCapacityValue}
                    multiple
                    displayEmpty
                    MenuProps={MenuProps}
                    disabled={
                      !batteryCapacities || batteryCapacities.length === 0
                    }
                  >
                    {batteryCapacities.map((batteryCapacity: any) => (
                      <MenuItem
                        key={batteryCapacity.id}
                        value={batteryCapacity.id}
                      >
                        {batteryCapacity.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="col-md-4 col-sm-6 form-group electric-car">
                  <Select
                    className="custom-select applyenginetype battery_range"
                    name="battery_range"
                    id="battery_range"
                    value={filters.batteryRanges || []}
                    onChange={handleBatteryRangeChange}
                    renderValue={renderBatteryRangeValue}
                    multiple
                    displayEmpty
                    MenuProps={MenuProps}
                    disabled={!batteryRanges || batteryRanges.length === 0}
                  >
                    {batteryRanges.map((batteryRange: any) => (
                      <MenuItem key={batteryRange.id} value={batteryRange.id}>
                        {batteryRange.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="col-md-4 col-sm-6 form-group  search-parm">
                  <Select
                    name="transmission"
                    id="transmission"
                    className="custom-select transmission applyenginetype"
                    style={selectStyle}
                    onChange={handleTransmissionChange}
                    value={filters.transmissions || []}
                    renderValue={renderTransmissionValue}
                    multiple
                    displayEmpty
                    MenuProps={MenuProps}
                    disabled={!transmissions || transmissions.length === 0}
                  >
                    {transmissions.map((transmission: any) => (
                      <MenuItem key={transmission.id} value={transmission.id}>
                        {transmission.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="col-md-4 col-sm-6 form-group  search-parm">
                  <Select
                    name="seat_material"
                    id="seat_material"
                    className="custom-select seat_material"
                    value={filters.seatMaterials || []}
                    renderValue={renderSeatMaterialValue}
                    multiple
                    displayEmpty
                    MenuProps={MenuProps}
                    disabled={!seatMaterials || seatMaterials.length === 0}
                    onChange={handleSeatMaterialChange}
                  >
                    {seatMaterials?.map((seatMaterial: any) => (
                      <MenuItem key={seatMaterial.id} value={seatMaterial.id}>
                        {seatMaterial.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="col-md-4 col-sm-6 form-group">
                  <Select
                    name="condition"
                    id="condition"
                    className="custom-select condition"
                    value={filters.conditions || []}
                    onChange={handleConditionChange}
                    renderValue={renderConditionValue}
                    multiple
                    displayEmpty
                    MenuProps={MenuProps}
                    disabled={!conditions || conditions.length === 0}
                  >
                    {conditions?.map((condition: any) => (
                      <MenuItem key={condition.id} value={condition.id}>
                        {condition.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="col-md-4 col-sm-6 form-group">
                  <Select
                    name="exterior_color"
                    id="exterior_color"
                    className="custom-select exterior_color"
                    value={filters.exteriorColors || []}
                    onChange={handleExteriorColorChange}
                    renderValue={renderExteriorColorValue}
                    multiple
                    displayEmpty
                    MenuProps={MenuProps}
                    disabled={!exteriorColors || exteriorColors.length === 0}
                  >
                    {exteriorColors?.map((exteriorColor: any) => (
                      <MenuItem key={exteriorColor.id} value={exteriorColor.id}>
                        {exteriorColor.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="col-md-4 col-sm-6 form-group">
                  <Select
                    name="interior_color"
                    id="interior_color"
                    className="custom-select interior_color"
                    value={filters.interiorColors || []}
                    onChange={handleInteriorColorChange}
                    renderValue={renderInteriorColorValue}
                    multiple
                    displayEmpty
                    MenuProps={MenuProps}
                    disabled={!interiorColors || interiorColors.length === 0}
                  >
                    {interiorColors?.map((interiorColor: any) => (
                      <MenuItem key={interiorColor.id} value={interiorColor.id}>
                        {interiorColor.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="col-lg-12">
                <hr className="hr-lines" />
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="pricerange-box">
                    <label htmlFor="year-from">{trans.year}</label>
                    <ThemeProvider theme={sliderTheme}>
                      <Slider
                        color={'primary'}
                        min={1940}
                        max={new Date().getFullYear() + 1}
                        value={usedYearRange}
                        onChange={handleUsedYearRangeChange}
                      />
                    </ThemeProvider>
                    <div className="slider-labels">
                      <div className="caption">
                        <span id="slider-range-value1">{usedYearRange[0]}</span>
                      </div>
                      <div className="right caption">
                        <span id="slider-range-value2">{usedYearRange[1]}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="pricerange-box">
                    <label htmlFor="year-from">
                      {trans.mileage} ({trans.km})
                    </label>
                    <ThemeProvider theme={sliderTheme}>
                      <Slider
                        color={'primary'}
                        min={0}
                        max={200000}
                        step={10000}
                        value={mileageRange}
                        onChange={handleMileageRangeChange}
                      />
                    </ThemeProvider>
                    <div className="slider-labels">
                      <div className="caption">
                        <span id="slider-range-value1">
                          {formatPrice(mileageRange[0])}
                        </span>
                      </div>
                      <div
                        className={`right caption ${
                          mileageRange[1] === 200000 ? 'addplus' : ''
                        }`}
                      >
                        <span id="slider-range-value2">
                          {formatPrice(mileageRange[1])}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="pricerange-box">
                    <label htmlFor="year-from">
                      {trans.price} ({trans.qar})
                    </label>
                    <ThemeProvider theme={sliderTheme}>
                      <Slider
                        color={'primary'}
                        min={0}
                        max={maxPrice}
                        step={10000}
                        value={priceRange}
                        onChange={handlePriceRangeChange}
                      />
                    </ThemeProvider>
                    <div className="slider-labels">
                      <div className="caption">
                        <span id="slider-range-value1">
                          {formatPrice(priceRange[0])}
                        </span>
                      </div>
                      <div
                        className={`right caption ${
                          priceRange[1] === maxPrice ? 'addplus' : ''
                        }`}
                      >
                        <span id="slider-range-value2">
                          {formatPrice(priceRange[1])}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-12 form-group switch">
                <ul className="switch-buttons list-inline optional-features-list-container">
                  {optionalFeatures?.map(optionalFeature => {
                    const isSelected = filters.optionalFeatures?.includes(
                      optionalFeature.id,
                    );
                    return (
                      <li key={optionalFeature.id}>
                        <OptionalFeaturesListItem
                          name={optionalFeature.name}
                          onClick={() =>
                            handleOptionalFeatureChange(optionalFeature.id)
                          }
                          checked={isSelected}
                          className={`optional-feature-list-item ${
                            isSelected && 'optional-feature-list-item-active'
                          }`}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary btn-clear" onClick={handleClear}>
              {trans.clear}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-adv-search"
              onClick={handleSearch}
            >
              {trans.search}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
