import { TOptionalFeature } from '../../../types/cars/used_car';
import OptionalFeatureIcon from '../../Icons/OptionalFeaturesIcons/OptionalFeatureIcon';
import '../../../styles/css/common.css';

interface IOptionalFeatureCardProps {
  name: TOptionalFeature['name'];
}

const OptionalFeatureCard = ({ name }: IOptionalFeatureCardProps) => {
  return (
    <div className="optional-feature-card">
      <OptionalFeatureIcon name={name} />
      <span className="optional-feature-name">{name}</span>
    </div>
  );
};

export default OptionalFeatureCard;
