import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import Banner from '../../Banners/BannerComponent';
import SignIn from '../../User/SignIn/SignInComponent';
import { RentalListingDetailsFeaturesList } from './RentListingDetailsFeaturesList';
import { RentListingDetailImageGallery } from './RentListingDetailImageGallery';
import { RentForm } from './RentForm';
import { IRentalCar } from '../../../types/cars/rental_car';
import { BikeListingDetailsSkeleton } from '../../UsedBikes/components/BikeDetailsSkeletons';
import { SliderSectionStyle } from '../../../common/styles/CommonStyles';
import '../../Listings/common/ListingDetailsFeaturesList/ListingDetailsFeaturesList-styles.css';

type RentCarListingDetailsProps = {
  car: IRentalCar;
  isLoading: boolean;
  signInModalInfo: {
    isVisible: boolean;
    setVisibility: (isVisible: boolean) => void;
  };
  responsiveFeatured: any;
};

export const RentCarListingDetails = ({
  car,
  signInModalInfo,
  isLoading,
  responsiveFeatured,
}: RentCarListingDetailsProps) => {
  const { trans, darkImageLoadingPlaceholder } = useContext(AppContext);
  const location = useLocation();
  const [showRentForm, setShowRentForm] = useState(false);
  const [coverImageSrc, setCoverImageSrc] = useState(
    car.cover ? car.cover : darkImageLoadingPlaceholder,
  );
  const handleRentNowClick = () => {
    setShowRentForm(true);
  };

  const onCloseRentForm = () => {
    setShowRentForm(false);
  };

  const setActive = (currentElement: any) => {
    document
      .getElementsByClassName('slick-current')[0]
      ?.classList.remove('slick-current');
    currentElement.target.parentNode.parentNode.classList.add('slick-current');
    setCoverImageSrc(currentElement.target.src);
  };

  if (isLoading) {
    return (
      <BikeListingDetailsSkeleton
        trans={trans}
        responsiveFeatured={responsiveFeatured}
      />
    );
  }

  return (
    <>
      <main id="main">
        <div className="container usedetailcar">
          <div className="row">
            <SliderSectionStyle className="get-deals">
              <div className="slider-holder used-detail">
                <RentListingDetailImageGallery
                  car={car}
                  image={car.image}
                  cover={coverImageSrc}
                  onImageClick={setActive}
                  isLoading={isLoading}
                />
              </div>
              <RentalListingDetailsFeaturesList
                car={car}
                handleRentNowClick={handleRentNowClick}
              />
            </SliderSectionStyle>
          </div>
        </div>
        <div className="container ">
          <Banner
            section_name="C3"
            className="default-image-banner C3-margin"
          />
        </div>
        {signInModalInfo.isVisible && (
          <SignIn
            toggleSignInDialog={signInModalInfo.setVisibility}
            location={location.pathname}
          />
        )}
      </main>
      {showRentForm && (
        <RentForm car={car?.id as any} onCloseRentForm={onCloseRentForm} />
      )}
    </>
  );
};
