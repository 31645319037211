import { useQuery } from 'react-query';
import { getBikeEngineTypes } from '../../apis/bikes';

export const useGetBikeEngineTypes = () => {
  return useQuery({
    queryKey: ['bike-engine-types'],
    queryFn: () => getBikeEngineTypes(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
