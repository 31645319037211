import Carousel from 'react-multi-carousel';
import './ramadanSliderSectionStyle.css';
import { RamadanSliderSkeleton } from '../RamadanSkeletons';
import { RamadanSliderImage } from './RamadanSliderImage';

interface RamadanSliderSectionProps {
  images: string[];
  darkImageLoadingPlaceholder: string;
  lang: string;
  isLoading: boolean;
}

export const RamadanSliderSection = ({
  images,
  darkImageLoadingPlaceholder,
  lang,
  isLoading,
}: RamadanSliderSectionProps) => {
  const responsiveModels = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 540, min: 0 },
      items: 1,
    },
  };

  if (isLoading) {
    return <RamadanSliderSkeleton />;
  }

  return (
    <Carousel
      responsive={responsiveModels}
      swipeable={true}
      draggable={true}
      rtl={lang === 'ar'}
      className="ramadan-offers-silder-carousel"
    >
      {images.map((image, index) => (
        <RamadanSliderImage
          key={index}
          src={image}
          defaultSrc={darkImageLoadingPlaceholder}
          alt="Slider Image"
          class="ramadan-slider-section-carousel"
        />
      ))}
    </Carousel>
  );
};
