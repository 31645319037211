import { useContext } from 'react';
import Banner from '../../Banners/BannerComponent';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import { AppContext } from '../../../contexts/AppContext';
import { useGetRamadanOffers } from '../../../common/hooks/ramadan_offers.hook';
import { RamadanCarCard } from '../components/RamadanCard';
import './ramadanOfferListPageStyle.css';
import { IRamadanOffers } from '../../../types/resources/cars/ramadan_offers';
import { RamadanOfferListSkeleton } from '../components/RamadanSkeletons';
import { ResultsNotFound } from '../../common';
import { MainHeadingH2 } from '../../../common/styles/CommonStyles';

const RamadanOfferListPage = () => {
  const { lang, trans } = useContext(AppContext);

  const { data: ramadanOffers, isLoading: isLoadingRamadanOffers } =
    useGetRamadanOffers({
      enabled: true,
    });

  if (!isLoadingRamadanOffers && !ramadanOffers) {
    return <ResultsNotFound lang={lang} trans={trans} />;
  }

  if (isLoadingRamadanOffers) {
    return <RamadanOfferListSkeleton trans={trans} />;
  }

  return (
    <>
      <MetaTag metaKey="ramadanOffer" href={`/${lang}/ramadan-offers`} />

      <main id="main">
        <div className="container  ramadan-list-offers">
          {ramadanOffers && ramadanOffers.length > 0 && (
            <section className="browse-offers-section container">
              <>
                <MainHeadingH2>{trans.ramadan_offers}</MainHeadingH2>
                <div className="browse-offers">
                  <>
                    {ramadanOffers?.map((offer: IRamadanOffers) => (
                      <RamadanCarCard
                        offer={offer}
                        key={offer.id}
                        redirectionUrl={`/${lang}/ramadan-offers/${offer.slug}`}
                        style="ramadan-slider-image"
                        className="ramadan-slider-section-container"
                        isLoading={isLoadingRamadanOffers}
                      />
                    ))}
                    <Banner section_name="R2" />
                  </>
                </div>
              </>
            </section>
          )}
        </div>
      </main>
    </>
  );
};

export default RamadanOfferListPage;
