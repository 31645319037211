import { useState } from 'react';
import { CarGridCardImageSkeleton } from '../../../UsedCars/UsedCarsSkeletons';
import { styled } from '@mui/system';

const CarGridImageCardStyled = styled('img')(() => ({
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  margin: 'auto',
}));
interface CarGridImageCardProps {
  defaultSrc?: string;
  src: string | undefined;
  alt?: string;
  class?: string;
  onClick?: any;
  style?: React.CSSProperties;
  onLoad?: any;
  width?: number;
  height?: number;
}

export const CarGridImageCard = ({
  src,
  alt,
  class: className,
  onClick,
  style,
  width,
  height,
}: CarGridImageCardProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  return (
    <>
      {isImageLoading && <CarGridCardImageSkeleton />}
      <CarGridImageCardStyled
        src={src}
        className={className}
        onClick={onClick}
        alt={alt}
        style={{ ...style, display: isImageLoading ? 'none' : 'block' }}
        onLoad={() => {
          setIsImageLoading(false);
        }}
        width={width}
        height={height}
        loading={isImageLoading ? 'eager' : 'lazy'}
      />
    </>
  );
};
