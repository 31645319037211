import React from 'react';

const GPSIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.227 8.51636C29.4595 8.51636 35.3635 14.4204 35.3635 21.8005C35.3635 29.033 29.4595 34.937 22.227 34.937C14.8469 34.937 8.94287 29.033 8.94287 21.8005C8.94287 14.4204 14.8469 8.51636 22.227 8.51636Z"
        stroke={color}
        stroke-width="3"
      />
      <path
        d="M2.15332 21.8005H8.94299M35.3637 21.8005H42.1533"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.2271 1.72681V8.51647M22.2271 34.9371V41.7268"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default GPSIcon;
