import authenticatedApi from '../../../instance/ApiInstance';
import { ICarType } from '../../../types/resources/cars';

const typesEndpoint = '/v2/resources/bikes/types';

export const getBikeTypes = async () => {
  try {
    const response = await authenticatedApi.get(typesEndpoint);
    const data: ICarType[] = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};
