import authenticatedApi from '../../../instance/ApiInstance';
import { ICondition } from '../../../types/resources/cars';

const conditionsEndpoint = '/v2/resources/bikes/conditions';

export const getBikeConditions = async (): Promise<ICondition[] | undefined> => {
  try {
    const response = await authenticatedApi.get(conditionsEndpoint);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
