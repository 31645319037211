import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { HeaderHolder, InfoBlock } from '../../../common/styles/CommonStyles';
import './TabPages-styles.css';

const TabPages = ({ setActiveTab, activeTab, carsCount }: any) => {
  const { trans } = useContext(AppContext);

  const setActiveInfoTab = (currentElement: any) => {
    var sectionId = currentElement.target.id;
    if (sectionId) {
      setActiveTab(sectionId);
    }
  };

  return (
    <InfoBlock className="tabs">
      <HeaderHolder className="style">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li
            className={`nav-item ${activeTab === 'approved' ? 'active' : ''}`}
          >
            <span
              className={`nav-link ${activeTab === 'approved' ? 'active' : ''}`}
              id="approved"
              onClick={setActiveInfoTab}
            >
              {trans.approved}
              {` (${
                carsCount?.approvedCount === undefined
                  ? 0
                  : carsCount?.approvedCount
              })`}
            </span>
          </li>
          <li className={`nav-item ${activeTab === 'pending' ? 'active' : ''}`}>
            <span
              className={`nav-link ${activeTab === 'pending' ? 'active' : ''}`}
              id="pending"
              onClick={setActiveInfoTab}
            >
              {trans.pending}
              {` (${
                carsCount?.pendingCount === undefined
                  ? 0
                  : carsCount?.pendingCount
              })`}
            </span>
          </li>
          <li
            className={`nav-item ${activeTab === 'rejected' ? 'active' : ''}`}
          >
            <span
              className={`nav-link ${activeTab === 'rejected' ? 'active' : ''}`}
              id="rejected"
              onClick={setActiveInfoTab}
            >
              {trans.rejected}
              {` (${
                carsCount?.rejectedCount === undefined
                  ? 0
                  : carsCount?.rejectedCount
              })`}
            </span>
          </li>
        </ul>
      </HeaderHolder>
    </InfoBlock>
  );
};

export default TabPages;
