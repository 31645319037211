import authenticatedApi from '../../../instance/ApiInstance';
import { IRentalCar } from '../../../types/cars/rental_car';
import { IMetadata } from '../../../types/common/metadata';
import { TRentalFilters } from '../components/RentalsFilter';

const rentalsEndpoint = '/v2/cars/rental-cars';

export interface IRentalSearch extends Partial<TRentalFilters> {
  page?: number;
  perPage: number;
  isFeatured?: boolean;
  isActive?: boolean;
  sortBy?: string;
  sortDirection?: string;
}

export interface IRentalCarsQuery {
  searchQueryParams?: IRentalSearch;
  page?: number;
  perPage?: number;
}

export const getRentalCars = async (
  params: IRentalSearch,
): Promise<{ data: IRentalCar[]; meta: IMetadata }> => {
  const response = await authenticatedApi.get(rentalsEndpoint, {
    params,
  });
  return response.data;
};

/**
 * Take key value pairs
 * Removes keys with null or undefined values
 * Create query params
 */
