import authenticatedApi from '../../instance/ApiInstance';
import { IBrand } from '../../types/resources/cars';
import { BrandName } from '../classes/Brand';

const brandsEndpoint = '/v2/resources/brands';

export interface IGetBrands {
  dealer?: number;
  type?: 'new' | 'used';
}

export const getBrands = async (params?: IGetBrands): Promise<IBrand[]> => {
  const response = await authenticatedApi.get(brandsEndpoint, {
    params,
  });
  return response.data;
};

export const getBrandsReviews = async (
  language?: string,
): Promise<IBrand[]> => {
  try {
    const url = `${brandsEndpoint}/reviews/brands`;

    // If language is specified, include it in the params
    const params = language ? { language } : {};

    const response = await authenticatedApi.get(url, { params });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBrandsNames = async () => {
  try {
    const response = await authenticatedApi.get('/v2/resources/brands');

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBrandsWithDealsNames = async (): Promise<BrandName[]> => {
  try {
    const result = await authenticatedApi.get('api/v2/get-brands-with-deals');

    const brandNames = result.data.data.map(
      (brandName: any) => new BrandName(brandName),
    );

    return brandNames;
  } catch (error) {
    throw error;
  }
};
