import { useContext, useEffect, useMemo } from 'react';
import Carousel from 'react-multi-carousel';
import { AppContext } from '../../../contexts/AppContext';
import { BannerModel, CarouselBannerInput } from '../../../models/BannerModel';
import ImageBanner from '../ImageBannerComponent';
import VideoBanner from '../VideoBannerComponent';
import { Skeleton } from '@mui/material';
import {
  CarouselBox,
  VideoHolder,
  VisualHolder,
} from '../CarouselBannerStyledComponent';
import { CarouselImage } from '../CarouselImage';
import './CarouselBanner-styles.css';

export default function CarouselBanner(props: CarouselBannerInput) {
  const bannerModels: Array<BannerModel> = props.bannerModels;
  const { lang, darkImageLoadingPlaceholder } = useContext(AppContext);

  useEffect(() => {
    let interval = setInterval(() => {
      if (document.getElementsByClassName('react-multi-carousel-dot-list')[0]) {
        document
          .getElementsByClassName('react-multi-carousel-dot-list')[0]
          .classList.add('carousel-indicators');
        if (lang == 'ar') {
          document
            .getElementsByClassName('react-multi-carousel-dot-list')[0]
            .classList.add('arabic-carousel-indicators');
        }
        clearInterval(interval);
      }
    }, 100);
  }, []);

  const responsiveBanner = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const carouselItems = useMemo(() => {
    return bannerModels
      .map(bannerModel => {
        if (
          bannerModel?.content_type === 'Video' &&
          bannerModel?.images?.length > 0
        ) {
          return (
            <div key={Math.random()} className="bannerslide-item">
              <VideoBanner
                bannerModel={bannerModel}
                carouselItem={true}
                onClick={() => props.onClick(bannerModel.id)}
                innerRef={props.innerRef}
              />
            </div>
          );
        } else if (bannerModel?.content_type === 'Image') {
          return (
            <div key={Math.random()} className="bannerslide-item">
              <ImageBanner
                redirect={props.redirect}
                bannerModel={bannerModel}
                className={props.className ? props.className : ''}
                onClick={() => props.onClick(bannerModel.id)}
              />
            </div>
          );
        }
      })
      .filter(Boolean);
  }, [
    bannerModels,
    props.className,
    props.innerRef,
    props.onClick,
    props.redirect,
  ]);

  if (carouselItems && carouselItems.length > 0) {
    const allTitles = bannerModels.map(bannerModel => {
      const bannerImages = bannerModel?.images;
      const banner =
        bannerImages?.find(banner => banner.lang === lang) ||
        bannerImages?.find(banner => banner.lang === 'en');
      const bannerTitle = banner?.title;

      // Check if images array is empty
      if (bannerImages?.length === 0) {
        return '';
      } else if (bannerTitle) {
        return bannerTitle;
      }
    });
    return (
      <VisualHolder>
        <VideoHolder dir={lang === 'ar' ? 'rtl' : 'ltr'}>
          <CarouselImage src="/images/bg-img.webp" alt="" />
          <CarouselBox>
            <Carousel
              className="banner-carousel"
              autoPlaySpeed={8000}
              arrows={false}
              showDots
              responsive={responsiveBanner}
              customTransition="none"
              infinite={true}
              customDot={<props.customDot bannerTitles={allTitles} />}
              key="banner-carousel"
            >
              {carouselItems}
            </Carousel>
          </CarouselBox>
        </VideoHolder>
      </VisualHolder>
    );
  } else {
    return (
      <VisualHolder>
        <VideoHolder dir={lang === 'ar' ? 'rtl' : 'ltr'}>
          <CarouselImage src="/images/bg-img.webp" alt="" />
          <CarouselBox>
            <Carousel
              className="banner-carousel"
              autoPlaySpeed={8000}
              arrows={false}
              showDots
              responsive={responsiveBanner}
              customTransition="none"
              infinite={true}
              key="skeleton-carousel"
              customDot={<props.customDot bannerTitles={['']} />}
            >
              <div key={Math.random()} className="bannerslide-item">
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  animation="wave"
                />
              </div>
            </Carousel>
          </CarouselBox>
        </VideoHolder>
      </VisualHolder>
    );
  }
}
