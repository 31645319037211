import { useState } from 'react';
import { RamadanOfferListImageSkeleton } from '../components/RamadanSkeletons';

interface RamadanOfferListImageProps {
  src?: string | undefined;
  defaultSrc?: string;
  alt?: string;
  class?: string;
  onClick?: any;
  style?: React.CSSProperties;
  onLoad?: any;
  width?: number;
  height?: number;
  trans?: any;
}

export const RamadanOfferListImage = (props: RamadanOfferListImageProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <>
      {isImageLoading && <RamadanOfferListImageSkeleton />}
      <img
        src={props.src}
        className={props.class}
        onClick={props.onClick}
        alt={props.alt}
        style={{
          ...props.style,
          display: isImageLoading ? 'none' : 'block',
        }}
        width={props.width}
        height={props.height}
        onLoad={() => {
          setIsImageLoading(false);
        }}
      />
    </>
  );
};
