import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { IUsedCar } from '../../../../types/cars/used_car';
import { formatPrice } from '../../../../helpers/formatHelper';
import { CarGridImageCard } from './CarGridImageCard';
import { CarGridCardSkeleton } from '../../../UsedCars/UsedCarsSkeletons';
import {
  AutoImgBox,
  CarInfoColumn,
  CarInfoColumnBtn,
  CarInfoColumnImgHolder,
  CarInfoColumnLogoImg,
  CarInfoColumnPriceHolder,
  CarInfoColumnPriceHolderPrice,
  CarInfoColumnTextBox,
  CarInfoColumnTextHolder,
  CarInfoColumnTitleCar,
  CarInfoColumnTitleCategory,
  SponsoredBadge,
} from '../../../../common/styles/CommonStyles';
import {
  CarInfoColumnDl,
  CarInfoColumnDlDd,
  CarInfoColumnDlDt,
} from '../../../UsedCars/components/UsedCarStyles';
import { CarGridDealerLogo } from './CarGridDealerLogo';
import '../../../../styles/css/swiper-bundle.css';
import '../../../User/AccountWishList/Card-styles.css';
import {
  useUsedCarsPageClick,
  useUsedCarsPageView,
} from '../../../UsedCars/hooks';
import { useInView } from 'react-intersection-observer';

type CarGridCardProps = {
  car: IUsedCar;
  redirectionLink: string;
  isFavorite: boolean;
  onFavoriteClick: (e: any, carId: number) => void;
  isLoading: boolean;
};

const CarGridCard = ({
  car,
  redirectionLink,
  isFavorite,
  onFavoriteClick,
  isLoading,
}: CarGridCardProps) => {
  const { trans, darkImageLoadingPlaceholder, lang, country } =
    useContext(AppContext);

  const { mutateAsync: usedCarsPageView } = useUsedCarsPageView();

  const { ref } = useInView({
    threshold: 0.01,
    onChange(inView) {
      if (inView) {
        usedCarsPageView(car.id);
      }
    },
    triggerOnce: true,
  });

  const { mutateAsync: usedCarPageClick } = useUsedCarsPageClick();

  if (isLoading) {
    return <CarGridCardSkeleton />;
  }

  return (
    <div
      key={car.id}
      className="swiper-slide"
      ref={ref}
      onClick={() => {
        usedCarPageClick({ id: car.id, event: 'click' });
      }}
    >
      <div className="slide-holder">
        <button
          className={`wishlist-icon loginpopupload ${
            isFavorite ? 'active' : ''
          }`}
          onClick={(e: any) => onFavoriteClick(e, car.id)}
        >
          <i className="fa fa-heart-o"></i>
          <i className="fa fa-heart"></i>
        </button>
        <Link to={redirectionLink}>
          <CarInfoColumn>
            <CarInfoColumnImgHolder>
              <AutoImgBox>
                {car.is_featured ? (
                  <SponsoredBadge className="badge">
                    {trans.featured}
                  </SponsoredBadge>
                ) : null}
                <CarGridImageCard
                  src={
                    car?.images ? car?.images?.[0] : darkImageLoadingPlaceholder
                  }
                  defaultSrc={darkImageLoadingPlaceholder}
                  alt={`${car.model?.brand?.slug}-${car.model?.slug}-image`}
                  width={300}
                  height={200}
                />
              </AutoImgBox>
            </CarInfoColumnImgHolder>
            <CarInfoColumnTextHolder>
              <CarInfoColumnTextBox>
                <CarInfoColumnLogoImg>
                  <CarGridDealerLogo
                    src={car.model?.brand.original_logo ?? ''}
                    defaultSrc={darkImageLoadingPlaceholder}
                    alt={`${car.dealer?.slug}-logo`}
                    width={40}
                    height={40}
                  />
                </CarInfoColumnLogoImg>
                <h3>
                  <CarInfoColumnTitleCar>
                    {car.model?.brand?.name}
                  </CarInfoColumnTitleCar>
                  <CarInfoColumnTitleCategory>
                    {car.model?.name}{' '}
                    {car && car.trim != null ? car.trim.name : ''}
                  </CarInfoColumnTitleCategory>
                </h3>
                <CarInfoColumnDl>
                  <CarInfoColumnDlDt>{trans.year}:</CarInfoColumnDlDt>
                  <CarInfoColumnDlDd>{car.year}</CarInfoColumnDlDd>
                  <CarInfoColumnDlDt>{trans.km_driven}:</CarInfoColumnDlDt>
                  <CarInfoColumnDlDd>
                    {car.mileage || 0} {trans.km}
                  </CarInfoColumnDlDd>
                  <CarInfoColumnDlDt>{trans.seller}:</CarInfoColumnDlDt>
                  <CarInfoColumnDlDd>
                    {car.dealer?.name ?? trans.private}
                  </CarInfoColumnDlDd>
                </CarInfoColumnDl>
              </CarInfoColumnTextBox>
              <CarInfoColumnPriceHolder>
                <CarInfoColumnPriceHolderPrice>
                  <span>{car?.city?.currencies.name}</span>
                  <span>{formatPrice(car.price)}</span>
                </CarInfoColumnPriceHolderPrice>
                <CarInfoColumnBtn
                  className="btn"
                  to={`/${country}/${lang}/used-cars/${car.model?.brand?.slug}/${car.model?.slug}/${car.year}/${car.id}`}
                >
                  {trans.view_car}
                </CarInfoColumnBtn>
              </CarInfoColumnPriceHolder>
            </CarInfoColumnTextHolder>
          </CarInfoColumn>
        </Link>
      </div>
    </div>
  );
};

export default CarGridCard;
