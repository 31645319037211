import { RamadanModelLogo } from '../../../../common/styles/CommonStyles';
import { IRamadanOffers } from '../../../../types/resources/cars/ramadan_offers';
import { RamadanDetailHeaderSkeleton } from '../RamadanSkeletons';
import './ramadanDetailHeaderStyle.css';

interface IRamadanDetailHeaderProps {
  offer?: IRamadanOffers;
  isLoading?: boolean;
}

export const RamadanDetailHeader = ({
  offer,
  isLoading,
}: IRamadanDetailHeaderProps) => {
  if (isLoading && !offer) {
    return <RamadanDetailHeaderSkeleton />;
  }

  return (
    <header className="ramadan-details-header d-flex w-100">
      <div style={{ width: '110px' }}>
        <RamadanModelLogo src={offer?.brand?.original_logo} alt="brand logo" />
      </div>
      <div className="m-auto">
        <img
          src={offer?.dealer?.logo}
          alt="dealer logo"
          className="ramadan-dealer-logo"
        />
      </div>
    </header>
  );
};
