import { useMemo } from 'react';
import { IBrand, IModel } from '../../../../../types/resources/cars';
import { INewCar } from '../../../../../types/cars/new_car';

export const useSetActive = (setCoverImageSrc: (newValue: string) => void) => {
  const setActive = currentElement => {
    currentElement.target.parentNode.parentNode &&
      document
        .getElementsByClassName('slick-current')[0]
        ?.classList.remove('slick-current', 'slick-active');
    currentElement.target.parentNode.parentNode.classList.add(
      'slick-current',
      'slick-active',
    );
    setCoverImageSrc(currentElement.target.src);
  };

  return { setActive };
};

export const useGetDealClick = (
  setShowDealForm: (newValue: boolean) => void,
) => {
  const getDealClick = (deal: INewCar) => {
    if (deal.call_to_action) {
      window.open(
        deal.call_to_action.startsWith('http')
          ? deal.call_to_action
          : 'https://' + deal.call_to_action,
        '_blank',
      );
    } else {
      setShowDealForm(true);
    }
  };

  return { getDealClick };
};

export const useFilteredNewModels = (
  brandModelsWithDealsData: IModel[] | undefined,
  selectedModel: IModel | undefined,
) => {
  const filteredNewModels = useMemo(
    () =>
      brandModelsWithDealsData?.filter(
        model => model.id !== selectedModel?.id,
      ) || [],
    [brandModelsWithDealsData, selectedModel],
  );

  return { filteredNewModels };
};

export const useBrandId = (
  brands: IBrand[] | undefined,
  brandSlug: string | undefined,
) => {
  const brandId =
    useMemo(() => {
      const brand = brands?.find((brand: IBrand) => brand.slug === brandSlug);
      return brand?.id;
    }, [brands, brandSlug]) || 0;

  return { brandId };
};

export const useSelectedModel = (
  brandModelsWithDealsData: IModel[] | undefined,
  modelSlug: string | undefined,
) => {
  const selectedModel = useMemo(() => {
    const model = brandModelsWithDealsData?.find(
      (model: IModel) => model.slug === modelSlug,
    );
    return model;
  }, [brandModelsWithDealsData, modelSlug]);

  return { selectedModel };
};
