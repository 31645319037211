import { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { AppContext } from '../../../contexts/AppContext';
import PageLoader from '../PageLoader/PageLoaderComponent';
import countries from 'i18n-iso-countries';
import { isValidEmail } from '../../../Helper';
import { submitContact } from '../../../services/ApiService';
import {
  ColLg12,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  Row,
  SellYourCar,
} from '../../../common/styles/CommonStyles';
import styled from '@emotion/styled';
import './ContactUs-styles.css';

const ContactTitleStyleH3 = styled('h3')(() => ({
  '.lang-au &': {
    textAlign: 'right',
  },
  '&.contact-title': {
    fontSize: '30px',
    lineHeight: '1.3',
    paddingBlock: '30px',
    textTransform: 'none',
    fontWeight: 800,
  },
}));

export default function ContactUs(this: any) {
  const { lang, trans } = useContext(AppContext);
  const [showPageLoader, setShowPageLoader] = useState(true);

  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  countries.registerLocale(require('i18n-iso-countries/langs/ar.json'));
  var englishCountries = Object.values(countries.getNames('en')).sort();
  var arabicCountries = Object.values(countries.getNames('ar')).sort();

  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [filesError, setFilesError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [mobile, setMobile] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [country, setCountry] = useState('');
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isBusinessInquiry, setIsBusinessInquiry] = useState(false);

  const handlePhoneNumberChange = (e: any) => {
    setMobile(e);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 100);
  }, []);

  const handleFilesChange = (e: any) => {
    if (e && e.target && e.target.files) {
      let _selectedFiles = [...selectedFiles];
      Array.from(e.target.files).forEach((file: any) => {
        if (_selectedFiles.length === 6) {
          setFilesError('At most 6 photos/videos are allowed');
          return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          _selectedFiles.push({ file: file, uri: reader.result });
          setSelectedFiles(_selectedFiles);
        };
      });
    }
  };

  const removeSelectedFile = (e: any) => {
    let _selectedFiles = [...selectedFiles];
    if (
      e &&
      e.target &&
      e.target.nextElementSibling &&
      e.target.nextElementSibling.title
    ) {
      _selectedFiles = _selectedFiles.filter(
        (f: any) => f.file?.name !== e.target.nextElementSibling.title,
      );
      setSelectedFiles(_selectedFiles);
    }
  };

  const submitInquiry = () => {
    setNameError('');
    setMobileError('');
    setEmailError('');
    setMessageError('');

    let isValid = true;

    if (name.trim() === '') {
      setNameError(trans.name_required);
      isValid = false;
    }

    if (email !== '' && !email.match(isValidEmail)) {
      setEmailError(trans.email_invalid);
      isValid = false;
    }

    if (mobile.trim() === '') {
      setMobileError(trans.mobile_required);
      isValid = false;
    }

    if (message.trim() === '') {
      setMessageError(trans.message_required);
      isValid = false;
    }

    if (!isValid) return;

    setShowPageLoader(true);

    let form: any = {};
    form.name = name;
    form.email = email;
    form.mobile_number = mobile;
    form.country = country;
    form.message = message;
    form.is_business_inquiry = isBusinessInquiry;

    selectedFiles.forEach(async (element: any, index: any) => {
      form[`attachements[${index}]`] = element.file;
    });

    submitContact(form).then(response => {
      setShowPageLoader(false);
    });
  };

  return (
    <>
      {showPageLoader && <PageLoader />}
      <main id="main">
        <MainHead>
          <MainHeadContainer className="container">
            <Row>
              <ColLg12>
                <MainHeadH1>{trans.contact_us}</MainHeadH1>
              </ColLg12>
            </Row>
          </MainHeadContainer>
        </MainHead>
        <SellYourCar className="container contact-page">
          <Row>
            <ColLg12>
              <Row className="generic-form sell-car-form">
                <ColLg12>
                  <ContactTitleStyleH3 className="contact-title">
                    {trans.get_in_touch_with_us}
                  </ContactTitleStyleH3>
                </ColLg12>
                <div className="col-md-6 col-sm-6 form-group">
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e: any) => setName(e.target.value)}
                    placeholder={trans.name}
                  />
                  <span className="errorMessage">{nameError}</span>
                </div>
                <div className="col-md-6 col-sm-6 form-group">
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    placeholder={trans.email}
                  />
                  <span className="errorMessage">{emailError}</span>
                </div>
                <div className="col-md-6 col-sm-6 form-group mobile-number">
                  <PhoneInput
                    country="qa"
                    inputClass="custom-tel"
                    value={mobile}
                    onChange={handlePhoneNumberChange}
                    placeholder=""
                    masks={{ qa: '........' }}
                  />
                  <span className="errorMessage">{mobileError}</span>
                </div>
                <div className="col-md-6 col-sm-6 form-group">
                  <select
                    className="custom-select"
                    value={country}
                    onChange={(e: any) => setCountry(e.target.value)}
                  >
                    <option selected>{trans.country}</option>
                    {(lang === 'en' ? englishCountries : arabicCountries).map(
                      (country: any) => (
                        <option value={country}>{country}</option>
                      ),
                    )}
                  </select>
                </div>
                <div className="col-md-12 col-sm-12 form-group">
                  <textarea
                    className="form-control"
                    maxLength={225}
                    onChange={(e: any) => setMessage(e.target.value)}
                    placeholder={trans.message}
                  ></textarea>
                  <span className="errorMessage">{messageError}</span>
                </div>
                <div className="col-xl-12 form-group">
                  <label className="contact-attach-lbl">
                    {trans.attachment}
                  </label>
                  <div className="upload-file">
                    <output id="Filelist">
                      <ul className="thumb-Images" id="imgList">
                        {selectedFiles.map((file: any) => {
                          return (
                            <li key={Math.random()}>
                              <div className="img-wrap">
                                <span
                                  className="close"
                                  onClick={removeSelectedFile}
                                >
                                  ×
                                </span>
                                <img
                                  className="thumb"
                                  src={URL.createObjectURL(file.file)}
                                  title={file.file.name}
                                />
                              </div>
                              <div className="FileNameCaptionStyle">
                                {file.name}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </output>
                    <span className="btn btn-success fileinput-button">
                      <span onClick={removeSelectedFile}>+</span>
                      <input
                        type="file"
                        name="files[]"
                        id="files"
                        multiple
                        accept="image/jpeg, image/png, image/gif,"
                        onChange={handleFilesChange}
                      />
                    </span>
                  </div>
                  <span className="errorMessage">{filesError}</span>
                </div>
                <div className="col-xl-12 pt0 form-group buttons">
                  <input
                    type="checkbox"
                    name="business-inquiry-chkbox"
                    className="business-inquiry-chkbox"
                    checked={isBusinessInquiry}
                    onClick={() => setIsBusinessInquiry(!isBusinessInquiry)}
                  />
                  <span className="business-inquiry-txt">
                    {trans.business_inquiry}
                  </span>
                  <button
                    type="button"
                    className="btn btn-primary btn-send"
                    onClick={submitInquiry}
                  >
                    {trans.send}
                  </button>
                </div>
              </Row>
            </ColLg12>
          </Row>
        </SellYourCar>
      </main>
    </>
  );
}
