import { useQuery } from 'react-query';
import { getBikeColors } from '../../apis/bikes';

export const useGetBikeColors = () => {
  return useQuery({
    queryKey: ['bike-colors'],
    queryFn: () => getBikeColors(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
