export const metaData = {
  home: {
    title: 'Buy and Sell New & Used Cars in Qatar | One Auto Car',
    description:
      'Ready to sell your car in Qatar? List your car for sale hassle-free with One Auto Car. Reach potential buyers quickly and easily. Start selling today!',
  },
  usedCars: {
    title: 'Used Cars for Sale in Qatar | One Auto Car',
    description:
      'Discover a wide selection of top-quality used cars for sale in Qatar on One Auto Car. Browse our listings and find the perfect pre-owned vehicle for your needs. Explore now!',
  },
  dealers: {
    title: 'Find Trusted Dealers in Qatar for Your Next Car | One Auto Car',
    description:
      'Explore a network of reliable dealerships at One Auto Car. Find the perfect automotive partner to meet your needs. Browse our extensive list of dealers now!',
  },
  newCarsBrand: {
    title: 'Buy New %BRAND% Cars In Qatar | One Auto Car',
    description:
      'Explore the latest %BRAND% models for sale in Qatar at One Auto Car. Find the perfect new %BRAND% car to suit your style and needs. Browse our extensive inventory today!',
  },
  newCarsBrandModel: {
    title: 'Discover %BRAND% %MODEL% Cars for Sale in Qatar | One Auto Car',
    description:
      'Looking for a %BRAND% %MODEL% for sale in Qatar? Explore our extensive selection of new %MODEL% cars and find unbeatable deals at One Auto Car. Browse now!',
  },
  usedCarsDetails: {
    title: 'Buy %BRAND% %MODEL% %YEAR% In Qatar | One Auto Car',
    description:
      'Buy a %YEAR% %BRAND% %MODEL% in Qatar. Explore our selection at One Auto Car and buy your dream car today.',
  },
  dealer: {
    title: '%NAME% | One Auto Car',
    description:
      'Discover the best deals at %NAME% for your One Auto Car. Explore our wide range of vehicles and find your perfect ride today.',
  },
  articles: {
    title: 'Automotive Articles and Insights | One Auto Car',
    description:
      "Explore OneAutoCar's informative articles covering the latest trends and insights in the automotive industry. Stay informed with us!",
  },
  reviews: {
    title: 'Car Reviews | One Auto Car',
    description:
      'Explore OneAutoCar’s car reviews to find the best car for you. We provide in-depth reviews of the latest cars in the market.',
  },
  articleTitle: {
    title: '%NAME% | One Auto Car',
    description: 'A Summary about the article',
  },
  financing: {
    title: 'Financing | One Auto Car',
    description:
      'Discover flexible financing options at One Auto Car. Get the keys to your dream car with our hassle-free financing solutions.',
  },
  dealerNewCars: {
    title: '%NAME% New Cars | One Auto Car',
    description:
      'Discover the latest models at %NAME%! Explore our selection of new cars at One Auto Car for an exceptional driving experience.',
  },
  dealerUsedCars: {
    title: '%NAME% Used Cars | One Auto Car',
    description:
      'Discover a wide selection of quality used cars at %NAME% Your trusted source for pre-owned vehicles. Explore One Auto Car today!',
  },
  whyWeRock: {
    title: 'Why we Rock | One Auto Car',
    description:
      'Discover why One Auto Car rocks! Explore our exceptional vehicles and unbeatable services. Your journey to automotive excellence begins here.',
  },
  accountDetails: {
    title: 'Account Details | One Auto Car',
    description:
      'Access and manage your One Auto Car account details seamlessly. Update information and preferences with ease. Your car, your control.',
  },
  register: {
    title: 'Register | One Auto Car',
    description:
      'Register now at One Auto Car for exclusive benefits and updates. Join our automotive community today!',
  },
  login: {
    title: 'Login | One Auto Car',
    description:
      'Securely access your One Auto Car account with our easy login process. Drive convenience and control. Log in now!',
  },
  brands: {
    title: 'Explore Our Latest New Cars for Sale in Qatar | One Auto Car',
    description:
      'Discover the finest selection of new cars for sale in Qatar at One Auto Car. Explore our diverse range of brand-new vehicles and find your perfect ride today!',
  },
  sellYourCar: {
    title: 'List Your Car for Sale in Qatar For Free | One Auto Car',
    description:
      'Ready to sell your car in Qatar? List your car for sale hassle-free with One Auto Car. Reach potential buyers quickly and easily. Start selling today!',
  },
  ramadanOffer: {
    title: 'Ramadan Offers | One Auto Car',
    description:
      'Explore the best Ramadan offers in Qatar at One Auto Car. Discover unbeatable deals on new cars, and find your perfect ride today!',
  },
  accountWishList: {
    title: 'Wishlist | One Auto Car',
    description:
      'Explore your wishlist at One Auto Car. Find your dream car and save it to your wishlist. Browse your favorite cars now!',
  },
  usedBikes: {
    title: 'Used Bikes for Sale in Qatar | One Auto Car',
    description:
      'Discover a wide selection of top-quality used bikes for sale in Qatar on One Auto Car. Browse our listings and find the perfect pre-owned bike for your needs. Explore now!',
  },
  usedBikesDetails: {
    title: 'Buy %BRAND% %MODEL% %YEAR% In Qatar | One Auto Car',
    description:
      'Buy a %YEAR% %BRAND% %MODEL% in Qatar. Explore our selection at One Auto Car and buy your dream bike today.',
  },
  sellYourBike: {
    title: 'List Your Bike for Sale in Qatar For Free | One Auto Car',
    description:
      'Ready to sell your bike in Qatar? List your bike for sale hassle-free with One Auto Car. Reach potential buyers quickly and easily. Start selling today!',
  },
  dealerNewBikes: {
    title: '%NAME% New Bikes | One Auto Car',
    description:
      'Discover the latest models at %NAME%! Explore our selection of new bikes at One Auto Car for an exceptional riding experience.',
  },
  dealerUsedBikes: {
    title: '%NAME% Used Bikes | One Auto Car',
    description:
      'Discover a wide selection of quality used bikes at %NAME% Your trusted source for pre-owned bikes. Explore One Auto Car today!',
  },
  bikeBrands: {
    title: 'Explore Our Latest New Bikes for Sale in Qatar | One Auto Car',
    description:
      'Discover the finest selection of new bikes for sale in Qatar at One Auto Car. Explore our diverse range of brand-new bikes and find your perfect ride today!',
  },
  newBikesBrandModel: {
    title: 'Discover %BRAND% %MODEL% Bikes for Sale in Qatar | One Auto Car',
    description:
      'Looking for a %BRAND% %MODEL% for sale in Qatar? Explore our extensive selection of new %MODEL% bikes and find unbeatable deals at One Auto Car. Browse now!',
  },
};
