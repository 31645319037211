import {
  DownloadAppDropHolderStyle,
  DownloadAppDropPStyle,
  DownloadAppDropStyle,
  DownloadAppStrongStyle,
  DownloadAppStyle,
} from '../../common/styles/CommonStyles';

interface QRTooltipProps {
  textContent: string;
  tooltipTextContent1: string;
  tooltipTextContent2: string;
}

export default function DownloadAppQRTooltip(props: QRTooltipProps) {
  return (
    <DownloadAppStyle>
      <DownloadAppStrongStyle>{props.textContent}</DownloadAppStrongStyle>
      <DownloadAppDropStyle>
        <DownloadAppDropHolderStyle>
          <DownloadAppDropPStyle>
            {props.tooltipTextContent1} <br />
            {props.tooltipTextContent2}
          </DownloadAppDropPStyle>
          <img src={`/images/QR-download.png`} alt="QR Code" />
        </DownloadAppDropHolderStyle>
      </DownloadAppDropStyle>
    </DownloadAppStyle>
  );
}
