export const SunroofIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="58"
      height="37"
      viewBox="0 0 58 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.8511 24.2511C53.1361 24.2511 53.4326 24.1371 53.6492 23.9205L54.4588 23.1109C54.9035 22.6662 54.9035 21.9479 54.4588 21.5032C54.0141 21.0585 53.2958 21.0585 52.8511 21.5032L52.0415 22.3127C51.5968 22.7574 51.5968 23.4758 52.0415 23.9205C52.2582 24.1371 52.5546 24.2511 52.8397 24.2511H52.8511Z"
        fill={color}
      />
      <path
        d="M55.9533 26.6797H54.8017C54.1745 26.6797 53.6729 27.1814 53.6729 27.8085C53.6729 28.4356 54.1745 28.9373 54.8017 28.9373H55.9533C56.5804 28.9373 57.0821 28.4356 57.0821 27.8085C57.0821 27.1814 56.5804 26.6797 55.9533 26.6797Z"
        fill={color}
      />
      <path
        d="M53.6614 31.72C53.2167 31.2753 52.4984 31.2753 52.0651 31.72C51.6318 32.1647 51.6204 32.883 52.0651 33.3163L52.8747 34.1259C53.0913 34.3425 53.3878 34.4565 53.6728 34.4565C53.9579 34.4565 54.2543 34.3425 54.471 34.1259C54.9156 33.6812 54.9156 32.9628 54.471 32.5295L53.6614 31.72Z"
        fill={color}
      />
      <path
        d="M48.1537 33.3391C47.5266 33.3391 47.0249 33.8408 47.0249 34.4679V35.6196C47.0249 36.2467 47.5266 36.7484 48.1537 36.7484C48.7808 36.7484 49.2825 36.2467 49.2825 35.6196V34.4679C49.2825 33.8408 48.7808 33.3391 48.1537 33.3391Z"
        fill={color}
      />
      <path
        d="M42.6465 31.72L41.8369 32.5295C41.3922 32.9742 41.3922 33.6926 41.8369 34.1373C42.0536 34.3539 42.35 34.4679 42.6351 34.4679C42.9201 34.4679 43.2166 34.3539 43.4332 34.1373L44.2428 33.3277C44.6875 32.883 44.6875 32.1647 44.2428 31.72C43.7981 31.2753 43.0798 31.2753 42.6351 31.72H42.6465Z"
        fill={color}
      />
      <path
        d="M42.6348 27.82C42.6348 27.1929 42.1331 26.6912 41.506 26.6912H40.3544C39.7273 26.6912 39.2256 27.1929 39.2256 27.82C39.2256 28.4471 39.7273 28.9488 40.3544 28.9488H41.506C42.1331 28.9488 42.6348 28.4471 42.6348 27.82Z"
        fill={color}
      />
      <path
        d="M41.8369 21.5032C41.3922 21.9479 41.3922 22.6662 41.8369 23.0995L42.6465 23.9091C42.8631 24.1257 43.1596 24.2397 43.4446 24.2397C43.7297 24.2397 44.0262 24.1257 44.2428 23.9091C44.6875 23.4644 44.6875 22.746 44.2428 22.3127L43.4332 21.5032C42.9886 21.0585 42.2702 21.0585 41.8369 21.5032Z"
        fill={color}
      />
      <path
        d="M48.1537 18.8804C47.5266 18.8804 47.0249 19.3821 47.0249 20.0092V21.1608C47.0249 21.7879 47.5266 22.2896 48.1537 22.2896C48.7808 22.2896 49.2825 21.7879 49.2825 21.1608V20.0092C49.2825 19.3821 48.7808 18.8804 48.1537 18.8804Z"
        fill={color}
      />
      <path
        d="M48.1534 23.5669C45.816 23.5669 43.9004 25.4711 43.9004 27.8199C43.9004 30.1688 45.8046 32.0729 48.1534 32.0729C50.5023 32.0729 52.4064 30.1688 52.4064 27.8199C52.4064 25.4711 50.5023 23.5669 48.1534 23.5669ZM48.1534 29.8039C47.0588 29.8039 46.1694 28.9145 46.1694 27.8199C46.1694 26.7253 47.0588 25.8359 48.1534 25.8359C49.248 25.8359 50.1374 26.7253 50.1374 27.8199C50.1374 28.9145 49.248 29.8039 48.1534 29.8039Z"
        fill={color}
      />
      <path
        d="M36.3296 27.82C36.3296 21.298 41.6316 15.9959 48.1993 15.9959C48.8264 15.9959 49.3281 15.4942 49.3281 14.8671C49.3281 14.7417 49.3053 14.6163 49.2711 14.5022L48.9404 5.79095C48.7124 2.9404 45.9417 0.705566 42.6464 0.705566H8.29157C4.98493 0.705566 2.22559 2.9404 1.99755 5.79095L0.0933807 29.5988C-0.0206414 30.967 0.469652 32.3125 1.45024 33.3843C2.65888 34.6841 4.44902 35.4367 6.3874 35.4367L36.9453 35.4595C37.3444 35.4595 37.7207 35.2428 37.9259 34.8894C38.1311 34.5359 38.1311 34.1026 37.9259 33.7492C36.8769 31.959 36.3296 29.9066 36.3296 27.82ZM46.8994 11.8911H3.77629L4.02714 8.72132H46.7854L46.9108 11.8911H46.8994ZM44.7558 14.1602C42.6692 14.6733 40.765 15.6653 39.1573 16.9993H3.35441L3.58246 14.1602H44.7558ZM8.28016 2.97461H42.635C44.7672 2.97461 46.546 4.29726 46.6714 5.92778L46.6942 6.46368H4.20958L4.24378 5.97339C4.38061 4.29726 6.14795 2.97461 8.28016 2.97461ZM6.376 33.1676C5.07615 33.1676 3.87891 32.6888 3.10356 31.845C2.71589 31.4231 2.2598 30.7162 2.33961 29.7698L3.18338 19.2456H36.9681C35.1438 21.6172 34.0606 24.5932 34.0606 27.8086C34.0606 29.6558 34.4254 31.4801 35.121 33.179L6.3874 33.1562L6.376 33.1676Z"
        fill={color}
      />
    </svg>
  );
};
