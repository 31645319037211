import OptionalFeatureIcon from '../../Icons/OptionalFeaturesIcons/OptionalFeatureIcon';
import '../../../styles/css/common.css';

interface IOptionalFeaturesListItemProps {
  name: string;
  onClick?: () => void;
  checked?: boolean;
  className?: string;
  className2?: string;
  nameColor?: string;
  iconColor?: string;
}

const OptionalFeaturesListItem = ({
  checked = false,
  onClick = () => {},
  name,
  className,
  className2,
  nameColor,
  iconColor,
}: IOptionalFeaturesListItemProps) => {
  const textColor = checked ? nameColor : '#191E29';
  return (
    <div className={className} onClick={onClick}>
      <OptionalFeatureIcon
        name={name}
        checked={checked}
        iconColor={iconColor}
      />
      <span className={className2} style={{ color: textColor }}>
        {name}
      </span>
    </div>
  );
};

export default OptionalFeaturesListItem;
