import { LocalStorageItem } from '../models/LocalStorageItem';

export class LocalStorageService {
  //#region public methods
  public static get<T>(
    key: string,
    userId?: string,
    deleteIfExpired: boolean = true,
  ) {
    if (localStorage) {
      if (userId) {
        key = `${key}-${userId}`;
      }
      const serializedItem = localStorage.getItem(key);
      if (serializedItem) {
        const localStorageItem: LocalStorageItem<T> =
          JSON.parse(serializedItem);

        if (
          deleteIfExpired &&
          localStorageItem.expirationDate &&
          LocalStorageService.isDateExpired(
            new Date(localStorageItem.expirationDate),
          )
        ) {
          LocalStorageService.delete(key);
          return null;
        }
        return localStorageItem.data;
      }
    }
  }

  public static set<T>(key: string, item: T, ttl?: number, userId?: string) {
    if (localStorage && item) {
      const localStorageItem = new LocalStorageItem<T>(item);
      if (ttl) {
        localStorageItem.expirationDate = LocalStorageService.addMinutes(
          new Date(),
          ttl,
        );
      }
      const serializedItem = JSON.stringify(localStorageItem);
      if (userId) {
        key = `${key}-${userId}`;
      }
      localStorage.setItem(key, serializedItem);
    }
  }

  public static delete(key: string, userId?: string) {
    if (localStorage) {
      if (userId) {
        key = `${key}-${userId}`;
      }
      localStorage.removeItem(key);
    }
  }
  //#endregion public methods

  //#region private methods
  private static isDateExpired(date: Date) {
    if (!date) {
      return false;
    }
    return date.getTime() <= new Date().getTime();
  }

  private static addMinutes(date: Date, minutes: number): Date {
    return new Date(date.getTime() + minutes * 60000);
  }
  //#endregion private methods
}
