import {
  BrowseBrandsSection,
  CarsSliderBlock,
  GridCardsRental,
} from '../../../common/styles/CommonStyles';
import {
  CarGridCardSkeleton,
  CarGridCarouselSkeleton,
  CarListCardSkeleton,
} from '../../UsedCars/UsedCarsSkeletons';
import { RentPagination } from '../components';
import '../../UsedCars/components/UsedCarBody-styles.css';

export const RentalCarComponentSkeleton = ({
  responsive,
  isGrid,
  noResultsFound,
  setIsGrid,
  sortCars,
  currentPage,
  pagesCount,
  PAGE_SIZE,
  setCurrentPage,
}: {
  responsive?: any;
  isGrid: boolean;
  noResultsFound: boolean;
  setIsGrid: (isGrid: boolean) => void;
  sortCars: (arg: any) => void;
  currentPage: number;
  pagesCount: number;
  PAGE_SIZE: number;
  setCurrentPage: (arg: any) => void;
}) => {
  return (
    <>
      <section style={{ paddingInline: '15px', marginTop: '70px' }}>
        <CarsSliderBlock className="cars-slider-block featured mt-4">
          <CarGridCarouselSkeleton responsive={responsive} />
        </CarsSliderBlock>
      </section>
      <BrowseBrandsSection className="searchcar container">
        <RentPagination
          noResultsFound={noResultsFound}
          isGrid={isGrid}
          setIsGrid={() => setIsGrid(!isGrid)}
          onSort={sortCars}
          currentPage={currentPage}
          totalPages={pagesCount}
          pageSize={PAGE_SIZE}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
        <div
          id="used_cars_for_sale_lisit"
          className={`search-details usedcars-rows ${
            isGrid && 'd-flex flex-wrap justify-content-start'
          }`}
          style={{ ...(isGrid && { gap: '15px' }) }}
        >
          {isGrid ? (
            <>
              {[...Array(10)].map((_, i) => (
                <GridCardsRental key={i}>
                  <CarGridCardSkeleton key={i} />
                </GridCardsRental>
              ))}
            </>
          ) : (
            <>
              {[...Array(10)].map((_, i) => (
                <CarListCardSkeleton key={i} />
              ))}
            </>
          )}
        </div>

        <span className="pagination-middle">
          <div
            style={{
              height: isGrid ? '65px' : '32px',
              margin: '0 auto',
              width: '200px',
            }}
          />
        </span>
      </BrowseBrandsSection>
    </>
  );
};
