import {
  BrowseBrandsSection,
  CarsSliderBlock,
  GridCardsRental,
} from '../../../common/styles/CommonStyles';
import Banner from '../../Banners/BannerComponent';
import { ResultsNotFound } from '../../common';
import FeaturedRentalsCarousel from './FeaturedRentalsCarousel';
import { RentCarGridCard } from './RentCarGridCard';
import { RentCarListCard } from './RentCarListCard';
import { RentPagination } from './RentPagination';
import PagingComponent from '../../Other/PagingComponent';
import { IRentalCar } from '../../../types/cars/rental_car';
import { RentalCarComponentSkeleton } from '../RentalCarLayout/RentalCarLayoutSkeleton';
import '../../../styles/css/swiper-bundle.css';
import '../../UsedCars/components/UsedCarBody-styles.css';

export const RentalCarBodyComponent = ({
  featuredRentals,
  carouselLoader,
  isGrid,
  setIsGrid,
  sortCars,
  currentPage,
  pagesCount,
  rentedCarsWithBanners,
  isLoading,
  responsiveFeatured,
  lang,
  noResultsFound,
  PAGE_SIZE,
  setCurrentPage,
  trans,
  country,
}: {
  featuredRentals: IRentalCar[] | undefined;
  carouselLoader: boolean;
  isGrid: boolean;
  setIsGrid: (isGrid: boolean) => void;
  sortCars: (sort: string) => void;
  currentPage: number;
  pagesCount: number;
  rentedCarsWithBanners: (string | IRentalCar)[];
  isLoading: boolean;
  lang: string;
  responsiveFeatured: any;
  noResultsFound: boolean;
  PAGE_SIZE: number;
  setCurrentPage: (page: number) => void;
  trans: any;
  country: string | null;
}) => {
  if (isLoading) {
    return (
      <RentalCarComponentSkeleton
        isGrid={isGrid}
        responsive={responsiveFeatured}
        noResultsFound={noResultsFound}
        pagesCount={pagesCount}
        setCurrentPage={setCurrentPage}
        setIsGrid={setIsGrid}
        sortCars={sortCars}
        PAGE_SIZE={PAGE_SIZE}
        currentPage={currentPage}
      />
    );
  }
  return (
    <>
      <section style={{ paddingInline: '15px', marginTop: '70px' }}>
        <CarsSliderBlock className="cars-slider-block featured mt-4">
          <FeaturedRentalsCarousel
            featuredRentals={featuredRentals}
            isLoading={carouselLoader}
            responsiveBreakPoints={responsiveFeatured}
          />
        </CarsSliderBlock>
      </section>
      <BrowseBrandsSection className="searchcar container">
        <RentPagination
          noResultsFound={noResultsFound}
          isGrid={isGrid}
          setIsGrid={() => setIsGrid(!isGrid)}
          onSort={sortCars}
          currentPage={currentPage}
          totalPages={pagesCount}
          pageSize={PAGE_SIZE}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
        <div
          id="used_cars_for_sale_lisit"
          className={`search-details usedcars-rows ${
            isGrid && 'd-flex flex-wrap justify-content-start'
          }`}
          style={{ ...(isGrid && { gap: '15px' }) }}
        >
          {rentedCarsWithBanners &&
            rentedCarsWithBanners?.length > 0 &&
            rentedCarsWithBanners?.map((rentedCar: any, index: number) => (
              <div key={index} className="GridBanner">
                {rentedCar === 'banner' ? (
                  <>
                    <div
                      key={Math.random()}
                      style={{ flexBasis: '100%', height: 0 }}
                    ></div>
                    <Banner
                      key={Math.random()}
                      section_name="C2"
                      className="default-image-banner C2-margin w-100"
                    />
                    <div
                      key={Math.random()}
                      style={{ flexBasis: '100%', height: 0 }}
                    ></div>
                  </>
                ) : isGrid ? (
                  <GridCardsRental key={index}>
                    <RentCarGridCard car={rentedCar} isLoading={isLoading} />
                  </GridCardsRental>
                ) : (
                  <RentCarListCard
                    key={index}
                    car={rentedCar}
                    isLoading={isLoading}
                  />
                )}
              </div>
            ))}
        </div>
        {noResultsFound && (
          <ResultsNotFound lang={lang} trans={trans} country={country} />
        )}
        {rentedCarsWithBanners?.length > 0 && (
          <span className="pagination-middle">
            <PagingComponent
              pagesCount={pagesCount}
              pageSize={PAGE_SIZE}
              pageChangeHandler={(page: number) => setCurrentPage(page)}
              currentPage={currentPage}
            />
          </span>
        )}
      </BrowseBrandsSection>
    </>
  );
};
