import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { metaData } from './metaData';
import { arMetaData } from './arMetaData';

type EnMetaDataKeys = keyof typeof metaData;
type ArMetaDataKeys = keyof typeof arMetaData;

type TMetaDataKeys = EnMetaDataKeys & ArMetaDataKeys;
interface IMetaTagProps {
  metaKey: TMetaDataKeys;
  rel?: string;
  href: string;
  params?: IParams;
  imageUrl?: string;
  index?: boolean;
  title?: string;
  description?: string;
}

interface IParams {
  '%NAME%'?: string;
  '%BRAND%'?: string;
  '%MODEL%'?: string;
  '%YEAR%'?: string | number;
}

interface IReplacePlaceHolders {
  content: string;
  params?: IParams;
}

// type DealerLeadsData = { [key: string]: string };

const replacePlaceholders = ({ content, params }: IReplacePlaceHolders) => {
  if (!params) return content;

  const placeholders = {
    '%NAME%': params['%NAME%'],
    '%BRAND%': params['%BRAND%'],
    '%MODEL%': params['%MODEL%'],
    '%YEAR%': params['%YEAR%'],
  };

  let replacedContent = content;

  for (const placeholder in placeholders) {
    if (placeholders[placeholder] !== undefined) {
      replacedContent = replacedContent.replaceAll(
        placeholder,
        placeholders[placeholder],
      );
    }
  }

  //check for any remaining placeholders
  const remainingPlaceholders = replacedContent?.match(/%\w+%/g);
  if (remainingPlaceholders) {
    return '';
  }

  return replacedContent;
};

function MetaTag({
  title: metaTitle,
  description: metaDescription,
  metaKey,
  rel,
  params,
  href,
  imageUrl,
  index = true,
}: IMetaTagProps) {
  const lang = useMemo(() => {
    return window.location.pathname.split('/')[1] || 'en';
  }, [window.location.pathname]);

  const websiteURL = useMemo(() => {
    return window.location.origin;
  }, [window.location.origin]);

  const englishHref = useMemo(() => {
    if (href === '/ar') return websiteURL + '/en';
    return websiteURL + href.replace('/ar/', '/en/');
  }, [href]);

  const arabicHref = useMemo(() => {
    if (href === '/en') return websiteURL + '/ar';
    return websiteURL + href.replace('/en/', '/ar/');
  }, [href]);

  const title = metaTitle
    ? metaTitle
    : lang === 'ar'
    ? arMetaData[metaKey].title
    : metaData[metaKey].title || '';

  const description = metaDescription
    ? metaDescription
    : lang === 'ar'
    ? arMetaData[metaKey].description
    : metaData[metaKey].description || '';

  return (
    <Helmet htmlAttributes={{ lang }}>
      <title>{replacePlaceholders({ content: title, params })}</title>
      <meta
        name="description"
        content={replacePlaceholders({ content: description, params })}
      />
      <meta
        name="og:title"
        content={replacePlaceholders({ content: title, params })}
      />
      <meta
        name="og:description"
        content={replacePlaceholders({ content: description, params })}
      />
      <meta name="og:image" content={imageUrl} />
      {index ? (
        <meta name="robots" content="index, follow" />
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}
      <link rel="canonical" href={websiteURL + href} />
      <link rel="alternate" hrefLang="en" href={englishHref} />
      <link rel="alternate" hrefLang="ar" href={arabicHref} />
      <link rel="alternate" hrefLang="x-default" href={englishHref} />
    </Helmet>
  );
}

export default MetaTag;

export const metaDataTitle = item => {
  return item?.title.replace(/<\/?[^>]+(>|$)/g, '');
};

export const metaDataDescription = item => {
  return item?.description
    .slice(0, 300)
    .replace(/\.(\s|$)/, '$1')
    .replace(/<\/?[^>]+(>|$)/g, '');
};
