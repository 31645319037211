import { AppConstants } from '../constants/AppConstants';
import { BannerModel } from '../models/BannerModel';
import { getBanners } from '../services/ApiService';
import { getBannerInternal } from '../services/BannerService';
import { LocalStorageService } from '../services/LocalStorageService';

interface IBannerInstance {
  getBanner: (sectionName: string, isMobile: boolean) => Promise<BannerModel>;
  getBanners: (
    sectionName: string,
    shuffle: boolean,
    isMobile: boolean,
  ) => Promise<Array<BannerModel>>;
  fetchBanners: (reset?: boolean, isMobile?: boolean) => Promise<void>;
}

const BannerInstance: IBannerInstance = {
  getBanner: async (sectionName: string, isMobile: boolean) => {
    const banners = LocalStorageService.get<{
      [key: string]: Array<BannerModel>;
    }>(AppConstants.LOCAL_STORAGE.BANNERS);

    if (!banners) {
      await BannerInstance.fetchBanners(false, isMobile);
    }

    const groupKeys = getGroupKeysAndKey(sectionName, isMobile);

    const array: BannerModel[] = [];

    groupKeys.bannerKeys.map(groupKey => {
      const banner = getBannerInternal(groupKey);
      array.push(...(banner as BannerModel[]));
    });

    return array[0];
  },
  getBanners: async (
    sectionName: string,
    shuffle: boolean,
    isMobile: boolean,
  ) => {
    const banners = LocalStorageService.get<{
      [key: string]: BannerModel;
    }>(AppConstants.LOCAL_STORAGE.BANNERS);
    if (!banners) {
      await BannerInstance.fetchBanners(false, isMobile);
    }

    const keys = getGroupKeysAndKey(sectionName, isMobile);

    const array: BannerModel[] = [];

    keys.bannerKeys.map(groupKey => {
      const banner = getBannerInternal(groupKey);
      array.push(...(banner as BannerModel[]));
    });

    return array;
  },

  fetchBanners: async (
    reset: boolean = false,
    isMobile: boolean = false,
  ): Promise<void> => {
    // reset param is used to enable refetching data from BE on every browser refresh, default = false > rely on the cached list of banners in LS, which has a 60-minute TTL
    let banners =
      LocalStorageService.get<{ [key: string]: Array<BannerModel> }>(
        AppConstants.LOCAL_STORAGE.BANNERS,
      ) ?? {};
    if (!reset && JSON.stringify(banners) !== JSON.stringify({})) {
      return;
    }
    const data = (await getBanners()) as Array<BannerModel>;

    // Sort banners for each BannerModel
    data?.forEach(bannerModel => {
      if (bannerModel.banners) {
        bannerModel.banners.sort((a, b) =>
          a.order > b.order ? 1 : b.order > a.order ? -1 : 0,
        );
      }
    });

    const grouped: { [key: string]: BannerModel } = data?.reduce(
      (acc: { [key: string]: BannerModel }, bannerModel: BannerModel) => {
        const groupKey = `${bannerModel.placement}-${bannerModel.section_name}-${bannerModel.content_type}`;

        acc[groupKey] = bannerModel;

        return acc;
      },
      {},
    );

    // Default TTL: 60 minutes, data will expire after 60 minute
    LocalStorageService.set<{ [key: string]: BannerModel }>(
      AppConstants.LOCAL_STORAGE.BANNERS,
      grouped,
      AppConstants.LOCAL_STORAGE.DEFAULT_TTL,
    );
  },
};

function getGroupKeysAndKey(sectionName: string, isMobile: boolean) {
  const excludedBanners = ['A1', 'E1'];
  const placement =
    !isMobile || excludedBanners.includes(sectionName)
      ? AppConstants.BANNERS_PLACEMENT.PORTAL
      : AppConstants.BANNERS_PLACEMENT.MOBILE;

  const banners =
    LocalStorageService.get<{ [key: string]: BannerModel }>(
      AppConstants.LOCAL_STORAGE.BANNERS,
    ) ?? {};

  const bannerKeys = Object.keys(banners).filter(key =>
    key.includes(`${placement}-${sectionName}`),
  );

  const groupKey = `${placement}-${sectionName}`;

  return { bannerKeys, groupKey };
}

export default BannerInstance;
