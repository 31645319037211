import React, { useState } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImageZoomModal = ({ imageSrc, open, handleClose, handleOpen }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-modal"
        aria-describedby="modal for image zoom"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            outline: 0,
            borderRadius: '8px',
            padding: '8px',
            '& img': {
              maxWidth: '90vw',
              maxHeight: '90vh',
              objectFit: 'fill',
            },
            '@media (max-width: 600px)': {
              width: '95vw',
              '& img': {
                maxHeight: '70vh',
              },
            },
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              color: '#fff',
              alignSelf: 'flex-end',
              marginTop: '-10px',
              marginRight: '-10px',
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={imageSrc}
            alt="Zoomed"
            style={{
              width: 'fit-content',
              objectFit: 'contain',
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default ImageZoomModal;
