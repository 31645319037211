import authenticatedApi from '../../../instance/ApiInstance';
import { IBrand } from '../../../types/resources/cars';

const brandsEndpoint = '/v2/resources/bikes/brands';

export interface IGetBikeBrands {
  dealer?: number;
  type?: 'new' | 'used';
}

export const getBikeBrands = async (params?: IGetBikeBrands): Promise<IBrand[]> => {
  const response = await authenticatedApi.get(brandsEndpoint, {
    params,
  });
  return response.data;
};
