import React, { useContext, useEffect, useRef, useState } from 'react';
import { IUsedBike } from '../../../types/bikes';
import { AppContext } from '../../../contexts/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateUsedBikeInquiry } from '../hooks';
import PageLoader from '../../Other/PageLoader/PageLoaderComponent';
import parse from 'html-react-parser';
import { isValidEmail } from '../../../Helper';
import '../../../styles/css/steps.css';

interface IUsedBikesInquiryFormProps {
  bikeId: IUsedBike['id'];
  closeDealForm: () => void;
}

export const UsedBikeInquiryForm = ({
  bikeId,
  closeDealForm,
}: IUsedBikesInquiryFormProps) => {
  const { lang, trans, country } = useContext(AppContext);
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const ref1 = useRef<any>(null);
  const ref2 = useRef<any>(null);
  const ref3 = useRef<any>(null);
  const ref4 = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { mutateAsync: createInquiry, isLoading: isCreating } =
    useCreateUsedBikeInquiry();

  useEffect(() => {
    ref1?.current.focus();
  }, []);

  const handlePhoneNumberInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPhoneNumber(event.target.value.slice(0, 8));
  };

  const goToStep = (num: any) => {
    switch (num) {
      case 1:
        var activeSteps = document.getElementsByClassName('active');
        if (activeSteps && activeSteps.length > 0) {
          Array.from(activeSteps).forEach((activeStep: any) => {
            activeStep.classList.remove('active');
          });
        }

        document.getElementById('step1')?.classList.add('active');
        document.getElementById('target_step1')?.classList.add('active');
        ref1?.current.focus();
        break;
      case 2:
        var activeSteps = document.getElementsByClassName('active');
        if (activeSteps && activeSteps.length > 0) {
          Array.from(activeSteps).forEach((activeStep: any) => {
            activeStep.classList.remove('active');
          });
        }

        document.getElementById('step2')?.classList.add('active');
        document.getElementById('target_step1')?.classList.add('active');
        document.getElementById('target_step2')?.classList.add('active');
        ref2?.current.focus();
        break;
      case 3:
        var activeSteps = document.getElementsByClassName('active');
        if (activeSteps && activeSteps.length > 0) {
          Array.from(activeSteps).forEach((activeStep: any) => {
            activeStep.classList.remove('active');
          });
        }

        document.getElementById('step3')?.classList.add('active');
        document.getElementById('target_step1')?.classList.add('active');
        document.getElementById('target_step2')?.classList.add('active');
        document.getElementById('target_step3')?.classList.add('active');
        ref3?.current.focus();
        break;
      case 4:
        var activeSteps = document.getElementsByClassName('active');
        if (activeSteps && activeSteps.length > 0) {
          Array.from(activeSteps).forEach((activeStep: any) => {
            activeStep.classList.remove('active');
          });
        }

        document.getElementById('step4')?.classList.add('active');
        document.getElementById('target_step1')?.classList.add('active');
        document.getElementById('target_step2')?.classList.add('active');
        document.getElementById('target_step3')?.classList.add('active');
        document.getElementById('target_step4')?.classList.add('active');
        ref4?.current.focus();
        break;
      case 5:
        submitInquiry();
        break;
      default:
        break;
    }
  };

  const validatePhoneNumber = () => {
    const allowedStartingDigits = ['3', '4', '5', '6', '7', '8'];
    if (phoneNumber.length != 8) {
      setPhoneNumberError(trans.phone_at_least_8);
      return false;
    }
    if (!allowedStartingDigits.includes(phoneNumber[0])) {
      setPhoneNumberError(trans.phone_should_be_valid);
      return false;
    }
    setPhoneNumberError(null);
    return true;
  };

  const submitInquiry = async () => {
    if (fullName.trim() === '' || email === '' || !validatePhoneNumber())
      return;

    try {
      await createInquiry({
        name: fullName,
        email,
        mobile_number:
          country === 'qa' ? `+974${phoneNumber}` : `+966${phoneNumber}`,
        used_bike_id: bikeId,
      });

      var activeSteps = document.getElementsByClassName('active');
      if (activeSteps && activeSteps.length > 0) {
        Array.from(activeSteps).forEach((activeStep: any) => {
          activeStep.classList.remove('active');
        });
      }

      if (location.pathname.includes('featured'))
        navigate(`../${lang}/featured/thank-you`);
      if (location.pathname.includes('used-bikes'))
        navigate(`../${lang}/used/thank-you`);
    } catch (error) {
      return;
    }
  };

  return (
    <>
      <div
        className="modal steps-wizard-modal fade show deals-form-modal"
        id="exampleModalCenter"
      >
        {isCreating && <PageLoader />}
        <div
          className="steps-wizard-modal-dialog steps-wizard-modal-dialog-centered"
          role="document"
        >
          <div className="steps-wizard-modal-content">
            <button
              type="button"
              className="close close-steps-wizard-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => closeDealForm()}
            >
              <span aria-hidden="true">×</span>
            </button>
            <form className="buttonnew">
              <input
                type="hidden"
                name="_token"
                value="lnx7rIxp88K1DwGh3yyoY448TiIX9jYNAWbo3Qcw"
              />
              <input
                type="hidden"
                name="featured_car_id"
                id="carid"
                value="21"
              />
              <div className="getdeal-popup">
                <div className="step-app" id="wizard-modal">
                  <div className="step-content vertical-center">
                    <div className="step-tab-panel active" id="step1">
                      <div className="steps-wizard-form">
                        <h2 className="steps-title">{trans.hello}!</h2>
                        <div className="form-group">
                          <label htmlFor="firstname">
                            {parse(trans.lets_start_with_your_full_name)}. *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={fullName}
                            onChange={e => setFullName(e.target.value)}
                            placeholder={trans.type_your_answer_here}
                            ref={ref1}
                            onKeyDown={event =>
                              event.key === 'Enter' &&
                              fullName.trim() !== '' &&
                              goToStep(2)
                            }
                          />
                        </div>
                        <button
                          type="button"
                          id="next_step1"
                          className="btn btn-primary next-btn"
                          disabled={fullName.trim() === ''}
                          onClick={() => goToStep(2)}
                        >
                          {trans.next}
                        </button>
                      </div>
                    </div>
                    <div className="step-tab-panel" id="step2">
                      <div className="steps-wizard-form">
                        <div className="form-group">
                          <label htmlFor="emailaddress">
                            {parse(trans.what_is_your_email_address)} *
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                            placeholder={trans.type_your_answer_here}
                            ref={ref2}
                            onKeyDown={event =>
                              event.key === 'Enter' &&
                              email.trim() !== '' &&
                              email.match(isValidEmail) &&
                              goToStep(3)
                            }
                          />
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary next-btn"
                          disabled={
                            email.trim() === '' || !email.match(isValidEmail)
                          }
                          onClick={() => goToStep(3)}
                        >
                          {trans.next}
                        </button>
                      </div>
                    </div>
                    <div className="step-tab-panel" id="step3">
                      <div className="steps-wizard-form">
                        <div className="form-group">
                          <label htmlFor="phonenumber">
                            {parse(trans.and_your_phone_number)} *
                          </label>
                          <div
                            className="d-flex flex-column justify-content-bottom align-items-start"
                            style={{ rowGap: '5px' }}
                          >
                            <div
                              dir="ltr"
                              className="d-flex justify-content-center align-items-top"
                              style={{ gap: '20px' }}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ gap: '5px' }}
                              >
                                {country === 'qa' ? (
                                  <img
                                    src="/images/language1.png"
                                    alt="Qatar Flag"
                                  />
                                ) : (
                                  <img
                                    src="/images/saudi-flag.svg"
                                    alt="Qatar Flag"
                                  />
                                )}
                                <span style={{ fontSize: '29px' }}>
                                  {country === 'qa' ? '+974:' : '+966:'}
                                </span>
                              </div>
                              <input
                                type="number"
                                // pattern="\d*"
                                min="0"
                                // maxLength={8}
                                onKeyDown={evt => {
                                  if (['e', 'E'].includes(evt.key))
                                    evt.preventDefault();
                                  if (
                                    evt.key === 'Enter' &&
                                    phoneNumber.trim().length >= 8
                                  )
                                    goToStep(5);
                                }}
                                className="form-control"
                                value={phoneNumber}
                                onChange={handlePhoneNumberInput}
                                placeholder={trans.type_your_answer_here}
                                ref={ref3}
                              />
                            </div>
                            <small
                              className={`align-self-${
                                lang == 'ar' ? 'end' : 'start'
                              }`}
                              style={{ color: '#f00', minHeight: '20px' }}
                            >
                              {phoneNumberError}
                            </small>
                          </div>
                          <input
                            type="hidden"
                            name="phone_code"
                            id="phone_code"
                            value={country === 'qa' ? '+974' : '+966'}
                          />
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary next-btn"
                          disabled={phoneNumber.trim().length < 8}
                          onClick={() => goToStep(5)}
                        >
                          {trans.submit}
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul className="step-steps">
                    <li
                      id="target_step1"
                      className="active"
                      onClick={() => goToStep(1)}
                    >
                      1
                    </li>
                    <li id="target_step2" onClick={() => goToStep(2)}>
                      2
                    </li>
                    <li id="target_step3" onClick={() => goToStep(3)}>
                      3
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
