import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { Link } from 'react-router-dom';
import LazyImage from '../../../Other/LazyImageComponent';
import { IRamadanOffers } from '../../../../types/resources/cars/ramadan_offers';
import './ramadanOfferCardStyle.css';
import { RamadanOfferListImage } from '../../RamadanOfferListPage/RamadanOfferListImage';
import { RamadanOfferListImageSkeleton } from '../RamadanSkeletons';

type RamadanCarCardProps = {
  offer: IRamadanOffers;
  redirectionUrl: string;
  className?: string;
  style?: string;
  isLoading?: boolean;
};
export const RamadanCarCard = ({
  offer,
  redirectionUrl,
  className,
  style,
  isLoading,
}: RamadanCarCardProps) => {
  const { lightImageLoadingPlaceholder } = useContext(AppContext);

  const modelName = offer.model_ramadan_offer
    .map(model => model.models.name)
    .join(', ');

  if (isLoading) {
    return <RamadanOfferListImageSkeleton />;
  }

  return (
    <Link to={redirectionUrl}>
      {offer.images && (
        <div className={className}>
          <div
            style={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <RamadanOfferListImage
              src={offer.ramadan_offer_cover_image}
              class={style}
              defaultSrc={lightImageLoadingPlaceholder}
              alt="Ramadan Offer Image"
            />
          </div>
          <div className="ramadan-card-content-container">
            <div className="ramadan-card-content">
              <p className="ramadan-card-content-title">{offer.title}</p>
              <div>
                <LazyImage
                  src={offer.brand.original_logo}
                  class="ramadan-card-brand-logo"
                />
              </div>
            </div>
          </div>
          <div className="ramadan-card-content-m-container">
            <p className="ramadan-card-content-models">
              {modelName.length > 45
                ? modelName.substring(0, 45) + '...'
                : modelName}
            </p>
          </div>
        </div>
      )}
    </Link>
  );
};
