import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import PageLoader from './PageLoader/PageLoaderComponent';
import {
  ColLg12,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  PrivacyContent,
  PrivacyContentA,
  PrivacyContentHolder,
  PrivacyContentP,
  PrivacyNav,
  PrivacyNavLi,
  PrivacyNavLiStrong,
  PrivacyTitle,
  Row,
  SellYourCar,
} from '../../common/styles/CommonStyles';
export default function TermsAndConditions(this: any) {
  const { lang, trans } = useContext(AppContext);
  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      setShowPageLoader(false);
    }, 100);
  }, []);

  return (
    <>
      {showPageLoader && <PageLoader />}
      <main id="main">
        <MainHead>
          <MainHeadContainer className="container">
            <Row>
              <ColLg12>
                <MainHeadH1>{trans.terms}</MainHeadH1>
              </ColLg12>
            </Row>
          </MainHeadContainer>
        </MainHead>
        <SellYourCar className="container privacy-block">
          <PrivacyContent>
            {lang === 'en' ? (
              <>
                <PrivacyNav>
                  <PrivacyNavLi>
                    <PrivacyNavLiStrong>
                      By using our platform, you should confirm that you accept
                      these terms of use and that you agree to comply with them.
                    </PrivacyNavLiStrong>
                  </PrivacyNavLi>
                  <PrivacyNavLi>
                    If you do not agree to these terms, you must not use our
                    platform.
                  </PrivacyNavLi>
                </PrivacyNav>
                <PrivacyContentP>
                  By accessing One Autocar, you are agreeing to the following
                  terms and conditions:
                </PrivacyContentP>
                <PrivacyContentHolder>
                  <PrivacyTitle>
                    You Should Agree That You Will Not:
                  </PrivacyTitle>
                  <PrivacyContentP>
                    Upon using One Autocar, we directly collect your personal
                    information the moment you submit them.{' '}
                  </PrivacyContentP>
                  <PrivacyContentP>
                    These personal information may include:
                  </PrivacyContentP>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      Violate or abuse any laws relating to the business.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      Post any material that is offensive, malicious, or
                      indecent.
                    </PrivacyNavLi>
                    <PrivacyNavLi>Breach any third-party rights.</PrivacyNavLi>
                    <PrivacyNavLi>
                      Distribute or contain spam, chain letters, or pyramid
                      schemes.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      Post any false or misleading information.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      Send out viruses or other technologies that could hurt One
                      Autocar or its users' interests or property.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      Copy, modify, or distribute any other person's content
                      without their consent.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      Disregard measures used to prevent or restrict access to
                      One Autocar.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      Collect information about others, including email
                      addresses, without their consent.
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>

                <PrivacyContentHolder>
                  <PrivacyTitle>Responsibility</PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      The person is the only responsible party for all the
                      information that he/she submits to the platform, and any
                      consequences that may result from their post.
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>We have the right to:</PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      Refuse / delete any content that we believe is
                      inappropriate or unsuitable or violating the above terms.{' '}
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      Restrict a user's usage of the site either temporarily or
                      permanently, or refuse a user's registration based on
                      their submitted information.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      Disable any user, account or password at any time, in case
                      of: threatening, abusive, defamatory, discriminatory or
                      offensive content.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      Disclose your identity to any third party who is claiming
                      that any content posted by you is a violation of their
                      intellectual property rights, is defamatory or breaches
                      their right to privacy, including cyber bullying and
                      harassment.{' '}
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>
                    Changes are applicable at anytime:
                  </PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      We may update our terms from time to time.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      Updated terms will become effective from the date of
                      posting.{' '}
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      These changes will be effective immediately on any deal
                      that was already posted on the platform.
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>Account safety:</PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      Make sure all the required information such as the user's
                      identification code or password are confidential.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      {' '}
                      If you think that anyone other than you knows your
                      credentials, you must immediately notify us at{' '}
                      <PrivacyContentA href="mailto:info@oneautocar.com">
                        info@oneautocar.com
                      </PrivacyContentA>
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>Car Details</PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      The photo posted of the car should be a real image of the
                      car in possession, photos from other  resources are
                      prohibited.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      You pledge that any photos or information provided
                      regarding a car for sale is correct and exact, and  that
                      you have all the right to sell the vehicle.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      You confirm and agree that any material you send us must:
                      <PrivacyNav className="privacy-nav">
                        <PrivacyNavLi>Be accurate.</PrivacyNavLi>
                        <PrivacyNavLi>
                          Complies with all applicable rules, regulations and
                          laws.
                        </PrivacyNavLi>
                      </PrivacyNav>
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      You pledge and agree that any material you send us must
                      not:
                      <PrivacyNav className="privacy-nav">
                        <PrivacyNavLi>
                          Be fraudulent or misleading.
                        </PrivacyNavLi>
                        <PrivacyNavLi>
                          Be unlawful, harmful, threatening, defamatory,
                          obscene, harassing or racially and ethnically
                          offensive or objectionable to us.
                        </PrivacyNavLi>
                        <PrivacyNavLi>
                          Facilitate or promote illegal activity.
                        </PrivacyNavLi>
                        <PrivacyNavLi>
                          Contain anything which is technically harmful.
                        </PrivacyNavLi>
                        <PrivacyNavLi>
                          Violate the intellectual property rights or other
                          rights of any third party.
                        </PrivacyNavLi>
                        <PrivacyNavLi>
                          Promote unlawful violence or discriminate based on
                          race, gender, colour, religious belief, sexual
                          orientation and disability.
                        </PrivacyNavLi>
                      </PrivacyNav>
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>We are not responsible for:</PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>Bugs or viruses.</PrivacyNavLi>
                    <PrivacyNavLi>
                      Websites we link to: as our sites contain links to other
                      sites and resources provided by third parties, we have no
                      control over the content of those sites or resources.
                    </PrivacyNavLi>
                    <PrivacyNavLi>Any sales done on the platform.</PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>
                    Our responsibilities for loss or damage
                  </PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      For consumers:
                      <PrivacyNav className="privacy-nav">
                        <PrivacyNavLi>
                          We at One Autocar do not exclude or limit in any way
                          our liability to you where it would be unlawful to do
                          so. This includes liability for major factors like
                          death or personal injury caused by our negligence or
                          the negligence of our employees or subcontractors and
                          for any fraudulent violence.
                        </PrivacyNavLi>
                      </PrivacyNav>
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      For business users:
                      <PrivacyNav className="privacy-nav">
                        <PrivacyNavLi>
                          We exclude all implied circumstances, assurances or
                          other terms that may apply to our site or any content
                          included in it.
                        </PrivacyNavLi>
                      </PrivacyNav>
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      We will not be in charge of any loss or damage,
                      negligence, breach of statutory duty, or otherwise, even
                      if expected.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      We will not be responsible for:
                      <PrivacyNav className="privacy-nav">
                        <PrivacyNavLi>Loss of revenue or sales.</PrivacyNavLi>
                        <PrivacyNavLi>Business obstruction</PrivacyNavLi>
                        <PrivacyNavLi>Loss of expected savings</PrivacyNavLi>
                        <PrivacyNavLi>
                          Any indirect or consequential loss or damage.
                        </PrivacyNavLi>
                      </PrivacyNav>
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
              </>
            ) : (
              <>
                <PrivacyNav>
                  <PrivacyNavLi>
                    <PrivacyNavLiStrong>
                      باختيارك لاستخدام منصّتنا، تؤكد قبولك لشروط الاستخدام هذه
                      وموافقتك على الامتثال لها.
                    </PrivacyNavLiStrong>
                  </PrivacyNavLi>
                  <PrivacyNavLi>
                    إذا كنت لا توافق على هذه الشروط، يجب ألا تستخدم منصتنا.
                  </PrivacyNavLi>
                </PrivacyNav>
                <PrivacyContentP>
                  من خلال استخدام إلى One Autocar، فإنك توافق على الشروط
                  والأحكام التالية:
                </PrivacyContentP>

                <PrivacyContentHolder>
                  <PrivacyTitle>يجب أن توافق على أنك لن:</PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      تنتهك أو تسيء استخدام أي قوانين متعلقة بالعمل.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      تنشر أي مواد مسيئة أو خبيثة أو غير لائقة.
                    </PrivacyNavLi>
                    <PrivacyNavLi>تخرق أي حقوق طرف ثالث.</PrivacyNavLi>
                    <PrivacyNavLi>
                      توزع بريد عشوائي أو رسائل متسلسلة أو مخططات هرمية.
                    </PrivacyNavLi>
                    <PrivacyNavLi>تنشر أي معلومات خاطئة أو مضللة.</PrivacyNavLi>
                    <PrivacyNavLi>
                      تصدر فيروسات أو أي تقنيات أخرى قد تضر One Autocar أو مصالح
                      أو ممتلكات مستخدمي One Autocar.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      تنسخ أو تعدل أو توزع محتوى أي شخص آخر دون موافقته.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      تتجاهل الإجراءات المستخدمة لمنع أو تقييد الوصول إلى One
                      Autocar.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      تجمع المعلومات عن الآخرين ؛ بما في ذلك عناوين البريد
                      الإلكتروني دون موافقتهم.
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>

                <PrivacyContentHolder>
                  <PrivacyTitle>المسؤولية:</PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      الشخص هو المسؤول الوحيد عن جميع المعلومات التي يقدمها إلى
                      المنصة، وأي عواقب قد تنجم عن منشوره.
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>لدينا الحق في:</PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      رفض / حذف أي محتوى نعتقد أنه غير لائق أو غير مناسب أو
                      ينتهك الشروط المذكورة أعلاه.{' '}
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      تقييد استخدام المستخدم للموقع إما بشكل مؤقت أو دائم، أو
                      رفض تسجيل المستخدم بناءً على المعلومات المقدمة.
                    </PrivacyNavLi>

                    <PrivacyNavLi>
                      تعطيل أي مستخدم أو حساب أو كلمة مرور في أي وقت، في حال كان
                      المحتوى مهدد أو مسيء أو تشهيري أو تمييزي أو مسيء.
                    </PrivacyNavLi>

                    <PrivacyNavLi>
                      الكشف عن هويتك لأي طرف ثالث يدعي أن أي محتوى تنشره يعد
                      انتهاكًا لحقوق الملكية الفكرية الخاصة به أو تشهيريًا أو
                      ينتهك حقه في الخصوصية، بما في ذلك التنمر والمضايقة عبر
                      الإنترنت.{' '}
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>
                    التغييرات قابلة للتطبيق في أي وقت:
                  </PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      قد نقوم بتحديث شروطنا من وقت لآخر.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      الشروط المحدثة تصبح سارية المفعول من تاريخ النشر.{' '}
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      التغييرات تصبح سارية على الفور على أي صفقة تم نشرها بالفعل
                      على المنصة.
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>أمن الحساب:</PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      تأكد من أن جميع المعلومات المطلوبة مثل رمز تعريف المستخدم
                      أو كلمة المرور سرية.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      {' '}
                      إذا كنت تعتقد أن أي شخص آخر غيرك يعرف بيانات اعتمادك، فيجب
                      عليك إعلامنا على الفور على{' '}
                      <PrivacyContentA href="mailto:info@oneautocar.com">
                        info@oneautocar.com
                      </PrivacyContentA>
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>تفاصيل السيارات:</PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      يجب أن تكون الصورة المنشورة صورة حقيقية للسيارة التي
                      بحوزتك، ويحظر الحصول على صور من مصادر أخرى.
                    </PrivacyNavLi>

                    <PrivacyNavLi>
                      عليك التعهد بأن أي صور أو معلومات مقدمة بخصوص سيارة معروضة
                      للبيع صحيحة ودقيقة، وأن لك كل الحق في بيع السيارة.
                    </PrivacyNavLi>

                    <PrivacyNavLi>
                      عليك أن تؤكد وتوافق على أن أي معلومة ترسلها إلينا يجب أن:
                      <PrivacyNav className="privacy-nav">
                        <PrivacyNavLi>تكون دقيقة.</PrivacyNavLi>
                        <PrivacyNavLi>
                          تتوافق مع جميع القواعد واللوائح والقوانين المعمول بها.
                        </PrivacyNavLi>
                      </PrivacyNav>
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      يجب أن تتعهد وتوافق على أن أي مادة ترسلها يجب ألا:
                      <PrivacyNav className="privacy-nav">
                        <PrivacyNavLi>تكون احتيالية أو مضللة.</PrivacyNavLi>

                        <PrivacyNavLi>
                          تكون غير قانونية أو ضارة أو مهددة أو تشهيرية أو فاحشة
                          أو مضايقة أو مسيئة أو عرقية أو إثنية أو مرفوضة لنا.
                        </PrivacyNavLi>

                        <PrivacyNavLi>
                          تسهّل أو تروّج لنشاط غير قانوني.
                        </PrivacyNavLi>

                        <PrivacyNavLi>
                          تحتوي على أي شيء ضار من الناحية التقنية.
                        </PrivacyNavLi>

                        <PrivacyNavLi>
                          تنتهك حقوق الملكية الفكرية أو الحقوق الأخرى لأي طرف
                          ثالث.
                        </PrivacyNavLi>

                        <PrivacyNavLi>
                          تروّج للعنف غير القانوني أو التمييز على أساس العرق
                          والجنس واللون والمعتقد الديني والتوجه الجنسي والإعاقة.
                        </PrivacyNavLi>
                      </PrivacyNav>
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>نحن لسنا مسؤولين عن:</PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      الأعطال والأخطاء البرمجية أو الفيروسات.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      محتوى المواقع التي تُنشر روابطها على تطبيقنا لأنها روابط
                      لمواقع وموارد أخرى مقدمة من جهات خارجية. فإننا لا نتحكم في
                      محتوى تلك المواقع أو الموارد.
                    </PrivacyNavLi>
                    <PrivacyNavLi>أي مبيعات تتم على المنصة.</PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>مسؤولياتنا عن الخسارة أو الضرر</PrivacyTitle>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      بالنسبة للمستهلكين:
                      <PrivacyNav className="privacy-nav">
                        <PrivacyNavLi>
                          نحن في One Autocar لا نستثني أو نحد بأي شكل من الأشكال
                          مسؤوليتنا تجاهك حيث يكون القيام بذلك غير قانوني. وهذا
                          يشمل المسؤولية عن العوامل الرئيسية مثل الوفاة أو
                          الإصابة الشخصية الناجمة عن إهمالنا أو إهمال موظفينا أو
                          المقاولين من الباطن وعن أي عنف احتيالي.
                        </PrivacyNavLi>
                      </PrivacyNav>
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      بالنسبة لأصحاب الأعمال:
                      <PrivacyNav className="privacy-nav">
                        <PrivacyNavLi>
                          نستبعد جميع الظروف أو التأكيدات أو الشروط الأخرى التي
                          قد تنطبق على موقعنا أو أي محتوى مدرج فيه.
                        </PrivacyNavLi>
                      </PrivacyNav>
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      لن نكون مسؤولين عن أي خسارة أو ضرر أو إهمال أو خرق للواجب
                      القانوني أو غير ذلك ، حتى لو كان ذلك متوقعًا.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      لن نكون مسؤولين عن:
                      <PrivacyNav className="privacy-nav">
                        <PrivacyNavLi>
                          خسارة الإيرادات أو المبيعات.
                        </PrivacyNavLi>
                        <PrivacyNavLi>تعطّل العمل</PrivacyNavLi>
                        <PrivacyNavLi>خسارة المدخرات المتوقعة</PrivacyNavLi>
                        <PrivacyNavLi>
                          أي خسارة أو ضرر غير مباشر أو تبعي.
                        </PrivacyNavLi>
                      </PrivacyNav>
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
              </>
            )}
          </PrivacyContent>
        </SellYourCar>
      </main>
    </>
  );
}
