import { useQuery } from 'react-query';
import { search } from '../apis/search.apis';

export const useSearch = ({ query }) => {
  return useQuery({
    queryKey: ['search', query],
    queryFn: () => search(query),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 5, // 5 minutes
    enabled: !!query,
  });
};
