import { useQuery } from 'react-query';
import {
  IGetReviews,
  getLast4CreatedReviews,
  getReviewBySlug,
  getReviews,
} from '../apis';
import { IReview } from '../../../types/reviews';
import { queryClient } from '../../../App';

interface IUseGetReviews {
  query: IGetReviews;
  enabled: boolean;
}

export const useGetReviews = ({ query, enabled }: IUseGetReviews) => {
  return useQuery({
    queryKey: ['reviews', query],
    queryFn: () => getReviews(query),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: enabled,
  });
};

export const useGetMoreReviews = (skip?: string) => {
  return useQuery({
    queryKey: ['reviews', 'more-reviews', skip],
    queryFn: () => getLast4CreatedReviews(skip),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!skip,
    onSuccess: data => {
      // Filter out the review with the specified Slug
      const filteredReviews = data.filter(
        (review: IReview) => review.slug !== skip,
      );
      queryClient.setQueryData(['reviews', 'more-reviews'], filteredReviews);
    },
  });
};

export const useGetReviewBySlug = (slug: string) => {
  return useQuery({
    queryKey: ['reviews', slug],
    queryFn: () => getReviewBySlug(slug),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!slug,
  });
};
