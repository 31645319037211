import React, { Dispatch, SetStateAction } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { TUsedCarsFilter } from '../../UsedCars/UsedCarsComponent';
import {
  ListingsFiltersBarContainer,
  UsedCarsFilterButton,
} from '../../UsedCars/components/UsedCarStyles';
import { IBrand, IModel } from '../../../types/resources/cars';
import '../../User/SellYourCar/sellYourCar-styles.css';

interface IListingFilter {
  setShowAdvancedFilter: Dispatch<SetStateAction<boolean>>;
  filters: TFilters;
  setFilters: Dispatch<SetStateAction<TFilters>>;
  getFilteredFilters: (filters: TFilters) => {};
  setUsedCarQueryParams: Dispatch<SetStateAction<TUsedCarsFilter | undefined>>;
  filtersNumber: number;
  setFiltersNumber: Dispatch<SetStateAction<number>>;
  brands: IBrand[] | undefined;
  models: IModel[] | undefined;
  dealers: any[] | undefined;
  isFetchingModels: boolean;
}

const ITEM_HEIGHT = 48;
export const MenuProps = {
  PaperProps: {
    sx: {
      color: '#4E4E4E',
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + 8,
      width: 200,
    },
  },
  disableScrollLock: true,
  style: {
    position: 'absolute' as 'absolute',
  },
};

const selectStyle = {
  color: '#4E4E4E',
  fontWeight: 700,
  marginTop: 28,
  marginBottom: 28,
  borderRadius: 8,
  fontFamily: 'Lato',
  width: 230,
};

export type TFilters = {
  brandIds: number[];
  modelIds: number[];
  types: number[];
  maxPrice: number;
  minPrice: number;
  minMileage: number;
  maxMileage: number;
  minYear: number;
  maxYear: number;
  transmissions?: number[];
  cylinders?: number[];
  conditions?: number[];
  exteriorColors?: number[];
  interiorColors?: number[];
  engineTypes?: number[];
  engineSizes?: number[];
  sellerType?: string;
  dealerIds?: number[];
  batteryCapacities?: number[];
  batteryRanges?: number[];
  seatMaterials?: number[];
  optionalFeatures: number[];
  colors?: number[];
};

export function generateOptions(highestNumber: number): {
  id: number;
  value: string;
}[] {
  const options: any = [];
  for (let i: number = 10000; i <= highestNumber; i += 10000) {
    options.push({ id: i, value: i.toLocaleString() });
  }

  return options;
}

export function ListingFilterBar({
  setShowAdvancedFilter,
  filters,
  setFilters,
  getFilteredFilters,
  setUsedCarQueryParams,
  filtersNumber,
  brands,
  models,
  isFetchingModels,
  dealers,
}: IListingFilter) {
  //#region States

  const windowWidth = window.innerWidth;

  const { trans } = React.useContext(AppContext);

  //#endregion

  const handleBrandChange = (
    event: SelectChangeEvent<typeof filters.brandIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      const newFilters = {
        ...filters,
        brandIds: value,
        modelIds: filters.modelIds?.filter(modelId => {
          const model = models?.find(model => model.id === modelId);
          return model ? value.includes(model.brand_id) : false;
        }),
      };
      if (filters.modelIds?.length > 0 && newFilters.modelIds?.length === 0) {
        // setFiltersNumber(prev => prev - 1);
      }
      setFilters(newFilters);
      const filteredFilters = getFilteredFilters(newFilters);
      setUsedCarQueryParams(filteredFilters);
    }
  };

  const handleModelChange = (
    event: SelectChangeEvent<typeof filters.modelIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      const newFilters = {
        ...filters,
        modelIds: value,
      };
      setFilters(newFilters);
      const filteredFilters = getFilteredFilters(newFilters);
      setUsedCarQueryParams(filteredFilters);
    }
  };

  const handleDealerChange = (
    event: SelectChangeEvent<typeof filters.dealerIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      const newFilters = {
        ...filters,
        dealerIds: value,
        sellerType: 'dealer',
      };
      setFilters(newFilters);
      const filteredFilters = getFilteredFilters(newFilters);
      setUsedCarQueryParams(filteredFilters);
    }
  };

  const handleUsedYearRangeChange = (
    event: Event,
    newValue: number | number[],
  ) => {
    const newFilters = {
      ...filters,
      minYear: newValue[0],
      maxYear: newValue[1],
    };
    setFilters(newFilters);
    const filteredFilters = getFilteredFilters(newFilters);
    setUsedCarQueryParams(filteredFilters);
  };

  const renderBrandValue = (selected: Array<string | number>) => {
    if (!selected || selected.length === 0) {
      return trans.brands;
    }

    return selected
      .map(id => {
        const brand = brands?.find(brand => brand.id === id);
        return brand?.name;
      })
      .join(', ');
  };

  const renderModelValue = (selected: Array<string | number>) => {
    if (isFetchingModels) return <p>Loading...</p>;
    if (selected.length === 0) {
      return trans.models;
    }

    const filteredModels = models?.filter(model => selected.includes(model.id));
    return filteredModels?.map(model => model.name).join(', ');
  };

  const renderYearRangeValue = (selected: Array<string | number>) => {
    if (filters?.minYear === -1 && filters?.maxYear === -1) {
      return trans.yearsMinMax;
    }
    return trans.yearsMinMax
      .replace(
        'min',
        filters?.minYear !== -1 ? filters?.minYear.toString() : '1940',
      )
      .replace(
        'max',
        filters?.maxYear !== -1
          ? filters?.maxYear.toString()
          : (new Date().getFullYear() + 1).toString(),
      );

    // const filteredModels = models?.filter(model => selected.includes(model.id));
    // return filteredModels?.map(model => model.name).join(', ');
  };

  const renderDealerValue = (selected: Array<string | number>) => {
    if (selected.length === 0) {
      return trans.dealers1;
    }

    const filteredDealers = dealers?.filter(dealer =>
      selected.includes(dealer.id),
    );
    return filteredDealers?.map(dealer => dealer.name).join(', ');
  };

  const sliderTheme = createTheme({
    palette: {
      primary: {
        light: '#00d5bb',
        main: '#00d5bb',
        dark: '#00d5bb',
        contrastText: '#00d5bb',
      },
    },
  });

  return (
    <>
      <ListingsFiltersBarContainer>
        <UsedCarsFilterButton
          autoCapitalize="none"
          onClick={() => setShowAdvancedFilter(true)}
        >
          <span>
            <img src="/images/icons/equalizer-line.png" alt="" />
          </span>
          <span>Filters ({filtersNumber})</span>
        </UsedCarsFilterButton>
        <Select
          id="brand_id"
          multiple
          value={filters?.brandIds || []}
          onChange={handleBrandChange}
          className="custom-select"
          renderValue={renderBrandValue}
          MenuProps={MenuProps}
          displayEmpty
          style={selectStyle}
          disabled={!brands}
        >
          {brands?.map(brand => (
            <MenuItem key={brand.id} value={brand.id}>
              <ListItemText primary={brand.name} />
            </MenuItem>
          ))}
        </Select>
        {windowWidth > 700 && (
          <Select
            id="model_id"
            multiple
            value={filters.modelIds || []}
            onChange={handleModelChange}
            className="custom-select mui-fixed"
            renderValue={renderModelValue}
            MenuProps={MenuProps}
            displayEmpty
            disabled={!models}
            style={selectStyle}
          >
            {models?.map(model => (
              <MenuItem key={model.id} value={model.id}>
                <ListItemText primary={model.name} />
              </MenuItem>
            ))}
          </Select>
        )}

        {windowWidth > 991 && (
          <Select
            id="year_range_id"
            multiple
            value={filters.modelIds || []}
            className="custom-select mui-fixed"
            renderValue={renderYearRangeValue}
            MenuProps={MenuProps}
            displayEmpty
            style={selectStyle}
          >
            <MenuItem>
              <ThemeProvider theme={sliderTheme}>
                <Slider
                  color={'primary'}
                  min={1940}
                  max={new Date().getFullYear() + 1}
                  value={[
                    filters?.minYear !== -1 ? filters?.minYear : 1940,
                    filters?.maxYear !== -1
                      ? filters?.maxYear
                      : new Date().getFullYear() + 1,
                  ]}
                  onChange={handleUsedYearRangeChange}
                />
              </ThemeProvider>
            </MenuItem>
          </Select>
        )}

        {windowWidth > 1199 && (
          <Select
            id="dealer_id"
            multiple
            value={filters?.dealerIds || []}
            onChange={handleDealerChange}
            className="custom-select"
            renderValue={renderDealerValue}
            MenuProps={MenuProps}
            displayEmpty
            style={selectStyle}
          >
            {dealers?.map(dealer => (
              <MenuItem key={dealer.id} value={dealer.id}>
                <ListItemText primary={dealer.name} />
              </MenuItem>
            ))}
          </Select>
        )}
      </ListingsFiltersBarContainer>
    </>
  );
}

export default ListingFilterBar;
