import { useQuery } from 'react-query';
import { getTransmissionNames } from '../apis/transmission.apis';
import { TransmissionName } from '../classes/Transmission';

export const useTransmissionNames = () => {
  return useQuery({
    queryKey: ['transmission-names'],
    queryFn: (): Promise<TransmissionName[]> => getTransmissionNames(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
