import authenticatedApi from '../../../instance/ApiInstance';
import { IUsedBike } from '../../../types/bikes';
import { IUsedCar } from '../../../types/cars/used_car';
import { IMetadata } from '../../../types/common/metadata';
import { TFilters } from '../../Listings/common/ListingFiltersBar';

const usedBikesEndpoint = 'v2/bikes/used-bikes';

export interface ICreateUsedBike {
  year: number;
  description: string;
  whatsapp_number: string;
  mobile_number: string;
  call_to_action: string;
  mileage: number;
  price: number;
  doors: number;
  model_id: number;
  city_id: number;
  type_id: number;
  transmission_id: number;
  engine_type_id: number;
  engine_size_id: number;
  battery_capacity_id: number;
  cylinder_id: number;
  battery_range_id: number;
  exterior_color_id: number;
  interior_color_id: number;
  condition_id: number;
  seat_material_id: number;
  variant_id: number;
  optional_features_ids: number[];
  add_images: Array<{ id: number }>;
}

export interface IUpdateUsedBike {
  year?: number;
  description?: string;
  whatsapp_number?: string;
  mobile_number?: string;
  call_to_action?: string;
  mileage?: number;
  price?: number;
  doors?: number;
  model_id?: number;
  city_id?: number;
  type_id?: number;
  transmission_id?: number;
  engine_type_id?: number;
  engine_size_id?: number;
  battery_capacity_id?: number;
  cylinder_id?: number;
  battery_range_id?: number;
  exterior_color_id?: number;
  interior_color_id?: number;
  condition_id?: number;
  seat_material_id?: number;
  variant_id?: number;
  optional_features_ids?: number[];
  add_images?: Array<{ id: number }>;
  remove_images?: Array<{ url: string }>;
}

export interface IGetUsedBikes extends Partial<TFilters> {
  page: number;
  perPage: number;
  is_featured?: boolean;
  sortBy?: string;
  sortDirection?: string;
  status?: string;
  city?: number[];
}

export interface ICreateUsedBikeInquiry {
  name: string;
  email: string;
  mobile_number: string;
  used_bike_id: number;
}

export type IUsedBikeResponse = {
  data: IUsedCar[];
  meta: IMetadata;
};

export const getUsedBikes = async (
  params: IGetUsedBikes,
  searchQuery?: string,
): Promise<IUsedBikeResponse> => {
  const response = await authenticatedApi.get(usedBikesEndpoint, {
    params: {
      ...params,
      search: searchQuery,
    },
  });
  return response.data;
};

export const getFavoriteUsedBikes = async (): Promise<number[]> => {
  try {
    const response = await authenticatedApi.get('v2/bike-favorites');
    const data = response.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const addBikeFavorite = async (bikeId: number) => {
  try {
    const response = await authenticatedApi.post(`/v2/bike-favorites`, {
      used_bike_id: bikeId,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteBikeFavorite = async (bikeId: number) => {
  try {
    const response = await authenticatedApi.delete(
      `/v2/bike-favorites/${bikeId}`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUsedBikeById = async ({
  id,
}): Promise<IUsedBike | undefined> => {
  try {
    const response = await authenticatedApi.get(`v2/bikes/used-bikes/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createUsedBikeInquiry = async (data: ICreateUsedBikeInquiry) => {
  const response = await authenticatedApi.post(
    'v2/inquiries/used-bike-inquiries',
    data,
  );
  return response.data;
};

export const uploadUsedBikeImage = async (file: File) => {
  try {
    const response = await authenticatedApi.post(
      `v2/image/used-bike-image`,
      {
        image: file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const createUsedBike = async (data: ICreateUsedBike) => {
  try {
    const response = await authenticatedApi.post(usedBikesEndpoint, data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateUsedBike = async (id: number, data: IUpdateUsedBike) => {
  try {
    const response = await authenticatedApi.put(
      `${usedBikesEndpoint}/${id}`,
      data,
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
