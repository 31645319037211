import { Button } from '@mui/material';
import { IRamadanOffers } from '../../../../types/resources/cars/ramadan_offers';
import './ramadanFeaturedDetailsStyle.css';
import { RamadanFeaturedDetailsSkeleton } from '../RamadanSkeletons';
import checkMark from '../../../../common/images/check-mark.png';

interface RamadanFeaturedDetailsProps {
  offer: IRamadanOffers;
  getDealClick: (deal: any) => void;
  trans: Record<string, string>;
  isLoading: boolean;
}

export const RamadanFeaturedDetails = ({
  offer,
  getDealClick,
  trans,
  isLoading,
}: RamadanFeaturedDetailsProps) => {
  if (isLoading && !offer) {
    return <RamadanFeaturedDetailsSkeleton />;
  }

  return (
    <div className="ramadan-details d-flex flex-column w-100 pl-3">
      <h1>{offer.title}</h1>
      <div className="detail">
        <p>{offer.description}</p>
      </div>
      <div className="ramadan-featured-details">
        <div className="holder">
          <div className="d-flex flex-end ramadan-offer-btn">
            <Button
              onClick={() => getDealClick(offer)}
              variant="contained"
              className="btn btn-secondary ramadan-inquiry"
            >
              {trans.get_offer}
            </Button>
          </div>
        </div>
        {offer.ramadan_offer_benefits &&
          offer.ramadan_offer_benefits.length > 0 && (
            <div className="benefits">
              <h2>{trans.benefits}</h2>
              <ul className="information-nav d-flex flex-column justify-content-start flex-wrap">
                {offer.ramadan_offer_benefits.map((offer, index) => (
                  <li key={index}>
                    <img src={checkMark} />
                    <span>{offer.benefit}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
      </div>
    </div>
  );
};
