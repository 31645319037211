import { useContext, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { AppContext } from '../../contexts/AppContext';
import { updateLanguage } from '../../services/ApiService';
import SignIn from '../User/SignIn/SignInComponent';
import DownloadAppQRTooltip from './DownloadAppQRTooltip';
import SearchBar from './HeaderSearchComponent';
import { DownloadAppBanner } from './DownloadAppBanner';
import ReviewsRedirectionButton from './ReviewsRedirectionButton';
import { useSpring, animated } from 'react-spring';
import { SellYourVehicleModal } from '../User/SellYourVehicle/SellYourVehicleModal';
import '../../styles/css/header-styles.css';
import { CountrySelectorModal } from '../common/CountrySelectorModal/CountrySelectorModal';

interface HeaderProps {
  showBanner: boolean;
  hideBanner: (showBanner: boolean) => void;
}

export default function Header({ showBanner, hideBanner }: HeaderProps) {
  const { lang, trans, userData, country } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [showCollapsedMenu, setShowCollapsedMenu] = useState<boolean>(false);
  const [languageSwitchUrl, setLanguageSwitchUrl] = useState('');
  const [sellCar, setSellCar] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('Qatar');
  const [showCountryModal, setShowCountryModal] = useState(false);

  const languageToSwitch = lang === 'ar' ? 'en' : 'ar';
  const pathSegments = location.pathname.split('/');

  const currentCountryCode = pathSegments[1];
  const currentLang = pathSegments[2];
  const currentPath = pathSegments.slice(3).join('/');

  const [showSignIn, setShowSignIn] = useState(false);
  const [sellVehicle, setSellVehilce] = useState(false);
  const [showIcon, setShowIcon] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (currentLang !== languageToSwitch) {
      setLanguageSwitchUrl(
        `/${currentCountryCode}/${languageToSwitch}/${currentPath}`,
      );
    }
  }, [languageToSwitch, currentCountryCode, currentLang, currentPath]);

  const setActive = (currentElement: any) => {
    if (width <= 1200) setShowCollapsedMenu(!showCollapsedMenu);
    currentElement.target.parentNode.classList.add('active');
  };

  useEffect(() => {
    setShowCollapsedMenu(false);
  }, [location.pathname]);

  const toggleShowCountryModal = (show: any) => {
    setShowCountryModal(show);
  };

  useEffect(() => {
    const storedCountry = localStorage.getItem('country');
    if (storedCountry) {
      setSelectedCountry(storedCountry === 'qa' ? 'Qatar' : 'Saudi Arabia');
    }
  }, []);

  const sellYourCarClick = () => {
    if (userData) {
      setSellVehilce(true);
      setSellCar(false);
    } else {
      setSellCar(true);
      setShowSignIn(true);
      setSellVehilce(false);
    }
  };

  const toggleSignInDialog = (show: any) => {
    setShowSignIn(show);
    setSellVehilce(false);
  };

  const isSearchIconVisible = useMemo(() => {
    return width < 1200;
  }, [width]);

  const iconSpring = useSpring({
    opacity: showIcon ? 1 : 0,
    transform: `translateX(${showIcon ? 0 : -200}px)`,
  });

  const inputSpring = useSpring({
    opacity: showIcon ? 0 : 1,
    transform: `translateX(${showIcon ? 200 : 0}px)`,
  });

  const changeLanguageClick = (e: any) => {
    e.preventDefault();
    if (userData?.api_token) {
      let form = {
        lang: languageToSwitch,
      };
      updateLanguage(form).then(response => {
        if (response.status === 'success') {
          if (userData) {
            userData.Language_Preference = languageToSwitch;
            localStorage.setItem('userData', JSON.stringify(userData));
          }

          window.location.href = languageSwitchUrl;
        }
      });
    } else {
      window.location.href = languageSwitchUrl;
    }
  };

  const searchIconClick = () => {
    setShowIcon(!showIcon);
  };

  const handleOpenModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleSelectCountry = (country: string) => {
    const countryCode = country === 'Qatar' ? 'qa' : 'sa';
    localStorage.setItem('country', countryCode);
    const currentPath = location.pathname.split('/').slice(3).join('/');
    navigate(`/${countryCode}/${lang}/${currentPath}`);
    // navigate(`/${countryCode}/${lang}`);
    window.location.reload();
  };

  useEffect(() => {
    const countryCodeParam = window.location.pathname.split('/')[1];
    if (country && countryCodeParam && country !== countryCodeParam) {
      localStorage.setItem('country', countryCodeParam);
      window.location.reload();
    } else if (!country && !countryCodeParam) {
      setShowCountryModal(true);
    } else if (countryCodeParam === 'null') {
      setShowCountryModal(true);
    }
  }, []);

  return (
    <div>
      <header id="header">
        <div className="container">
          <DownloadAppBanner showBanner={showBanner} hideBanner={hideBanner} />
          <div className="header-holder">
            <strong className="logo">
              <Link to={`/${country}/` + lang}>
                <animated.img
                  src={`/images/logo.webp`}
                  alt="One Auto Car"
                  style={isSearchIconVisible ? iconSpring : {}}
                  loading={'lazy'}
                />
              </Link>
            </strong>
            <div className="nav-holder">
              <div className="search-info-bar">
                <div className="left-align">
                  <ReviewsRedirectionButton />
                  <DownloadAppQRTooltip
                    textContent={trans.download_app}
                    tooltipTextContent1={trans.scan_qr}
                    tooltipTextContent2={trans.to_download}
                  />
                  <select className="vodiapicker">
                    <option
                      value="en"
                      className="test"
                      data-thumbnail={`/images/language1.png`}
                    >
                      English
                    </option>
                    <option value="au" data-thumbnail={`/images/language2.png`}>
                      العربية
                    </option>
                  </select>
                  <div className="lang-select">
                    <div className="d-flex">
                      <img
                        src={`/images/arrow-drop-down-line.svg`}
                        className="lang-header"
                        alt="Flag"
                        onClick={handleOpenModal}
                      />
                      {selectedCountry === 'Qatar' ? (
                        <img
                          className="country-header"
                          src={`/images/language1.png`}
                          alt="Flag"
                          onClick={handleOpenModal}
                        />
                      ) : (
                        <img
                          className="country-header"
                          src={`/images/saudi-flag.svg`}
                          alt="Flag"
                          onClick={handleOpenModal}
                        />
                      )}
                      {isModalVisible && (
                        <div className="modal-dropdown">
                          <p className="modal-dropdown-text">
                            {trans.SelectMarketCountry}
                          </p>
                          <div className="modal-dropdown-content">
                            <div
                              className={`modal-dropdown-item ${
                                selectedCountry === 'Qatar' ? 'selected' : ''
                              }`}
                              onClick={() => {
                                handleSelectCountry('Qatar');
                                setModalVisible(false);
                              }}
                            >
                              <span className="modal-dropdown-item-text">
                                {trans.qatar}
                              </span>
                              <img
                                className="modal-dropdown-item-flag"
                                src={`/images/language1.png`}
                                alt="Flag"
                              />
                            </div>
                            <div
                              className={`modal-dropdown-item ${
                                selectedCountry === 'Saudi Arabia'
                                  ? 'selected'
                                  : ''
                              }`}
                              onClick={() => {
                                handleSelectCountry('Saudi Arabia');
                                setModalVisible(false);
                              }}
                            >
                              <span className="modal-dropdown-item-text">
                                {trans.saudiArabia}
                              </span>
                              <img
                                className="modal-dropdown-item-flag"
                                src={`/images/saudi-flag.svg`}
                                alt="Flag"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <Link
                        to={languageSwitchUrl}
                        onClick={changeLanguageClick}
                        className="lang-button"
                      >
                        <span className="lang-header english pl-3">
                          {'العربية'}
                        </span>
                        <span className="lang-header arabic pl-3">
                          {'English'}
                        </span>
                      </Link>
                    </div>
                  </div>
                  {!userData ? (
                    <Link
                      to={`/${country}/${lang}/login`}
                      className="login-button"
                    >
                      {trans.sign_in}
                    </Link>
                  ) : (
                    <Link
                      to={`/${country}/${lang}/account-details`}
                      className="login-button"
                    >
                      {trans.hello} {userData.first_name}
                    </Link>
                  )}
                </div>
              </div>
              <div className="header-separator" />
              <div className="nav-info-bar">
                <Navbar color="light" light expand="xl" sticky="top">
                  <span className="navbar-brand nav-brand-placeholder"></span>
                  <NavbarToggler
                    onClick={() => setShowCollapsedMenu(!showCollapsedMenu)}
                  />
                  <Collapse isOpen={showCollapsedMenu} navbar>
                    <div className="nav-column active">
                      <Nav navbar>
                        <NavItem
                          className={`${
                            window.location.pathname === `/${country}/${lang}`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to={`/${country}/${lang}`}
                            onClick={setActive}
                          >
                            {trans.home}
                          </Link>
                        </NavItem>
                        <NavItem
                          className={`${
                            window.location.pathname ===
                            `/${country}/${lang}/new-cars`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link smooth"
                            to={`/${country}/${lang}/new-cars`}
                            onClick={setActive}
                          >
                            {trans.new_cars}
                          </Link>
                        </NavItem>
                        <NavItem
                          className={`${
                            window.location.pathname ===
                            `/${country}/${lang}/used-cars`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link smooth"
                            to={`/${country}/${lang}/used-cars`}
                            onClick={setActive}
                          >
                            {trans.used_cars}
                          </Link>
                        </NavItem>
                        <NavItem
                          className={`${
                            window.location.pathname ===
                              `/${country}/${lang}/new-bikes` ||
                            window.location.pathname ===
                              `/${country}/${lang}/used-bikes`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link className="nav-link" to="#">
                            {trans.bikes}
                          </Link>
                          <div className="menu-dopdown">
                            <ul className="dopdown-nav">
                              <NavItem
                                className={`${
                                  window.location.pathname ===
                                  `/${country}/${lang}/new-bikes`
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                <Link
                                  className="nav-link"
                                  to={`/${country}/${lang}/new-bikes`}
                                  onClick={setActive}
                                >
                                  {trans.new_bikes}
                                </Link>
                              </NavItem>
                              <NavItem
                                className={`${
                                  window.location.pathname ===
                                  `/${country}/${lang}/used-bikes`
                                    ? 'disabled'
                                    : 'disabled'
                                }`}
                              >
                                <Link
                                  className="nav-link disabled"
                                  to={`/${country}/${lang}/used-bikes`}
                                  onClick={setActive}
                                  style={{
                                    color: 'gray',
                                    backgroundColor: 'transparent',
                                    opacity: 0.4,
                                  }}
                                >
                                  {trans.used_bikes}
                                </Link>
                              </NavItem>
                            </ul>
                          </div>
                        </NavItem>
                        <NavItem
                          className={`${
                            window.location.pathname ===
                              `/${country}/${lang}/sell-your-car` ||
                            window.location.pathname ===
                              `/${country}/${lang}/sell-your-bike`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <button
                            className="nav-link button-as-link float-left"
                            onClick={sellYourCarClick}
                          >
                            {trans.sell_now}
                          </button>
                        </NavItem>
                        <NavItem
                          className={`${
                            window.location.pathname ===
                            `/${country}/${lang}/rent-cars`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link smooth"
                            to={`/${country}/${lang}/rent-cars`}
                            onClick={setActive}
                          >
                            {trans.rent}
                          </Link>
                        </NavItem>
                        <NavItem
                          className={`${
                            window.location.pathname ===
                            `/${country}/${lang}/dealers`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to={`/${country}/${lang}/dealers`}
                            onClick={setActive}
                          >
                            {trans.dealers}
                          </Link>
                        </NavItem>
                        {/* <NavItem
                          className={`${
                            window.location.pathname === `/${lang}/financing`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to={`/${lang}/financing`}
                            onClick={setActive}
                          >
                            {trans.financing}
                          </Link>
                        </NavItem> */}
                        {/* <NavItem
                          className={`${
                            window.location.pathname === `/${lang}/insurance`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to={`/${lang}/insurance`}
                            onClick={setActive}
                          >
                            {trans.insurance}
                          </Link>
                        </NavItem> */}
                        <NavItem
                          className={`${
                            window.location.pathname ===
                            `/${country}/${lang}/articles`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to={`/${country}/${lang}/articles`}
                            onClick={setActive}
                          >
                            {trans.articles}
                          </Link>
                        </NavItem>
                        {width <= 480 && (
                          <NavItem
                            className={`${
                              window.location.pathname ===
                              `/${country}/${lang}/reviews`
                                ? 'active'
                                : ''
                            }`}
                          >
                            <Link
                              className="nav-link"
                              to={`/${country}/${lang}/reviews`}
                              onClick={setActive}
                            >
                              {trans.reviews}
                            </Link>
                          </NavItem>
                        )}
                      </Nav>
                    </div>
                  </Collapse>
                </Navbar>
                <SearchBar
                  inputSpring={inputSpring}
                  isSearchIconVisible={isSearchIconVisible}
                  showIcon={showIcon}
                />
              </div>
              <span
                className="form-opener"
                onClick={searchIconClick}
                style={{
                  cursor: 'pointer',
                }}
              >
                <i className="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>
      </header>
      {showSignIn && (
        <SignIn
          sellCar={sellCar}
          toggleSignInDialog={toggleSignInDialog}
          location={location.pathname}
        />
      )}
      {sellVehicle && (
        <SellYourVehicleModal
          location={location.pathname}
          toggleSignInDialog={toggleSignInDialog}
        />
      )}
      {showCountryModal && (
        <CountrySelectorModal toggleShowCountryModal={toggleShowCountryModal} />
      )}
    </div>
  );
}
