import { useState } from 'react';
import { ArticlesCardImageSkeleton } from './ArticclesSkeleton/ArticlesSkeleton';
import { styled } from '@material-ui/core';

interface ArticlesCardImageProps {
  defaultSrc?: string;
  src: string | undefined;
  alt?: string;
  class?: string;
  onClick?: any;
  style?: React.CSSProperties;
  onLoad?: any;
  width?: number;
  height?: number;
}

const ArticlesCardImageStyled = styled('img')(() => ({
  margin: 'auto',
  width: '100%',
  height: '100%',
  '-o-object-fit': 'cover',
  objectFit: 'cover',
}));

export const ArticlesCardImage = ({
  src,
  alt,
  class: className,
  onClick,
  style,
  width,
  height,
}: ArticlesCardImageProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  return (
    <>
      {isImageLoading && <ArticlesCardImageSkeleton />}
      <ArticlesCardImageStyled
        src={src}
        className={className}
        onClick={onClick}
        alt={alt}
        style={{
          ...style,
          display: isImageLoading ? 'none' : 'block',
        }}
        onLoad={() => {
          setIsImageLoading(false);
        }}
        width={width}
        height={height}
      />
    </>
  );
};
