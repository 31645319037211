import { useContext, useEffect, useState } from 'react';
import { formatDistance } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import {
  AutoImgBox,
  CarListInfo,
  CarListInfoDd,
  CarListInfoDt,
  DealsAdvantages,
  DealsAdvantagesDealTitle,
  DealsAdvantagesH3,
  DealsAdvantagesSmallLogos,
  DealsAdvantagesSmallLogosImg,
  DealsInfoRow,
  DealsLeft,
  DealsNav,
  DealsNavLi,
  DealsRight,
  DealsRightBox,
  DealsRightBtn,
  DealsRightFrame,
  DealsRightFrameQarSpan,
  DealsRightFrameStrong,
  ImgBox,
} from '../../../common/styles/CommonStyles';
import { CarListImageCard } from '../../Listings/common/CarGridCard/CarListImageCard';
import '../AccountDetails/styles.css';

const Card = ({ car, carDeleted }: any) => {
  const [showDelete, setShowDelete] = useState(false);
  const { lang, darkImageLoadingPlaceholder, trans, country } =
    useContext(AppContext);
  const navigate = useNavigate();

  const editListing = () => {
    if (car.type === 'car') {
      navigate(`/${country}/${lang}/sell-your-car/car-details-form`, {
        state: car,
      });
    } else {
      navigate(`/${country}/${lang}/sell-your-bike`, { state: car });
    }
  };

  const carDeletedCallback = (isSuccess: boolean) => {
    setShowDelete(false);
    carDeleted(isSuccess);
  };

  useEffect(() => {
    var bodyTag = document.getElementsByTagName('body')[0];
    if (showDelete) {
      bodyTag.classList.add('modal-open');
    } else {
      bodyTag.classList.remove('modal-open');
    }
  }, [showDelete]);

  const toggleDelete = () => setShowDelete(!showDelete);
  return (
    <>
      <DealsInfoRow>
        <DealsLeft className="deals-left">
          <ImgBox>
            <AutoImgBox>
              <CarListImageCard
                src={car?.images?.[0]}
                defaultSrc={darkImageLoadingPlaceholder}
                alt={`${car?.model?.slug}-image`}
              />
            </AutoImgBox>
          </ImgBox>
          <DealsAdvantages className="deals-advantages">
            <DealsAdvantagesSmallLogos className="small-logos">
              <DealsAdvantagesSmallLogosImg
                src={
                  car?.model?.brand?.original_logo ||
                  car?.bike_model?.brand?.original_logo
                }
                alt={car?.model?.brand?.name || car?.bike_model?.brand?.name}
              />
            </DealsAdvantagesSmallLogos>
            <DealsAdvantagesDealTitle>
              {(car?.model?.brand?.name || car?.bike_model?.brand?.name) ?? ''}
            </DealsAdvantagesDealTitle>
            <DealsAdvantagesH3>
              {(car?.model?.name || car?.bike_model?.brand?.name) ?? ''}
            </DealsAdvantagesH3>
            <DealsNav className="deals-nav">
              <DealsNavLi>
                {formatDistance(new Date(car?.created_at), new Date(), {
                  addSuffix: true,
                })}
              </DealsNavLi>
            </DealsNav>
          </DealsAdvantages>
        </DealsLeft>
        <DealsRight className="deals-right">
          <div onClick={editListing}>
            <a href="" className="edit-deals">
              <i className="fa fa-edit"></i>
            </a>
          </div>

          <DealsRightBox className="box">
            <CarListInfo>
              <CarListInfoDt>{trans.year}:</CarListInfoDt>
              <CarListInfoDd>{car?.year}</CarListInfoDd>
              <CarListInfoDt>{trans.mileage}:</CarListInfoDt>
              <CarListInfoDd>{car?.mileage} KMs</CarListInfoDd>
              <CarListInfoDt>{trans.seller}:</CarListInfoDt>
              <CarListInfoDd>
                {(car?.dealer?.name || car?.bike_dealer?.name) ?? trans.private}
              </CarListInfoDd>
              <CarListInfoDt>{trans.contact}:</CarListInfoDt>
              <CarListInfoDd>{car?.mobile_number ?? trans.n_a}</CarListInfoDd>
            </CarListInfo>
          </DealsRightBox>
          <DealsRightFrame>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <DealsRightFrameStrong>
                <DealsRightFrameQarSpan>{trans.qar}</DealsRightFrameQarSpan>{' '}
                <span> {car.price}</span>
              </DealsRightFrameStrong>
              {car?.status === 'approved' && (
                <DealsRightBtn
                  className="btn btn-secondary"
                  to={
                    car.type === 'car'
                      ? `/${country}/${lang}/used-cars/${car?.model?.brand?.slug}/${car?.model?.slug}/${car?.year}/${car?.id}`
                      : `/${country}/${lang}/used-bikes/${car?.bike_model?.brand?.slug}/${car?.bike_model?.slug}/${car?.year}/${car?.id}`
                  }
                >
                  {trans.view_car}
                </DealsRightBtn>
              )}
              <DealsRightBtn
                to={''}
                className="btn btn-secondary remove"
                onClick={toggleDelete}
              >
                {trans.remove}
              </DealsRightBtn>
            </div>
          </DealsRightFrame>
        </DealsRight>
      </DealsInfoRow>
      {showDelete && (
        <DeleteConfirmationModal
          toggleDelete={toggleDelete}
          carId={car.id}
          callback={carDeletedCallback}
          car={car}
        />
      )}
    </>
  );
};

export default Card;
