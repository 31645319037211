import { Skeleton, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const DetailColumn = styled(Link)(() => ({
  display: ['-webkit-box', '-ms-flexbox', 'flex'],
  border: '1px solid #928c8b',
  height: '100%',
  '@media screen and (min-width: 1200px)': {
    height: '190px',
  },
  '@media screen and (max-width: 1199px)': {
    display: 'block',
  },
}));

export const InfoWrapper = styled('div')(() => ({
  '@media screen and (min-width: 1200px)': {
    position: 'absolute',
    bottom: '15px',
    left: '12px',
  },
  '@media screen and (max-width: 991px)': {
    position: 'absolute',
    bottom: '15px',
    left: '12px',
  },
}));

export const DetailColumnH2 = styled('h3')(() => ({
  margin: '0 0 15px',
  fontSize: '17px',
  lineHeight: 1.3,
  color: '#000',
  fontWeight: 700,
  textTransform: 'capitalize',
  overflow: 'hidden',
  '@media screen and (max-width: 1399px)': {
    margin: '0 0 5px',
  },
  '@media screen and (max-width: 991px)': {
    fontSize: '16px',
  },
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
}));

export const ImageHolder = styled('div')(() => ({
  width: '54.5%',
  '-ms-flex-negative': 0,
  flexShrink: 0,
  '@media screen and (min-width: 1200px)': {
    height: '188px',
  },
  '@media screen and (max-width: 1199px)': {
    width: '100%',
  },
}));

export const TextHolder = styled('div')(() => ({
  msFlexPositive: 1,
  flexGrow: 1,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 400,
  color: '#3d3d3d',
  padding: '15px 10px 35px 12px',
  position: 'relative',
  textTransform: 'capitalize',
  height: '190px',
  '@media screen and (max-width: 1199px)': {
    borderWidth: '0 1px 1px',
    padding: '20px 15px',
  },
}));

export const TextHolderP = styled('p')(() => ({
  margin: '0 0 5px',
  textTransform: 'none',
  overflow: 'hidden',
  '@media screen and (max-width: 1399px)': {
    margin: '0 0 10px',
  },
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  wordBreak: 'break-word',
}));

export const TitleDate = styled('span')(() => ({
  display: 'block',
  fontSize: '11px',
  lineHeight: '14px',
  color: '#6b6667',
  fontWeight: 700,
}));

export const ArticleDetailImageSkeleton = styled(Skeleton)(() => ({
  margin: 'auto',
  width: '100%',
  height: 643,
  objectFit: 'cover',
  '@media screen and (max-width: 1399px)': {
    width: '100%',
    height: '568px',
  },
  '@media screen and (max-width: 1199px)': {
    height: '478px',
  },
  '@media screen and (max-width: 991px)': {
    height: '343px',
  },
  '@media screen and (max-width: 767px)': {
    height: '359px',
  },
  '@media screen and (max-width: 575px)': {
    height: '242px',
  },
  '@media screen and (max-width: 430px)': {
    height: '180px',
  },
}));
