import authenticatedApi from '../../../instance/ApiInstance';

const newCarInquiriesEndpoint = 'v2/inquiries/new-car-inquiries';

export interface ICreateNewCarInquiry {
  name: string;
  email: string;
  mobile_number: string;
  new_car_id: number;
}

export const createNewCarInquiry = async (data: ICreateNewCarInquiry) => {
  const response = await authenticatedApi.post(newCarInquiriesEndpoint, data);
  return response.data;
};
