import authenticatedApi from '../../../instance/ApiInstance';
import { IRentalCar } from '../../../types/cars/rental_car';
import { IMetadata } from '../../../types/common/metadata';

export type TRentalFeatured = {
  searchQueryParams?: Record<string, string>;
  page?: number;
  perPage?: number;
};

export const getFeaturedRentals = async ({
  searchQueryParams,
  page,
  perPage,
}: TRentalFeatured): Promise<{ data: IRentalCar[]; meta: IMetadata }> => {
  searchQueryParams = {
    ...searchQueryParams,
  };
  
  try {
    const response = await authenticatedApi.get(`/v2/cars/rental-cars`, {
      params: {
        ...searchQueryParams,
        perPage,
        page,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
