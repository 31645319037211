import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import LazyImage from '../../Other/LazyImageComponent';
import BikeIcon from '../../../common/images/bike.svg';
import CarIcon from '../../../common/images/car.svg';
import { styled } from '@mui/material';
import '../AccountDetails/styles.css';
import '../SignIn/signin-styles.css';

interface ISellYourVehicleModalProps {
  toggleSignInDialog: (value: boolean) => void;
  location: string;
}

export const SellYourVehicleModal = ({
  location,
  toggleSignInDialog,
}: ISellYourVehicleModalProps) => {
  const { lang, trans, country } = useContext(AppContext);
  const [currentLoc, setCurrentLocation] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentLoc) {
      if (location !== currentLoc) {
        toggleSignInDialog(false);
      }
    } else {
      setCurrentLocation(location);
    }
  }, [location]);

  const Styled = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #e5e5e5',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px 0px #e5e5e5',
    maxWidth: '200px',
    minWidth: '200px',
    ':hover': {
      border: '1px solid #00D6BC',
      opacity: '0.8',
      cursor: 'pointer',
    },
    '&.disabled': {
      cursor: 'not-allowed',
      opacity: '0.5',
      ':hover': {
        border: '1px solid #e5e5e5',
        opacity: '0.5',
      },
    },
    '@media screen and (max-width: 480px)': {
      maxWidth: '150px',
      minWidth: '150px',
    },
    '@media screen and (max-width: 360px)': {
      maxWidth: '120px',
      minWidth: '120px',
    },
  }));

  const LoginSignup = styled('div')(() => ({
    marginBlock: '20px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  }));

  return (
    <div className="modal fade modal-center advance-search-modal loginpopup show">
      <div
        className="modal-dialog modal-dialog-signup modal-lg style"
        style={{
          maxWidth: '450px',
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => toggleSignInDialog(false)}
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <LoginSignup>
              <strong>{trans.choose_a_category}</strong>
              <div
                className="login-img d-flex justify-content-center"
                style={{ maxWidth: 'none', width: '100%', gap: '20px' }}
              >
                <Styled className="disabled">
                  <LazyImage
                    src={BikeIcon}
                    alt="login icon"
                    defaultSrc=""
                    style={{
                      height: '151px',
                    }}
                    // onClick={() => {
                    //   navigate(`/${lang}/sell-your-bike`);
                    // }}
                  />
                  <strong>{trans.sellYourBike}</strong>
                </Styled>

                <Styled>
                  <LazyImage
                    src={CarIcon}
                    alt="login icon"
                    defaultSrc=""
                    style={{
                      height: '151px',
                    }}
                    onClick={() => {
                      navigate(`/${country}/${lang}/sell-your-car`);
                    }}
                  />
                  <strong>{trans.sell_car}</strong>
                </Styled>
              </div>
            </LoginSignup>
          </div>
        </div>
      </div>
    </div>
  );
};
