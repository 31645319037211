import { IUsedCar } from '../cars/used_car';
import { IDealer } from '../resources';
import { IBrand, IModel } from '../resources/cars';
import { User } from '../user';

type TUsedCar = Pick<
  IUsedCar,
  'id' | 'images' | 'price' | 'year' | 'engine_type'
> & {
  model: Pick<IModel, 'id' | 'name' | 'slug'> & {
    brand: Pick<IBrand, 'id' | 'name' | 'slug'>;
  };
};

type TUsedBike = Pick<
  IUsedCar,
  'id' | 'images' | 'price' | 'year' | 'engine_type'
> & {
  bike_model: Pick<IModel, 'id' | 'name' | 'slug'> & {
    brand: Pick<IBrand, 'id' | 'name' | 'slug'>;
  };
};

type TUser = Pick<User, 'id' | 'first_name' | 'last_name'>;
type TDealer = Pick<IDealer, 'id' | 'name'>;

type TChatParticipant = {
  id: number;
  user: TUser;
  dealer: TDealer;
};

export const enum EChatConversationType {
  Car = 'car',
  Bike = 'bike',
}

export interface IChatConversation {
  id: number;
  last_message: string;
  last_message_datetime: Date;
  used_car_id: number;
  used_bike_id: number;
  created_at: Date;
  updated_at: Date;
  used_car: TUsedCar;
  used_bike: TUsedBike;
  chat_participants: TChatParticipant[];
  _count: {
    chat_messages: number;
  };
  chat_conversation_type: EChatConversationType;
}
