import { Container } from 'reactstrap';
import { BrandCardSkeleton } from '../../common/skeletons/BrandCardSkeleton';
import { Grid } from '@mui/material';
import {
  BrowseBrandsSection,
  MainHeadingH1,
} from '../../../common/styles/CommonStyles';

export const NewBikesBrandsSkeleton = ({ trans }: { trans: any }) => {
  return (
    <main id="main">
      <div className="container newcars" style={{ paddingTop: '32px' }}>
        <BrowseBrandsSection className="container">
          <MainHeadingH1>{trans.browse_bikes_by_brands}</MainHeadingH1>
          <Container
            style={{
              paddingBottom: '16px',
            }}
          >
            <Grid container spacing={2}>
              {Array(3)
                .fill(0)
                .map((_, index) => (
                  <Grid key={index} item xs={6} sm={6} md={3} lg={2} xl={2}>
                    <BrandCardSkeleton />
                  </Grid>
                ))}
            </Grid>
          </Container>
        </BrowseBrandsSection>
      </div>
    </main>
  );
};
