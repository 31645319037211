import { useQuery } from 'react-query';
import { getOptionalFeatures } from '../apis/optional_features.api';

export const useGetOptionalFeatures = () => {
  return useQuery({
    queryKey: ['optionalFeatures'],
    queryFn: () => getOptionalFeatures(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
