import { useQuery } from 'react-query';
import { IGetBikeTrims, getBikeTrims } from '../../apis/bikes';

export const useGetBikeTrims = (data: IGetBikeTrims) => {
  const { enabled, model } = data;
  return useQuery({
    queryKey: ['bike-trims', model],
    queryFn: () => getBikeTrims({ model }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
    placeholderData: [],
  });
};
