import { useState } from 'react';
import { NewBikeDealerImageShowroomSkeleton } from './DealerCardSkeleton';
import { styled } from '@material-ui/core';

export interface NewBikeDealerImageShowroomProps {
  defaultSrc?: string;
  src: string | undefined;
  alt?: string;
  class?: string;
  onClick?: any;
  style?: React.CSSProperties;
  onLoad?: any;
  width?: number;
  height?: number;
}

const Styled = styled('img')(() => ({
  display: 'inline-block',
  verticalAlign: 'middle',
  maxHeight: '70px',
  marginInline: '15px',
  maxWidth: '230px',
}));

export default function NewBikeDealerImageShowroom({
  src,
  alt,
  class: ClassName,
  height,
  onClick,
  style,
  width,
}: NewBikeDealerImageShowroomProps) {
  const [isImageLoading, setIsImageLoading] = useState(true);
  return (
    <>
      {isImageLoading && <NewBikeDealerImageShowroomSkeleton />}
      <Styled
        src={src}
        className={ClassName}
        onClick={onClick}
        alt={alt}
        style={{ ...style, display: isImageLoading ? 'none' : 'inline-block' }}
        onLoad={() => {
          setIsImageLoading(false);
        }}
        width={width}
        height={height}
      />
    </>
  );
}
