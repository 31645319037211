import { useQuery } from 'react-query';
import { getExteriorColors } from '../apis/exterior_color.apis';

export const useGetExteriorColors = () => {
  return useQuery({
    queryKey: ['exteriorColors'],
    queryFn: () => getExteriorColors(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
