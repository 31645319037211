import { Skeleton } from '@mui/material';
import Carousel from 'react-multi-carousel';
import PaginationComponent from '../../Listings/common/PaginationComponent';
import {
  AutoImgBox,
  BrowseBrandsSection,
  CarInfoColumn,
  CarInfoColumnImgHolder,
  CarInfoColumnLogoImg,
  CarInfoColumnPriceHolder,
  CarInfoColumnPriceHolderPrice,
  CarInfoColumnTextBox,
  CarInfoColumnTextHolder,
  CarInfoColumnTitleCar,
  CarInfoColumnTitleCategory,
  CarListInfo,
  CarListInfoDd,
  CarListInfoDt,
  CarsSliderBlock,
  DealsAdvantages,
  DealsAdvantagesDealTitle,
  DealsAdvantagesH3,
  DealsAdvantagesSmallLogos,
  DealsInfoRow,
  DealsLeft,
  DealsNav,
  DealsNavLi,
  DealsRight,
  DealsRightFrame,
  DealsRightFrameQarSpan,
  DealsRightFrameStrong,
  GridCardsRental,
  ImgBox,
} from '../../../common/styles/CommonStyles';
import {
  CarInfoColumnDl,
  CarInfoColumnDlDd,
  CarInfoColumnDlDt,
} from '../components/UsedCarStyles';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { CarGridDealerLogoSkeleton } from '../../Listings/common/CarGridCard/CarGridDealerLogo';
import '../../UsedCars/components/UsedCarBody-styles.css';

export const CarGridCardImageSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      height={184}
      className="CarListGridImage"
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const CarListCardImageSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);

  return (
    <Skeleton
      variant="rectangular"
      height={150}
      className="CarListGridImage"
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const CarGridCardSkeleton = () => {
  return (
    <div key={Math.random()} className="swiper-slide">
      <div className="slide-holder">
        <CarInfoColumn>
          <CarInfoColumnImgHolder>
            <CarGridCardImageSkeleton />
          </CarInfoColumnImgHolder>
          <CarInfoColumnTextHolder>
            <CarInfoColumnTextBox>
              <CarInfoColumnLogoImg>
                <CarGridDealerLogoSkeleton />
              </CarInfoColumnLogoImg>
              <CarInfoColumnTitleCar>
                <Skeleton variant="text" width={100} />
              </CarInfoColumnTitleCar>
              <CarInfoColumnTitleCategory>
                <Skeleton variant="text" width={100} />
              </CarInfoColumnTitleCategory>
              <CarInfoColumnDl>
                <CarInfoColumnDlDt>
                  <Skeleton variant="text" width={50} />
                </CarInfoColumnDlDt>
                <CarInfoColumnDlDd>
                  <Skeleton variant="text" width={100} />
                </CarInfoColumnDlDd>
                <CarInfoColumnDlDt>
                  <Skeleton variant="text" width={50} />
                </CarInfoColumnDlDt>
                <CarInfoColumnDlDd>
                  <Skeleton variant="text" width={100} />
                </CarInfoColumnDlDd>
                <CarInfoColumnDlDt>
                  <Skeleton variant="text" width={50} />
                </CarInfoColumnDlDt>
                <CarInfoColumnDlDd>
                  <Skeleton variant="text" width={100} />
                </CarInfoColumnDlDd>
              </CarInfoColumnDl>
            </CarInfoColumnTextBox>
            <CarInfoColumnPriceHolder>
              <CarInfoColumnPriceHolderPrice>
                <span className="qar">
                  <Skeleton variant="text" width={70} />
                </span>
              </CarInfoColumnPriceHolderPrice>
              <Skeleton variant="rounded" height={28} width={100} />
            </CarInfoColumnPriceHolder>
          </CarInfoColumnTextHolder>
        </CarInfoColumn>
      </div>
    </div>
  );
};

export const CarListCardSkeleton = () => {
  return (
    <span className="" style={{ color: '#000' }}>
      <DealsInfoRow className="usedcars test">
        <DealsLeft className="deals-left">
          <ImgBox>
            <AutoImgBox>
              <CarListCardImageSkeleton />
            </AutoImgBox>
          </ImgBox>
          <DealsAdvantages className="deals-advantages">
            <DealsAdvantagesSmallLogos className="small-logos">
              <CarGridDealerLogoSkeleton />
            </DealsAdvantagesSmallLogos>
            <DealsAdvantagesDealTitle>
              <Skeleton variant="text" width={100} />
            </DealsAdvantagesDealTitle>
            <DealsAdvantagesH3>
              <Skeleton variant="text" width={100} />
            </DealsAdvantagesH3>
            <DealsNav className="deals-nav">
              <DealsNavLi>
                <Skeleton variant="text" width={100} />
              </DealsNavLi>
            </DealsNav>
          </DealsAdvantages>
        </DealsLeft>
        <DealsRight className="deals-rights">
          <DealsRight className="box">
            <CarListInfo>
              <CarListInfoDt>
                <Skeleton variant="text" width={50} />
              </CarListInfoDt>
              <CarListInfoDd>
                <Skeleton variant="text" width={100} />
              </CarListInfoDd>
              <CarListInfoDt>
                <Skeleton variant="text" width={50} />
              </CarListInfoDt>
              <CarListInfoDd>
                <Skeleton variant="text" width={100} />
              </CarListInfoDd>
              <CarListInfoDt>
                <Skeleton variant="text" width={50} />
              </CarListInfoDt>
              <CarListInfoDd>
                <Skeleton variant="text" width={100} />
              </CarListInfoDd>
            </CarListInfo>
          </DealsRight>
          <DealsRightFrame>
            <DealsRightFrameStrong>
              <DealsRightFrameQarSpan>
                <Skeleton variant="text" width={100} />
              </DealsRightFrameQarSpan>
            </DealsRightFrameStrong>
            <Skeleton variant="text" width={100} />
          </DealsRightFrame>
        </DealsRight>
      </DealsInfoRow>
    </span>
  );
};

export const CarGridCarouselSkeleton = ({
  responsive,
  rtl,
}: {
  responsive?: any;
  rtl?: boolean;
}) => {
  return (
    <Carousel
      autoPlay
      autoPlaySpeed={4000}
      responsive={responsive}
      swipeable={true}
      draggable={true}
      infinite={true}
      rtl={rtl}
      customLeftArrow={<div className="swiper-button-prev"></div>}
      customRightArrow={<div className="swiper-button-next"></div>}
      key="used-cars-carousel-skeleton"
    >
      {Array(10)
        .fill(0)
        .map((_, i) => (
          <CarGridCardSkeleton key={i} />
        ))}
    </Carousel>
  );
};

export const CarComponentSkeleton = ({
  responsive,
  isGrid,
  noResults,
  toggleGridView,
  sortCars,
  currentPage,
  pagesCount,
  pageSize,
  setCurrentPage,
  trans1,
  trans2,
}: {
  responsive?: any;
  isGrid: boolean;
  noResults: boolean;
  toggleGridView: (isGrid: boolean) => void;
  sortCars: (arg: any) => void;
  currentPage: number;
  pagesCount: number;
  pageSize: number;
  setCurrentPage: (arg: any) => void;
  trans1: any;
  trans2: any;
}) => {
  return (
    <div style={{ position: 'relative', paddingTop: '100px' }}>
      <div className="container usedcars">
        <BrowseBrandsSection
          className="searchcar container"
          style={{ marginTop: 10 }}
        >
          <CarsSliderBlock className="cars-slider-block featured mt-4">
            <CarGridCarouselSkeleton responsive={responsive} />
          </CarsSliderBlock>
          <PaginationComponent
            trans1={trans1}
            trans2={trans2}
            noResultsFound={noResults}
            isGrid={isGrid}
            setIsGrid={toggleGridView}
            onSort={sortCars}
            currentPage={currentPage}
            totalPages={pagesCount}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
          />

          <div
            id="used_cars_for_sale_lisit"
            className={`search-details usedcars-rows ${
              isGrid && 'd-flex flex-wrap justify-content-start'
            }`}
            style={{ ...(isGrid && { gap: '15px' }) }}
          >
            {isGrid ? (
              <>
                {[...Array(16)].map((_, i) => (
                  <GridCardsRental key={i}>
                    <CarGridCardSkeleton key={i} />
                  </GridCardsRental>
                ))}
              </>
            ) : (
              <>
                {[...Array(16)].map((_, i) => (
                  <CarListCardSkeleton key={i} />
                ))}
              </>
            )}
          </div>

          <span className="pagination-middle">
            <div
              style={{
                height: isGrid ? '65px' : '32px',
                margin: '0 auto',
                width: '200px',
              }}
            />
          </span>
        </BrowseBrandsSection>
      </div>
    </div>
  );
};
