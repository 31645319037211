import { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../contexts/AppContext';
import {
  forgotPasswordOTP,
  resetPassword,
  verifyOTPwithMobile,
} from '../../services/ApiService';
import PageLoader from '../Other/PageLoader/PageLoaderComponent';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { successNotification } from '../Other/ToastMessage';
import '../User/SignUp/signup-styles.css';
import '../User/LogIn/login-styles.css';

enum ScreenEnum {
  Mobile = 'mobileNumberInput',
  OTP = 'OTPInput',
  Password = 'passwordInput',
}

export default function ForgotPassword(this: any) {
  const { trans, lang, country } = useContext(AppContext);
  const navigate = useNavigate();
  const [screen, setScreen] = useState<ScreenEnum>(ScreenEnum.Mobile);
  const [tempAPIToken, setTempAPIToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState('');
  const [otpCode, setOTPCode] = useState('');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const [newPassword, setNewPassword] = useState({
    password: '',
    confirmedPassword: '',
    isConfirmed: false,
  });

  // Submit Mobile Number
  const handleSubmitMobile = async () => {
    try {
      setIsLoading(true);

      if (mobile.length < 8) {
        setIsLoading(false);
        return successNotification(
          'Please provide a valid mobile number',
          'warning',
          'Invalid Mobile Number',
        );
      }

      const response = await forgotPasswordOTP(mobile);

      successNotification(
        'Please provide the 6 digit number sent to your mobile device',
        'success',
        'OTP Sent',
      );

      setSeconds(59);
      setMinutes(1);
      setScreen(ScreenEnum.OTP);
      setIsLoading(false);
    } catch (e) {
      successNotification('Failed to send OTP', 'danger', 'Error');
      setIsLoading(false);
    }
  };

  // Submit OTP
  const handleSubmitOTP = async () => {
    try {
      setIsLoading(true);

      if (otpCode.length !== 6) {
        setIsLoading(false);
        return successNotification(
          'Please provide a valid OTP code',
          'warning',
          'Invalid OTP',
        );
      }

      const response = await verifyOTPwithMobile(mobile, otpCode);

      if (response.error) return resetInput(setOTPCode);

      successNotification(null, 'success', 'OTP Verified');

      setTempAPIToken(response?.access_token);

      setScreen(ScreenEnum.Password);
      setIsLoading(false);
    } catch (e: any) {
      successNotification('', 'danger', 'Failed to verify OTP');
      setIsLoading(false);
    }
  };

  // Submit Password
  const handleSubmitNewPassword = async () => {
    try {
      setIsLoading(true);
      if (!newPassword.password || !newPassword.confirmedPassword) {
        setIsLoading(false);
        return setNewPassword({ ...newPassword, isConfirmed: false });
      }

      const response = await resetPassword(newPassword.password, tempAPIToken);

      if (response.error)
        return setNewPassword({
          password: '',
          confirmedPassword: '',
          isConfirmed: false,
        });

      successNotification('', 'success', 'password reset successfully');

      navigate(`/${country}/${lang}/login`);
      setIsLoading(false);
    } catch (e: any) {
      successNotification('', 'danger', e?.response?.data?.message);
      setIsLoading(false);
    }
  };

  // OTP Timer
  useEffect(() => {
    if (screen == ScreenEnum.OTP) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, screen]);

  const resetInput = (cb: any) => {
    cb('');
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <main id="main">
        <div className="container">
          <div>
            <div className="signup-holder">
              <div
                className="signup-box"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <div className="login-logo">
                  <img src="/images/logo.jpg" />
                </div>
                {screen == ScreenEnum.Mobile && (
                  <div
                    className="login-form forgot-password-form"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div className="form-group">
                      <p>{trans.send_code_confirmation_msg}</p>
                    </div>
                    <div className="form-group" style={{ width: '40vh' }}>
                      <PhoneInput
                        country="qa"
                        isValid={(value, country) => {
                          if (value.length < 8) return false;
                          return true;
                        }}
                        inputClass="custom-tel"
                        value={mobile}
                        onChange={(e: any) => setMobile(e)}
                        placeholder=""
                        masks={{ qa: '........' }}
                      />
                    </div>
                    <div className="form-group"></div>
                    <div className="form-group button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmitMobile}
                      >
                        {trans.next}
                      </button>
                    </div>
                  </div>
                )}
                {screen == ScreenEnum.OTP && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '20px',
                      }}
                    >
                      <p>{trans.enter_code}</p>
                      <input
                        style={{ textAlign: 'center' }}
                        type="number"
                        className="form-control"
                        placeholder="Please enter code"
                        maxLength={6}
                        value={otpCode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setOTPCode(e.target.value)
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '20px',
                      }}
                    >
                      {seconds > 0 || minutes > 0 ? (
                        <button
                          disabled={true}
                          type="button"
                          className="btn forgot-password-secondary-button"
                        >
                          Time Remaining:
                          {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={handleSubmitMobile}
                          style={{
                            backgroundColor: '#ffff',
                            border: '1.5px solid #00d6bc',
                            minWidth: '160px',
                            textAlign: 'center',
                            color: '#00d6bc',
                            outline: 'none',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            borderRadius: '8px',
                          }}
                          className="btn"
                        >
                          Resend Code
                        </button>
                      )}

                      <div>
                        <button
                          disabled={otpCode.length !== 6}
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSubmitOTP}
                        >
                          {trans.next}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {screen == ScreenEnum.Password && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <div>
                      <p>{trans.type_password}</p>
                    </div>
                    <div style={{ width: '50%' }}>
                      <input
                        placeholder="New Password"
                        type="password"
                        className="form-control"
                        maxLength={255}
                        value={newPassword.password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setNewPassword({
                            ...newPassword,
                            password: e.target.value,
                          })
                        }
                      />
                    </div>

                    <input
                      style={{ width: '50%' }}
                      onBlur={() => {
                        if (
                          newPassword.confirmedPassword !== newPassword.password
                        ) {
                          setNewPassword({
                            ...newPassword,
                            isConfirmed: false,
                          });
                        } else {
                          setNewPassword({
                            ...newPassword,
                            isConfirmed: true,
                          });
                        }
                      }}
                      placeholder="Confirm New Password"
                      type="password"
                      className="form-control"
                      maxLength={255}
                      value={newPassword.confirmedPassword}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setNewPassword({
                          ...newPassword,
                          confirmedPassword: e.target.value,
                        })
                      }
                    />
                    {!newPassword.isConfirmed &&
                      newPassword.confirmedPassword && (
                        <p>Passwords do not match</p>
                      )}
                    <div className="button">
                      <button
                        disabled={!newPassword.isConfirmed}
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmitNewPassword}
                      >
                        {trans.next}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
