import { useMutation, useQuery } from 'react-query';
import {
  ICreateUsedBike,
  ICreateUsedBikeInquiry,
  IGetUsedBikes,
  IUpdateUsedBike,
  addBikeFavorite,
  createUsedBike,
  createUsedBikeInquiry,
  deleteBikeFavorite,
  getFavoriteUsedBikes,
  getUsedBikeById,
  getUsedBikes,
  updateUsedBike,
  uploadUsedBikeImage,
} from '../apis';
import { queryClient } from '../../../App';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';

interface IUseGetUsedBikes {
  query: IGetUsedBikes;
  enabled: boolean;
  searchQuery?: string;
}

interface IUseGetFavoriteUsedBikes {
  enabled: boolean;
  userData: any;
}

export const useGetUsedBikes = ({
  query,
  enabled,
  searchQuery,
}: IUseGetUsedBikes) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['usedBikes', query, searchQuery, lang],
    queryFn: () => getUsedBikes(query, searchQuery),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: enabled,
  });
};

export const useGetFavoriteUsedBikes = ({
  enabled = true,
  userData,
}: IUseGetFavoriteUsedBikes) => {
  return useQuery({
    queryKey: ['favoriteUsedBikes', userData],
    queryFn: () => getFavoriteUsedBikes(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useAddFavoriteUsedBike = () => {
  return useMutation({
    mutationFn: (bikeId: number) => addBikeFavorite(bikeId),
    mutationKey: ['addFavoriteUsedBike'],
    onSuccess: () => {
      queryClient.invalidateQueries('favoriteUsedBikes');
    },
  });
};

export const useDeleteFavoriteUsedBike = () => {
  return useMutation({
    mutationFn: (bikeId: number) => deleteBikeFavorite(bikeId),
    mutationKey: ['deleteFavoriteUsedBike'],
    onSuccess: () => {
      queryClient.invalidateQueries('favoriteUsedBikes');
    },
  });
};

export const useGetUsedBikeById = (id?: string) => {
  return useQuery({
    queryKey: ['usedBike', id],
    queryFn: () => getUsedBikeById({ id }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!id,
  });
};

export const useCreateUsedBikeInquiry = () => {
  return useMutation({
    mutationFn: (data: ICreateUsedBikeInquiry) => createUsedBikeInquiry(data),
    mutationKey: ['createUsedBikeInquiry'],
  });
};
export const useUploadUsedBikeImage = () => {
  return useMutation({
    mutationFn: (file: File) => uploadUsedBikeImage(file),
  });
};

export const useCreateUsedBike = () => {
  return useMutation({
    mutationFn: (data: ICreateUsedBike) => createUsedBike(data),
  });
};

export const useUpdateUsedBike = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IUpdateUsedBike }) =>
      updateUsedBike(id, data),
  });
};
