import { useContext, useMemo } from 'react';
import Carousel from 'react-multi-carousel';
import { AppContext } from '../../contexts/AppContext';
import BrandCard from '../Listings/brands/components/BrandCard';
import { IBrand } from '../../types/resources/cars';
import { BrandCardSkeleton } from '../common/skeletons/BrandCardSkeleton';
import {
  BtnViewHolderView,
  CarsSliderBlock,
  HeaderHolder,
  HeaderHolderP,
  InfoBlock,
  InfoBlockBtnViewHolder,
  InfoBlockH2,
} from '../../common/styles/CommonStyles';
import '../../styles/css/swiper-bundle.css';

interface IHomeNewCarsProps {
  brands: IBrand[];
  isLoading: boolean;
}

export default function HomeNewCars({ brands, isLoading }: IHomeNewCarsProps) {
  const { lang, trans, country } = useContext(AppContext);

  const responsiveModels = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const BrandRows = useMemo(() => {
    if (!brands || brands.length === 0) return null;
    let rows: Array<any> = [];
    for (let i = 0; i < brands.length; i += 2) {
      rows.push(
        <div
          key={Math.random()}
          className="d-flex flex-column justify-content-center pb-3"
          style={{
            width: 'fit-content',
            gap: '10px',
            margin: 'auto',
          }}
        >
          <div style={{ width: '150px', height: '150px', margin: 0 }}>
            <BrandCard
              brand={brands[i]}
              redirectionUrl={`/${country}/${lang}/new-cars/${brands[i].slug}`}
            />
          </div>
          {brands[i + 1] ? (
            <div style={{ width: '150px', height: '150px', margin: 0 }}>
              <BrandCard
                brand={brands[i + 1]}
                redirectionUrl={`/${country}/${lang}/new-cars/${
                  brands[i + 1].slug
                }`}
              />
            </div>
          ) : (
            <></>
          )}
        </div>,
      );
    }
    return rows;
  }, [brands, lang]);

  if (isLoading || !BrandRows)
    return (
      <InfoBlock>
        <HeaderHolder className="style">
          <InfoBlockH2>{trans.new_cars}</InfoBlockH2>
          <HeaderHolderP>{trans.browse_top_brands}</HeaderHolderP>
        </HeaderHolder>
        <CarsSliderBlock>
          <Carousel
            responsive={responsiveModels}
            swipeable={true}
            draggable={true}
            infinite={true}
            rtl={lang == 'ar'}
            customLeftArrow={<div className="swiper-button-prev"></div>}
            customRightArrow={<div className="swiper-button-next"></div>}
            key={Math.random()}
          >
            {[...Array(7)].map((_, i) => (
              <div
                key={Math.random()}
                className="d-flex flex-column justify-content-center pb-3"
                style={{
                  width: 'fit-content',
                  gap: '10px',
                  margin: 'auto',
                }}
              >
                <div style={{ width: '150px', height: '150px', margin: 0 }}>
                  <BrandCardSkeleton />
                </div>
                <div style={{ width: '150px', height: '150px', margin: 0 }}>
                  <BrandCardSkeleton />
                </div>
              </div>
            ))}
          </Carousel>
          <InfoBlockBtnViewHolder>
            <BtnViewHolderView to={`/${lang}/new-cars`}>
              {trans.view_all}
            </BtnViewHolderView>
          </InfoBlockBtnViewHolder>
        </CarsSliderBlock>
      </InfoBlock>
    );

  return (
    <InfoBlock>
      <HeaderHolder className="style">
        <InfoBlockH2>{trans.new_cars}</InfoBlockH2>
        <HeaderHolderP>{trans.browse_top_brands}</HeaderHolderP>
      </HeaderHolder>
      <CarsSliderBlock>
        {BrandRows && (
          <Carousel
            responsive={responsiveModels}
            swipeable={true}
            draggable={true}
            infinite={true}
            rtl={lang == 'ar'}
            customLeftArrow={<div className="swiper-button-prev"></div>}
            customRightArrow={<div className="swiper-button-next"></div>}
            key={Math.random()}
          >
            {BrandRows}
          </Carousel>
        )}
        <InfoBlockBtnViewHolder>
          <BtnViewHolderView to={`/${country}/${lang}/new-cars`}>
            {trans.view_all}
          </BtnViewHolderView>
        </InfoBlockBtnViewHolder>
      </CarsSliderBlock>
    </InfoBlock>
  );
}
