import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import BrandCard from '../../Listings/brands/components/BrandCard';
import { Container, Grid } from '@mui/material';
import MetaTag from '../../../common/meta/MetaTag';
import { useGetBrands } from '../../../common/hooks/brand.hooks.';
import { BrandCardSkeleton } from '../../common/skeletons/BrandCardSkeleton';
import { BrowseBrandsSection } from '../../../common/styles/CommonStyles';
import { MainHeadingH1 } from '../../../common/styles/CommonStyles';

const CarBrands = () => {
  const { trans, lang, country } = useContext(AppContext);

  const { data: brands, isLoading: isLoadingBrands } = useGetBrands({
    type: 'new',
  });

  return (
    <>
      <MetaTag metaKey="brands" href={`/${country}/${lang}/new-cars`} />
      <main id="main">
        <div className="container newcars" style={{ paddingTop: '32px' }}>
          <BrowseBrandsSection className="container">
            <MainHeadingH1>{trans.browse_cars_by_brands}</MainHeadingH1>
            <Container
              style={{
                paddingBottom: '16px',
              }}
            >
              <Grid container spacing={2}>
                {!isLoadingBrands
                  ? brands &&
                    brands.length > 0 &&
                    brands.map(brand => (
                      <Grid
                        key={brand.id}
                        item
                        xs={6}
                        sm={6}
                        md={3}
                        lg={2}
                        xl={2}
                      >
                        <BrandCard
                          key={brand.id}
                          redirectionUrl={`/${country}/${lang}/new-cars/${brand.slug}`}
                          brand={brand}
                        />
                      </Grid>
                    ))
                  : Array(25)
                      .fill(0)
                      .map((_, index) => (
                        <Grid
                          key={index}
                          item
                          xs={6}
                          sm={6}
                          md={3}
                          lg={2}
                          xl={2}
                        >
                          <BrandCardSkeleton />
                        </Grid>
                      ))}
              </Grid>
            </Container>
          </BrowseBrandsSection>
        </div>
      </main>
    </>
  );
};

export default CarBrands;
