import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { BannerModel, VideoBannerInput } from '../../models/BannerModel';
import ReactPlayer from 'react-player/lazy';
import {
  VideoBox,
  VideoHolder,
  VisualHolder,
} from './CarouselBannerStyledComponent';

export default function VideoBanner(props: VideoBannerInput) {
  const { lang } = useContext(AppContext);
  const bannerModel: BannerModel = props.bannerModel;

  const bannerImages = bannerModel?.images;

  const banner =
    bannerImages?.find(banner => banner.lang === lang) ||
    bannerImages?.find(banner => banner.lang === 'en');

  const url = banner?.url;

  const thumbnail = banner?.thumbnail;

  if (props.carouselItem) {
    return (
      <ReactPlayer
        width={'100%'}
        height={'100%'}
        light={thumbnail ? thumbnail : true}
        style={{ backgroundColor: 'black' }}
        url={url}
        playing={true}
        controls={true}
      />
    );
  } else {
    return (
      <VisualHolder>
        <VideoHolder dir={lang == 'ar' ? 'rtl' : 'ltr'}>
          <VideoBox>
            <ReactPlayer
              width={'100%'}
              height={'100%'}
              light={true}
              style={{ backgroundColor: 'black' }}
              url={url}
            />
          </VideoBox>
        </VideoHolder>
      </VisualHolder>
    );
  }
}
