export const getUsedCarYearsArray = (): number[] => {
  const currentYear = new Date().getFullYear();
  const startYear = 1970;

  const yearsArray: number[] = [];

  for (let year = startYear; year <= currentYear; year++) {
    yearsArray.push(year);
  }

  return yearsArray.reverse();
};
