import { Fragment, useContext } from 'react';
import { getDeviceType, getPlatform } from './PlatformDetection';
import { Paper, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { AppContext } from '../../contexts/AppContext';

interface IDownloadAppBannerProps {
  showBanner: boolean;
  hideBanner: (showBanner: boolean) => void;
}

const StyledPaper = styled(Paper)(() => ({
  textAlign: 'center',
  backgroundColor: '#232323',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 70,
  boxShadow: '0px 0px 5px 0px #00d5bb',
}));

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 600,
  color: '#fff',
  marginLeft: 2,
}));

const StyledImage = styled('img')(() => ({
  width: 70,
  height: 70,
  objectFit: 'contain',
}));

const StyledExitButton = styled(Button)(() => ({
  borderRadius: '50%',
  height: 10,
  width: 10,
  minWidth: 10,
  color: '#fff',
  flex: 1,
}));

const StyledButton = styled(Button)(() => ({
  height: 40,
  width: 165,
  color: '#fff',
  flex: 4,
  backgroundColor: '#00d5bb',
  textTransform: 'none',
}));

export const DownloadAppBanner = ({
  showBanner,
  hideBanner,
}: IDownloadAppBannerProps) => {
  const platform = getPlatform();
  const deviceType = getDeviceType();
  const { trans } = useContext(AppContext);

  const handleDownload = () => {
    if (platform === 'Android') {
      window.open(
        'https://play.google.com/store/apps/details?id=com.oneautocar',
        '_blank',
      );
    } else if (platform === 'iOS') {
      window.open(
        'https://apps.apple.com/qa/app/one-autocar-all-deals-oneplace/id1629835090',
        '_blank',
      );
    }
    hideBanner(!showBanner);
  };

  const Dismiss = () => {
    hideBanner(!showBanner);
  };

  if (
    !showBanner ||
    deviceType !== 'Mobile' ||
    (platform !== 'Android' && platform !== 'iOS')
  ) {
    return null;
  }

  return (
    <Fragment>
      {showBanner ? (
        <StyledPaper
          square
          elevation={0}
          style={{ position: 'relative', zIndex: 5 }}
        >
          <StyledDiv>
            <StyledImage src="/images/meta_tags_image.png" alt="OneAutoCar" />
            <StyledTypography>{trans.Get_One_Autocar_App}</StyledTypography>
          </StyledDiv>
          <StyledDiv>
            <StyledButton variant="contained" onClick={handleDownload}>
              {trans.Download_Now}
            </StyledButton>
            <StyledExitButton onClick={Dismiss}>x</StyledExitButton>
          </StyledDiv>
        </StyledPaper>
      ) : null}
    </Fragment>
  );
};
