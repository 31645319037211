export const SensorsIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9793 24.7116C10.3424 23.0897 9.33618 20.837 9.33618 18.3441C9.33618 15.8511 10.3424 13.5985 11.9793 11.9766L14.1118 14.1091C13.0155 15.1904 12.3397 16.6921 12.3397 18.3441C12.3397 19.996 13.0155 21.4978 14.0968 22.5941L11.9793 24.7116ZM24.7143 24.7116C26.3512 23.0897 27.3574 20.837 27.3574 18.3441C27.3574 15.8511 26.3512 13.5985 24.7143 11.9766L22.5818 14.1091C23.6781 15.1904 24.3539 16.6921 24.3539 18.3441C24.3539 19.996 23.6781 21.4978 22.5968 22.5941L24.7143 24.7116ZM18.3468 15.3405C16.6949 15.3405 15.3433 16.6921 15.3433 18.3441C15.3433 19.996 16.6949 21.3476 18.3468 21.3476C19.9988 21.3476 21.3504 19.996 21.3504 18.3441C21.3504 16.6921 19.9988 15.3405 18.3468 15.3405ZM30.361 18.3441C30.361 21.663 29.0094 24.6665 26.8318 26.8291L28.9643 28.9616C31.6825 26.2434 33.3645 22.489 33.3645 18.3441C33.3645 14.1992 31.6825 10.4448 28.9643 7.72656L26.8318 9.85908C27.9521 10.9696 28.8408 12.2915 29.4466 13.7479C30.0524 15.2044 30.3632 16.7666 30.361 18.3441ZM9.8618 9.85908L7.72929 7.72656C5.01108 10.4448 3.3291 14.1992 3.3291 18.3441C3.3291 22.489 5.01108 26.2434 7.72929 28.9616L9.8618 26.8291C7.68424 24.6665 6.33264 21.663 6.33264 18.3441C6.33264 15.0252 7.68424 12.0216 9.8618 9.85908Z"
        fill={color}
      />
    </svg>
  );
};
