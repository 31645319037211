import { useMutation, useQuery } from 'react-query';
import {
  IDeleteChatConversationProps,
  IGetChatMessagesByConversationProps,
  IMarkMessagesAsReadProps,
  ISendMessageProps,
  deleteChatConversation,
  getChatConversations,
  getChatMessagesByConversation,
  markMessagesAsRead,
  sendMessage,
} from '../utils/chatApis';

export const useGetChatConversations = () => {
  return useQuery({
    queryKey: ['chatConversations'],
    queryFn: () => getChatConversations(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useGetChatMessagesByConversation = (
  data: IGetChatMessagesByConversationProps,
) => {
  const { enabled, chat_conversation_id } = data;
  return useQuery({
    queryKey: ['chatMessages', chat_conversation_id],
    queryFn: () => getChatMessagesByConversation(data),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useSendMessage = () => {
  return useMutation({
    mutationFn: (data: ISendMessageProps) => sendMessage(data),
  });
};

export const useMarkMessagesAsRead = () => {
  return useMutation({
    mutationFn: (data: IMarkMessagesAsReadProps) => markMessagesAsRead(data),
  });
};

export const useDeleteChatConversation = () => {
  return useMutation({
    mutationFn: (data: IDeleteChatConversationProps) =>
      deleteChatConversation(data),
  });
};
