import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';

const LandingPageBottomSection = () => {
  const { lang } = useContext(AppContext);

  return (
    <div className="w-100 d-flex flex-column mt-5">
      <img
        src={`/images/landing-page-bottom-section-image-${lang}.png`}
        alt=""
        style={{ height: 'auto' }}
      />
      <div
        id="stores-redirect-buttons"
        className="d-flex justify-content-between stores-redirect-buttons"
        style={{
          padding: '0px 10%',
          marginTop: '50px',
          width: '100%',
          maxWidth: '1140px',
        }}
      >
        <a
          href="https://apps.apple.com/qa/app/one-autocar-all-deals-oneplace/id1629835090"
          target="_blank"
          className="store-button"
        >
          <img src="/images/img20.png" alt="" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.oneautocar"
          target="_blank"
          className="store-button"
        >
          <img src="/images/img21.png" alt="" />
        </a>
      </div>
      <h1
        className="footer-copyrights"
        style={{ margin: '30px auto', color: '#ffffff' }}
      >
        © 2021 ONE AUTOCAR. All Rights Reserved.
      </h1>
    </div>
  );
};

export default LandingPageBottomSection;
