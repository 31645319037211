type Platform = 'Windows' | 'MacOS' | 'Linux' | 'Android' | 'iOS' | 'Unknown';
type DeviceType = 'Mobile' | 'Desktop';

export const getPlatform = (): Platform => {
  let userAgent = navigator.userAgent;

  if (/Windows/.test(userAgent)) {
    return 'Windows';
  } else if (/Linux/.test(userAgent) && !/Android/.test(userAgent)) {
    // To exclude Android devices
    return 'Linux';
  } else if (/Android/.test(userAgent)) {
    return 'Android';
  } else if (/iPhone|iPod|iPad/.test(userAgent)) {
    return 'iOS';
  } else {
    return 'Unknown';
  }
};

export const getDeviceType = (): DeviceType => {
  if (
    /(android|webos|iphone|ipad|ipod|blackberry|windows phone)/i.test(
      navigator.userAgent,
    )
  ) {
    return 'Mobile';
  } else {
    return 'Desktop';
  }
};
