import { useMutation, useQuery } from 'react-query';
import {
  ICreateNewBikeInquiry,
  createNewBikeInquiry,
  getBikeModelDeals,
} from '../apis';

interface IBikeModelsDeals {
  modelId: number;
  enabled?: boolean;
}

export const useBikeModelDeals = ({ modelId, enabled }: IBikeModelsDeals) => {
  return useQuery({
    queryKey: ['modelDeals', modelId],
    queryFn: () => getBikeModelDeals({ bike_model_id: modelId }),
    enabled: enabled,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useCreateNewBikeInquiry = () => {
  return useMutation({
    mutationFn: (data: ICreateNewBikeInquiry) => createNewBikeInquiry(data),
  });
};
