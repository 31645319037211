import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { RentCarGridCard } from './RentCarGridCard';
import { IRentalCar } from '../../../types/cars/rental_car';
import Carousel from 'react-multi-carousel';
import { CarGridCarouselSkeleton } from '../../UsedCars/UsedCarsSkeletons/UsedCarsCardSkeleton';
import '../../../styles/css/swiper-bundle.css';

type FeaturedRentalsCarouselProps = {
  featuredRentals: Array<IRentalCar> | undefined;
  isLoading: boolean;
  responsiveBreakPoints: any;
};

const FeaturedRentalsCarousel = ({
  featuredRentals,
  isLoading,
  responsiveBreakPoints,
}: FeaturedRentalsCarouselProps) => {
  const { lang } = useContext(AppContext);

  const rtl = lang == 'ar' ? true : false;

  if (isLoading) {
    return (
      <CarGridCarouselSkeleton responsive={responsiveBreakPoints} rtl={rtl} />
    );
  }

  return (
    <Carousel
      autoPlay
      autoPlaySpeed={4000}
      rtl={rtl}
      infinite={true}
      swipeable={true}
      draggable={true}
      responsive={responsiveBreakPoints}
      customLeftArrow={<div className="swiper-button-prev"></div>}
      customRightArrow={<div className="swiper-button-next"></div>}
      key="rental-cars-carousel"
    >
      {featuredRentals &&
        featuredRentals.length > 0 &&
        featuredRentals?.map(featuredRental => (
          <RentCarGridCard key={featuredRental.id} car={featuredRental} />
        ))}
    </Carousel>
  );
};

export default FeaturedRentalsCarousel;
