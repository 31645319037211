import { useContext, useEffect, useState } from 'react';
import BannerInstance from '../../instance/BannerInstance';
import { BannerInput, BannerModel } from '../../models/BannerModel';
import '../../styles/css/banner.css';
import CarouselBanner from './CarouselBannerComponemt/CarouselBannerComponent';
import ImageBanner from './ImageBannerComponent';
import VideoBanner from './VideoBannerComponent';
import { useInView } from 'react-intersection-observer';
import { AppContext } from '../../contexts/AppContext';

export default function Banner(props: BannerInput) {
  const { lang } = useContext(AppContext);
  const [bannerModel, setBannerModel] = useState<BannerModel>();
  const [bannerModels, setBannerModels] = useState(new Array<BannerModel>());
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {
    if (props.multiple) {
      BannerInstance.getBanners(
        props.section_name,
        props.shuffle ? props.shuffle : false,
        isMobile,
      ).then(response => {
        //filter response if it has an object with the key section_name
        const filteredResponse = response.filter(
          (item: any) => item.section_name !== props.section_name,
        );
        setBannerModels(filteredResponse);
      });
    } else {
      BannerInstance.getBanner(props.section_name, isMobile).then(response =>
        setBannerModel(response),
      );
    }
  }, [props.section_name, props.multiple, props.shuffle]);

  const handleBannerClick = (bannerId: number) => {
    // bannerPageClick(bannerId);
  };

  // const handleBannerView = (bannerId: number) => {
  //   bannerPageView(bannerId);
  // };

  const { ref } = useInView({
    threshold: 0.01,
    onChange(inView) {
      if (inView) {
        // handleBannerView(bannerModel.id);
      }
    },
    triggerOnce: true,
  });

  useEffect(() => {
    if (props.setBannerTitles) {
      if (bannerModels && bannerModels.length > 0) {
        const titles = bannerModels.map(bannerModel => {
          const bannerImages = bannerModel?.images;
          const banner =
            bannerImages?.find(banner => banner.lang === lang) ||
            bannerImages?.find(banner => banner.lang === 'en');
          const bannerTitle = banner?.title;

          // Check if images array is empty
          if (bannerImages?.length === 0) {
            return '';
          } else if (bannerTitle) {
            return bannerTitle;
          }
        });
        props.setBannerTitles(titles);
      } else {
        props.setBannerTitles(['']);
      }
    }
  }, [bannerModels, lang, props.setBannerTitles]);

  if (props.multiple && props.customDot) {
    return (
      <CarouselBanner
        redirect={props.redirect}
        bannerModels={bannerModels}
        className={props.className}
        customDot={props.customDot}
        onClick={handleBannerClick}
        innerRef={ref}
        key={Math.random()}
      />
    );
  } else {
    if (bannerModel && bannerModel?.content_type === 'Video') {
      return (
        <VideoBanner
          bannerModel={bannerModel}
          onClick={() => handleBannerClick(bannerModel.id)}
          innerRef={ref}
        />
      );
    } else {
      return (
        <ImageBanner
          redirect={props.redirect}
          bannerModel={bannerModel as BannerModel}
          className={props.className}
          onClick={() => handleBannerClick(bannerModel?.id as number)}
          innerRef={ref}
        />
      );
    }
  }
}
