import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const BannerHolder = styled('div')(() => ({
  backgroundColor: '#3d3d3d',
  marginBlock: '30px 20px',
  borderRadius: '3px',
  position: 'relative',
  padding: '35px 300px',
  textAlign: 'center',
  color: '#fff',
  '@media (max-width: 1199px)': {
    paddingInline: '240px',
  },
  '@media (max-width: 991px)': {
    padding: '25px 160px',
    marginBlock: '45px 25px',
  },
  '@media (max-width: 575px)': {
    padding: '20px 124px',
  },
  '@media (max-width: 479px)': {
    padding: '20px 15px',
    textAlign: 'center',
  },
}));

export const BannerHolderH2 = styled('h2')(() => ({
  textTransform: 'uppercase',
  marginBottom: '5px',
  fontSize: '26px',
  fontWeight: 700,
  '@media (max-width: 1199px)': {
    fontSize: '23px',
  },
  '@media (max-width: 991px)': {
    fontSize: '21px',
  },
  '@media (max-width: 767px)': {
    fontSize: '18px',
    maxWidth: '270px',
    margin: '10px auto 5px',
  },
  '@media (max-width: 575px)': {
    fontSize: '16px',
    maxWidth: '220px',
  },
  '@media (max-width: 479px)': {
    fontSize: '14px',
    maxWidth: '210px',
  },
}));

export const BannerHolderP = styled('p')(() => ({
  fontStyle: 'italic',
}));

export const BannerHolderTextBoxImg = styled('img')(() => ({
  marginBottom: '5px',
}));

export const BannerHolderBtnSecondary = styled(Link)(() => ({
  top: '50%',
  transform: 'translateY(-50%)',
  position: 'absolute',
  right: '90px',
  borderRadius: '5px',
  zIndex: 9,
  letterSpacing: '1px',
  '&:active, &:focus, &:hover': {
    backgroundColor: '#04E4CC',
    borderColor: '#04E4CC',
    color: '#fff',
  },
  '@media (max-width: 1199px)': {
    right: '45px',
  },
  '@media (max-width: 991px)': {
    right: '20px',
    minWidth: '120px',
  },
  '@media (max-width: 575px)': {
    right: '15px',
    minWidth: '90px',
    fontSize: '14px',
  },
  '@media (max-width: 479px)': {
    minWidth: '60px',
    fontSize: '13px',
    right: '10px',
  },
  '@media (max-width: 374px)': {
    minWidth: '58px',
    fontSize: '11px',
    right: '5px',
    paddingInline: '9px',
  },
  '.lang-au &': {
    right: 'auto',
    left: '90px',
    '@media (max-width: 1199px)': {
      right: 'auto',
      left: '45px',
    },
    '@media (max-width: 480px)': {
      left: '10px',
    },
  },
}));

export const BannerHolderImgBox = styled('div')(() => ({
  top: '50%',
  transform: 'translateY(-50%)',
  position: 'absolute',
  left: '130px',
  maxHeight: '190px',
  marginTop: '-13px',
  '@media (max-width: 1199px)': {
    left: '70px',
    marginTop: '-15px',
  },
  '@media (max-width: 991px)': {
    left: '0',
    marginTop: '-8px',
    maxHeight: '150px',
  },
  '@media (max-width: 767px)': {
    maxHeight: '163px',
  },
  '@media (max-width: 479px)': {
    maxHeight: '100px',
  },
  '@media (max-width: 374px)': {
    maxHeight: '80px',
  },
  '.lang-au &': {
    left: 'auto',
    right: '130px',
    '@media (max-width: 1199px)': {
      left: 'auto',
      right: '70px',
    },
    '@media (max-width: 480px)': {
      right: '10px',
    },
  },
}));

export const BannerHolderImgBoxImg = styled('img')(() => ({
  maxHeight: '190px',
  '@media (max-width: 991px)': {
    maxHeight: '150px',
  },
  '@media (max-width: 767px)': {
    maxHeight: '163px',
  },
  '@media (max-width: 575px)': {
    maxHeight: '100px',
  },
  '@media (max-width: 374px)': {
    maxHeight: '80px',
  },
}));

export const BannerHolderBrandLogo = styled('img')(() => ({
  maxWidth: '60px',
  margin: '0 auto 10px',
  display: 'block',
  height: 'auto',
}));

export const BannerHolderTextBox = styled('div')(() => ({
  overflow: 'hidden',
  fontSize: '19px',
  lineHeight: 1.4,
  '@media (max-width: 991px)': {
    fontSize: '16px',
    padding: '0 10px',
  },
  '@media (max-width: 767px)': {
    position: 'static',
    marginBottom: '20px',
  },
  '@media (max-width: 575px)': {
    fontSize: '12px',
  },
  '@media (max-width: 479px)': {
    fontSize: '13px',
    marginBottom: '15px',
  },
  '.lang-au &': {
    '@media (max-width: 480px)': {
      paddingLeft: '100px',
      paddingRight: '100px',
    },
  },
}));

export const SellCarBanner = styled('div')(() => ({
  backgroundColor: '#3d3d3d',
  marginBlock: '30px 20px',
  borderRadius: '3px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  position: 'relative',
  color: '#fff',
}));

export const SellCarBannerH2Strong = styled('strong')(() => ({
  display: 'block',
}));

export const SellCarBannerH2 = styled('h2')(() => ({
  textTransform: 'uppercase',
  color: '#fff',
  fontSize: '30px',
  lineHeight: 1.3,
  fontWeight: 700,
  marginBottom: '10px',
  '@media (max-width: 1199px)': {
    fontSize: '26px',
  },
  '@media (max-width: 991px)': {
    fontSize: '19px',
    lineHeight: '1.6',
  },
  '@media (max-width: 575px)': {
    fontSize: '17px',
  },
  '@media (max-width: 479px)': {
    fontSize: '15px',
  },
}));

export const SellCarBannerH2Sup = styled('sup')(() => ({
  fontSize: '22px',
  fontWeight: 400,
  marginRight: '2px',
  '@media (max-width: 767px)': {
    fontSize: '17px',
  },
  '@media (max-width: 575px)': {
    fontSize: '15px',
  },
  '@media (max-width: 479px)': {
    fontSize: '13px',
  },
}));

export const SellCarBannerRightContentImgBox = styled('div')(() => ({
  display: 'inline-block',
  verticalAlign: 'middle',
  maxWidth: '156px',
  margin: '-20px 30px 0 10px',
  '@media (max-width: 1199px)': {
    maxWidth: '145px',
    margin: '-20px 18px 0 0',
  },
  '@media (max-width: 991px)': {
    marginLeft: '-15px',
    maxWidth: '95px',
    margin: '-20px 25px 0 0',
  },
  '@media (max-width: 767px)': {
    marginTop: 0,
    position: 'absolute',
    left: ' -100px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '@media (max-width: 479px)': {
    maxWidth: '80px',
    left: '-75px',
  },
  'lang-au &': {
    '@media (max-width: 767px)': {
      left: '100px',
    },
    '@media (max-width: 575px)': {
      left: 0,
      right: 0,
      marginTop: 0,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '@media (max-width: 480px)': {
      left: '0',
      marginRight: '-117px',
      marginLeft: '117px',
    },
    '@media (max-width: 479px)': {
      right: '35px',
      left: '0',
    },
  },
}));

export const SellCarBannerRightContentTxt = styled('span')(() => ({
  display: 'inline-block',
  verticalAlign: 'middle',
  '@media (max-width: 991px)': {
    marginLeft: '-15px',
  },
  '&.value ': {
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  '&.list ': {
    '@media (max-width: 767px)': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: '20px',
      width: '120px',
      fontSize: '14px',
    },
    '@media (max-width: 575px)': {
      left: '15px',
      fontSize: '12px',
      width: '100px',
    },
    '@media (max-width: 479px)': {
      left: '17px',
      fontSize: '11px',
    },
  },
}));

export const SellCarBannerRightContent = styled('div')(() => ({
  width: '50%',
  paddingRight: '5%',
  textAlign: 'center',
  position: 'relative',
  zIndex: 9,
  boxSizing: 'border-box',
  fontSize: '18px',
  letterSpacing: '1px',
  ':before': {
    position: 'absolute',
    content: '""',
    zIndex: -1,
    bottom: ' -57px',
    top: 0,
    right: '100%',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 0 170px 157px',
    borderColor: 'transparent transparent #3d3d3d',
  },
  '@media (max-width: 1399px)': {
    fontSize: '16px',
  },
  '@media (max-width: 1199px)': {
    paddingRight: '3%',
    fontSize: '14px',
    ':before': {
      borderWidth: '0 0 157px 137px',
    },
  },
  '@media (max-width: 991px)': {
    ':before': {
      borderWidth: ' 0 0 149px 137px',
    },
    fontSize: '12px',
    paddingTop: '30px',
  },
  '@media (max-width: 767px)': {
    textAlign: 'right',
    width: '30%',
    ':before': {
      borderWidth: ' 0 0 145px 137px',
    },
  },
  '@meida (max-width: 575px)': {
    ':before': {
      borderWidth: '0 0 132px 107px',
    },
  },
  '@media (max-width: 479px)': {
    width: '32%',
    fontSize: '12px',
    paddingLeft: '30px',
    ':before': {
      borderWidth: '0 0 126px 77px',
    },
  },
  '.lang-au &': {
    paddingRight: 0,
    paddingLeft: '5%',
    ':before': {
      left: '100%',
      right: 'auto',
      borderWidth: '0 157px 170px 0',
    },
    '@media (max-width: 1199px)': {
      ':before': {
        borderWidth: '0 137px 157px 0',
      },
    },
    '@media (max-width: 1024px)': {
      ':before': {
        borderWidth: '0 137px 156px 0',
      },
    },
    '@media (max-width: 991px)': {
      ':before': {
        borderWidth: '0 137px 149px 0',
      },
    },
    '@media (max-width: 767px)': {
      ':before': {
        borderWidth: '0 137px 145px 0',
      },
    },
    '@media (max-width: 575px)': {
      ':before': {
        borderWidth: '0 137px 132px 0',
      },
    },
    '@media (max-width: 479px)': {
      ':before': {
        borderWidth: '0 137px 125px 0',
      },
    },
  },
}));

export const SellCarBannerLeftContent = styled('div')(() => ({
  width: '50%',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: '#00D6BC',
  boxSizing: 'border-box',
  padding: '20px 13% 10px 9%',
  '@media (max-width: 1399px)': {
    paddingLeft: '7%',
  },
  '@media (max-width: 1199px)': {
    paddingLeft: '6%',
  },
  '@media (max-width: 991px)': {
    paddingLeft: '3%',
  },
  '@media (max-width: 767px)': {
    padding: '15px',
    width: '70%',
  },
  '@media (max-width: 479px)': {
    width: '68%',
  },
  '.lang-au &': {
    textAlign: 'right',
    paddingInline: '9% 13%',
    '@media (max-width: 1399px)': {
      paddingRight: '7%',
    },
    '@media (max-width: 1199px)': {
      paddingRight: '6%',
    },
  },
}));

export const SellCarBannerBtnSecondary = styled('button')(() => ({
  backgroundColor: '#3D3D3D',
  borderColor: '#3D3D3D',
  borderRadius: '3px',
  paddingBlock: '9px',
  '&:focus, &:hover, &.focus, &:not(:disabled):not(.disabled).active': {
    backgroundColor: '#606060',
    borderColor: '#606060',
    color: '#fff',
  },
  '@media (max-width: 991px)': {
    minWidth: '120px',
  },
  '@media (max-width: 575px)': {
    minWidth: '100px',
    paddingBlock: '8px',
    fontSize: '13px',
  },
}));

export const SellCarBannerBrandLogo = styled('img')(() => ({
  position: 'absolute',
  right: '10px',
  top: '10px',
  maxWidth: '50px',
  '.lang-au &': {
    right: 'auto',
    left: '10px',
  },
}));
