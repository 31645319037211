import authenticatedApi from '../../../instance/ApiInstance';
import { IMetadata } from '../../../types/common/metadata';
import { IReview } from '../../../types/reviews';
import { TReviewsFilters } from '../components/ReviewsFilter/ReviewsFilter';

const reviewsEndpoint = '/v2/reviews';

export interface IGetReviews extends Partial<TReviewsFilters> {
  page?: number;
  perPage?: number;
}

export const getReviews = async (
  params: IGetReviews,
): Promise<{ data: IReview[]; meta: IMetadata }> => {
  const filteredParams = {
    ...(params.language && { language: params.language }),
    ...(params.brandIds && { brandIds: params.brandIds }),
    ...(params.modelIds && { modelIds: params.modelIds }),
    is_active: true,
  };

  const response = await authenticatedApi.get(reviewsEndpoint, {
    params: filteredParams,
  });

  return response.data;
};

export const getReviewBySlug = async (slug: string): Promise<IReview> => {
  const response = await authenticatedApi.get(`${reviewsEndpoint}/${slug}`);

  return response.data;
};

export const getLast4CreatedReviews = async (skip?: string) => {
  const response = await authenticatedApi.get(
    `${reviewsEndpoint}/more-reviews`,
    {
      params: { skip },
    },
  );

  return response.data;
};
