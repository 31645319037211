import CarCarousel from './CarCarousel';
import CarouselTypeFilters from './CarouselTypeFilters';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { IUsedCar } from '../../../types/cars/used_car';
import {
  BtnViewHolderView,
  CarsSliderBlock,
  HeaderHolder,
  HomeUsedCars,
  InfoBlock,
  InfoBlockBtnViewHolder,
  InfoBlockH2,
  ViewCarsBox,
  ViewCarsBoxBtn,
  ViewCarsBoxBtnImg,
  ViewCarsBoxTitle,
  ViewCarsBoxWrap,
} from '../../../common/styles/CommonStyles';

type CarCarouselWithTypeFiltersProps = {
  cars: Array<IUsedCar>;
  carouselTitle: string;
  goToAllCarsTitle: string;
  allCarsPath: string;
  selectedFilter: any;
  setSelectedFilter: (filter: any) => void;
  isLoading: boolean;
  favorites: Array<number>;
  onFavoriteClick: (e: any, carId: number) => void;
  generateRedirectionLink: (car: IUsedCar, lang: string) => string;
};

const CarCarouselWithTypeFilters = ({
  cars,
  carouselTitle,
  goToAllCarsTitle,
  allCarsPath,
  selectedFilter,
  setSelectedFilter,
  isLoading,
  favorites,
  onFavoriteClick,
  generateRedirectionLink,
}: CarCarouselWithTypeFiltersProps) => {
  const { trans } = useContext(AppContext);

  return (
    <>
      <InfoBlock className="tabs">
        <HeaderHolder className="style">
          <InfoBlockH2>{carouselTitle}</InfoBlockH2>
          <CarouselTypeFilters
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        </HeaderHolder>
        <ViewCarsBox>
          <ViewCarsBoxWrap>
            <ViewCarsBoxTitle>{goToAllCarsTitle}</ViewCarsBoxTitle>
            <ViewCarsBoxBtn to={allCarsPath}>
              <ViewCarsBoxBtnImg src="/images/chevron-right.png" alt="img" />
            </ViewCarsBoxBtn>
          </ViewCarsBoxWrap>
        </ViewCarsBox>
        <CarsSliderBlock className="slider-holder.style">
          <HomeUsedCars>
            <CarCarousel
              cars={cars}
              favoriteCars={favorites}
              onFavouriteClick={onFavoriteClick}
              generateRedirectLink={generateRedirectionLink}
              isLoading={isLoading}
            />
          </HomeUsedCars>
        </CarsSliderBlock>
        <InfoBlockBtnViewHolder>
          <BtnViewHolderView to={allCarsPath}>
            {trans.view_all}
          </BtnViewHolderView>
        </InfoBlockBtnViewHolder>
      </InfoBlock>
    </>
  );
};

export default CarCarouselWithTypeFilters;
