import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';

const LandingPageTopSection = () => {
  const { trans } = useContext(AppContext);

  return (
    <div
      className="green-gradient align-items-center"
      style={{ width: '100%' }}
    >
      <div>
        <h1 className="top-section-title text-center m-1">{trans.all_deals}</h1>
        <h1 className="top-section-title text-center m-1">{trans.one_place}</h1>
        <img
          className="top-section-image"
          src="/images/landing-page-top-section-image.png"
          alt=""
        />
        <p className="top-section-description mt-4 mt-4">
          {trans.landing_page_top_section_description_1}
          <br />
          {trans.landing_page_top_section_description_2}
        </p>
      </div>
    </div>
  );
};

export default LandingPageTopSection;
