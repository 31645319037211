export const arMetaData = {
  home: {
    title: 'شراء وبيع السيارات الجديدة والمستعملة في قطر | One Auto Car',
    description:
      'هل أنت مستعد لبيع سيارتك في قطر؟ اعرض سيارتك للبيع بكل سهولة مع One Auto Car. تواصل مع المشترين المحتملين بسرعة وسهولة. ابدأ البيع اليوم!',
  },
  usedCars: {
    title: 'سيارات مستعملة للبيع في قطر | One Auto Car',
    description:
      'اكتشف مجموعة واسعة من السيارات المستعملة عالية الجودة للبيع في قطر على One Auto Car. تصفح قوائمنا وابحث عن السيارة المثالية لتلبية احتياجاتك. استكشف الآن!',
  },
  dealers: {
    title: 'ابحث عن الوكلاء الموثوقين في قطر لسيارتك القادمة | One Auto Car',
    description:
      'استكشف شبكة من الوكالات الموثوقة في One Auto Car. اعثر على الشريك المثالي لتلبية احتياجاتك في السيارات. تصفح قائمتنا الواسعة للوكلاء الآن!',
  },
  newCarsBrand: {
    title: 'أشتري سيارات %BRAND% الجديدة في قطر | One Auto Car',
    description:
      'استكشف أحدث موديلات %BRAND% المعروضة للبيع في قطر على One Auto Car. ابحث عن السيارة الجديدة المثالية %BRAND% لتناسب أسلوبك واحتياجاتك.',
  },
  newCarsBrandModel: {
    title: 'سيارات %BRAND% %MODEL% للبيع في قطر | One Auto Car',
    description:
      'هل تبحث عن سيارة %BRAND% %MODEL% للبيع في قطر؟ استكشف تشكيلتنا الواسعة من سيارات %MODEL% الجديدة واعثر على صفقات لا تقاوم في One Auto Car. تصفح الآن!',
  },
  usedCarsDetails: {
    title: 'أشتري %YEAR% %BRAND% %MODEL% في قطر | One Auto Car',
    description:
      'أشتري %YEAR% %BRAND% %MODEL% في قطر. استكشف تشكيلتنا في One Auto Car واشترِ سيارتك المثالية اليوم.',
  },
  dealer: {
    title: 'One Auto Car | %NAME%',
    description:
      'اكتشف أفضل العروض في %NAME% لسيارتك في One Auto Car. استعرض مجموعتنا الواسعة من السيارات واعثر على رحلتك المثالية اليوم.',
  },
  articles: {
    title: 'مقالات متنوعة في عالم السيارات | One Auto Car',
    description:
      'استكشف المقالات المعلوماتية في One Auto Car التي تغطي أحدث الاتجاهات والرؤى في صناعة السيارات. ابقَ على اطلاع معنا!',
  },
  reviews: {
    title: 'تقييمات السيارات | One Auto Car',
    description:
      'استعرض تقييمات السيارات في One Auto Car لتجد السيارة المثالية لك. نقدم مراجعات شاملة لأحدث السيارات في السوق.',
  },
  articleTitle: {
    title: '%NAME% | One Auto Car',
    description: 'ملخص عن المقالة.',
  },
  financing: {
    title: 'التمويل | One Auto Car',
    description:
      'اكتشف خيارات التمويل المرنة في One Auto Car. احصل على مفاتيح سيارتك المثالية بحلول تمويل خالية من المتاعب.',
  },
  dealerNewCars: {
    title: 'سيارات %NAME% الجديدة | One Auto Car',
    description:
      'اكتشف أحدث موديلات سيارات %NAME%! استعرض تشكيلتنا من السيارات الجديدة في One Auto Car لتجربة قيادة استثنائية.',
  },
  dealerUsedCars: {
    title: 'سيارات %NAME% المستعملة | One Auto Car',
    description:
      'اكتشف مجموعة واسعة من سيارات %NAME% المستعملة عالية الجودة . مصدر موثوق للسيارات المملوكة مسبقًا. استكشف One Auto Car اليوم!',
  },
  whyWeRock: {
    title: 'لماذا نحن متميزون | One Auto Car',
    description:
      'اكتشف لماذا يتميز One Auto Car! استعرض سياراتنا الاستثنائية وخدماتنا التي لا تُضاهى. رحلتك نحو التميز في عالم السيارات تبدأ هنا.',
  },
  accountDetails: {
    title: 'تفاصيل الحساب | One Auto Car',
    description:
      'الوصول إلى وإدارة تفاصيل حسابك في One Auto Car بسهولة. قم بتحديث المعلومات والتفضيلات بسلاسة. سيارتك، تحت تحكمك.',
  },
  register: {
    title: 'تسجيل | One Auto Car',
    description:
      'سجل الآن في One Auto Car للحصول على فوائد وتحديثات حصرية. انضم إلى مجتمعنا في عالم السيارات اليوم!',
  },
  login: {
    title: 'تسجيل الدخول | One Auto Car',
    description:
      'الوصول الآمن إلى حسابك في One Auto Car من خلال عملية تسجيل الدخول السهلة. تحكم في راحتك وسهولة الاستخدام. سجل الدخول الآن!',
  },
  brands: {
    title: 'استكشف أحدث السيارات الجديدة للبيع في قطر | One Auto Car',
    description:
      'اكتشف أروع تشكيلة من السيارات الجديدة للبيع في قطر على One Auto Car. استعرض مجموعة متنوعة من السيارات الجديدة وابحث عن سيارتك المثالية اليوم!',
  },
  sellYourCar: {
    title: 'اعرض سيارتك للبيع في قطر مجاناً | One Auto Car',
    description:
      'هل أنت مستعد لبيع سيارتك في قطر؟ اعرض سيارتك للبيع بكل سهولة مع One Auto Car. تواصل مع المشترين المحتملين بسرعة وسهولة. ابدأ البيع اليوم!',
  },
  ramadanOffer: {
    title: 'عروض رمضان | One Auto Car',
    description:
      'استعرض أفضل عروض رمضان في قطر على One Auto Car. اكتشف صفقات لا تُضاهى على السيارات الجديدة، وابحث عن رحلتك المثالية اليوم!',
  },
  accountWishList: {
    title: 'قائمة الأمنيات | One Auto Car',
    description:
      'استعرض قائمة أمنياتك في One Auto Car. ابحث عن سيارة أحلامك واحفظها في قائمة الأمنيات. استعرض سياراتك المفضلة الآن!',
  },
  usedBikes: {
    title: 'دراجات مستعملة للبيع في قطر | One Auto Car',
    description:
      'اكتشف مجموعة واسعة من الدراجات المستعملة عالية الجودة للبيع في قطر على One Auto Car. تصفح قوائمنا وابحث عن الدراجة المثالية لتلبية احتياجاتك. استكشف الآن!',
  },
  usedBikesDetails: {
    title: 'اشتري %YEAR% %BRAND% %MODEL% في قطر | One Auto Car',
    description:
      'اشتري %YEAR% %BRAND% %MODEL% في قطر. استكشف تشكيلتنا في One Auto Car واشترِ دراجتك المثالية اليوم.',
  },
  sellYourBike: {
    title: 'اعرض دراجتك للبيع في قطر مجاناً | One Auto Car',
    description:
      'هل أنت مستعد لبيع دراجتك في قطر؟ اعرض دراجتك للبيع بكل سهولة مع One Auto Car. تواصل مع المشترين المحتملين بسرعة وسهولة. ابدأ البيع اليوم!',
  },
  dealerNewBikes: {
    title: 'دراجات %NAME% جديدة | One Auto Car',
    description:
      'اكتشف أحدث موديلات %NAME%! استعرض تشكيلتنا من الدراجات الجديدة في One Auto Car لتجربة قيادة استثنائية.',
  },
  dealerUsedBikes: {
    title: 'دراجات %NAME% المستعملة | One Auto Car',
    description:
      'اكتشف مجموعة واسعة من دراجات %NAME% المستعملة عالية الجودة. مصدر موثوق للدراجات المملوكة مسبقًا. استكشف One Auto Car اليوم!',
  },
  bikeBrands: {
    title: 'استكشف أحدث الدراجات الجديدة للبيع في قطر | One Auto Car',
    description:
      'اكتشف أروع تشكيلة من الدراجات الجديدة للبيع في قطر على One Auto Car. استعرض مجموعة متنوعة من الدراجات الجديدة وابحث عن دراجتك المثالية اليوم!',
  },
  newBikesBrandModel: {
    title: 'اكتشف دراجات %BRAND% %MODEL% للبيع في قطر | One Auto Car',
    description:
      'هل تبحث عن دراجة %BRAND% %MODEL% للبيع في قطر؟ استكشف تشكيلتنا الواسعة من دراجات %MODEL% الجديدة واعثر على صفقات لا تُضاهى في One Auto Car. تصفح الآن!',
  },
};
