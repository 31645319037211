export {};

console.log('initializing teads setup');

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log('development');
} else {
  console.log('production');
}
declare global {
  interface Window {
    teads: {
      cleanup?: (() => void) | undefined;
    };
  }
}

// Initialize window.teads object
window.teads = window.teads || {};
