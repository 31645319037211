import {
  NOTIFICATION_TYPE,
  Store,
  NOTIFICATION_CONTAINER,
} from 'react-notifications-component';

const successNotification = (
  message: any,
  type: NOTIFICATION_TYPE,
  title: string,
  containerPlacement: NOTIFICATION_CONTAINER = 'top-right',
) => {
  Store.addNotification({
    title: title,
    message: message,
    insert: 'top',
    container: containerPlacement,
    type: type,
    animationIn: ['animated', 'fadeInRight'],
    animationOut: ['animated', 'fadeOutRight'],
    dismiss: {
      duration: 5000,
      onScreen: true,
      showIcon: true,
    },
  });
};
const errorNotification = (error: any) => {
  const { response } = error;
  if (error.response) {
    if (response.status !== 401) {
      if (response.status === 500) {
        Store.addNotification({
          title: `Server Error`,
          message: 'Something went Wrong!',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeInRight'],
          animationOut: ['animated', 'fadeOutRight'],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
          },
        });
      } if (response.status === 400) {
        Store.addNotification({
          title: error?.response?.data?.message,
          message: 'Something went Wrong!',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeInRight'],
          animationOut: ['animated', 'fadeOutRight'],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
          },
        });
      } else {
        Store.addNotification({
          title: `Error with status code :${response.status} `,
          message: response.statusText,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeInRight'],
          animationOut: ['animated', 'fadeOutRight'],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
          },
        });
      }
    }
  }
};

export { errorNotification, successNotification };
