export class AppConstants {
  public static BANNERS_PLACEMENT = class {
    public static readonly PORTAL = 'Portal';
    public static readonly MOBILE = 'MobileApp';
  };

  public static LOCAL_STORAGE = class {
    public static readonly BANNERS = 'banners';
    public static readonly DEFAULT_TTL = 15;
  };

  public static BANNERS_CONTENT_TYPE = class {
    public static readonly IMAGE = 'Image';
    public static readonly Video = 'Video';
  };
}
