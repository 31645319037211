import { useContext, useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { AppContext } from '../../contexts/AppContext';
import { PaginationItem } from '@mui/lab';
import { Link, useLocation } from 'react-router-dom';

export default function Paging(props: any) {
  const { lang } = useContext(AppContext);
  const [pagesCount, setPagesCount] = useState(props.pagesCount ?? 0);
  const [currentPage, setCurrentPage] = useState(props.currentPage ?? 0);
  const windowWidth = window.innerWidth;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    props.pageChangeHandler(value - 1);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setPagesCount(props.pagesCount ?? 0);
  }, [props.pagesCount]);

  useEffect(() => {
    setCurrentPage(props.currentPage ?? 0);
  }, [props.currentPage]);

  const getURL = (item): string => {
    if (props.pageCount === 1 || props.pageCount === 0) {
      queryParams.delete('page');
    } else {
      queryParams.set('page', item.page);
    }

    return queryParams.toString();
  };

  return (
    <div dir={lang === 'ar' ? 'rtl' : 'ltr'} className="pagination-wrapper">
      <Pagination
        count={pagesCount}
        variant="outlined"
        shape="rounded"
        onChange={handleChange}
        page={currentPage + 1}
        defaultPage={1}
        boundaryCount={1}
        siblingCount={1}
        size={windowWidth < 600 ? 'small' : 'medium'}
        renderItem={item => {
          if (item.page === currentPage + 1) {
            return (
              <PaginationItem
                {...item}
                style={{ backgroundColor: '#f4f4f4', color: '#000' }}
              />
            );
          } else if (item.type === 'next' || item.type === 'previous') {
            return <PaginationItem {...item} />;
          } else if (!item.page) {
            return <PaginationItem {...item} />;
          }
          return (
            <Link to={`?${getURL(item)}`}>
              <PaginationItem
                {...item}
                style={{ backgroundColor: '#fff', color: '#000' }}
              />
            </Link>
          );
        }}
      />
    </div>
  );
}
