import { useContext, useMemo, useState } from 'react';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import { AppContext } from '../../../contexts/AppContext';
import { useParams } from 'react-router-dom';
import { useGetDealClick } from '../../NewCars/components/NewCarDetailComponents/hooks/useNewCarDetail';
import { RamadanDetailHeader } from '../components/RamadanHeader/RamadanDetailHeader';
import { RamadanSliderSection } from '../components/RamadanSlider/RamadanSliderSection';
import { RamadanFeaturedDetails } from '../components/RamadanFeatured/RamadanFeaturedDetails';
import Banner from '../../Banners/BannerComponent';
import { RamadanInquiryForm } from '../components/RamadanInquiryForm';
import { useGetRamadanOffersBySlug } from '../../../common/hooks/ramadan_offers.hook';
import './ramadanOffersDetailsPageStyle.css';
import { IRamadanOffers } from '../../../types/resources/cars/ramadan_offers';
import { ResultsNotFound } from '../../common';

const RamadanOffersDetailsPage = () => {
  const { lang, darkImageLoadingPlaceholder, trans } = useContext(AppContext);
  const params = useParams();
  const offerSlug = params.OfferSlug;
  const [showDealForm, setShowDealForm] = useState(false);

  const { data: ramadanOffer, isLoading: isLoadingRamadanOffer } =
    useGetRamadanOffersBySlug(offerSlug as string);

  const images = useMemo(() => {
    return ramadanOffer?.images || [];
  }, [ramadanOffer]);

  const ramadanOfferModels = useMemo(() => {
    return ramadanOffer?.models || [];
  }, [ramadanOffer]);

  const closeDealForm = () => {
    setShowDealForm(false);
  };

  const { getDealClick } = useGetDealClick(setShowDealForm);

  if (!isLoadingRamadanOffer && !ramadanOffer) {
    return <ResultsNotFound lang={lang} trans={trans} />;
  }

  return (
    <>
      <MetaTag
        metaKey="ramadanOffer"
        href={`/${lang}/ramadan-offers/${params.OfferSlug}`}
        params={{
          '%NAME%': ramadanOffer?.title,
        }}
      />
      <main id="main">
        <div className="ramadan-container details">
          <>
            <RamadanDetailHeader
              offer={ramadanOffer}
              isLoading={isLoadingRamadanOffer}
            />
            <div className="d-flex ramadan-offer-details pt-3 pb-3">
              <RamadanSliderSection
                lang={lang}
                images={images}
                darkImageLoadingPlaceholder={darkImageLoadingPlaceholder}
                isLoading={isLoadingRamadanOffer}
              />
              <RamadanFeaturedDetails
                offer={ramadanOffer as IRamadanOffers}
                getDealClick={getDealClick}
                trans={trans}
                isLoading={isLoadingRamadanOffer}
              />
            </div>
            <Banner
              section_name="R1"
              className="default-image-banner C2-margin w-100"
            />
          </>
        </div>
      </main>
      {showDealForm && (
        <RamadanInquiryForm
          offerId={ramadanOffer?.id ?? 0}
          closeDealForm={closeDealForm}
          offerModels={ramadanOfferModels}
        />
      )}
    </>
  );
};

export default RamadanOffersDetailsPage;
