import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import {
  loginUserByEmail,
  loginUserByMobile,
} from '../../../services/ApiService';
import Banner from '../../Banners/BannerComponent';
import PageLoader from '../../Other/PageLoader/PageLoaderComponent';
import parse from 'html-react-parser';
import MetaTag from '../../../common/meta/MetaTag';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneInput from 'react-phone-input-2';
import '../../User/LogIn/login-styles.css';

export default function Login(this: any) {
  const { lang, trans, country } = useContext(AppContext);
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState('');
  const passwordRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [loginTab, setLoginTab] = useState<'mobile' | 'email'>('mobile');

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 995;

  // Get URL from Nav Params
  const location = useLocation();
  const previousUrl: string = location?.state?.previousUrl;

  // Remove /en/ from URL and replace with User's Preferred Language
  const [sanitizedUrl, setSanitizedUrl] = useState<string>('');

  const sanitizeUrl = () => {
    let __url: string =
      previousUrl && previousUrl.split('/').slice(2).join('/');
    setSanitizedUrl(__url);
  };

  useEffect(() => {
    sanitizeUrl();
  }, [previousUrl]);

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 100);
  }, []);

  const loginUserClick = async () => {
    setEmailError('');
    setMobileError('');
    setPasswordError('');
    setError('');

    let isValid = true;

    if (loginTab === 'email' && email.trim() === '') {
      setEmailError(trans.email_required);
      isValid = false;
    }

    if (loginTab === 'mobile' && mobile.trim() === '') {
      setMobileError(trans.mobile_required);
      isValid = false;
    }

    if (password.trim() === '') {
      setPasswordError(trans.password_required);
      isValid = false;
    }

    if (!isValid) return;

    setShowPageLoader(true);

    const form =
      loginTab === 'mobile'
        ? { mobile_number: mobile, password }
        : {
            email,
            password,
          };

    try {
      const response =
        loginTab === 'mobile'
          ? await loginUserByMobile(form)
          : await loginUserByEmail(form);

      const accessToken = response.access_token;
      const refreshToken = response.refresh_token;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      if (sanitizedUrl) {
        window.location.href = `/${country}/${lang}/${sanitizedUrl}`;
      } else if (!sanitizedUrl) {
        window.location.href = `/${country}/${lang}`;
      }
    } catch (error: any) {
      console.log(error);
      const errorMessage = error?.response?.data?.messages
        ? error?.response?.data?.messages?.[0]
        : Array.isArray(error?.response?.data?.message)
        ? error?.response?.data?.message?.[0]
        : error?.response?.data?.message;
      const exception = error?.response?.data?.exception;
      if (errorMessage) {
        setError(errorMessage);
      } else if (exception) {
        setError(exception);
      } else {
        setError('Something went wrong, please try again later.');
      }
    } finally {
      setShowPageLoader(false);
    }
  };

  return (
    <>
      <MetaTag href={`/${country}/${lang}/login`} metaKey="login" />
      {showPageLoader && <PageLoader />}
      <main id="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="login-holder">
                {!isMobile && (
                  <Banner className="login-banner" section_name="E1" />
                )}
                <div className="login-info">
                  <form className="login-form">
                    <input
                      type="hidden"
                      name="_token"
                      value="nO0gaDnHWZIPVaOSzmehTeUzyQ6prAS4Sh2baetC"
                    />
                    <div className="login-logo">
                      <img src="/images/logo.jpg" alt="One Autocar" />
                    </div>
                    <h2>{trans.login}</h2>
                    <Tabs
                      className="login-tabs"
                      value={loginTab}
                      onChange={(e, val) => setLoginTab(val)}
                      textColor="primary"
                      sx={{
                        '.Mui-selected': {
                          fontFamily: 'Lato',
                          color: '#00d6bc !important',
                        },
                      }}
                      variant="fullWidth"
                      TabIndicatorProps={{
                        style: { backgroundColor: '#00d6bc' },
                      }}
                      // indicatorColor="primary"
                      aria-label="secondary tabs example"
                    >
                      <Tab
                        value="mobile"
                        label="Mobile"
                        className="login-tab"
                      />
                      <Tab value="email" label="Email" className="login-tab" />
                    </Tabs>
                    <div className="form-group">
                      {loginTab === 'email' && (
                        <>
                          <input
                            ref={emailRef}
                            type="email"
                            className="form-control"
                            value={email}
                            onKeyDown={event =>
                              event.key === 'Enter' &&
                              passwordRef.current?.focus()
                            }
                            onChange={(e: any) => setEmail(e.target.value)}
                            placeholder={trans.email}
                          />
                          {emailError && (
                            <span className="errorMessage">
                              {parse(emailError)}
                            </span>
                          )}
                        </>
                      )}
                      {loginTab === 'mobile' && (
                        <>
                          <PhoneInput
                            country={country === 'qa' ? 'qa' : 'sa'}
                            inputClass="custom-tel"
                            value={mobile}
                            onChange={setMobile}
                            placeholder=""
                            masks={{ qa: '........' }}
                          />
                          {mobileError && (
                            <span className="errorMessage">
                              {parse(mobileError)}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        ref={passwordRef}
                        type="password"
                        className="form-control"
                        onKeyDown={event =>
                          event.key === 'Enter' && loginUserClick()
                        }
                        value={password}
                        onChange={(e: any) => setPassword(e.target.value)}
                        placeholder={trans.password}
                      />
                      {passwordError && (
                        <span className="errorMessage">
                          {parse(passwordError)}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <span className="errorMessage">{parse(error)}</span>
                    </div>
                    <div className="form-group button">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={loginUserClick}
                        disabled={
                          passwordRef.current?.value === '' &&
                          ((loginTab === 'email' &&
                            emailRef.current?.value === '') ||
                            loginTab === 'mobile' ||
                            mobile === '')
                        }
                      >
                        {trans.sign_in}
                      </button>
                    </div>
                    <div className="form-group">
                      <Link
                        to={`/${country}/${lang}/forgot-password`}
                        className="forget-password"
                      >
                        {trans.forgot_password}
                      </Link>
                    </div>
                    <div className="need-account">
                      <strong>{trans['need_an_account?']}</strong>
                      <Link
                        to={`/${country}/${lang}/register`}
                        className="btn btn-primary"
                      >
                        {trans.sign_up}
                      </Link>
                    </div>
                  </form>
                </div>
                {isMobile && (
                  <Banner className="login-banner" section_name="E1" />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
