import { createContext, useState } from 'react';
import CarBrand from '../models/listings/CarBrand';
import { getBrandsWithDeals } from '../services/ApiService';
import { IBrand } from '../types/resources/cars';

interface DataContextProps {
  brands: Array<IBrand>;
  refreshBrands: () => void;
  setBrands: (brands: Array<IBrand>) => void;
  isLoading: boolean;
}

export const DataContext = createContext<DataContextProps>({
  brands: [],
  refreshBrands: () => {},
  setBrands: () => {},
  isLoading: false,
});

export function DataProvider({ children }) {
  const [brands, setBrands] = useState<Array<IBrand>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const refreshBrands = async () => {
    if (!brands || brands.length == 0) {
      setIsLoading(true);
      const response = await getBrandsWithDeals();
      setBrands(response);
      setIsLoading(false);
    }
    return brands;
  };

  return (
    <DataContext.Provider
      value={{ brands, refreshBrands, setBrands, isLoading }}
    >
      {children}
    </DataContext.Provider>
  );
}
