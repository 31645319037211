import { useState } from 'react';
import { BrandCardImageSkeleton } from '../../../common/skeletons/BrandCardSkeleton';
import styled from '@emotion/styled';

export interface BrandCardImageProps {
  defaultSrc?: string;
  src: string | undefined;
  alt?: string;
  class?: string;
  onClick?: any;
  style?: React.CSSProperties;
  onLoad?: any;
  width?: number;
  height?: number;
}
const LogoHoldImg = styled('img')(() => ({
  display: 'block',
  height: 'auto',
  maxWidth: '100%',
  maxHeight: '80px',
}));

export default function BrandCardImage({
  src,
  alt,
  class: ClassName,
  height,
  onClick,
  style,
  width,
}: BrandCardImageProps) {
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <>
      {isImageLoading && <BrandCardImageSkeleton />}
      <LogoHoldImg
        src={src}
        className={ClassName}
        onClick={onClick}
        alt={alt}
        style={{ ...style, display: isImageLoading ? 'none' : 'block' }}
        onLoad={() => {
          setIsImageLoading(false);
        }}
        width={width}
        height={height}
      />
    </>
  );
}
