import authenticatedApi from '../../instance/ApiInstance';
import { ICarType } from '../../types/resources/cars';
import { TypeName } from '../classes/Type';

const typesEndpoint = '/v2/resources/cars/types';

export const getCarTypes = async () => {
  try {
    const response = await authenticatedApi.get(typesEndpoint);
    const data: ICarType[] = response.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const getModelTypesNames = async (
  modelId?: number,
): Promise<TypeName[]> => {
  try {
    const response = await authenticatedApi.get(
      `api/v2/brand/models/${modelId}/types/names`,
    );

    const typeNames = response.data.data.map(
      (typeName: any) => new TypeName(typeName),
    );

    return typeNames;
  } catch (error) {
    throw error;
  }
};

export const getTypeNames = async (): Promise<TypeName[]> => {
  try {
    const response = await authenticatedApi.get(`/v2/resources/cars/types`);

    const typeNames = response.data.map(
      (typeName: any) => new TypeName(typeName),
    );

    return typeNames;
  } catch (error) {
    throw error;
  }
};
