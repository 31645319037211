import styled from '@emotion/styled';

type ListButtonProps = {
  isList: boolean;
  onToggle: () => void;
  className?: string;
};

const Styled = styled('button')(() => ({
  '@media (max-width: 992px)': {
    display: 'none',
  },
}));

const ListButton = (props: ListButtonProps) => {
  return (
    <Styled
      style={{ padding: '0', marginRight: '10px' }}
      className="btn grid-list-btn"
      onClick={props.onToggle}
    >
      {props.isList ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 96 960 960"
          fill="#ffffff"
          width="32"
          height="32"
        >
          <path d="M180 546q-24 0-42-18t-18-42V276q0-24 18-42t42-18h600q24 0 42 18t18 42v210q0 24-18 42t-42 18H180Zm0 390q-24 0-42-18t-18-42V666q0-24 18-42t42-18h600q24 0 42 18t18 42v210q0 24-18 42t-42 18H180Z" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 96 960 960"
          fill="#ffffff"
          width="32"
          height="32"
        >
          <path d="M180 546q-24 0-42-18t-18-42V276q0-24 18-42t42-18h600q24 0 42 18t18 42v210q0 24-18 42t-42 18H180Zm0-60h600V276H180v210Zm0 450q-24 0-42-18t-18-42V666q0-24 18-42t42-18h600q24 0 42 18t18 42v210q0 24-18 42t-42 18H180Zm0-60h600V666H180v210Zm0-600v210-210Zm0 390v210-210Z" />
        </svg>
      )}
    </Styled>
  );
};

export default ListButton;
