import { CarListing } from '../../../models/listings/cars/CarListing';
import { IUsedCar } from '../../../types/cars/used_car';
import { UsedCarsFilter } from '../UsedCarsFilter';

const sliceIntoChunks = (arr: Array<any>, chunkSize: number) => {
  const res: Array<any> = [];
  for (let i = 0; i < arr?.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const useInsertBanners = () => {
  const insertBanners = (interval: number, usedCars: Array<IUsedCar>) => {
    const temp: Array<any> = sliceIntoChunks(usedCars, interval);
    const carBannerArray: Array<any> = [];
    temp.forEach((chunk, index) => {
      const pushedChunk =
        index + 1 == temp.length ? chunk : [...chunk, 'banner'];
      carBannerArray.push(...pushedChunk);
    });

    return carBannerArray;
  };

  return { insertBanners };
};

export const useCreateQuery = (
  usedCarFilter: UsedCarsFilter,
  currentPage: number,
  orderBy: string,
  order: string,
) => {
  const createQuery = (featured: any = null, page: any = null) => {
    var query = '?';
    if (usedCarFilter) {
      if (usedCarFilter.brand && usedCarFilter.brand !== 'all')
        query += '&brand_id=' + usedCarFilter.brand;
      if (usedCarFilter.model && usedCarFilter.model !== 'all')
        query += '&model_id=' + usedCarFilter.model;
      if (usedCarFilter.type && usedCarFilter.type !== 'all')
        query += '&type_id=' + usedCarFilter.type;
      if (usedCarFilter.minYear) query += '&from_year=' + usedCarFilter.minYear;
      if (usedCarFilter.maxYear) query += '&to_year=' + usedCarFilter.maxYear;
      if (usedCarFilter.minMileage)
        query += '&min_mileage=' + usedCarFilter.minMileage;
      if (usedCarFilter.maxMileage)
        query += '&max_mileage=' + usedCarFilter.maxMileage;
      if (usedCarFilter.minPrice)
        query += '&min_price=' + usedCarFilter.minPrice;
      if (usedCarFilter.maxPrice)
        query += '&max_price=' + usedCarFilter.maxPrice;

      if (usedCarFilter.dealerId)
        query += '&dealer_id=' + usedCarFilter.dealerId;
      if (usedCarFilter.engineType)
        query += '&engine_type=' + usedCarFilter.engineType;
      if (usedCarFilter.engineSize)
        query += '&engine_size=' + usedCarFilter.engineSize;
      if (usedCarFilter.cylinders)
        query += '&cylinders=' + usedCarFilter.cylinders;
      if (usedCarFilter.transmission)
        query += '&transmission=' + usedCarFilter.transmission;
      if (usedCarFilter.sellerType)
        query += '&seller_type=' + usedCarFilter.sellerType;
      if (usedCarFilter.seatMaterial)
        query += '&seat_material=' + usedCarFilter.seatMaterial;
      if (usedCarFilter.condition)
        query += '&condition=' + usedCarFilter.condition;
      if (usedCarFilter.exteriorColor)
        query += '&exterior_color=' + usedCarFilter.exteriorColor;
      if (usedCarFilter.interiorColor)
        query += '&interior_color=' + usedCarFilter.interiorColor;
    }

    query += '&perPage=' + page;
    query += '&page=' + (currentPage + 1);
    query += `&orderby=${orderBy}`;
    query += `&order=${order}`;
    query += `&is_featured=${featured}`;

    return query;
  };

  return { createQuery };
};

export const useHandleSetQueries = (
  createQuery: (featured: any, page: any) => string,
  setUsedCarQuery: (createQuery: any) => void,
  setFeaturedUsedCarQuery: (createQuery: any) => void,
  currentPage: number,
  featuredPagesCount: number,
  pageSize: number,
) => {
  const handleSetQueries = () => {
    setUsedCarQuery(createQuery('false', pageSize));
    if (featuredPagesCount && currentPage >= featuredPagesCount) return;
    setFeaturedUsedCarQuery(createQuery('true', 20));
  };
  return { handleSetQueries };
};

export type TSortBy =
  | 'status_changed_at'
  | 'price'
  | 'mileage'
  | 'year'
  | 'price_per_day';
export type TOrder = 'asc' | 'desc';

export const useSortCars = (
  setSort: (newValue: { sortBy: TSortBy; order: TOrder }) => void,
) => {
  const sortCars = (sortBy: any) => {
    switch (sortBy) {
      case 'newest':
        setSort({ sortBy: 'status_changed_at', order: 'desc' });
        break;
      case 'oldest':
        setSort({ sortBy: 'status_changed_at', order: 'asc' });
        break;
      case 'lowp':
        setSort({ sortBy: 'price', order: 'asc' });
        break;
      case 'lowpr':
        setSort({ sortBy: 'price_per_day', order: 'asc' });
        break;
      case 'highp':
        setSort({ sortBy: 'price', order: 'desc' });
        break;
      case 'highpr':
        setSort({ sortBy: 'price_per_day', order: 'desc' });
        break;
      case 'lowm':
        setSort({ sortBy: 'mileage', order: 'asc' });
        break;
      case 'highm':
        setSort({ sortBy: 'mileage', order: 'desc' });
        break;
      case 'latestm':
        setSort({ sortBy: 'year', order: 'desc' });
        break;
      case 'oldestm':
        setSort({ sortBy: 'year', order: 'asc' });
        break;
      default:
        break;
    }
  };

  return { sortCars };
};

export const useHandleFavouriteCarClick = (
  userData: any,
  addFavorite: (carId: number) => Promise<any>,
  deleteFavorite: (carId: number) => Promise<any>,
  setShowSignIn: (newValue: boolean) => void,
) => {
  const handleFavouriteCarClick = (currentElement: any, carId: number) => {
    if (userData) {
      if (currentElement.currentTarget.classList.contains('active')) {
        deleteFavorite(carId);
        currentElement.currentTarget.classList.remove('active');
      } else {
        addFavorite(carId);
        currentElement.currentTarget.classList.add('active');
      }
    } else setShowSignIn(true);
  };
  return { handleFavouriteCarClick };
};
