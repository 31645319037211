import './PageLoader-styles.css';

export default function PageLoader({
  loadSectionOnly = true,
  icon = false,
  style,
}: {
  loadSectionOnly?: boolean;
  icon?: boolean;
  style?: React.CSSProperties;
}) {
  if (icon) {
    return <div id="loader"></div>;
  }

  return (
    <div
      id="loader"
      style={{
        ...style,
        background: `url(/images/page-loader.gif) no-repeat center`,
        position: loadSectionOnly ? 'absolute' : 'fixed',
        left: loadSectionOnly ? '0' : 'unset',
        zIndex: loadSectionOnly ? '1' : '9999',
      }}
    ></div>
  );
}
