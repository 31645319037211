import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const WebsiteLayout = () => {
  const [isHeaderFooterVisible, setIsHeaderFooterVisible] = useState(false);
  const current = useLocation();
  useEffect(() => {
    setIsHeaderFooterVisible(
      !current.pathname.includes('thank-you') &&
        !current.pathname.includes('landing-page'),
    );
  }, [current]);

  useEffect(() => {
    if (typeof (window as any).teads?.cleanup === 'function') {
      (window as any).teads.cleanup();
    }

    setTimeout(() => {
      (function (d, p) {
        var js,
          s = d.getElementsByTagName('script')[0];
        if (s && s.parentNode) {
          js = d.createElement('script');
          js.className = 'teads';
          js.async = true;
          js.type = 'text/javascript';
          js.src = '//a.teads.tv/page/' + p + '/tag';
          s.parentNode.insertBefore(js, s);
        }
      })(window.document, '206952');
    }, 4000);
  }, [current]);

  if (!isHeaderFooterVisible) return <Outlet />;
  else
    return (
      <>
        <Outlet />
      </>
    );
};

export default WebsiteLayout;
