import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { RentCarListingDetails } from './components';
import { useRentCarDetails } from './hooks';
import { BikeListingDetailsSkeleton } from '../UsedBikes/components/BikeDetailsSkeletons';

const RentalCarsDetails = (props: any) => {
  const responsiveFeatured = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  };
  const { lang, trans } = useContext(AppContext);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const params = useParams();
  const {
    data: carDetails,
    isLoading,
    isError,
  } = useRentCarDetails(
    params && params.id ? Number(params.id) : Number(params.carId),
  );

  useEffect(() => {
    if (carDetails && carDetails.id) {
      setShowModal(false);
    } else if (isError || !isLoading) {
      navigate(`/${lang}/rent-cars`);
    }
  }, [isError, navigate]);

  if (isLoading) {
    return (
      <BikeListingDetailsSkeleton
        trans={trans}
        responsiveFeatured={responsiveFeatured}
      />
    );
  }

  return (
    <>
      {carDetails && carDetails.id && (
        <RentCarListingDetails
          car={carDetails}
          signInModalInfo={{
            isVisible: showModal,
            setVisibility: setShowModal,
          }}
          isLoading={isLoading}
          responsiveFeatured={responsiveFeatured}
        />
      )}
    </>
  );
};

export default RentalCarsDetails;
