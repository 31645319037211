import ReactDOM from 'react-dom/client';
import App, { queryClient } from './App';
import reportWebVitals from './reportWebVitals';
import './styles/css/responsive.css';
import './styles/css/bootstrap.css';
import './styles/css/font-awesome.min.css';
import './styles/css/main.css';
import './styles/css/lang/au.css';
import 'react-phone-input-2/lib/style.css';
import './styles/css/LatoFont.css';
import 'react-notifications-component/dist/theme.css';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { AppProvider } from './contexts/AppContext';
import './config/teadsSetup';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <App />
      </AppProvider>
    </QueryClientProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
