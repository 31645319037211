import styled from '@emotion/styled';

export const LogoBox = styled('div')(() => ({
  width: '100%',
  height: '100%',
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 0 0 1px #E5E5E5',
  margin: '1px',
  border: '2px solid transparent',
  ':hover': {
    borderRadius: '5px',
    borderColor: '#00D6BC',
    cursor: 'pointer',
  },
}));

export const WrapBox = styled('div')(() => ({
  overflow: 'hidden',
}));

export const LogoHold = styled('div')(() => ({
  margin: '0 auto',
  width: '80px',
  height: '92px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const LogoBoxText = styled('h3')(() => ({
  display: 'block',
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 700,
  color: '#000',
  textAlign: 'center',
  padding: '10px 0 5px',
}));
