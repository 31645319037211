import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const FinancingFrame = styled('div')(() => ({
  position: 'relative',
  paddingInline: '15px',
  '@media screen and (max-width: 767px)': {
    paddingInline: '30px',
  },
}));

export const FinancingFrameH2 = styled('h2')(() => ({
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: 1.4,
  marginBottom: '30px',
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const FinancingSection = styled('div')(() => ({
  marginInline: '-1%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
}));

export const FinancingColumn = styled('div')(() => ({
  width: '23%',
  margin: '0 1% 2%',
  '@media screen and (max-width: 480px)': {
    width: '48%',
  },
}));

export const FinancingColumnBox = styled(Link)(() => ({
  width: '100%',
  minHeight: '170px',
  padding: '10px',
  display: ['-webkit-box', '-ms-flexbox', 'flex'],
  '-webkit-box-align': 'center',
  '-ms-flex-align': 'center',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 2px 5px 0 rgb(0 0 0 / 18%)',
  borderRadius: '5px',
}));

export const ShowroomHeader = styled('header')(() => ({
  paddingBlock: '40px',
  textAlign: 'center',
}));

export const ShowroomHeaderImgBox = styled('div')(() => ({
  maxWidth: '294px',
  margin: '0 auto 10px',
}));

export const ShowroomHeaderImgBoxImg = styled('img')(() => ({
  maxHeight: '42px',
}));

export const ShowroomHeaderH1 = styled('h1')(() => ({
  fontSize: '25px',
  fontWeight: 300,
  textTransform: 'capitalize',
}));

export const ChooseCategory = styled('div')(() => ({
  paddingTop: '40px',
  marginBottom: '150px',
  ':before': {
    position: 'absolute',
    left: '-9999px',
    right: '-9999px',
    top: 0,
    bottom: '70px',
    content: '""',
    backgroundColor: '#00D6BC',
    zIndex: -1,
  },
}));

export const ChooseCategoryH2 = styled('h2')(() => ({
  fontSize: '28px',
  textAlign: 'center',
  marginBottom: '40px',
  lineHeight: 1.4,
  fontWeight: 700,
  color: '#fff',
}));

export const ChooseBlock = styled(Link)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  padding: '15px',
  boxShadow: '0 5px 4px 0 rgba(0, 0, 0, 0.15)',
  marginInline: '2%',
  backgroundColor: '#fff',
  minWidth: '235px',
  minHeight: '270px',
}));

export const ChooseBlockChoosing = styled('div')(() => ({
  maxWidth: '113px',
  margin: '0 auto 20px',
}));

export const ChooseBlockChoosingImg = styled('img')(() => ({
  maxHeight: '102px',
}));

export const ChooseBlockBtnSecondary = styled('span')(() => ({
  borderRadius: '5px',
  fontSize: '24px',
  fontWeight: '700',
  paddingBlock: '2px',
  minWidth: '130px',
}));

export const BrowseBrands = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  margin: '0 -1%',
  paddingLeft: '2%',
  paddingRight: '2%',
  '@media screen and (max-width: 1199px)': {
    paddingLeft: '3%',
    paddingRight: '3%',
  },
}));

export const NewUsedShowRoomCarsFormHolder = styled('div')(() => ({
  backgroundColor: 'transparent',
  margin: '0 15px 30px',
}));

export const CarsFormHolder = styled('div')(() => ({
  backgroundColor: '#00D6BC',
  borderRadius: '10px',
  margin: '30px 15px 40px',
  padding: '25px 15px 12px',
  overflow: 'hidden',
  '@media (max-width: 1199px)': {
    paddingBottom: '20px',
    marginBottom: '30px',
    '&.searchcar': {
      overflow: 'hidden',
      paddingTop: '10px',
    },
  },
  '@media (max-width: 767px)': {
    padding: '20px 15px 10px',
    marginLeft: '0',
    marginRight: '0',
  },
  '&.cars-form-holder': {
    backgroundColor: 'transparent',
    margin: '0 15px 30px',
  },
  '&.searchcar': {
    marginBottom: '10px',
    overflow: 'visible',
  },
  '.lang-au &': {
    textAlign: 'right',
  },
}));
