import { useQuery } from 'react-query';
import {
  IGetModels,
  getAllModels,
  getBrandModelsNames,
  getModelsForUsedCars,
  getModels,
  getModelsForReviews,
} from '../apis/model.apis';
import { ModelName } from '../classes/Model';
import { IModel } from '../../types/resources/cars';

export const useBrandModelsNames = (brandId?: number) => {
  return useQuery({
    queryKey: ['model-names', brandId],
    queryFn: (): Promise<ModelName[]> => getBrandModelsNames(brandId),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: brandId !== undefined,
  });
};

export const useGetModelsWithUsedCars = (data: IGetModels) => {
  const { enabled, brands } = data;
  return useQuery({
    queryKey: ['models-used-cars', brands],
    queryFn: () => getModels({ brands }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
    placeholderData: [],
  });
};

export const useModelsForUsedCars = ({ brandIds }: { brandIds?: number[] }) => {
  return useQuery({
    queryKey: ['models-for-used-cars', brandIds],
    queryFn: (): Promise<IModel[]> => getModelsForUsedCars(brandIds),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: brandIds !== undefined && brandIds.length > 0,
  });
};

export const useModelsForReviews = ({
  brandIds,
  language,
}: {
  brandIds: number[];
  language: string;
}) => {
  return useQuery({
    queryKey: ['models-for-reviews', brandIds, language],
    queryFn: (): Promise<IModel[]> => getModelsForReviews(brandIds, language),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: brandIds !== undefined && brandIds.length > 0,
  });
};

export const useModels = ({
  brand,
  type,
  enabled = true,
}: {
  brand: number | undefined;
  type?: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['models', brand, type],
    queryFn: (): Promise<IModel[]> => getAllModels({ brand, type }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!brand && enabled,
  });
};
