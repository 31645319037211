export const validateMobileNumber = (mobileNumber: string) => {
  mobileNumber = mobileNumber.replace(/^(\+974|974)(?=\d)/, '');
  mobileNumber = mobileNumber.replace(/\s/g, '');

  return (
    mobileNumber.startsWith('3') ||
    mobileNumber.startsWith('5') ||
    mobileNumber.startsWith('6') ||
    mobileNumber.startsWith('7')
  );
};
