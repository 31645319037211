import { useState } from 'react';
import { NewCarsDetailsPageSliderDealerLogoSkeleton } from '../NewCarsCardsSkeletons';
import { styled } from '@mui/material';

const FeaturedDetailsImgStyled = styled('img')(() => ({
  '@media (max-width: 767px)': {
    width: '100px',
  },
  '@media (min-width: 768px)': {
    width: '200px',
  },
  '@media (max-width: 600px)': {
    width: '100px',
  },
}));
interface NewCarDetailPageDealerLogoProps {
  defaultSrc?: string;
  src: string | undefined;
  alt?: string;
  class?: string;
  onClick?: any;
  style?: React.CSSProperties;
  onLoad?: any;
  width?: number;
  height?: number;
}

export const NewCarDetailPageDealerLogo = ({
  src,
  alt,
  class: className,
  onClick,
  style,
  width,
  height,
}: NewCarDetailPageDealerLogoProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <>
      {isImageLoading && <NewCarsDetailsPageSliderDealerLogoSkeleton />}
      <FeaturedDetailsImgStyled
        src={src}
        className={className}
        onClick={onClick}
        alt={alt}
        style={{ ...style, display: isImageLoading ? 'none' : 'block' }}
        onLoad={() => {
          setIsImageLoading(false);
        }}
        width={width}
        height={height}
      />
    </>
  );
};
