import {
  DetailsHeader,
  DetailsHeaderH1,
  DetailsHeaderH2,
  DetailsHeaderHolder,
  RamadanModelLogo,
} from '../../../../common/styles/CommonStyles';
import { IModel } from '../../../../types/resources/cars';
import { NewCarsDetailsPageHeaderSkeleton } from '../NewCarsCardsSkeletons';

interface IHeaderProps {
  selectedModel: IModel;
  isLoading?: boolean;
}

export const Header = ({ selectedModel, isLoading }: IHeaderProps) => {
  if (isLoading) {
    return <NewCarsDetailsPageHeaderSkeleton />;
  }
  return (
    <DetailsHeader className="d-flex justify-content-between w-100">
      <DetailsHeaderHolder>
        <h1>
          <DetailsHeaderH1>{selectedModel.brand.name}</DetailsHeaderH1>
          <DetailsHeaderH2 className="detail-swtich">
            {selectedModel.name}
          </DetailsHeaderH2>
        </h1>
      </DetailsHeaderHolder>
      <div style={{ width: '110px' }}>
        <RamadanModelLogo
          src={selectedModel.brand.white_logo}
          alt={`${selectedModel.brand.slug}-logo`}
        />
      </div>
    </DetailsHeader>
  );
};
