import authenticatedApi from '../../instance/ApiInstance';
import {
  IBatteryCapacity,
  ICylinder,
  IEngineSize,
  IEngineType,
  IOptionalFeature,
  ITransmission,
  IVariant,
  IBatteryRange,
} from '../../types/resources/cars';

const engineTypeEndpoint = '/v2/resources/cars/engine-types';

export interface IEngineTypeResources {
  battery_capacities: IBatteryCapacity[];
  cylinders: ICylinder[];
  engine_sizes: IEngineSize[];
  optional_features: IOptionalFeature[];
  transmissions: ITransmission[];
  variants: IVariant[];
  battery_ranges: IBatteryRange[];
}

export interface IEngineTypeWithResources
  extends IEngineType,
    IEngineTypeResources {}

export const getEngineTypesWithResources = async (): Promise<
  IEngineTypeWithResources[]
> => {
  const response = await authenticatedApi.get(
    `${engineTypeEndpoint}/resources`,
  );
  return response.data;
};
