import authenticatedApi from '../../instance/ApiInstance';
import { IEngineTypeWithResources } from '../../types/resources/cars';

const engineTypesEndpoint = '/v2/resources/cars/engine-types/resources';

export const getEngineTypesWithResources = async (): Promise<
  IEngineTypeWithResources[]
> => {
  const response = await authenticatedApi.get(engineTypesEndpoint);
  return response.data;
};
