import authenticatedApi from '../../instance/ApiInstance';

export const getTransmissionNames = async () => {
  try {
    const response = await authenticatedApi.get(
      '/v2/resources/cars/transmissions',
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
