import { useQuery } from 'react-query';
import { IModel } from '../../../types/resources/cars';
import { IGetAllBikeModels, getModelsForUsedBikes } from '../../apis/bikes';

export const useModelsForUsedBikes = ({
  params,
  enabled,
}: {
  params: IGetAllBikeModels;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['models-for-used-bikes', params],
    queryFn: (): Promise<IModel[]> =>
      getModelsForUsedBikes({ brand: params.brand, type: params.type }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!params.brand && enabled,
  });
};
