import { format } from 'date-fns';
import parse from 'html-react-parser';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import Banner from '../../Banners/BannerComponent';
import MetaTag, {
  metaDataDescription,
  metaDataTitle,
} from '../../../common/meta/MetaTag';
import { useGetArticlesBySlug, useGetMoreArticles } from '../hooks/useArticles';
import { MoreArticles } from '../components';
import { ArticlesDetailsImage } from '../components/ArticlesDetailsImage';
import { ArticleDetailSkeleton } from '../components/ArticclesSkeleton/ArticlesSkeleton';
import {
  ColLg12,
  FeaturedDetailsPage,
  MainHead,
  MainHeadContainer,
  MainHeadH3,
  Row,
} from '../../../common/styles/CommonStyles';
import {
  ArticleDateNavLi,
  ArticleDateNavUl,
} from '../ArticleDetailStyledComponent';
import './ArticleDetailComponent-styles.css';

export default function ArticleDetail() {
  const { lang, trans, lightImageLoadingPlaceholder } = useContext(AppContext);
  const params = useParams();

  const { data: selectedArticle, isLoading: articlesLoading } =
    useGetArticlesBySlug(params.name ?? '');

  const { data: allArticles, isLoading: moreArticlesLoading } =
    useGetMoreArticles(selectedArticle?.slug);

  const isLoading = articlesLoading || moreArticlesLoading;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.name]);

  if (!selectedArticle && isLoading) {
    return <ArticleDetailSkeleton trans={trans} />;
  }
  if (!selectedArticle) return null;

  return (
    <>
      <MetaTag
        title={metaDataTitle(selectedArticle)}
        description={metaDataDescription(selectedArticle)}
        metaKey="articles"
        href={`/${lang}/article/${selectedArticle?.slug}`}
        params={{ '%NAME%': selectedArticle?.title }}
      />

      <main id="main">
        <MainHead>
          <MainHeadContainer className="container">
            <Row>
              <ColLg12>
                <MainHeadH3>{trans.articles}</MainHeadH3>
              </ColLg12>
            </Row>
          </MainHeadContainer>
        </MainHead>
        {selectedArticle?.id !== undefined && (
          <FeaturedDetailsPage className="container articles">
            <div className="article-details-info">
              <h1>{selectedArticle.title}</h1>
              <ArticleDateNavUl className="article-date-nav">
                <ArticleDateNavLi>{trans.by} ONE AUTOCAR</ArticleDateNavLi>
                <ArticleDateNavLi>
                  {format(new Date(selectedArticle?.created_at), 'MMM d, yyyy')}
                </ArticleDateNavLi>
              </ArticleDateNavUl>
              <div className="imgbox">
                <ArticlesDetailsImage
                  src={selectedArticle?.image ?? `/images/callus-logo.png`}
                  alt={`${selectedArticle?.slug}-image`}
                />
              </div>
              <span className="article-description">
                {parse(selectedArticle?.description)}
              </span>
              <Banner
                section_name="D2"
                className="default-image-banner"
              ></Banner>
            </div>
            <MoreArticles
              allArticles={allArticles}
              lang={lang}
              darkImageLoadingPlaceholder={lightImageLoadingPlaceholder}
              trans={trans}
              isLoading={isLoading}
            />
          </FeaturedDetailsPage>
        )}
      </main>
    </>
  );
}
