import { useQuery } from 'react-query';
import { TBrandParams } from '../brand.hooks.';
import { getBikeBrands } from '../../apis/bikes';
import { AppContext } from '../../../contexts/AppContext';
import { useContext } from 'react';

interface IUseGetBikeBrands {
  params?: TBrandParams;
  enabled?: boolean;
}

export const useBikeBrands = ({ params, enabled }: IUseGetBikeBrands) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['bike-brand-names', params, enabled, lang],
    queryFn: () => getBikeBrands(params),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: enabled,
  });
};
