export const WarrantyIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="30"
      height="37"
      viewBox="0 0 30 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.4612 22.4683C26.4612 21.7517 27.0428 21.1701 27.7594 21.1701C28.476 21.1701 29.0576 21.7517 29.0576 22.4683V23.7665C29.0576 26.6991 26.8182 29.4915 23.9829 31.6906C20.1039 34.6986 15.2565 36.6536 15.2565 36.6536C14.9449 36.7796 14.597 36.7796 14.2854 36.6536C14.2854 36.6536 9.438 34.6986 5.55901 31.6906C2.72376 29.4915 0.484375 26.6991 0.484375 23.7665C0.484375 18.9736 0.492168 12.6994 0.496062 9.79275C0.498659 8.31671 1.33599 6.96788 2.65755 6.31099L13.1171 1.11304C14.2153 0.566502 15.5058 0.570412 16.6001 1.12084L26.913 6.30973C28.228 6.9705 29.0576 8.31672 29.0576 9.78887V17.2755C29.0576 17.9921 28.476 18.5737 27.7594 18.5737C27.0428 18.5737 26.4612 17.9921 26.4612 17.2755V9.78887C26.4612 9.29815 26.1847 8.84896 25.7459 8.62827L15.4343 3.4407C15.0682 3.25635 14.6386 3.25637 14.2725 3.43812L3.81294 8.63607C3.37285 8.85547 3.09374 9.30466 3.09244 9.79667C3.08855 12.702 3.08076 18.9749 3.08076 23.7665C3.08076 25.9929 4.99689 27.97 7.15059 29.6395C10.0404 31.8802 13.5104 33.4912 14.7723 34.0404C16.0315 33.4912 19.5016 31.8802 22.3914 29.6395C24.5451 27.97 26.4612 25.9929 26.4612 23.7665V22.4683ZM8.08269 19.4877C7.54264 19.0164 7.48552 18.1959 7.95677 17.6559C8.42801 17.1159 9.24846 17.0588 9.78851 17.53L13.378 20.6573L19.6625 13.8016C20.1468 13.2732 20.9685 13.2382 21.4969 13.7224C22.0252 14.2066 22.0603 15.0284 21.5761 15.5567L14.436 23.3459C13.9609 23.8651 13.1573 23.9093 12.6264 23.4471L8.08269 19.4877Z"
        fill={color}
      />
    </svg>
  );
};
