import { Skeleton } from '@mui/material';
import './ramadanSkeletonStyle.css';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import { MainHeadingH2 } from '../../../../common/styles/CommonStyles';

export const RamadanDetailHeaderSkeleton = () => {
  return (
    <header className="ramadan-details-header d-flex w-100">
      <div>
        <Skeleton
          variant="rectangular"
          className="ramadan-model-logo"
          height={60}
        />
      </div>
      <div className="m-auto">
        <Skeleton
          variant="rectangular"
          className="ramadan-dealer-logo"
          height={60}
        />
      </div>
    </header>
  );
};

export const RamadanFeaturedDetailsSkeleton = () => {
  return (
    <div className="ramadan-details d-flex flex-column w-100 pl-3">
      <h2>
        <Skeleton width={700} />
      </h2>
      <div className="detail">
        <Skeleton width={720} />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
      <div className="d-flex flex-row-reverse justify-content-between w-100 ramadan-featured-details">
        <div className="holder">
          <div className="d-flex flex-end ramadan-offer-btn">
            <Skeleton width={300} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const RamadanSliderSkeleton = () => {
  return (
    <div className="ramadan-slider-section-carousel pb-3">
      <Skeleton variant="rectangular" width={360} height={360} />
    </div>
  );
};

export const RamadanOfferListImageSkeleton = () => {
  return (
    <div className="w-100 h-100 overflow-hidden">
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={152}
        style={{
          borderRadius: '15px',
          overflow: 'hidden',
        }}
        className="ramadan-carousel-home-image"
      />
    </div>
  );
};

export const RamadanOfferPageListImageSkeleton = () => {
  return (
    <div className="w-100 h-100 overflow-hidden">
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={163}
        style={{
          borderRadius: '15px',
          overflow: 'hidden',
        }}
        className="page-list-ramadan-image"
      />
    </div>
  );
};

export const RamadanOfferListSkeleton = ({ trans }: { trans: any }) => {
  return (
    <main id="main">
      <div className="container ramadan-list-offers">
        <section className="browse-offers-section container">
          <>
            <MainHeadingH2>{trans.ramadan_offers}</MainHeadingH2>
            <div className="browse-offers">
              <>
                {[...Array(6)].map((_, i) => (
                  <div key={i} className="ramadan-offer-card">
                    <RamadanOfferPageListImageSkeleton />
                    <div className="ramadan-card-content-container">
                      <div className="ramadan-card-content">
                        <Skeleton variant="text" width={150} height={10} />
                        <Skeleton
                          variant="rectangular"
                          width={50}
                          height={50}
                        />
                      </div>
                    </div>
                    <div className="ramadan-card-content-m-container">
                      <Skeleton variant="text" width={150} height={15} />
                    </div>
                  </div>
                ))}
              </>
            </div>
          </>
        </section>
      </div>
    </main>
  );
};

export const RamadanOfferHomeCarouselSkeleton = ({
  lang,
  trans,
  responsiveModels,
}: {
  lang: string;
  trans: any;
  responsiveModels: any;
}) => {
  return (
    <div className="ramadan-info-block">
      <div className="header-holder style">
        <div className="ramadan-view">
          <h2>{trans.ramadan_offers}</h2>
          <div className="ramadan-view-all">
            <Link to={`/${lang}/ramadan-offers`} className="view">
              {trans.view_all}
            </Link>
          </div>
        </div>
      </div>
      <div className="ramadan-slider-block">
        <Carousel
          responsive={responsiveModels}
          infinite={true}
          swipeable={true}
          draggable={true}
          rtl={lang == 'ar'}
          partialVisbile={true}
          className="ramadan-offers-carousel"
          customLeftArrow={<div className="swiper-button-prev"></div>}
          customRightArrow={<div className="swiper-button-next"></div>}
        >
          {[...Array(9)].map((_, i) => (
            <div key={i} className="ramadan-offer-card-carousel">
              <RamadanOfferListImageSkeleton />
              <div className="ramadan-card-content-container">
                <div className="ramadan-card-content">
                  <Skeleton variant="text" width={150} height={10} />
                  <Skeleton variant="rectangular" width={50} height={50} />
                </div>
              </div>
              <div className="ramadan-card-content-m-container">
                <Skeleton variant="text" width={150} height={15} />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
