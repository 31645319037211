import { useContext, useEffect, useState } from 'react';
import AccountLeftComponent from '../AccountLeftComponent';
import { AppContext } from '../../../contexts/AppContext';
import PageLoader from '../../Other/PageLoader/PageLoaderComponent';
import Card from './Card';
import TabPages from './TabPages';
import {
  errorNotification,
  successNotification,
} from '../../Other/ToastMessage';
import { ListingStatus } from '../../../common/apis/user.apis';
import { useUserGetMyListings } from '../../../common/hooks/user.hook';
import { SellYourVehicleModal } from '../SellYourVehicle/SellYourVehicleModal';
import '../AccountDetails/header-styles.css';
import '../../UsedCars/components/UsedCarBody-styles.css';

const AccountListings = () => {
  const { lang, trans, country } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState(ListingStatus.Approved);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const allStatuses = [
    ListingStatus.Approved,
    ListingStatus.Pending,
    ListingStatus.Rejected,
  ];

  const {
    data,
    isLoading: showPageLoader,
    refetch,
  } = useUserGetMyListings({
    params: {
      status: allStatuses,
      lang: lang,
    },
    enabled: true,
  });

  const approvedCount = data?.filter(
    (car: any) => car.status === ListingStatus.Approved,
  ).length;

  const pendingCount = data?.filter(
    (car: any) => car.status === ListingStatus.Pending,
  ).length;

  const rejectedCount = data?.filter(
    (car: any) => car.status === ListingStatus.Rejected,
  ).length;

  const carDeleted = async (isSuccess: boolean) => {
    if (isSuccess) {
      successNotification('deleted', 'success', 'Account Listings');

      refetch();
    } else {
      errorNotification('not deleted');
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <main id="main">
        <div className="accounts-container">
          <div className="accounts-frame">
            {showPageLoader && <PageLoader loadSectionOnly />}
            <AccountLeftComponent country={country} />
            <section className="accounts-content">
              <header className="accounts-header listing">
                <h2>{trans.listings}</h2>
                <div
                  className="btn btn-secondary add-used"
                  style={{ color: '#fff' }}
                  onClick={handleOpenModal}
                >
                  <i className="fa fa-plus"></i>
                  {trans.add_listing}
                </div>
                {modalOpen && (
                  <SellYourVehicleModal
                    location={window.location.pathname}
                    toggleSignInDialog={handleCloseModal}
                  />
                )}
              </header>
              <TabPages
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                carsCount={{
                  approvedCount,
                  pendingCount,
                  rejectedCount,
                }}
              />
              <div className="search-details usedcars-rows">
                {data
                  ?.filter((car: any) => {
                    if (activeTab === 'approved') {
                      return car.status === ListingStatus.Approved;
                    } else if (activeTab === 'pending') {
                      return car.status === ListingStatus.Pending;
                    } else if (activeTab === 'rejected') {
                      return car.status === ListingStatus.Rejected;
                    }
                    return false;
                  })
                  .map((car: any) => (
                    <Card key={car.id} car={car} carDeleted={carDeleted} />
                  ))}
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default AccountListings;
