import authenticatedApi from '../../../instance/ApiInstance';

const rentalsEndpoint = '/v2/cars/rental-cars';

export const getRentCarDetails = async (id: number) => {
  try {
    const response = await authenticatedApi.get(`${rentalsEndpoint}/${id}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};
