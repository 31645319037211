import {
  ColLg12,
  MoreArticleH2,
  Row,
} from '../../../common/styles/CommonStyles';
import { IArticle } from '../../../types/articles/article';
import { ArticleCard } from '../../common/ArticleCard';
import { MoreArticlesSkeleton } from './ArticclesSkeleton/ArticlesSkeleton';

interface MoreArticlesProps {
  allArticles: IArticle[];
  lang: string;
  trans: any;
  darkImageLoadingPlaceholder: string;
  isLoading: boolean;
}

export const MoreArticles = ({
  allArticles,
  trans,
  isLoading,
}: MoreArticlesProps) => {
  if (isLoading) {
    return <MoreArticlesSkeleton trans={trans} />;
  }
  return (
    <>
      <Row className="more-article">
        <ColLg12>
          <MoreArticleH2>{trans.more_articles}</MoreArticleH2>
        </ColLg12>
      </Row>
      <Row>
        {allArticles?.map((article, index) => (
          <ArticleCard key={index} article={article} isLoading={isLoading} />
        ))}
      </Row>
    </>
  );
};
