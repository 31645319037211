export const NavigationSystemIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5505 8.47604L12.8758 27.113L17.4412 17.9822L25.9066 14.5958L7.5505 8.47604ZM4.44784 4.27708L32.6901 13.6902C32.8363 13.7386 32.9641 13.8309 33.0561 13.9543C33.1481 14.0778 33.1999 14.2266 33.2045 14.3806C33.209 14.5345 33.1662 14.6861 33.0817 14.8148C32.9972 14.9436 32.8752 15.0433 32.7322 15.1003L19.6172 20.346L12.9719 33.6367C12.9043 33.772 12.7973 33.8838 12.6651 33.9573C12.5329 34.0309 12.3815 34.0627 12.2309 34.0488C12.0803 34.0348 11.9373 33.9757 11.8209 33.8791C11.7044 33.7825 11.6199 33.653 11.5782 33.5076L3.48821 5.19467C3.45073 5.06313 3.4499 4.92386 3.48579 4.79189C3.52167 4.65991 3.59293 4.54025 3.69185 4.4458C3.79077 4.35136 3.91361 4.28573 4.04711 4.256C4.18061 4.22626 4.31969 4.23355 4.44934 4.27708H4.44784Z"
        fill={color}
      />
    </svg>
  );
};
