import { useQuery } from 'react-query';
import { getBikeOptionalFeatures } from '../../apis/bikes';

export const useGetBikeOptionalFeatures = () => {
  return useQuery({
    queryKey: ['bike-optionalFeatures'],
    queryFn: () => getBikeOptionalFeatures(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
