export const CameraIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.56152 6.31963C3.56427 5.92523 3.72206 5.54774 4.00081 5.26871C4.27956 4.98969 4.65689 4.83151 5.05128 4.82837H32.1072C32.9302 4.82837 33.5969 5.49666 33.5969 6.31963V30.369C33.5942 30.7634 33.4364 31.1409 33.1577 31.4199C32.8789 31.6989 32.5016 31.8571 32.1072 31.8602H5.05128C4.65603 31.8598 4.27711 31.7026 3.99777 31.4229C3.71843 31.1433 3.56152 30.7642 3.56152 30.369V6.31963ZM6.56507 7.83191V28.8567H30.5934V7.83191H6.56507ZM18.5792 22.8496C19.7741 22.8496 20.9201 22.375 21.765 21.53C22.6099 20.6851 23.0845 19.5392 23.0845 18.3443C23.0845 17.1494 22.6099 16.0035 21.765 15.1586C20.9201 14.3137 19.7741 13.839 18.5792 13.839C17.3844 13.839 16.2384 14.3137 15.3935 15.1586C14.5486 16.0035 14.0739 17.1494 14.0739 18.3443C14.0739 19.5392 14.5486 20.6851 15.3935 21.53C16.2384 22.375 17.3844 22.8496 18.5792 22.8496ZM18.5792 25.8532C16.5878 25.8532 14.6779 25.0621 13.2697 23.6539C11.8615 22.2457 11.0704 20.3358 11.0704 18.3443C11.0704 16.3528 11.8615 14.4429 13.2697 13.0347C14.6779 11.6266 16.5878 10.8355 18.5792 10.8355C20.5707 10.8355 22.4806 11.6266 23.8888 13.0347C25.297 14.4429 26.0881 16.3528 26.0881 18.3443C26.0881 20.3358 25.297 22.2457 23.8888 23.6539C22.4806 25.0621 20.5707 25.8532 18.5792 25.8532ZM26.0881 9.33368H29.0916V12.3372H26.0881V9.33368Z"
        fill={color}
      />
    </svg>
  );
};
