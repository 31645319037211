import { useCallback, useContext, useEffect } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { AutoImgBox, SlideContent } from '../../../common/styles/CommonStyles';
import Carousel from 'react-multi-carousel';
import { BikeLazyImage } from './BikeLazyImage/BikeLazyImage';
import { BikeCarouselLazyImage } from './BikeLazyImage/BikeCarouselLazyImage';
import { BikeListingDetailsImageGallerySkeletons } from './BikeDetailsSkeletons';
import '../../Listings/common/ListingDetailsImageGallery/ListingDetailsImageGallery-styles.css';

type BikeListingDetailsImageGalleryProps = {
  images: Array<any>;
  coverImage: any;
  onImageClick: (newImage: any) => void;
  handleOpen: () => void;
  setImageSrc: (imageSrc: string) => void;
  isLoading: boolean;
};

export const BikeListingDetailsImageGallery = ({
  coverImage,
  handleOpen,
  images,
  onImageClick,
  setImageSrc,
  isLoading,
}: BikeListingDetailsImageGalleryProps) => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  const responsiveFeatured = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  };

  const handleSetImageSrc = useCallback(
    (imageSrc: string) => {
      setImageSrc(imageSrc);
    },
    [setImageSrc],
  );

  useEffect(() => {
    handleSetImageSrc(coverImage);
  }, [coverImage, handleSetImageSrc]);

  if (isLoading) {
    return (
      <BikeListingDetailsImageGallerySkeletons
        responsiveFeatured={responsiveFeatured}
      />
    );
  }

  return (
    <>
      <div className="slick-slider-big2 slick-initialized slick-slider">
        <div className="slick-list draggable">
          <div className="slick-track" style={{ width: '100%' }}>
            <div
              className="item slick-current slick-active"
              data-slick-index="0"
              aria-hidden="false"
            >
              <SlideContent>
                <AutoImgBox>
                  <BikeLazyImage
                    src={coverImage}
                    defaultSrc={darkImageLoadingPlaceholder}
                    alt="img"
                    onClick={handleOpen}
                  />
                </AutoImgBox>
              </SlideContent>
            </div>
          </div>
        </div>
      </div>
      <div className="slider-nav-thumbnails2 slick-initialized slick-slider">
        <Carousel
          responsive={responsiveFeatured}
          swipeable={true}
          draggable={true}
          infinite={true}
          customLeftArrow={
            <div className="swiper-button-prev swiper-button-prev1"></div>
          }
          customRightArrow={
            <div className="swiper-button-next swiper-button-next1"></div>
          }
        >
          {images.map((image: any, index) => (
            <div
              key={Math.random()}
              className={`item slick-active ${
                image === coverImage ? 'slick-current' : ''
              }`}
            >
              <AutoImgBox>
                <BikeCarouselLazyImage
                  src={image}
                  defaultSrc={darkImageLoadingPlaceholder}
                  alt="img"
                  onClick={onImageClick}
                  class="lazy"
                />
              </AutoImgBox>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};
