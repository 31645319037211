import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { IModel } from '../../../../types/resources/cars';
import { Box } from '@mui/material';
import { NewCarDetailPageImage } from './NewCarDetailPageImage';
import { NewCarsDetailsPageOtherModelsSkeleton } from '../NewCarsCardsSkeletons';
import {
  AutoImgBox,
  CarInfoColumn,
  CarInfoColumnImgHolder,
  CarInfoColumnLogoImg,
  CarInfoColumnLogoImgImg,
  CarInfoColumnTextBox,
  CarInfoColumnTextHolder,
  CarInfoColumnTitleCar,
  CarInfoColumnTitleCategory,
} from '../../../../common/styles/CommonStyles';
import '../../../Listings/common/ListingDetailsImageGallery/ListingDetailsImageGallery-styles.css';
import { useModelPageClick } from '../../hooks';

interface IOtherModelsProps {
  filteredNewModels: IModel[];
  darkImageLoadingPlaceholder: string;
  isLoading: boolean;
}

export const OtherModels = ({
  filteredNewModels,
  darkImageLoadingPlaceholder,
  isLoading,
}: IOtherModelsProps) => {
  const { lang, trans, country } = useContext(AppContext);

  const { mutateAsync: modelClick } = useModelPageClick();

  if (!filteredNewModels || filteredNewModels.length === 0) {
    return (
      <Box sx={{ m: 3 }} /> // margin: 16px;
    );
  }

  if (isLoading) {
    return <NewCarsDetailsPageOtherModelsSkeleton trans={trans} />;
  }
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
        <h2
          className="other-models-header"
          style={{
            color: '#3d3d3d',
            fontSize: '24px',
            lineHeight: '1.3',
            fontWeight: '800',
            marginBottom: '30px',
          }}
        >
          <strong>
            {trans.other} {filteredNewModels?.[0]?.brand.name} {trans.models}
          </strong>
        </h2>
      </div>
      <div
        className="d-flex justify-content-start align-items-center flex-wrap mb-4 deal-page-models-wrapper"
        style={{ gap: '20px' }}
      >
        {filteredNewModels.map(model => (
          <CarInfoColumn key={model.id} className="deal-page">
            <Link
              to={`/${country}/${lang}/new-cars/${model.brand.slug}/${model.slug}`}
              onClick={() => modelClick(Number(model.id))}
            >
              <CarInfoColumnImgHolder>
                <AutoImgBox>
                  <NewCarDetailPageImage
                    src={model.cover}
                    defaultSrc={darkImageLoadingPlaceholder}
                    alt={`${model.slug}-cover-image`}
                  />
                </AutoImgBox>
              </CarInfoColumnImgHolder>
              <CarInfoColumnTextHolder>
                <CarInfoColumnTextBox>
                  <CarInfoColumnLogoImg>
                    <CarInfoColumnLogoImgImg
                      src={model.brand.original_logo}
                      alt={`${model.brand.slug}-logo`}
                    />
                  </CarInfoColumnLogoImg>
                  <CarInfoColumnTitleCar>
                    {model.brand.name}
                  </CarInfoColumnTitleCar>
                  <CarInfoColumnTitleCategory>
                    {model.name}
                  </CarInfoColumnTitleCategory>
                </CarInfoColumnTextBox>
              </CarInfoColumnTextHolder>
            </Link>
          </CarInfoColumn>
        ))}
      </div>
    </div>
  );
};
