import { useMutation, useQuery } from 'react-query';
import {
  BrandPageClick,
  BrandPageView,
  NewCarModelPageClick,
  NewcarModelPageView,
  getBrandModelsWithDeals,
  getBrandsWithDeals,
  getModelDeals,
  modelPageView,
} from '../apis';
import {
  ICreateNewCarInquiry,
  createNewCarInquiry,
} from '../apis/createNewCarInquiry';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';

interface IBrandModelWithDeals {
  brandId: number;
}

interface IModelsDeals {
  modelId: number;
}

interface IModelPageView {
  modelId: number;
}

export const useModelDeals = ({ modelId }: IModelsDeals) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['modelDeals', modelId, lang],
    queryFn: () => getModelDeals({ model_id: modelId }),
    enabled: !!modelId,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useBrandModelsWithDeals = ({ brandId }: IBrandModelWithDeals) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['brandModels', brandId, lang],
    queryFn: () => getBrandModelsWithDeals({ brandId }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!brandId && brandId !== -1,
  });
};

export const useBrandWithDeals = () => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['brandWithDeals', lang],
    queryFn: () => getBrandsWithDeals(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useCreateNewCarInquiry = () => {
  return useMutation({
    mutationFn: (data: ICreateNewCarInquiry) => createNewCarInquiry(data),
  });
};

export const useBrandPageClick = () => {
  return useMutation({
    mutationFn: (id: number) => BrandPageClick(id),
  });
};

export const useBrandPageView = () => {
  return useMutation({
    mutationFn: (id: number) => BrandPageView(id),
  });
};

export const useModelPageClick = () => {
  return useMutation({
    mutationFn: (id: number) => NewCarModelPageClick(id),
  });
};

export const useModelPageView = () => {
  return useMutation({
    mutationFn: (id: number) => NewcarModelPageView(id),
  });
};
