import { useState } from 'react';
import { CarListCardImageSkeleton } from '../../../UsedCars/UsedCarsSkeletons';
import { styled } from '@mui/material';

const CarListImageCardStyled = styled('img')(() => ({
  objectFit: 'cover',
  height: '100%',
  width: '100%',
  margin: 'auto',
}));
interface CarListImageCardProps {
  defaultSrc?: string;
  src: string | undefined;
  alt?: string;
  class?: string;
  onClick?: any;
  style?: React.CSSProperties;
  onLoad?: any;
  width?: number;
  height?: number;
}

export const CarListImageCard = ({
  src,
  alt,
  class: className,
  onClick,
  style,
  width,
  height,
}: CarListImageCardProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  return (
    <>
      {isImageLoading && <CarListCardImageSkeleton />}
      <CarListImageCardStyled
        src={src}
        className={className}
        onClick={onClick}
        alt={alt}
        style={{ ...style, display: isImageLoading ? 'none' : 'block' }}
        onLoad={() => {
          setIsImageLoading(false);
        }}
        width={width}
        height={height}
      />
    </>
  );
};
