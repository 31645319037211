import { useQuery } from 'react-query';
import { getEngineTypesWithResources } from '../apis/engineType.hooks';

export const useGetEngineTypesWithResources = () => {
  return useQuery({
    queryKey: ['engineTypesWithResources'],
    queryFn: () => getEngineTypesWithResources(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
