import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PageLoader from '../../../Other/PageLoader/PageLoaderComponent';
import AccountChatCard from '../AccountChatCard/AccountChatCard';
import AccountChatRoom from '../AccountChatRoom/AccountChatRoom';
import { io } from 'socket.io-client';
import {
  EChatConversationType,
  IChatConversation,
} from '../../../../types/chats/chat_conversation';
import { AppContext } from '../../../../contexts/AppContext';
import './AccountChatsList-styles.css';

interface IAccountChatsListProps {
  chatConversations: IChatConversation[] | undefined;
  isLoadingChatConversations: boolean;
  fetchConversations: () => void;
  setUnreadChatMessagesCount: React.Dispatch<React.SetStateAction<number>>;
}

const AccountChatsList = ({
  chatConversations,
  isLoadingChatConversations,
  fetchConversations,
  setUnreadChatMessagesCount,
}: IAccountChatsListProps) => {
  const { id, type: ChatConversationTypeParam } = useParams();
  const [userParticipantId, setUserParticipantId] = useState<number | null>(
    null,
  );
  const { userData } = useContext(AppContext);
  const chatConversationIdRef = useRef<number | undefined>(undefined);
  const socket = useRef<any>(null);
  const location = useLocation();
  const locationState = location.state;

  useEffect(() => {
    const socketTemp = io(`${process.env.REACT_APP_BASE_WEBSOCKET_URL}`, {
      withCredentials: true,
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    socket.current = socketTemp;
  }, []);

  useEffect(() => {
    if (socket.current) {
      socket.current.on('msgToClient', data => {
        const dataChatConversationId =
          data.chat_conversation_type === EChatConversationType.Car
            ? data.car_chat_conversation_id
            : data.bike_chat_conversation_id;

        const chatBelongsToExistingConversation = chatConversations?.some(
          obj =>
            obj.id === dataChatConversationId &&
            obj.chat_conversation_type === data.chat_conversation_type,
        );
        if (
          !chatBelongsToExistingConversation &&
          dataChatConversationId !== chatConversationIdRef.current
        ) {
          setUnreadChatMessagesCount(prev => prev + 1);
          fetchConversations();
        }
      });
    }
    return () => {
      if (socket.current) socket.current.off('msgToClient');
    };
  }, [chatConversations, fetchConversations, socket.current]);

  useEffect(() => {
    if (id !== 'new' && chatConversations) {
      const participant = chatConversations
        .find(
          chatConversation =>
            chatConversation.id === parseInt(id!) &&
            chatConversation.chat_conversation_type ===
              ChatConversationTypeParam,
        )
        ?.chat_participants.find(
          participant => participant.user?.id === userData?.id,
        )?.id;

      setUserParticipantId(participant!);
    }
  }, [id, chatConversations, userData]);

  return (
    <>
      {isLoadingChatConversations && <PageLoader loadSectionOnly />}
      <section className="accounts-content chat-page">
        <ul
          className="chat-users-nav customscroll"
          style={{ width: id ? '36%' : '100%' }}
        >
          {locationState && locationState.new && (
            <AccountChatCard
              key={0}
              openedChat={{
                id: id ? parseInt(id) : null,
                type:
                  (ChatConversationTypeParam as EChatConversationType) ?? null,
              }}
              setUserParticipantId={setUserParticipantId}
              newChatState={locationState}
            />
          )}
          {chatConversations?.map(chatConversation => (
            <AccountChatCard
              key={`${chatConversation.id}-${chatConversation.chat_conversation_type}`}
              chatConversation={chatConversation}
              openedChat={{
                id: id ? parseInt(id) : null,
                type:
                  (ChatConversationTypeParam as EChatConversationType) ?? null,
              }}
              setUserParticipantId={setUserParticipantId}
            />
          ))}
        </ul>
        {chatConversations && id ? (
          locationState && locationState.new ? (
            <AccountChatRoom
              socket={socket.current}
              setUnreadChatMessagesCount={setUnreadChatMessagesCount}
              userParticipantId={userParticipantId}
              setUserParticipantId={setUserParticipantId}
              newChatState={locationState}
              chatConversationIdRef={chatConversationIdRef}
            />
          ) : userParticipantId ? (
            <AccountChatRoom
              chatConversation={chatConversations.find(
                chatConversation =>
                  chatConversation.id === parseInt(id) &&
                  chatConversation.chat_conversation_type ===
                    ChatConversationTypeParam,
              )}
              userParticipantId={userParticipantId}
              socket={socket.current}
              setUnreadChatMessagesCount={setUnreadChatMessagesCount}
              chatConversationIdRef={chatConversationIdRef}
            />
          ) : null
        ) : null}
      </section>
    </>
  );
};

export default AccountChatsList;
