import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import './CountrySelectorModal.css';
import '../../../styles/css/header-styles.css';

interface CountrySelectorModalProps {
  toggleShowCountryModal: (arg0: boolean) => void;
}

export const CountrySelectorModal = ({
  toggleShowCountryModal,
}: CountrySelectorModalProps) => {
  const { lang, trans } = useContext(AppContext);
  const [selectedCountry, setSelectedCountry] = useState<string>('Qatar');

  const handleSelectCountry = () => {
    const countryCode = selectedCountry === 'Qatar' ? 'qa' : 'sa';
    localStorage.setItem('country', countryCode);
    toggleShowCountryModal(false);
    window.location.pathname = `/${countryCode}/${lang}`;
  };

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div className="modal fade modal-center advance-search-modal loginpopup show">
      <div className="country-selector-modal">
        <div className="country-selector-modal-content-header">
          <h2 className="country-selector-modal-content-header-title">
            {trans.Welcometooneautocar}
          </h2>
          <p className="country-selector-modal-content-header-subtitle">
            {trans.chooseCountry}
          </p>
        </div>
        <div className="country-selector-modal-content-body">
          <div
            className={`modal-dropdown-item ${
              selectedCountry === 'Qatar' ? 'selected' : ''
            }`}
            onClick={() => setSelectedCountry('Qatar')}
          >
            <span className="modal-dropdown-item-text">{trans.qatar}</span>
            <img
              className="modal-dropdown-item-flag"
              src={`/images/language1.png`}
              alt="Flag"
            />
          </div>
          <div
            style={{
              cursor: 'pointer',
            }}
            className={`modal-dropdown-item ${
              selectedCountry === 'Saudi Arabia' ? 'selected' : ''
            }`}
            onClick={() => setSelectedCountry('Saudi Arabia')}
          >
            <span className="modal-dropdown-item-text">
              {trans.saudiArabia}
            </span>
            <img
              className="modal-dropdown-item-flag"
              src={`/images/saudi-flag.svg`}
              alt="Flag"
            />
          </div>
        </div>
        <button
          className="country-selector-modal-content-footer"
          onClick={() => handleSelectCountry()}
        >
          {trans.sell_confirm}
        </button>
      </div>
    </div>
  );
};
