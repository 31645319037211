import authenticatedApi from '../../../instance/ApiInstance';
import { INewBike } from '../../../types/bikes';

const newBikeEndpoint = '/v2/bikes/new-bikes';
const newBikeInquiriesEndpoint = 'v2/inquiries/new-bike-inquiries';

export interface IGetBikeModelDealsParams {
  bike_model_id?: number;
}

export interface ICreateNewBikeInquiry {
  name: string;
  email: string;
  mobile_number: string;
  new_bike_id: number;
}

export const getBikeModelDeals = async (
  params?: IGetBikeModelDealsParams,
): Promise<INewBike[] | undefined> => {
  try {
    const response = await authenticatedApi.get(newBikeEndpoint, {
      params,
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createNewBikeInquiry = async (data: ICreateNewBikeInquiry) => {
  const response = await authenticatedApi.post(newBikeInquiriesEndpoint, data);
  return response.data;
};
