import {
  ColLg12,
  MoreArticleH2,
  Row,
} from '../../../common/styles/CommonStyles';
import { IReview } from '../../../types/reviews';
import { ReviewCard } from './ReviewCard/ReviewCard';

interface MoreReviewsProps {
  reviews: IReview[];
  lang: string;
  trans: any;
  className?: string;
  onClick?: any;
}

export const MoreReviews = ({
  reviews,
  lang,
  trans,
  className,
  onClick: oncClick,
}: MoreReviewsProps) => {
  return (
    <>
      <Row className="more-article">
        <ColLg12>
          <MoreArticleH2>{trans.more_reviews}</MoreArticleH2>
        </ColLg12>
      </Row>
      <Row className="more-reviews">
        {reviews?.map((review, index) => (
          <ReviewCard
            className={className}
            key={index}
            review={review}
            lang={lang}
            trans={trans}
            index={index}
            onClick={() => oncClick(review.slug)}
            style={{
              width: '100%',
              objectFit: 'cover',
              minHeight: '310px',
              maxHeight: '310px',
              borderRadius: '5px',
            }}
          />
        ))}
      </Row>
    </>
  );
};
