import authenticatedApi from '../../instance/ApiInstance';
import { IModel } from '../../types/resources/cars';
import { ModelName } from '../classes/Model';

const modelsEndpoint = '/v2/resources/cars/models';

export interface IGetModels {
  enabled?: boolean;
  brands?: number[];
}

export interface IGetAllModels {
  brand?: number;
  type?: string;
}

export const getModels = async (params: IGetModels): Promise<IModel[]> => {
  const response = await authenticatedApi.get(modelsEndpoint, {
    params,
  });
  return response.data;
};

export const getAllModels = async (
  params: IGetAllModels,
): Promise<IModel[]> => {
  const response = await authenticatedApi.get(`${modelsEndpoint}/all`, {
    params,
  });
  return response.data;
};

export const getBrandModelsNames = async (
  brandId?: number,
): Promise<ModelName[]> => {
  try {
    const response = await authenticatedApi.get(
      `api/v2/brand/${brandId}/models/names`,
    );

    const modelNames = response.data.data.map(
      (modelName: any) => new ModelName(modelName),
    );

    return modelNames;
  } catch (error) {
    throw error;
  }
};

export const getModelsForUsedCars = async (
  brandId?: number[],
): Promise<IModel[]> => {
  try {
    const response = await authenticatedApi.get(`v2/resources/cars/models`, {
      params: {
        brands: brandId,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getModelsForReviews = async (
  brandId?: number[],
  language?: string,
): Promise<IModel[]> => {
  try {
    const params = {
      brands: brandId,
    };

    if (language) {
      params['language'] = language;
    }

    const response = await authenticatedApi.get(
      `v2/resources/cars/models/reviews`,
      {
        params: params,
      },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
