import { useQuery } from 'react-query';
import {
  getCarTypes,
  getModelTypesNames,
  getTypeNames,
} from '../apis/type.apis';
import { TypeName } from '../classes/Type';

export const useGetCarTypes = () => {
  return useQuery({
    queryKey: ['types'],
    queryFn: () => getCarTypes(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useModelTypesNames = (modelId?: number) => {
  return useQuery({
    queryKey: ['type-names', modelId],
    queryFn: (): Promise<TypeName[]> => getModelTypesNames(modelId),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: modelId !== undefined,
  });
};

export const useTypeNames = () => {
  return useQuery({
    queryKey: 'type-names',
    queryFn: (): Promise<TypeName[]> => getTypeNames(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};
