import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { styled } from '@material-ui/core';
import { cp } from 'fs';

const ErrorContainer = styled('div')(() => ({
  border: '1px solid #E5E5E5',
  backgroundColor: '#fff',
  borderRadius: '10px',
  padding: '6% 4%',
  margin: '50px auto',
  maxWidth: '1435px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  '@media (max-width: 1460px)': {
    marginInline: '20px',
    width: 'auto',
  },
}));

const ErrorPage = styled('div')(() => ({
  maxWidth: '450px',
  margin: '0 auto',
  color: '#919191',
  fontSize: '20px',
}));

const ErrorPageImgBox = styled('div')(() => ({
  marginBottom: '40px',
}));

const ErrorPageH2 = styled('h2')(() => ({
  fontSize: '50px',
  fontWeight: 700,
}));

const ErrorPageP = styled('p')(() => ({
  marginBottom: '50px',
}));

const ErrorPageLink = styled(Link)(() => ({
  lineHeight: '25px',
  minWidth: '300px',
  paddingBlock: '5px',
  fontSize: '22px',
  fontWeight: 400,
  borderRadius: '10px',
}));

export default function PageNotFound(this: any) {
  const { lang, trans, country } = useContext(AppContext);

  return (
    <main id="main">
      <ErrorContainer className="container">
        <ErrorPage>
          <ErrorPageImgBox>
            <img src={'/images/404-image.png'} alt="Error Image" />
          </ErrorPageImgBox>
          <ErrorPageH2>{trans.error}</ErrorPageH2>
          <ErrorPageP>{trans.page_doesnt_exist}</ErrorPageP>
          <ErrorPageLink to={`/${country}/${lang}`} className="btn btn-primary">
            {trans.back_to_home_page}
          </ErrorPageLink>
        </ErrorPage>
      </ErrorContainer>
    </main>
  );
}
