import { SelectOption } from '../../../common/classes/SelectOption';
import { createTheme } from '@mui/material';

const smallBaggageSizeData = [
  {
    id: 1,
    name: '1+',
    arabic_name: '1+',
  },
  {
    id: 2,
    name: '2+',
    arabic_name: '2+',
  },
  {
    id: 3,
    name: '3+',
    arabic_name: '3+',
  },
  {
    id: 4,
    name: '4+',
    arabic_name: '4+',
  },
  {
    id: 5,
    name: '5+',
    arabic_name: '5+',
  },
];

const largeBaggageSizeData = [
  {
    id: 1,
    name: '1+',
    arabic_name: '1+',
  },
  {
    id: 2,
    name: '2+',
    arabic_name: '2+',
  },
  {
    id: 3,
    name: '3+',
    arabic_name: '3+',
  },
  {
    id: 4,
    name: '4+',
    arabic_name: '4+',
  },
  {
    id: 5,
    name: '5+',
    arabic_name: '5+',
  },
];

const seatsData = [
  {
    id: 1,
    name: '1+',
    arabic_name: '1+',
  },
  {
    id: 2,
    name: '2+',
    arabic_name: '2+',
  },
  {
    id: 3,
    name: '3+',
    arabic_name: '3+',
  },
  {
    id: 4,
    name: '4+',
    arabic_name: '4+',
  },
  {
    id: 5,
    name: '5+',
    arabic_name: '5+',
  },
];

const smallBaggageSizes = smallBaggageSizeData.map(
  (option: any) => new SelectOption(option),
);
const largeBaggageSizes = largeBaggageSizeData.map(
  (option: any) => new SelectOption(option),
);
const seats = seatsData.map((option: any) => new SelectOption(option));

const sliderTheme = createTheme({
  palette: {
    primary: {
      light: '#00d5bb',
      main: '#00d5bb',
      dark: '#00d5bb',
      contrastText: '#00d5bb',
    },
  },
});

const minPrice = 0;
const maxPrice = 10000;

const priceSliderStepSize = 50;

export {
  smallBaggageSizes,
  largeBaggageSizes,
  seats,
  sliderTheme,
  minPrice,
  maxPrice,
  priceSliderStepSize,
};
