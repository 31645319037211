import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import PageLoader from './PageLoader/PageLoaderComponent';
import ReactPlayer from 'react-player/lazy';
import MetaTag from '../../common/meta/MetaTag';
import { metaData } from '../../common/meta/metaData';
import {
  ColLg12,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  Row,
  SellYourCar,
} from '../../common/styles/CommonStyles';
import styled from '@emotion/styled';

const WhyWeRockStyled = styled('section')(() => ({
  padding: '30px 3% 40px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media screen and (max-width: 575px)': {
    padding: '30px 6% 40px',
  },
}));

const TxtBox = styled('div')(() => ({
  width: '46%',
  textAlign: 'justify',
  lineHeight: 1.5,
  '@media screen and (max-width: 767px)': {
    width: '100%',
    marginBottom: '30px',
  },
}));

const H1 = styled('h1')(() => ({
  marginBottom: '30px',
}));

const H1Img = styled('img')(() => ({
  maxHeight: '87px',
}));

const VideoSection = styled('div')(() => ({
  width: '48%',
  aspectRatio: '16/9',
  border: '3px solid #fff',
  boxShadow: '0 0 10px rgba(0,0,0,0.5)',
  '@media screen and (max-width: 767px)': {
    width: '100%',
  },
}));

const AboutMiddleSection = styled('section')(() => ({
  backgroundColor: '#3D3D3D',
  padding: '40px 7%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ImgBox = styled('div')(() => ({
  width: '45%',
  '@media screen and (max-width: 767px)': {
    width: '100%',
    marginBottom: '30px',
  },
  '@media screen and (max-width: 480px)': {
    padding: '20px 50px 50px',
  },
}));

const MiddleSectionTxtBox = styled('div')(() => ({
  width: '48%',
  textAlign: 'justify',
  lineHeight: 1.5,
  color: '#B1B1B1',
}));

const TxtBoxH2 = styled('h2')(() => ({
  color: '#fff',
  position: 'relative',
  paddingBottom: '20px',
  textTransform: 'uppercase',
  fontWeight: 900,
  letterSpacing: '1px',
  textAlign: 'center',
  fontSize: '28px',
  lineHeight: 1.2,
  marginBottom: '20px',
  ':before': {
    position: 'absolute',
    left: '50%',
    bottom: '0',
    transform: 'translateX(-50%)',
    width: '90px',
    height: '10px',
    content: '""',
    backgroundColor: '#00D6Bc',
  },
}));

const TxtBoxP = styled('p')(() => ({
  marginBottom: '20px',
}));

const DownloadOurApp = styled('div')(() => ({
  padding: '50px 20px',
}));

const DownloadOurAppImg = styled('img')(() => ({
  maxHeight: '280px',
}));

export default function WhyWeRock(this: any) {
  const { lang, trans } = useContext(AppContext);
  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 100);
  }, []);

  return (
    <>
      <MetaTag metaKey="whyWeRock" href={`/${lang}/why-we-rock`} />
      {showPageLoader && <PageLoader />}
      <main id="main">
        <MainHead>
          <MainHeadContainer className="container">
            <Row>
              <ColLg12>
                <MainHeadH1>{trans.about_us}</MainHeadH1>
              </ColLg12>
            </Row>
          </MainHeadContainer>
        </MainHead>
        <SellYourCar className="container aboutus-section">
          <WhyWeRockStyled>
            <TxtBox>
              <H1>
                <H1Img
                  src={`/images/why-we-rock${lang === 'ar' ? '-ar' : ''}.png`}
                  alt="Why We Rock"
                />
              </H1>
              <p dangerouslySetInnerHTML={{ __html: trans.wwr_p1 }}></p>
            </TxtBox>
            <VideoSection>
              <ReactPlayer
                width={'100%'}
                height={'100%'}
                light={true}
                url={'https://www.youtube.com/embed/WlB34OiNS9k'}
              />
            </VideoSection>
          </WhyWeRockStyled>
          <AboutMiddleSection>
            <ImgBox>
              <img src={`/images/about-mobiles.png`} alt="mobile image" />
            </ImgBox>
            <MiddleSectionTxtBox>
              <TxtBoxH2>{trans.our_app}</TxtBoxH2>
              <TxtBoxP
                dangerouslySetInnerHTML={{ __html: trans.wwr_p2 }}
              ></TxtBoxP>
            </MiddleSectionTxtBox>
          </AboutMiddleSection>
          <DownloadOurApp>
            <DownloadOurAppImg
              src={`/images/download-app${lang === 'ar' ? '-ar' : ''}.png`}
              alt="Download our app"
            />
          </DownloadOurApp>
        </SellYourCar>
      </main>
    </>
  );
}
