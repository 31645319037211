import authenticatedApi from '../../instance/ApiInstance';
import { ITrim } from '../../types/resources/cars';

const trimsEndpoint = '/v2/resources/cars/trims';

export interface IGetTrims {
  enabled?: boolean;
  model?: number;
}

export const getTrims = async ({ model }: IGetTrims): Promise<ITrim[]> => {
  const response = await authenticatedApi.get(trimsEndpoint, {
    params: {
      model_id: model,
    },
  });
  return response.data;
};
