import { useContext } from 'react';
import './ListedFeaturedDetails.css';
import { NewCarDetailPageDealerLogo } from '../NewCarDetailPageDealerLogo';
import { Button } from '@mui/material';
import { FeaturedFrameButtonContainer } from '../../NewCarsStyled';
import { GiSteeringWheel } from 'react-icons/gi';
import { AppContext } from '../../../../../contexts/AppContext';
import { INewCar } from '../../../../../types/cars/new_car';
import { IModel } from '../../../../../types/resources/cars';
import { FeaturedDetailsStyled } from '../../../../../common/styles/CommonStyles';

interface ListedFeaturedDetailsProps {
  deals: INewCar[];
  getDealClick: (deal: INewCar) => void;
  handleTestDriveClick: (testDriveUrl: string) => void;
  selectedModel: IModel;
}

export const ListedFeaturedDetails = ({
  deals,
  getDealClick,
  handleTestDriveClick,
  selectedModel,
}: ListedFeaturedDetailsProps) => {
  const { trans, lang } = useContext(AppContext);

  return (
    <FeaturedDetailsStyled
      className="d-flex flex-column justify-content-between"
      style={{
        width: '60%',
        gap: '20px',
      }}
    >
      <div className="listed-featured-details__header">
        <h2 className="listed-featured-details__header-title">
          {selectedModel?.name}
        </h2>
        <p
          className="listed-featured-details__header-description"
          dangerouslySetInnerHTML={{
            __html: selectedModel.description,
          }}
        />
      </div>
      {deals.length > 0 ? (
        <div
          className="listed-featured-details__deals-list"
          style={
            deals.length > 2
              ? {
                  height: '300px',
                  overflowY: 'auto',
                }
              : {}
          }
        >
          <div className="listed-featured-details__deals-list-content">
            {deals.map((deal, index) => (
              <div key={index} className="listed-featured-details__deal">
                <div className="listed-featured-details__deal-image">
                  {deal?.dealer?.logo && (
                    <NewCarDetailPageDealerLogo
                      src={deal.dealer.logo}
                      class="deal-image"
                      alt={`${deal.dealer.slug}-logo`}
                      style={{
                        width: '200px',
                        margin: 0,
                        alignItems: 'end',
                        height: '60px',
                        objectFit: 'contain',
                      }}
                    />
                  )}
                  <p className="listed-featured-details__deal-price">
                    {deal && deal.price !== null
                      ? `${trans.starting_from} ${deal.price} ${deal.cities.currencies.name}`
                      : `${trans.Pricenotavailable}`}
                  </p>
                </div>
                {deal ? (
                  <FeaturedFrameButtonContainer
                    style={{
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      onClick={() => getDealClick(deal)}
                      variant="contained"
                      style={{
                        height: '40px',
                        width: lang === 'ar' ? '150px' : '130px',
                        backgroundColor: '#00d6bc',
                        fontSize: lang === 'ar' ? '1.2rem' : '0.9rem',
                        textTransform: 'capitalize',
                        textAlign: 'center',
                      }}
                      className="btn btn-secondary listed-inquiry"
                    >
                      {deal?.price ? trans.get_deal : trans.get_quote}
                    </Button>
                    {deal?.test_drive_url && (
                      <Button
                        onClick={() => {
                          if (deal.test_drive_url) {
                            return handleTestDriveClick(deal?.test_drive_url);
                          }
                        }}
                        style={{
                          height: '40px',
                          width: lang === 'ar' ? '150px' : '130px',
                          color: '#fff',
                          borderColor: 'orange',
                          backgroundColor: 'orange',
                          fontSize: lang === 'ar' ? '1.2rem' : '0.9rem',
                          textTransform: 'capitalize',
                          textAlign: 'center',
                          textJustify: 'initial',
                          gap: lang === 'ar' ? '5px' : '0px',
                        }}
                        variant="contained"
                        startIcon={lang === 'en' && <GiSteeringWheel />}
                        endIcon={lang === 'ar' && <GiSteeringWheel />}
                      >
                        {trans.test_drive}
                      </Button>
                    )}
                  </FeaturedFrameButtonContainer>
                ) : (
                  trans.no_deals_available
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="listed-featured-details__no-deals">
          <p className="listed-featured-details__no-deals-text">
            {trans.no_deals_available}
          </p>
        </div>
      )}
    </FeaturedDetailsStyled>
  );
};
