import { useMutation } from 'react-query';
import { deactivateMe, updateAccountDetails } from '../ApiService';
import { queryClient } from '../../App';

const USER_KEY = ['user'];

export const useDeactivateAccount = () => {
  return useMutation({
    mutationFn: deactivateMe,
    onSuccess: () => {
      queryClient.invalidateQueries(USER_KEY);
    },
  });
};

export const useUpdateUserProfile = () => {
  return useMutation({
    mutationFn: (data: any) => updateAccountDetails(data),
    onSuccess: () => {
      queryClient.invalidateQueries(USER_KEY);
    },
  });
};
