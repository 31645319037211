import authenticatedApi from '../../../instance/ApiInstance';
import { IBikeEngineType } from '../../../types/resources/bikes';
import {
  IBatteryCapacity,
  IBatteryRange,
  ICylinder,
  IEngineSize,
  IOptionalFeature,
  ITransmission,
  IVariant,
} from '../../../types/resources/cars';

const engineTypeEndpoint = '/v2/resources/bikes/engine-types';

export interface IBikeEngineTypeResources {
  bike_battery_capacities: IBatteryCapacity[];
  bike_cylinders: ICylinder[];
  bike_engine_sizes: IEngineSize[];
  bike_optional_features: IOptionalFeature[];
  bike_transmissions: ITransmission[];
  bike_variants: IVariant[];
  bike_battery_ranges: IBatteryRange[];
}

export interface IBikeEngineTypeWithResources
  extends IBikeEngineType,
    IBikeEngineTypeResources {}

export const getBikeEngineTypes = async (): Promise<
IBikeEngineTypeWithResources[]
> => {
  const response = await authenticatedApi.get(
    `${engineTypeEndpoint}/resources`,
  );
  return response.data;
};
