import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import { IUsedBike } from '../../../types/bikes';
import { IBrand, ICondition, IModel } from '../../../types/resources/cars';
import { getUsedCarYearsArray } from '../../../helpers/years';
import { IBikeEngineTypeWithResources } from '../../../common/apis/bikes';
import {
  useBikeBrands,
  useModelsForUsedBikes,
} from '../../../common/hooks/bikes';
import { useGetBikeTrims } from '../../../common/hooks/bikes/useBikeTrims';
import { useGetBikeConditions } from '../../../common/hooks/bikes/useCondition';
import { useGetBikeEngineTypes } from '../../../common/hooks/bikes/useEngineTypes';
import { useGetBikeColors } from '../../../common/hooks/bikes/useColors';
import { useGetBikeOptionalFeatures } from '../../../common/hooks/bikes/useOptionalFeature';
import {
  useCreateUsedBike,
  useUpdateUsedBike,
  useUploadUsedBikeImage,
} from '../../UsedBikes';
import { v4 as uuidv4 } from 'uuid';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import PageLoader from '../../Other/PageLoader/PageLoaderComponent';
import { IBikeTrim } from '../../../types/resources/bikes/trims';
import PhoneInput from 'react-phone-input-2';
import OptionalFeaturesListItem from '../../Listings/common/OptionalFeaturesListItem';
import {
  ColLg12,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  MainHeadP,
  Row,
} from '../../../common/styles/CommonStyles';
import '../SellYourCar/sellYourCar-styles.css';
import '../../../styles/css/common.css';

const IMAGE_LIMIT = 15;
const SellYourBikeComponent = () => {
  const navigate = useNavigate();
  const { lang, trans, userData, country } = useContext(AppContext);
  const { state }: { state: IUsedBike } = useLocation();

  const [selectedBrand, setSelectedBrand] = useState<number | undefined>(
    state?.bike_model?.brand?.id,
  );
  const [selectedModel, setSelectedModel] = useState<number | undefined>(
    state?.bike_model_id,
  );
  const [selectedType, setSelectedType] = useState<number | undefined>(
    state?.bike_type_id,
  );
  const [selectedTrim, setSelectedTrim] = useState<number | undefined>(
    state?.bike_trim_id,
  );

  const [selectedYear, setSelectedYear] = useState<number | undefined>(
    state?.year,
  );
  const [selectedCondition, setSelectedCondition] = useState(
    state?.bike_condition_id,
  );
  const [selectedEngineType, setSelectedEngineType] = useState<
    number | undefined
  >(state?.bike_engine_type_id);
  const [selectedTransmission, setSelectedTransmission] = useState<
    number | undefined
  >(state?.bike_transmission_id);
  const [selectedEngineSize, setSelectedEngineSize] = useState<
    number | undefined
  >(state?.bike_engine_size_id);
  const [selectedCylinders, setSelectedCylinders] = useState<
    number | undefined
  >(state?.bike_cylinder_id);
  const [selectedBatteryCapacity, setSelectedBatteryCapacity] = useState<
    number | undefined
  >(state?.bike_battery_capacity_id);
  const [selectedBatteryRange, setSelectedBatteryRange] = useState<
    number | undefined
  >(state?.bike_battery_range_id);
  const [selectedColor, setSelectedColor] = useState<number | undefined>(
    state?.bike_color_id,
  );

  const [selectedPrice, setSelectedPrice] = useState(state?.price);
  const [selectedMileage, setSelectedMileage] = useState(state?.mileage);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(
    state?.mobile_number,
  );
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [descriptionLength, setDescriptionLength] = useState<number>(
    state?.description?.length ?? 0,
  );
  const [selectedOptionalFeatures, setSelectedOptionalFeatures] = useState<
    number[]
  >([]);

  const [filteredTypes, setFilteredTypes] = useState<
    IModel['types'] | undefined
  >([]);

  const [filesError, setFilesError] = useState('');

  const years = getUsedCarYearsArray();

  const [transmissions, setTransmissions] = useState<
    IBikeEngineTypeWithResources['bike_transmissions'] | undefined
  >([]);
  const [engineSizes, setEngineSizes] = useState<
    IBikeEngineTypeWithResources['bike_engine_sizes'] | undefined
  >([]);
  const [cylinders, setCylinders] = useState<
    IBikeEngineTypeWithResources['bike_cylinders'] | undefined
  >([]);
  const [batteryCapacities, setBatteryCapacities] = useState<
    IBikeEngineTypeWithResources['bike_battery_capacities'] | undefined
  >([]);
  const [batteryRanges, setBatteryRanges] = useState<
    IBikeEngineTypeWithResources['bike_battery_ranges'] | undefined
  >([]);

  const [mileageError, setMileageError] = useState('');
  const [colorError, setColorError] = useState('');
  const [transmissionError, setTransmissionError] = useState('');
  const [carDetailError, setCarDetailError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [yearError, setYearError] = useState('');
  const [brandError, setBrandError] = useState('');
  const [modelError, setModelError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [engineSizeError, setEngineSizeError] = useState('');
  const [cylindersError, setCylindersError] = useState('');
  const [batteryCapacityError, setBatteryCapacityError] = useState('');
  const [batteryRangeError, setBatteryRangeError] = useState('');
  const [engineTypeError, setEngineTypeError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const addedImages = useRef<any>([]);
  const removedImages = useRef<any>([]);
  const fileInputRef = useRef<any>(null);
  const descriptionInputRef = useRef<any>(null);

  const { data: brands, isLoading: isLoadingBrands } = useBikeBrands({
    enabled: true,
  });

  const { data: models, isLoading: isLoadingModels } = useModelsForUsedBikes({
    enabled: !!selectedBrand,
    params: {
      brand: selectedBrand,
    },
  });

  const { data: trims, isLoading: isLoadingTrims } = useGetBikeTrims({
    enabled: !!selectedModel,
    model: selectedModel,
  });

  const { data: conditions, isLoading: isLoadingConditions } =
    useGetBikeConditions();

  const { data: engineTypes, isLoading: isLoadingEngineTypes } =
    useGetBikeEngineTypes();

  const { data: colors, isLoading: isLoadingColors } = useGetBikeColors();

  const { data: optionalFeatures, isLoading: isLoadingOptionalFeatures } =
    useGetBikeOptionalFeatures();

  const { mutateAsync: uploadUsedBikeImage, isLoading: isImageUploading } =
    useUploadUsedBikeImage();

  const { mutateAsync: createUsedBike, isLoading: isCreating } =
    useCreateUsedBike();

  const { mutateAsync: updateUsedBike, isLoading: isUpdating } =
    useUpdateUsedBike();

  const handleOptionalFeatureChange = (id: number) => {
    const index = selectedOptionalFeatures.indexOf(id);
    if (index === -1) {
      setSelectedOptionalFeatures([...selectedOptionalFeatures, id]);
    } else {
      const updatedNumbers = [...selectedOptionalFeatures];
      updatedNumbers.splice(index, 1);
      setSelectedOptionalFeatures(updatedNumbers);
    }
  };

  const handleBrandChange = (e: any) => {
    setFilteredTypes([]);
    setSelectedModel(undefined);
    setSelectedBrand(parseInt(e.target.value));
  };

  useEffect(() => {
    if (optionalFeatures) {
      let _optionalFeatures: any = [];
      optionalFeatures.map(optionalFeature => {
        let feature = state?.optional_features?.filter(
          f => f.id === optionalFeature.id,
        )[0];
        if (feature) _optionalFeatures.push(feature.id);
      });
      setSelectedOptionalFeatures(_optionalFeatures);
    }
  }, [optionalFeatures]);

  useEffect(() => {
    if (models && state?.bike_model?.id && models.length > 0) {
      setFilteredTypes(
        models.find(model => model.id === state.bike_model?.id)?.types,
      );
    }
  }, [models]);

  useEffect(() => {
    if (engineTypes && state?.bike_engine_type_id && engineTypes.length > 0) {
      setCylinders(
        engineTypes.find(
          engineType => engineType.id === state.bike_engine_type_id,
        )?.bike_cylinders,
      );
      setSelectedCylinders(state?.bike_cylinder_id);

      setBatteryRanges(
        engineTypes.find(
          engineType => engineType.id === state.bike_engine_type_id,
        )?.bike_battery_ranges,
      );
      setSelectedBatteryCapacity(state?.bike_battery_capacity_id);

      setBatteryCapacities(
        engineTypes.find(
          engineType => engineType.id === state.bike_engine_type_id,
        )?.bike_battery_capacities,
      );
      setSelectedBatteryRange(state?.bike_battery_range_id);

      setEngineSizes(
        engineTypes.find(
          engineType => engineType.id === state.bike_engine_type_id,
        )?.bike_engine_sizes,
      );
      setSelectedEngineSize(state?.bike_engine_size_id);

      setTransmissions(
        engineTypes.find(
          engineType => engineType.id === state.bike_engine_type_id,
        )?.bike_transmissions,
      );
      setSelectedTransmission(state?.bike_transmission_id);
    }
  }, [engineTypes]);

  const handleModelChange = (e: any) => {
    if (e && e.target && e.target.value) {
      const types = models?.filter(
        (m: any) => m.id === parseInt(e.target.value),
      )[0]?.types;
      setFilteredTypes(types);
    } else {
      setFilteredTypes([]);
    }

    setSelectedType(undefined);
    setSelectedTrim(undefined);
    setSelectedModel(parseInt(e.target.value));
  };

  const handleTrimChange = (e: any) => {
    setSelectedTrim(parseInt(e.target.value));
  };

  const handleTypeChange = (e: any) => {
    setSelectedType(parseInt(e.target.value));
  };

  const handleYearChange = (e: any) => {
    setSelectedYear(parseInt(e.target.value));
  };

  const handleConditionChange = (e: any) => {
    setSelectedCondition(parseInt(e.target.value));
  };

  const handleEngineTypeChange = (e: any) => {
    if (e && e.target && e.target.value) {
      const cylinders = engineTypes?.filter(
        (engineType: IBikeEngineTypeWithResources) =>
          engineType.id === parseInt(e.target.value),
      )[0]?.bike_cylinders;

      setCylinders(cylinders);

      const batteryRanges = engineTypes?.filter(
        (engineType: IBikeEngineTypeWithResources) =>
          engineType.id === parseInt(e.target.value),
      )[0]?.bike_battery_ranges;
      setBatteryRanges(batteryRanges);

      const batteryCapacities = engineTypes?.filter(
        (engineType: IBikeEngineTypeWithResources) =>
          engineType.id === parseInt(e.target.value),
      )[0]?.bike_battery_capacities;
      setBatteryCapacities(batteryCapacities);

      const engineSizes = engineTypes?.filter(
        (engineType: IBikeEngineTypeWithResources) =>
          engineType.id === parseInt(e.target.value),
      )[0]?.bike_engine_sizes;
      setEngineSizes(engineSizes);

      const transmissions = engineTypes?.filter(
        (engineType: IBikeEngineTypeWithResources) =>
          engineType.id === parseInt(e.target.value),
      )[0]?.bike_transmissions;
      setTransmissions(transmissions);
    } else {
      setCylinders([]);
      setBatteryRanges([]);
      setBatteryCapacities([]);
      setEngineSizes([]);
      setTransmissions([]);
    }

    setSelectedCylinders(undefined);
    setSelectedBatteryCapacity(undefined);
    setSelectedBatteryRange(undefined);
    setSelectedEngineSize(undefined);
    setSelectedTransmission(undefined);
    setSelectedEngineType(parseInt(e.target.value));
  };

  const handleTransmissionChange = (e: any) => {
    setSelectedTransmission(parseInt(e.target.value));
  };

  const handleEngineSizeChange = (e: any) => {
    setSelectedEngineSize(parseInt(e.target.value));
  };

  const handleCylindersChange = (e: any) => {
    setSelectedCylinders(parseInt(e.target.value));
  };

  const handleBatteryCapacityChange = (e: any) => {
    setSelectedBatteryCapacity(parseInt(e.target.value));
  };

  const handleBatteryRangeChange = (e: any) => {
    setSelectedBatteryRange(parseInt(e.target.value));
  };

  const handleColorChange = (e: any) => {
    setSelectedColor(parseInt(e.target.value));
  };

  const handlePriceChange = (e: any) => {
    setSelectedPrice(parseInt(e.target.value));
  };

  const handleMileageChange = (e: any) => {
    if (e.target.value === '') setSelectedMileage(0);
    setSelectedMileage(parseInt(e.target.value));
  };

  const handlePhoneNumberChange = (e: any) => {
    setSelectedPhoneNumber(e);
  };

  const handleFilesChange = async (e: any) => {
    if (e && e.target && e.target.files) {
      let _selectedFiles = [...selectedFiles];
      Array.from(e.target.files).forEach(async (file: any) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async function () {
          if (_selectedFiles.length >= IMAGE_LIMIT) {
            setFilesError(trans.at_most_8_photo_video_allowed);
            return;
          }
          file['uid'] = uuidv4();
          _selectedFiles.push({
            file: file,
            meta: { uri: reader.result, existing: false },
            status: 'done',
          });
          setSelectedFiles(_selectedFiles);
          try {
            const response = await uploadUsedBikeImage(file);
            if (!response) {
              setFilesError('Image upload failed. Please try again.');
            } else {
              setFilesError('');
              const addedImage = { ...response, file };
              addedImages.current = [...addedImages.current, addedImage];
            }
          } catch (error) {
            console.error('Image upload failed:', error);
          } finally {
            fileInputRef.current.value = null;
          }
        };
      });
    }
  };

  useEffect(() => {
    const loadExistingImages = async (): Promise<Array<any>> => {
      if (!state?.images) return [];
      let tempSelectedFiles = new Array<any>();

      await Promise.all(
        state?.images?.map(async (image: string) => {
          const result = await urlToObject(image);
          result['uid'] = uuidv4();
          tempSelectedFiles.push({
            file: result,
            meta: {
              existing: true,
            },
            status: 'old',
          });
        }),
      );

      return tempSelectedFiles;
    };
    loadExistingImages().then(images => {
      setSelectedFiles(images);
    });
  }, []);

  async function urlToObject(fileName: any) {
    const response = await fetch(fileName);
    // here file is url/location of image
    const blob = await response.blob();
    const __file = new File([blob], fileName, { type: blob.type });
    return __file;
  }

  const getImageSource = (selectedFile: any) => {
    if (!state?.images) {
      return URL.createObjectURL(selectedFile.file);
    }
    if (selectedFile?.meta?.existing) {
      return selectedFile?.file?.name;
    }

    return URL.createObjectURL(selectedFile.file);
  };
  const removeSelectedFile = (selectedFile: any) => {
    if (selectedFile?.status === 'old') {
      removedImages.current = [
        ...removedImages.current,
        selectedFile?.file?.name,
      ];
    }
    let _selectedFiles = [...selectedFiles];
    _selectedFiles = _selectedFiles.filter(
      (f: any) => f?.file?.uid !== selectedFile?.file?.uid,
    );
    setSelectedFiles(_selectedFiles);
  };

  const ValidateRequiredFields = (form: any) => {
    let isValid = true;

    if (isNaN(form.mileage)) {
      setMileageError(trans.mileage_required);
      isValid = false;
    }
    if (!form.bike_color_id) {
      setColorError(trans.color_required);
      isValid = false;
    }
    if (!form.bike_transmission_id) {
      setTransmissionError(trans.transmission_required);
      isValid = false;
    }
    if (!form.description) {
      setCarDetailError(trans.bikeDetails_required);
      isValid = false;
    }
    if (!form.price) {
      setPriceError(trans.price_required);
      isValid = false;
    }
    if (!form.year) {
      setYearError(trans.year_required);
      isValid = false;
    }
    if (!form.bike_brand_id) {
      setBrandError(trans.brand_required);
      isValid = false;
    }
    if (!form.bike_model_id) {
      setModelError(trans.model_required);
      isValid = false;
    }
    if (!form.bike_type_id) {
      setTypeError(trans.type_required);
      isValid = false;
    }
    if (selectedEngineType !== 3 && form.bike_engine_size === '') {
      setEngineSizeError(trans.enginesize_required);
      isValid = false;
    }
    if (selectedEngineType !== 3 && form.bike_cylinders === '') {
      setCylindersError(trans.cylinders_required);
      isValid = false;
    }
    if (
      (selectedEngineType === 3 ||
        (selectedEngineType !== 2 && selectedEngineType !== 1)) &&
      form.bike_battery_capacity === ''
    ) {
      setBatteryCapacityError(trans.batterycapacity_required);
      isValid = false;
    }
    if (
      (selectedEngineType === 3 ||
        (selectedEngineType !== 2 && selectedEngineType !== 1)) &&
      form.bike_battery_range === ''
    ) {
      setBatteryRangeError(trans.batteryrange_required);
      isValid = false;
    }
    if (!form.bike_engine_type_id) {
      setEngineTypeError(trans.enginetype_required);
      isValid = false;
    }
    if (!form.mobile_number) {
      setPhoneNumberError(trans.mobile_required);
      isValid = false;
    }

    if (selectedFiles.length === 0) {
      setFilesError(trans.at_least_1_photo_video_required);
      isValid = false;
    }
    if (form[`images[${IMAGE_LIMIT + 1}]`]) {
      setFilesError(trans.at_most_8_photo_video_allowed);
      isValid = false;
    }

    return isValid;
  };

  const submitSellBike = async () => {
    setMileageError('');
    setColorError('');
    setTransmissionError('');
    setCarDetailError('');
    setPriceError('');
    setYearError('');
    setBrandError('');
    setModelError('');
    setTypeError('');
    setEngineSizeError('');
    setCylindersError('');
    setBatteryCapacityError('');
    setBatteryRangeError('');
    setEngineTypeError('');
    setPhoneNumberError('');
    setFilesError('');

    let form: any = {};

    //Add required fields first
    form.mileage = selectedMileage;
    form.bike_color_id = selectedColor;
    form.bike_transmission_id = selectedTransmission;
    form.description = descriptionInputRef.current.value;
    form.price = selectedPrice;
    form.year = selectedYear;
    form.bike_brand_id = selectedBrand;
    form.bike_model_id = selectedModel;
    form.bike_type_id =
      filteredTypes?.length === 1 ? filteredTypes[0].id : selectedType;
    form.bike_trim_id = trims?.length === 1 ? trims[0].id : selectedTrim;
    form.user_id = userData.id;
    form.bike_engine_type_id = selectedEngineType;

    if (selectedEngineType !== 3) {
      form.bike_cylinder_id = selectedCylinders;
      form.bike_engine_size = selectedEngineSize;
    }

    if (
      selectedEngineType === 3 ||
      (selectedEngineType !== 2 && selectedEngineType !== 1)
    ) {
      form.bike_battery_capacity_id = selectedBatteryCapacity;
      form.bike_battery_range_id = selectedBatteryRange;
    }
    form.mobile_number = selectedPhoneNumber;

    const isValid = ValidateRequiredFields(form);
    if (!isValid) {
      return;
    }

    form.bike_condition_id = selectedCondition;
    form.bike_engine_size_id = selectedEngineSize;

    form.optional_features_ids = selectedOptionalFeatures;
    form.add_images = addedImages.current.map(image => {
      return { id: image.id };
    });

    if (state) {
      form.remove_images = removedImages.current.map(image => {
        return { url: image };
      });
      const response = await updateUsedBike({ id: state.id, data: form });
      if (response) {
        navigate(`/${country}/${lang}/used-bikes`);
      } else {
        alert('Error');
      }
    } else {
      const response = await createUsedBike(form);
      if (response) {
        navigate(`/${country}/${lang}/used-bikes`);
      } else {
        alert('Error');
      }
    }
  };
  return (
    <>
      <MetaTag
        href={`/${country}/${lang}/sell-your-bike`}
        metaKey="sellYourBike"
      />
      {isLoadingBrands ||
        isLoadingConditions ||
        isLoadingEngineTypes ||
        isLoadingColors ||
        isLoadingOptionalFeatures ||
        isLoadingModels ||
        isLoadingTrims ||
        isCreating ||
        (isUpdating && <PageLoader />)}
      <main id="main" className="sell_your_car_data">
        <MainHead>
          <MainHeadContainer className="container">
            <Row>
              <ColLg12>
                <MainHeadH1>{trans.sellYourBike}</MainHeadH1>
                <MainHeadP>
                  {trans.sellYourBike} {trans.with} ONE AUTOCAR
                </MainHeadP>
              </ColLg12>
            </Row>
          </MainHeadContainer>
        </MainHead>
        <div className="container sell-your-car">
          <div className="row">
            <div className="col-12">
              <form className="row generic-form sell-car-form">
                <input
                  type="hidden"
                  name="_token"
                  value="zmffz6iN7Hniq6OaUYa2MyRgdN6vyDI46SOPQuji"
                />
                <div className="col-lg-12">
                  <h3>{trans.bike_details}</h3>
                </div>

                <div className="col-md-4 col-sm-6 form-group">
                  <select
                    id="bike_brand_id"
                    name="bike_brand_id"
                    className="custom-select brand"
                    value={selectedBrand}
                    onChange={handleBrandChange}
                  >
                    <option value="">{trans.select_brand} *</option>
                    {brands?.map((brand: IBrand) => (
                      <option key={brand.id} value={brand.id}>
                        {brand.name}
                      </option>
                    ))}
                  </select>
                  <span className="errorMessage">{brandError}</span>
                </div>

                <div className="col-md-4 col-sm-6 form-group">
                  <select
                    className="custom-select Model"
                    name="bike_model_id"
                    id="bike_model_id"
                    value={models ? selectedModel : undefined}
                    onChange={handleModelChange}
                    disabled={models?.length === 0 || !models}
                  >
                    <option value="">{trans.select_model} *</option>
                    {models?.map((model: IModel) => (
                      <option key={model.id} value={model.id}>
                        {model.name}
                      </option>
                    ))}
                  </select>
                  <span className="errorMessage">{modelError}</span>
                </div>

                <div className="col-md-4 col-sm-6 form-group">
                  <select
                    className="custom-select"
                    name="bike_trim_id"
                    id="bike_trim_id"
                    value={
                      trims?.length === 1
                        ? trims[0].id
                        : selectedTrim
                        ? selectedTrim
                        : undefined
                    }
                    onChange={handleTrimChange}
                    disabled={trims?.length === 0}
                  >
                    <option value="">{trans.select_trim}</option>
                    {trims?.map((trim: IBikeTrim) => (
                      <option key={trim.id} value={trim.id}>
                        {trim.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-4 col-sm-6 form-group">
                  <select
                    className="custom-select type"
                    name="bike_type_id"
                    id="bike_type_id"
                    value={
                      filteredTypes?.length === 1
                        ? filteredTypes[0]?.id
                        : selectedType
                    }
                    onChange={handleTypeChange}
                    disabled={filteredTypes?.length === 0}
                  >
                    <option value="">{trans.select_type} *</option>
                    {filteredTypes?.map(type => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                  <span className="errorMessage">{typeError}</span>
                </div>

                <div className="col-md-4 col-sm-6 form-group">
                  <select
                    itemType="number"
                    name="year"
                    id="years"
                    className="custom-select"
                    value={selectedYear ?? undefined}
                    onChange={handleYearChange}
                  >
                    <option value="">{trans.year} *</option>
                    {years.map(year => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <span className="errorMessage">{yearError}</span>
                </div>

                <div className="col-md-4 col-sm-6 form-group">
                  <select
                    name="bike_condition"
                    id="bike_condition"
                    className="custom-select condition"
                    value={selectedCondition}
                    onChange={handleConditionChange}
                  >
                    <option value="">{trans.car_condition}</option>
                    {conditions?.map((condition: ICondition) => (
                      <option key={condition.id} value={condition.id}>
                        {condition.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-4 col-sm-6 form-group">
                  <select
                    name="bike_engine_type"
                    id="bike_engine_type"
                    className="custom-select enginetype engine_type"
                    value={selectedEngineType ?? undefined}
                    onChange={handleEngineTypeChange}
                  >
                    <option value="">{trans.engine_type} *</option>
                    {engineTypes?.map((engineType: any) => (
                      <option key={engineType.id} value={engineType.id}>
                        {engineType.name}
                      </option>
                    ))}
                  </select>
                  <span className="errorMessage">{engineTypeError}</span>
                </div>

                <div className="col-md-4 col-sm-6 form-group">
                  <select
                    name="bike_transmission"
                    id="bike_transmission"
                    className="custom-select transmission applyenginetype"
                    value={selectedTransmission ?? undefined}
                    onChange={handleTransmissionChange}
                  >
                    <option value="">{trans.transmission} *</option>
                    {transmissions?.map((transmission: any) => (
                      <option key={transmission.id} value={transmission.id}>
                        {transmission.name}
                      </option>
                    ))}
                  </select>
                  <span className="errorMessage">{transmissionError}</span>
                </div>

                {selectedEngineType !== 3 && (
                  <div className="col-md-4 col-sm-6 form-group fuel-cars">
                    <select
                      name="bike_engine_size"
                      id="bike_engine_size"
                      className="custom-select engine_size applyenginetype"
                      value={selectedEngineSize ?? undefined}
                      onChange={handleEngineSizeChange}
                    >
                      <option value="">{trans.engine_size} *</option>
                      {engineSizes?.map((engineSize: any) => (
                        <option key={engineSize.id} value={engineSize.id}>
                          {engineSize.name}
                        </option>
                      ))}
                    </select>
                    <span className="errorMessage">{engineSizeError}</span>
                  </div>
                )}

                {selectedEngineType !== 3 && (
                  <div className="col-md-4 col-sm-6 form-group fuel-cars">
                    <select
                      name="bike_cylinder_id"
                      id="bike_cylinder_id"
                      className="custom-select cylinders applyenginetype"
                      value={selectedCylinders ?? undefined}
                      onChange={handleCylindersChange}
                    >
                      <option value="">{trans.cylinders} *</option>
                      {cylinders?.map((cylinder: any) => (
                        <option key={cylinder.id} value={cylinder.id}>
                          {cylinder.name}
                        </option>
                      ))}
                    </select>
                    <span className="errorMessage">{cylindersError}</span>
                  </div>
                )}

                {(selectedEngineType === 3 ||
                  (selectedEngineType !== 2 && selectedEngineType !== 1)) && (
                  <div className="col-md-4 col-sm-6 form-group electric-car">
                    <select
                      className="custom-select applyenginetype battery_capacity"
                      name="bike_battery_capacity_id"
                      id="bike_battery_capacity_id"
                      value={selectedBatteryCapacity ?? undefined}
                      onChange={handleBatteryCapacityChange}
                    >
                      <option value="">{trans.battery_capacity} *</option>
                      {batteryCapacities?.map((batteryCapacity: any) => (
                        <option
                          key={batteryCapacity.id}
                          value={batteryCapacity.id}
                        >
                          {batteryCapacity.name}
                        </option>
                      ))}
                    </select>
                    <span className="errorMessage">{batteryCapacityError}</span>
                  </div>
                )}

                {(selectedEngineType === 3 ||
                  (selectedEngineType !== 2 && selectedEngineType !== 1)) && (
                  <div className="col-md-4 col-sm-6 form-group electric-car">
                    <select
                      className="custom-select applyenginetype battery_range"
                      name="bike_battery_range_id"
                      id="bike_battery_range_id"
                      value={selectedBatteryRange ?? undefined}
                      onChange={handleBatteryRangeChange}
                    >
                      <option value="">{trans.battery_range} *</option>
                      {batteryRanges?.map((batteryRange: any) => (
                        <option key={batteryRange.id} value={batteryRange.id}>
                          {batteryRange.name}
                        </option>
                      ))}
                    </select>
                    <span className="errorMessage">{batteryRangeError}</span>
                  </div>
                )}

                <div className="col-md-4 col-sm-6 form-group">
                  <select
                    name="bike_color"
                    id="bike_color"
                    className="custom-select color"
                    value={selectedColor ?? undefined}
                    onChange={handleColorChange}
                  >
                    <option value="">{trans.color} *</option>
                    {colors?.map((Color: any) => (
                      <option key={Color.id} value={Color.id}>
                        {Color.name}
                      </option>
                    ))}
                  </select>
                  <span className="errorMessage">{colorError}</span>
                </div>

                <div className="col-12 bordered-row">
                  <div className="row">
                    <div className="col-md-3 col-sm-4 form-group">
                      <label htmlFor="price">
                        {trans.price} ({trans.qar}) *
                      </label>
                      <input
                        className="form-control"
                        id="price"
                        name="price"
                        type="number"
                        min="0"
                        value={selectedPrice}
                        onChange={handlePriceChange}
                      />
                      <span className="errorMessage">{priceError}</span>
                    </div>

                    <div className="col-md-3 col-sm-4 offset-md-1 form-group arabic-offset">
                      <label htmlFor="mileage">
                        {trans.mileage} ({trans.kms}) *
                      </label>
                      <input
                        className="form-control"
                        id="mileage"
                        name="mileage"
                        type="number"
                        min="0"
                        value={selectedMileage}
                        onChange={handleMileageChange}
                      />
                      <span className="errorMessage">{mileageError}</span>
                    </div>

                    <div className="col-md-3 col-sm-4 offset-md-1 form-group arabic-offset">
                      <label
                        className="mobile_code required"
                        htmlFor="mobile_code"
                      >
                        {trans.phone_number}
                      </label>
                      <PhoneInput
                        country="qa"
                        inputClass="custom-tel"
                        value={selectedPhoneNumber}
                        onChange={handlePhoneNumberChange}
                        placeholder=""
                        masks={{ qa: '........' }}
                      />
                      <span className="errorMessage">{phoneNumberError}</span>
                    </div>
                  </div>
                </div>

                <div className="col-xl-12 form-group switch">
                  <ul className="switch-buttons list-inline optional-features-list-syc-container optional-features-list-container">
                    {optionalFeatures?.map(optionalFeature => {
                      const isSelected = selectedOptionalFeatures.includes(
                        optionalFeature.id,
                      );
                      return (
                        <li key={optionalFeature.id}>
                          <OptionalFeaturesListItem
                            name={optionalFeature.name}
                            onClick={() =>
                              handleOptionalFeatureChange(optionalFeature.id)
                            }
                            checked={isSelected}
                            className={`optional-features-list-syc-item optional-feature-list-item  ${
                              isSelected && 'optional-feature-list-item-active'
                            }`}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="col-xl-12 form-group">
                  <label htmlFor="files">{trans.photos_videos} *</label>
                  <div className="upload-file">
                    <output id="Filelist">
                      <ul className="thumb-Images" id="imgList">
                        {selectedFiles.map((selectedFile: any) => {
                          return (
                            <li key={selectedFile?.file.uid}>
                              <div className="img-wrap">
                                <span
                                  className="close"
                                  onClick={() =>
                                    removeSelectedFile(selectedFile)
                                  }
                                >
                                  ×
                                </span>
                                <img
                                  className="thumb"
                                  src={getImageSource(selectedFile)}
                                  title={selectedFile?.file?.name}
                                  alt=""
                                />
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </output>
                    <span className="btn btn-success fileinput-button">
                      <span>+</span>
                      <input
                        ref={fileInputRef}
                        type="file"
                        name="files[]"
                        id="files"
                        accept="image/jpeg, image/png, image/gif, video/mp4, video/3gp"
                        onChange={handleFilesChange}
                      />
                      <br />
                    </span>
                  </div>
                  <div id="file-count" className="text-muted float-right">
                    <span id="file-current">{selectedFiles.length ?? 0}</span>
                    <span id="file-maximum">/ {IMAGE_LIMIT}</span>
                  </div>
                  <span className="errorMessage">{filesError}</span>
                </div>

                <div className="col-xl-12 form-group">
                  <label htmlFor="Details">{trans.bike_details} *</label>
                  <textarea
                    ref={descriptionInputRef}
                    name="description"
                    className="form-control description"
                    id="Details"
                    rows={3}
                    defaultValue={state?.description}
                    placeholder={trans.bike_details}
                    maxLength={600}
                    onChange={e => setDescriptionLength(e.target.value.length)}
                  ></textarea>
                  <div id="the-count" className="text-muted float-right">
                    <span id="current">{descriptionLength}</span>
                    <span id="maximum">/ 600</span>
                  </div>
                  <br />
                  <span className="errorMessage">{carDetailError}</span>
                </div>
                <div className="col-xl-12 form-group buttons">
                  <button
                    type="button"
                    disabled={isImageUploading}
                    style={{ cursor: isImageUploading ? 'not-allowed' : '' }}
                    className="btn btn-primary publish"
                    onClick={submitSellBike}
                  >
                    {trans.save}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SellYourBikeComponent;
