export const AirbagsIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="35"
      height="37"
      viewBox="0 0 35 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2993 10.1257C23.5107 10.1267 22.7547 10.4404 22.1971 10.9981C21.6396 11.5558 21.326 12.3118 21.3252 13.1004V20.0891L19.9478 18.7117C19.8351 18.599 19.6824 18.5358 19.5231 18.5358C19.3638 18.5358 19.211 18.599 19.0984 18.7117L17.2963 20.5138C17.1836 20.6264 17.1204 20.7792 17.1204 20.9385C17.1204 21.0978 17.1836 21.2505 17.2963 21.3632L21.3252 25.3921V28.147H10.468C9.52428 28.1479 8.61948 28.5232 7.95211 29.1905C7.28474 29.8577 6.90932 30.7625 6.9082 31.7062V32.9977C6.90932 33.9414 7.28474 34.8462 7.95211 35.5134C8.61948 36.1807 9.52428 36.556 10.468 36.5569H26.761C27.5134 36.5548 28.237 36.2671 28.7853 35.7519C29.3336 35.2367 29.6658 34.5324 29.7147 33.7816L31.7133 35.7802C31.8259 35.8928 31.9787 35.9561 32.138 35.9561C32.2972 35.9561 32.45 35.8928 32.5627 35.7802L34.3648 33.9781C34.4774 33.8654 34.5407 33.7126 34.5407 33.5534C34.5407 33.3941 34.4774 33.2413 34.3648 33.1287L29.7351 28.499V13.1004C29.7343 12.3118 29.4207 11.5558 28.8632 10.9981C28.3056 10.4404 27.5496 10.1267 26.761 10.1257H24.2993ZM28.5337 33.5822C28.5332 34.0523 28.3463 34.5029 28.014 34.8354C27.6817 35.1678 27.2311 35.3549 26.761 35.3555H10.468C9.84282 35.3549 9.24342 35.1063 8.80129 34.6642C8.35916 34.2222 8.11041 33.6229 8.10962 32.9977V31.7062C8.11041 31.081 8.35916 30.4817 8.80129 30.0396C9.24342 29.5976 9.84282 29.349 10.468 29.3484H21.9259C22.0852 29.3484 22.238 29.2851 22.3507 29.1725C22.4633 29.0598 22.5266 28.907 22.5266 28.7477V26.5936L28.5337 32.6006V33.5822ZM32.138 34.5061L18.5704 20.9385L19.5231 19.9858L33.0907 33.5534L32.138 34.5061ZM28.5337 13.1004V27.2976L22.5266 21.2905V13.1004C22.5271 12.6304 22.714 12.1797 23.0463 11.8473C23.3786 11.5148 23.8293 11.3278 24.2993 11.3271H26.7622C27.2321 11.3281 27.6824 11.5153 28.0144 11.8477C28.3465 12.1801 28.5332 12.6306 28.5337 13.1004Z"
        fill={color}
      />
      <path
        d="M26.1533 8.92408C26.7845 8.92344 27.3897 8.67242 27.836 8.22611C28.2823 7.7798 28.5333 7.17465 28.5339 6.54347V2.89477C28.5333 2.26359 28.2823 1.65844 27.836 1.21213C27.3897 0.765814 26.7845 0.514796 26.1533 0.51416H24.9075C24.2763 0.514796 23.6711 0.765814 23.2248 1.21213C22.7785 1.65844 22.5275 2.26359 22.5269 2.89477V6.54347C22.5275 7.17465 22.7785 7.7798 23.2248 8.22611C23.6711 8.67242 24.2763 8.92344 24.9075 8.92408H26.1533ZM23.7283 6.54347V2.89477C23.7287 2.58217 23.8531 2.28252 24.0742 2.06148C24.2952 1.84044 24.5949 1.71605 24.9075 1.71558H26.1533C26.4659 1.71605 26.7656 1.84044 26.9866 2.06148C27.2077 2.28252 27.332 2.58217 27.3325 2.89477V6.54347C27.332 6.85606 27.2077 7.15572 26.9866 7.37676C26.7656 7.5978 26.4659 7.72218 26.1533 7.72266H24.9075C24.5949 7.72218 24.2952 7.5978 24.0742 7.37676C23.8531 7.15572 23.7287 6.85606 23.7283 6.54347Z"
        fill={color}
      />
      <path
        d="M5.12158 24.5157C5.5087 24.9061 5.96952 25.2158 6.47729 25.4267C6.98505 25.6375 7.52965 25.7455 8.07947 25.7442H11.7438C12.8507 25.7429 13.9118 25.3026 14.6945 24.5199C15.4772 23.7373 15.9174 22.6761 15.9187 21.5692V11.8978C15.9174 10.791 15.4772 9.72979 14.6945 8.94712C13.9118 8.16445 12.8507 7.72418 11.7438 7.72291H8.07947C7.52388 7.72178 6.97371 7.83222 6.4616 8.04767C5.94948 8.26313 5.48584 8.57921 5.09815 8.97719L0.463085 13.9198C0.358579 14.0311 0.300371 14.178 0.300293 14.3307V19.7371C0.300333 19.8192 0.317197 19.9004 0.349845 19.9757C0.382494 20.051 0.430233 20.1188 0.490117 20.175L5.12158 24.5157ZM1.50171 14.571L5.96437 9.81217C6.23972 9.53037 6.56875 9.30661 6.93203 9.15411C7.29531 9.00162 7.68548 8.92348 8.07947 8.92433H11.7438C12.5321 8.92528 13.2879 9.23886 13.8453 9.7963C14.4028 10.3537 14.7163 11.1095 14.7173 11.8978V21.5692C14.7163 22.3576 14.4028 23.1133 13.8453 23.6708C13.2879 24.2282 12.5321 24.5418 11.7438 24.5427H8.07947C7.68443 24.5429 7.29333 24.4643 6.92903 24.3116C6.56473 24.1588 6.23454 23.935 5.95777 23.6531L1.50171 19.477V14.571Z"
        fill={color}
      />
      <path
        d="M6.71606 14.3697L3.11035 15.5703L3.49 16.7105L7.09571 15.5098L6.71606 14.3697Z"
        fill={color}
      />
      <path
        d="M3.49418 17.3651L3.11426 18.5049L6.71876 19.7064L7.09868 18.5666L3.49418 17.3651Z"
        fill={color}
      />
    </svg>
  );
};
