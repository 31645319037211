import { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import {
  useBikeBrands,
  useModelsForUsedBikes,
} from '../../../common/hooks/bikes';
import { NewCarModelListPageSkeleton } from '../../NewCars/components/NewCarsCardsSkeletons';
import {
  BrowseBrandsSection,
  MainHeadingH1,
} from '../../../common/styles/CommonStyles';
import { BrowseBrands } from '../../Dealers/components';
import Banner from '../../Banners/BannerComponent';
import { ResultsNotFound } from '../../common';
import { useParams } from 'react-router-dom';
import MetaTag from '../../../common/meta/MetaTag';
import { BikeModelCard } from './ModelCard';

const NewBikesModel = () => {
  const { lang, country } = useContext(AppContext);
  const params = useParams();
  const brandName = useMemo(() => {
    if (!params.brand) return;
    return (
      params.brand.charAt(0).toUpperCase() + params.brand.slice(1)
    )?.replace(/-/g, ' ');
  }, [params.brand]);
  return (
    <>
      <MetaTag
        metaKey="newCarsBrand"
        href={`/${country}/${lang}/new-bikes/${params.brand}`}
        params={{ '%BRAND%': brandName }}
      />
      <NewBikesModels params={params} lang={lang} brandName={brandName} />
    </>
  );
};

export const NewBikesModels = ({ params, lang, brandName }) => {
  const { trans, country } = useContext(AppContext);
  const brandSlug = params.brand;

  const { data: brandsWithDeals, isLoading: isFetchingBrandsWithDeals } =
    useBikeBrands({
      params: { type: 'new' },
    });

  const [brandId, setBrandId] = useState<number | undefined>(
    brandsWithDeals?.find(b => b.slug === brandSlug)?.id,
  );

  const { data: brandModelsWithDealsData, isLoading: isFetchingBrandModels } =
    useModelsForUsedBikes({
      params: { brand: brandId, type: 'new' },
      enabled: !!brandId,
    });

  useEffect(() => {
    const brandId = brandsWithDeals?.find(b => b.slug === brandSlug)?.id;
    setBrandId(brandId);
  }, [brandSlug, brandsWithDeals]);

  const isLoading = useMemo(() => {
    return isFetchingBrandsWithDeals || isFetchingBrandModels;
  }, [isFetchingBrandsWithDeals, isFetchingBrandModels]);

  if (isLoading) {
    return <NewCarModelListPageSkeleton trans={trans} />;
  }

  return (
    <main id="main">
      <div className="container " style={{ paddingTop: '32px' }}>
        {brandModelsWithDealsData && brandModelsWithDealsData.length > 0 ? (
          <BrowseBrandsSection className="container">
            <MainHeadingH1>
              {brandName} {trans.models}
            </MainHeadingH1>
            <BrowseBrands>
              {brandModelsWithDealsData?.map(model => (
                <BikeModelCard
                  model={model}
                  key={model.id}
                  isLoading={isLoading}
                />
              ))}
              <Banner section_name="B1" />
            </BrowseBrands>
          </BrowseBrandsSection>
        ) : (
          !isLoading && (
            <ResultsNotFound lang={lang} trans={trans} country={country} />
          )
        )}
      </div>
    </main>
  );
};

export default NewBikesModel;
