import { useQuery } from 'react-query';
import { IRentalSearch, getRentalCars } from '../apis';
import { AppContext } from '../../../contexts/AppContext';
import { useContext } from 'react';

interface IRentals {
  query: IRentalSearch;
  enabled: boolean;
}

export const useGetRentalCars = ({ query, enabled }: IRentals) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['searchRentCars', query, lang],
    queryFn: () => getRentalCars(query),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: enabled,
  });
};
