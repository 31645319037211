import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import Carousel from 'react-multi-carousel';
import { IRentalCar } from '../../../types/cars/rental_car';
import { BikeListingDetailsImageGallerySkeletons } from '../../UsedBikes/components/BikeDetailsSkeletons';
import { BikeCarouselLazyImage, BikeLazyImage } from '../../UsedBikes';
import '../../Listings/common/ListingDetailsImageGallery/ListingDetailsImageGallery-styles.css';
import '../../../styles/css/swiper-bundle.css';
import { AutoImgBox } from '../../../common/styles/CommonStyles';

type RentListingDetailImageGalleryProps = {
  car: IRentalCar;
  image: Array<string>;
  cover: string;
  onImageClick: (newImage: any) => void;
  isLoading: boolean;
};
export const RentListingDetailImageGallery = ({
  car,
  image,
  cover,
  onImageClick,
  isLoading,
}: RentListingDetailImageGalleryProps) => {
  const { darkImageLoadingPlaceholder, lang, trans } = useContext(AppContext);
  const rtl = lang === 'ar';
  const responsiveFeatured = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  };

  if (isLoading) {
    return (
      <BikeListingDetailsImageGallerySkeletons
        responsiveFeatured={responsiveFeatured}
      />
    );
  }

  return (
    <>
      <div className="slick-slider-big2 slick-initialized slick-slider">
        <div className="slick-list draggable">
          <div className="slick-track">
            <div
              className="item slick-current slick-active"
              data-slick-index="0"
              aria-hidden="false"
            >
              <div className="slide-content">
                <AutoImgBox>
                  <BikeLazyImage
                    src={cover ? cover : darkImageLoadingPlaceholder}
                    defaultSrc={darkImageLoadingPlaceholder}
                    alt="img"
                  />
                </AutoImgBox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slider-nav-thumbnails2 slick-initialized slick-slider">
        {Array.isArray(image) && image.length > 0 && (
          <Carousel
            responsive={responsiveFeatured}
            swipeable={true}
            draggable={true}
            infinite={true}
            rtl={rtl}
            customLeftArrow={
              <div className="swiper-button-prev swiper-button-prev1"></div>
            }
            customRightArrow={
              <div className="swiper-button-next swiper-button-next1"></div>
            }
          >
            {image?.map((image: string) => (
              <div
                key={Math.random()}
                className={`item slick-active ${
                  image === cover ? 'slick-current' : ''
                }`}
              >
                <AutoImgBox>
                  <BikeCarouselLazyImage
                    src={image}
                    defaultSrc={darkImageLoadingPlaceholder}
                    alt="img"
                    onClick={onImageClick}
                  />
                </AutoImgBox>
              </div>
            ))}
          </Carousel>
        )}
        <div className="deals-detail-rent">
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            {car && car.description ? (
              <>
                <div
                  style={{
                    fontSize: '20px',
                    fontWeight: '900',
                  }}
                >
                  <span>{trans.description}</span>
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    lineHeight: '1.5',
                    fontFamily: 'inherit',
                  }}
                >
                  <span>{car.description ? car.description : ''}</span>
                </div>
              </>
            ) : (
              <tr>
                <td>{''}</td>
              </tr>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
