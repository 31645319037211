import { useMutation, useQuery } from 'react-query';
import {
  UserListing,
  deleteBikeListing,
  deleteFavorites,
  deleteListings,
  getFavorites,
  getMyListings,
  getNotifications,
  logout,
} from '../apis/user.apis';
import { queryClient } from '../../App';
import { IGetUsedCars } from '../../Components/UsedCars/apis/usedCarsApi';

interface UserListingParams {
  params: UserListing;
  enabled: boolean;
}

interface UserWishListParams {
  params?: IGetUsedCars;
  enabled: boolean;
}

export const useUserLogout = () => {
  return useMutation({
    mutationKey: 'user-logout',
    mutationFn: () => logout(),
  });
};

export const useUserGetMyListings = ({
  params,
  enabled,
}: UserListingParams) => {
  return useQuery({
    queryKey: ['user-get-my-listings', params],
    queryFn: () => getMyListings(params),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: enabled,
  });
};

export const useUserDeleteListings = () => {
  return useMutation({
    mutationKey: 'user-delete-listings',
    mutationFn: (carId: number) => deleteListings(carId),
  });
};

export const useUserDeleteBikeListings = () => {
  return useMutation({
    mutationKey: 'user-delete-bike-listings',
    mutationFn: (bikeId: number) => deleteBikeListing(bikeId),
  });
};

export const useUserGetFavorites = ({
  params,
  enabled,
}: UserWishListParams) => {
  return useQuery({
    queryKey: ['user-get-favorites', params, enabled],
    queryFn: () => getFavorites(params),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};

export const useUserDeleteFavorite = () => {
  return useMutation({
    mutationKey: 'user-delete-favorite',
    mutationFn: ({ id, type }: { id: number; type: string }) =>
      deleteFavorites(id, type),
    onSuccess: () => {
      queryClient.invalidateQueries('user-get-favorites');
    },
  });
};

export const useGetNotifications = () => {
  return useQuery({
    queryFn: () => getNotifications(),
    queryKey: 'user-get-notifications',
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};
