import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { TabTitlesLi } from '../../../common/styles/CommonStyles';
import '../../User/AccountListings/TabPages-styles.css';

type CarouselTypeFiltersProps = {
  selectedFilter: any;
  setSelectedFilter: (filter: any) => void;
};

const CarouselTypeFilters = (props: CarouselTypeFiltersProps) => {
  const { trans } = useContext(AppContext);
  const typeFilters = [
    trans.featured,
    trans.sedan,
    trans.suv,
    trans.convertible,
    trans.coupe,
    trans.hatchback,
  ];

  return (
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      {typeFilters.map((filter: any) => (
        <TabTitlesLi key={Math.random()} className="nav-item">
          <span
            className={`nav-link  ${
              props.selectedFilter == filter ? 'active' : ''
            }`}
            id={`${filter}-tab`}
            onClick={() => props.setSelectedFilter(filter)}
          >
            {filter}
          </span>
        </TabTitlesLi>
      ))}
    </ul>
  );
};

export default CarouselTypeFilters;
