import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { updateLanguage } from '../../services/ApiService';
import HamburgerButton from './HamburgerButton';

const LandingPageHeader = () => {
  const { lang, trans, userData } = useContext(AppContext);
  const languageToSwitch = lang === 'ar' ? 'en' : 'ar';
  const [languageSwitchUrl, setLanguageSwitchUrl] = useState('');
  const currentPage = window.location.pathname
    .split('/')
    .filter(p => !['en', 'ar'].includes(p))
    .splice(1)
    .join('/');

  useEffect(() => {
    setLanguageSwitchUrl(
      `/${languageToSwitch}${currentPage === '' ? '' : `/${currentPage}`}`,
    );
  }, [languageToSwitch, currentPage]);

  const changeLanguage = () => {
    if (userData?.api_token) {
      let form = {
        lang: languageToSwitch,
      };

      updateLanguage(form).then(response => {
        if (response.status === 'success') {
          const userDataJson = localStorage.getItem('userData') ?? null;
          const userData = userDataJson ? JSON.parse(userDataJson) : null;

          if (userData) {
            userData.Language_Preference = languageToSwitch;
            localStorage.setItem('userData', JSON.stringify(userData));
          }

          window.location.href = languageSwitchUrl;
        }
      });
    } else {
      window.location.href = languageSwitchUrl;
    }
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center header-wrapper"
      style={{ width: '100%', margin: 'auto' }}
    >
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ width: '100%', margin: 'auto', maxWidth: '1140px' }}
      >
        <img
          src="/images/landing-page-logo.png"
          className="oac-logo"
          alt=""
          style={{ margin: '0', maxWidth: '170px' }}
        />
        <HamburgerButton onChangeLanguage={changeLanguage} />
        <div
          className="header-action-buttons align-items-center"
          style={{ gap: '30px' }}
        >
          <h1
            className="language-switch-button"
            style={{ color: '#ffffff', cursor: 'pointer' }}
            onClick={changeLanguage}
          >
            {lang == 'en' ? trans.arabic : trans.english}
          </h1>
          <a
            href="https://oneautocar.onelink.me/xvvH/h98gag4a"
            target="_blank"
            className="download-app-button"
          >
            {trans.download_app}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingPageHeader;
