import { useContext, useState } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { Skeleton } from '@mui/material';

export interface CarGridDealerLogoProps {
  defaultSrc?: string;
  src: string | undefined;
  alt?: string;
  class?: string;
  onClick?: any;
  style?: React.CSSProperties;
  onLoad?: any;
  width?: number;
  height?: number;
}

export const CarGridDealerLogoSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      height={40}
      width={40}
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const CarGridDealerLogo = ({
  src,
  alt,
  class: ClassName,
  height,
  onClick,
  style,
  width,
}: CarGridDealerLogoProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <>
      {isImageLoading && <CarGridDealerLogoSkeleton />}
      <img
        src={src}
        className={ClassName}
        onClick={onClick}
        alt={alt}
        style={{ ...style, display: isImageLoading ? 'none' : 'block' }}
        onLoad={() => {
          setIsImageLoading(false);
        }}
        width={width}
        height={height}
      />
    </>
  );
};
