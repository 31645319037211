import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import Banner from '../../Banners/BannerComponent';
import { ModelCard } from './NewCarDetailComponents/ModelCard';
import {
  useBrandModelsWithDeals,
  useBrandPageView,
  useBrandWithDeals,
} from '../hooks';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import { NewCarModelListPageSkeleton } from './NewCarsCardsSkeletons';
import { ResultsNotFound } from '../../common';
import {
  BrowseBrands,
  BrowseBrandsSection,
  MainHeadingH1,
} from '../../../common/styles/CommonStyles';
import { useInView } from 'react-intersection-observer';

export default function NewCarsBrandsWithTags() {
  const { lang, country } = useContext(AppContext);
  const params = useParams();
  const brandName = useMemo(() => {
    if (!params.brand) return;
    return (
      params.brand.charAt(0).toUpperCase() + params.brand.slice(1)
    )?.replace(/-/g, ' ');
  }, [params.brand]);
  return (
    <>
      <MetaTag
        metaKey="newCarsBrand"
        href={`/${country}/${lang}/new-cars/${params.brand}`}
        params={{ '%BRAND%': brandName }}
      />
      <NewCarsBrands params={params} lang={lang} brandName={brandName} />
    </>
  );
}

function NewCarsBrands({ params, lang, brandName }) {
  const { trans, country } = useContext(AppContext);
  const brandSlug = params.brand;

  const { data: brandsWithDeals, isLoading: isFetchingBrandsWithDeals } =
    useBrandWithDeals();

  const [brandId, setBrandId] = useState<number | undefined>(
    brandsWithDeals?.find(b => b.slug === brandSlug)?.id,
  );

  const { data: brandModelsWithDealsData, isLoading: isFetchingBrandModels } =
    useBrandModelsWithDeals({ brandId: brandId || -1 });

  const { mutateAsync: brandView } = useBrandPageView();

  const { ref } = useInView({
    threshold: 0.01,
    onChange(inView) {
      if (inView) {
        brandView(brandId ?? -1);
      }
    },
    triggerOnce: true,
  });

  useEffect(() => {
    const brandId = brandsWithDeals?.find(b => b.slug === brandSlug)?.id;
    setBrandId(brandId);
  }, [brandSlug, brandsWithDeals]);

  const isLoading = useMemo(() => {
    return isFetchingBrandsWithDeals || isFetchingBrandModels;
  }, [isFetchingBrandsWithDeals, isFetchingBrandModels]);

  if (isLoading) {
    return <NewCarModelListPageSkeleton trans={trans} />;
  }

  return (
    <main id="main" ref={ref}>
      <div className="container " style={{ paddingTop: '32px' }}>
        {brandModelsWithDealsData && brandModelsWithDealsData.length > 0 ? (
          <BrowseBrandsSection className="container">
            <MainHeadingH1>
              {brandName} {trans.Car_models}
            </MainHeadingH1>
            <BrowseBrands>
              {brandModelsWithDealsData?.map(model => (
                <ModelCard
                  model={model}
                  key={model.id}
                  isLoading={isLoading}
                  country={country}
                />
              ))}
              <Banner section_name="B1" />
            </BrowseBrands>
          </BrowseBrandsSection>
        ) : (
          !isLoading && (
            <ResultsNotFound lang={lang} trans={trans} country={country} />
          )
        )}
      </div>
    </main>
  );
}
