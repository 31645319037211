import LandingPageBottomSection from './LandingPageBottomSection';
import LandingPageHeader from './LandingPageHeader';
import LandingPageTopSection from './LandingPageTopSection';
import '../../styles/css/landing-page.css';

const LandingPageLayout = () => {
  return (
    <div
      className="landing-page-wrapper d-flex flex-column align-items-start"
      style={{ width: '100vw' }}
    >
      <LandingPageHeader />
      <LandingPageTopSection />
      <LandingPageBottomSection />
    </div>
  );
};

export default LandingPageLayout;
