import { useContext, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import PagingComponent from '../../Other/PagingComponent';
import GridButton from './GridButton';
import ListButton from './ListButton';
import '../../../styles/css/pagination-styles.css';

interface PaginationComponentProps {
  noResultsFound: boolean;
  isGrid: boolean;
  setIsGrid: (isGrid: boolean) => void;
  onSort: (filter: string) => void;
  currentPage: number;
  totalPages: number;
  pageSize: number;
  onPageChange: (arg: any) => void;
  trans1: string;
  trans2: string;
}

const PaginationComponent = ({
  noResultsFound,
  isGrid,
  setIsGrid,
  onSort,
  currentPage,
  totalPages,
  pageSize,
  onPageChange,
  trans1,
  trans2,
}: PaginationComponentProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { trans } = useContext(AppContext);
  const navigate = useNavigate();
  const filters = [
    { criteria: 'newest', name: trans.newest },
    { criteria: 'oldest', name: trans.oldest },
    { criteria: 'lowp', name: trans.lowp },
    { criteria: 'highp', name: trans.highp },
    { criteria: 'lowm', name: trans.lowm },
    { criteria: 'highm', name: trans.higm },
    { criteria: 'latestm', name: trans.latestm },
    { criteria: 'oldestm', name: trans.oldm },
  ];

  const toggleDropdown = () => setDropdownOpen(prevOpen => !prevOpen);

  return (
    <header id="used_cars_for_sale_header" className="title-header">
      <div className="d-flex" style={{ columnGap: '5px' }}>
        <h1>{trans1}</h1>
      </div>
      {!noResultsFound && (
        <div className="drop-page-holder">
          <div className="dropdown fitlers-drop">
            <div className="d-flex align-items-center" style={{ gap: '20px' }}>
              <div className="d-flex align-items-center">
                <GridButton isGrid={isGrid} onToggle={() => setIsGrid(true)} />
                <ListButton
                  isList={!isGrid}
                  onToggle={() => setIsGrid(false)}
                />
              </div>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className="btn dropdown-toggle"
              >
                <DropdownToggle caret className="btn">
                  <img src="/images/filter-icon.png" alt="filter-icon" />
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu"
                  style={{
                    padding: '0px',
                    width: '100%',
                  }}
                >
                  {filters.map((filter: any) => (
                    <DropdownItem
                      key={filter.criteria}
                      onClick={() => onSort(filter.criteria)}
                      className="dropdown-item"
                    >
                      {filter.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <nav aria-label="Page navigation" className="paging-holder">
            <ul className="align-paging-left">
              <PagingComponent
                pagesCount={totalPages}
                pageSize={pageSize}
                pageChangeHandler={(p: any) => {
                  navigate(`?page=${p + 1}`);
                  onPageChange(p);
                }}
                currentPage={currentPage}
              />
            </ul>
            <strong className="counts">
              <span>{trans2}</span> <span>{totalPages}</span>
            </strong>
          </nav>
        </div>
      )}
    </header>
  );
};

export default PaginationComponent;
