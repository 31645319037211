import { createContext, useContext, useMemo, useState } from 'react';
import TransAr from '../resources/lang/au/trans';
import TransEn from '../resources/lang/en/trans';
import { useLocation } from 'react-router-dom';

const lightImageLoadingPlaceholder = '/images/preloadingpng.png';
const darkImageLoadingPlaceholder = '/images/preloadedimage.png';
const bannerPlaceholder = '/images/preloadBanner.webp';

interface IAppContextProps {
  hasProvider: boolean;
  lang: string;
  trans: any;
  lightImageLoadingPlaceholder: string;
  darkImageLoadingPlaceholder: string;
  bannerPlaceholder: string;
  isSafari: number;
  userData: any;
  setUserData: (data: any) => void;
  country: string | null;
}

export const AppContext = createContext<IAppContextProps>({
  hasProvider: false,
  lang: '',
  trans: TransEn(),
  lightImageLoadingPlaceholder,
  darkImageLoadingPlaceholder,
  bannerPlaceholder,
  userData: null,
  isSafari: 0,
  setUserData: () => {},
  country: '',
});

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context.hasProvider === false) {
    console.error('No provider for AppContext');
  }
  return context;
};

export const AppProvider = ({ children }: any) => {
  const accessToken = localStorage.getItem('accessToken');
  const [userData, setUserData] = useState(
    accessToken ? JSON.parse(atob(accessToken.split('.')[1])) : null,
  );

  type TLang = 'ar' | 'en';

  const location = useLocation();

  const pathname = location.pathname.split('/')[2];

  const lang: TLang = useMemo(
    () => (pathname?.startsWith('ar') ? 'ar' : 'en'),
    [location],
  );

  var bodyTag = document.getElementsByTagName('body')[0];
  if (lang === 'ar') bodyTag.className = 'lang-au';
  else bodyTag.className = 'lang-en';

  return (
    <AppContext.Provider
      value={{
        hasProvider: true,
        lang,
        trans: lang === 'ar' ? TransAr() : TransEn(),
        lightImageLoadingPlaceholder,
        darkImageLoadingPlaceholder,
        bannerPlaceholder,
        isSafari: navigator.userAgent.indexOf('Safari'),
        userData,
        setUserData,
        country: localStorage.getItem('country'),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
