import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import PageLoader from './PageLoader/PageLoaderComponent';
import {
  ColLg12,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  PrivacyContent,
  PrivacyContentA,
  PrivacyContentB,
  PrivacyContentHolder,
  PrivacyContentP,
  PrivacyNav,
  PrivacyNavLi,
  PrivacyTitle,
  Row,
  SellYourCar,
} from '../../common/styles/CommonStyles';

export default function PrivacyPolicy(this: any) {
  const { lang, trans } = useContext(AppContext);
  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      setShowPageLoader(false);
    }, 100);
  }, []);

  return (
    <>
      {showPageLoader && <PageLoader />}
      <main id="main">
        <MainHead>
          <MainHeadContainer className="container">
            <Row>
              <ColLg12>
                <MainHeadH1>{trans.privacy}</MainHeadH1>
              </ColLg12>
            </Row>
          </MainHeadContainer>
        </MainHead>
        <SellYourCar className="container privacy-block">
          <PrivacyContent>
            {lang === 'en' ? (
              <>
                <PrivacyContentP>
                  <PrivacyContentB>
                    Please take the time to check our privacy policy, which
                    explains the information that we collect about you, how we
                    use it, and your rights in relation to this information.
                  </PrivacyContentB>
                </PrivacyContentP>
                <PrivacyContentHolder>
                  <PrivacyTitle>
                    What Type Of Personal Data Do We Collect About You?
                  </PrivacyTitle>
                  <PrivacyContentP>
                    Upon using One Autocar, we directly collect your personal
                    information the moment you submit it.{' '}
                  </PrivacyContentP>
                  <PrivacyContentP>
                    These personal information may include:
                  </PrivacyContentP>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      The basic and primary information you use whenever you
                      create an account with One Autocar; which includes the
                      name, email address, postcode and password.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      The secondary information you provide such as the phone
                      number, reviews and comments.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      We may collect information about the device you use to
                      access our platform, such as your IP address (which may
                      allow us to see your geographical location) and your
                      browser.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      We may also receive a confirmation from our dealers when
                      you buy a car from them which is used for accounting
                      purposes and to manage the purchase from the dealer’s end.
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>
                    What Do We Use This Personal Data For?
                  </PrivacyTitle>
                  <PrivacyContentP>
                    The purpose of using your personal information may include:
                  </PrivacyContentP>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      To contact you when needed and to share car offers with
                      you.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      To maintain the activity of your online account and check
                      requests submitted via the platform.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      For our customer service team; to be able to manage and
                      answer any concerns or complaints.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      For security purposes; to investigate fraudulent
                      behaviors, to be able to protect ourselves and others.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      For market research; we may contact you for feedback about
                      your experience with us.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      To send you offers and relevant content that we think you
                      might be interested in, based on your account information
                      history.
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>
                    With Whom We May Share Your Personal Data?
                  </PrivacyTitle>
                  <PrivacyContentP>
                    We share users’ personal data information with third parties
                    in the following circumstances:
                  </PrivacyContentP>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      With car dealers. Noting that the only information they
                      receive from us is your personal information and the car
                      of interest.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      With our suppliers and service providers; so that they can
                      contact you to confirm whether you bought a car through
                      our website or not.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      With the concerned parties engaged in fraud detection and
                      prevention.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      With third parties for direct marketing and targeted
                      advertising purposes.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      With our professional and legal advisors; upon your
                      consent only, unless our advisors are legally permitted to
                      do so.
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>
                    For Marketing & Advertising Purposes
                  </PrivacyTitle>
                  <PrivacyContentP>
                    We would love to communicate with our users depending on
                    their preferences. We may use your personal information to
                    send you our latest offers and deals through email or SMS.{' '}
                  </PrivacyContentP>
                  <PrivacyContentP>
                    If you no longer want to receive such emails, you can let us
                    know by contacting our team on “
                    <PrivacyContentA href="mailto:info@oneautocar.com">
                      info@oneautocar.com
                    </PrivacyContentA>
                    ”, or by clicking on the ‘unsubscribe’ link in any of those
                    emails.
                  </PrivacyContentP>
                  <PrivacyContentP>
                    Once unsubscribed, you will no longer receive such emails
                    from us, however we will keep on contacting you occasionally
                    to inform you about services that you are interested in.
                  </PrivacyContentP>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>The Rules</PrivacyTitle>
                  <PrivacyContentP>
                    One Autocar is not intended for and should not be used by
                    anyone under the age of 18. We do not accept and collect any
                    personal information from individuals under 18.
                  </PrivacyContentP>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>Your Rights</PrivacyTitle>
                  <PrivacyContentP>
                    You have the right to access, correct and delete your
                    personal information.
                  </PrivacyContentP>
                  <PrivacyContentP>
                    You also have the right to unaccept the use of your personal
                    data for certain purposes, including sending you promo
                    messages.{' '}
                  </PrivacyContentP>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>Contact Us</PrivacyTitle>
                  <PrivacyContentP>
                    If you have any concerns regarding our Privacy Policy,
                    please contact us through email at{' '}
                    <PrivacyContentA href="mailto:info@oneautocar.com">
                      info@oneautocar.com
                    </PrivacyContentA>
                    . Thank you.
                  </PrivacyContentP>
                </PrivacyContentHolder>
              </>
            ) : (
              <>
                <PrivacyContentP>
                  <PrivacyContentB>
                    يرجى أخذ الوقت الكافي للتحقق من سياسة الخصوصية الخاصة بنا،
                    والتي تشرح المعلومات التي نجمعها عنك، وكيف نستخدمها، وحقوقك
                    في ما يتعلق بهذه المعلومات.
                  </PrivacyContentB>
                </PrivacyContentP>
                <PrivacyContentHolder>
                  <PrivacyTitle>
                    ما نوع البيانات الشخصية التي نجمعها عنك؟
                  </PrivacyTitle>
                  <PrivacyContentP>
                    عند استخدام One Autocar، نجمع معلوماتك الشخصية مباشرة بمجرد
                    إرسالها. قد تتضمن ما يلي:{' '}
                  </PrivacyContentP>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      المعلومات الشخصية الأساسية والأولية التي تستخدمها عندما
                      تقوم بإنشاء حساب مع One Autocar؛ والذي يتطلّب الاسم وعنوان
                      البريد الإلكتروني والرمز البريدي وكلمة المرور.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      المعلومات الثانوية التي تقدمها، مثل رقم الهاتف والمراجعات
                      والتعليقات.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      قد نقوم بجمع معلومات حول الجهاز الذي تستخدمه للوصول إلى
                      منصتنا، مثل عنوان IP الخاص بك (والذي قد يسمح لنا برؤية
                      موقعك الجغرافي) ومتصفحك.
                    </PrivacyNavLi>

                    <PrivacyNavLi>
                      قد نتلقى أيضًا تأكيدًا من وكلائنا عند شراء سيارة منهم،
                      وذلك يُستخدم لأغراض المحاسبة وإدارة الشراء من جهة التاجر.
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>
                    لماذا نستخدم هذه البيانات الشخصية؟
                  </PrivacyTitle>
                  <PrivacyContentP>
                    قد يشمل الغرض من استخدام معلوماتك الشخصية ما يلي:
                  </PrivacyContentP>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      للحفاظ على نشاط حسابك على الإنترنت والتحقق من الطلبات
                      المقدمة عبر المنصة.
                    </PrivacyNavLi>

                    <PrivacyNavLi>
                      لصالح فريق خدمة العملاء لدينا؛ لتكون قادرًا على إدارة أي
                      مخاوف أو شكاوى، والإجابة عليها.
                    </PrivacyNavLi>

                    <PrivacyNavLi>
                      لأغراض أمنية؛ للتحقيق في السلوكيات الاحتيالية والقدرة على
                      حماية أنفسنا والآخرين.
                    </PrivacyNavLi>

                    <PrivacyNavLi>
                      لأبحاث السوق. قد نتصل بك للحصول على ملاحظات حول تجربتك
                      معنا.
                    </PrivacyNavLi>

                    <PrivacyNavLi>
                      لإرسال العروض والمحتوى ذي الصلة الذي نعتقد أنه يهمّك،
                      بناءً على سجل معلومات حسابك.
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>مع من قد نشارك بياناتك الشخصية؟</PrivacyTitle>
                  <PrivacyContentP>
                    نشارك معلومات عن بيانات المستخدمين الشخصية مع أطراف ثالثة في
                    الحالات التالية:
                  </PrivacyContentP>
                  <PrivacyNav>
                    <PrivacyNavLi>
                      مع تجار السيارات. ونشير إلى أن المعلومات الوحيدة التي
                      يتلقونها منا هي معلوماتك الشخصية والسيارة التي تهمك.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      مع الموردين ومقدمي الخدمات الذين نعمل معهم؛ حتى يتمكنوا من
                      الاتصال بك لتأكيد ما إذا كنت قد اشتريت سيارة من خلال
                      موقعنا أم لا.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      مع الجهات المعنية بكشف ومنع الاحتيال.
                    </PrivacyNavLi>
                    <PrivacyNavLi>
                      مع أطراف ثالثة للتسويق المباشر ولأغراض إعلانية مستهدفة.
                    </PrivacyNavLi>

                    <PrivacyNavLi>
                      مع مستشارينا المحترفين والقانونيين؛ بناءً على موافقتك فقط،
                      ما لم يُسمح لمستشارينا قانونًا بذلك.
                    </PrivacyNavLi>
                  </PrivacyNav>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>لأغراض التسويق والإعلان</PrivacyTitle>
                  <PrivacyContentP>
                    نودّ التواصل مع مستخدمينا حسب تفضيلاتهم. قد نستخدم معلوماتك
                    الشخصية لنرسل إليك أحدث عروضنا وصفقاتنا عبر البريد
                    الإلكتروني أو الرسائل القصيرة.{' '}
                  </PrivacyContentP>

                  <PrivacyContentP>
                    {' '}
                    في حال لا ترغب في تلقي مثل هذه الرسائل الإلكترونية، يمكنك
                    إخبارنا عن طريق الاتصال بفريقنا على "
                    <PrivacyContentA href="mailto:info@oneautocar.com">
                      info@oneautocar.com
                    </PrivacyContentA>
                    " ، أو بالنقر فوق رابط "إلغاء الاشتراك" في أي من رسائل
                    البريد الإلكتروني هذه.
                  </PrivacyContentP>

                  <PrivacyContentP>
                    بمجرد إلغاء الاشتراك، لن تتلقى هذ الرسائل الإلكتروني بعد
                    الآن، ولكننا سنواصل الاتصال بك من حين لآخر لإبلاغك بالخدمات
                    التي تهتم بها.
                  </PrivacyContentP>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>قواعد</PrivacyTitle>
                  <PrivacyContentP>
                    لا ينبغي استخدام One Autocar من قبل أي شخص عمره أقل من 18
                    عامًا. لا نجمع أي معلومات شخصية من الأفراد دون سن 18.
                  </PrivacyContentP>
                </PrivacyContentHolder>

                <PrivacyContentHolder>
                  <PrivacyTitle>حقوقك</PrivacyTitle>
                  <PrivacyContentP>
                    يحق لك الوصول إلى معلوماتك الشخصية وتصحيحها وحذفها.
                  </PrivacyContentP>

                  <PrivacyContentP>
                    يحق لك أيضًا عدم قبول استخدام بياناتك الشخصية لأغراض معينة،
                    بما في ذلك إرسال رسائل ترويجية إليك.
                  </PrivacyContentP>
                </PrivacyContentHolder>
                <PrivacyContentHolder>
                  <PrivacyTitle>اتصل بنا</PrivacyTitle>
                  <PrivacyContentP>
                    إذا كانت لديك أي مخاوف بشأن سياسة الخصوصية الخاصة بنا، فيرجى
                    التواصل معنا عبر البريد الإلكتروني على{' '}
                    <PrivacyContentA href="mailto:info@oneautocar.com">
                      info@oneautocar.com
                    </PrivacyContentA>
                    . شكرًا لك.
                  </PrivacyContentP>
                </PrivacyContentHolder>
              </>
            )}
          </PrivacyContent>
        </SellYourCar>
      </main>
    </>
  );
}
