import { useQuery } from 'react-query';
import { getConditions } from '../apis/condition.apis';

export const useGetConditions = () => {
  return useQuery({
    queryKey: ['conditions'],
    queryFn: () => getConditions(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
