import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import '../../styles/css/header-styles.css';
interface NewUsedCarsToggleProps {
  isNew: boolean;
  onToggle: (isNew: boolean) => void;
  onFocusWithin: (isFocused: boolean) => void;
}

export default function NewUsedCarsToggle(
  newUsedCarsToggleProps: NewUsedCarsToggleProps,
) {
  const { trans } = useContext(AppContext);

  return (
    <div
      className="toggles-wrapper"
      onMouseLeave={() => newUsedCarsToggleProps.onFocusWithin(false)}
      onMouseEnter={() => newUsedCarsToggleProps.onFocusWithin(true)}
    >
      <input
        type="radio"
        name="car-condition"
        id="new-checkbox"
        checked={newUsedCarsToggleProps.isNew}
      />
      <input
        type="radio"
        name="car-condition"
        id="old-checkbox"
        checked={!newUsedCarsToggleProps.isNew}
      />
      <div
        onClick={() => {
          newUsedCarsToggleProps.onToggle(true);
        }}
        className="toggle new-toggle"
      >
        <label htmlFor="new-checkbox">{trans.new}</label>
      </div>
      <div
        onClick={() => {
          newUsedCarsToggleProps.onToggle(false);
        }}
        className="toggle old-toggle"
      >
        <label htmlFor="old-checkbox">{trans.used}</label>
      </div>
    </div>
  );
}
