import { Skeleton } from '@mui/material';
import {
  ArticleDetailImageSkeleton,
  DetailColumn,
  DetailColumnH2,
  ImageHolder,
  InfoWrapper,
  TextHolder,
  TextHolderP,
  TitleDate,
} from '../ArticleCard/ArticleCardStyle';
import {
  AutoImgBox,
  ColLg12,
  FeaturedDetailsPage,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  MoreArticleH2,
  Row,
} from '../../../../common/styles/CommonStyles';
import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import {
  ArticleDateNavLi,
  ArticleDateNavUl,
  ArticleDetailsInfo,
  ArticleDetailsInfoH2,
} from '../../ArticleDetailStyledComponent';

export const ArticlesCardImageSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      width={'100%'}
      height={188}
      className="articles-list-image"
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const ArticlesDetailImageSkeleton = () => {
  return <ArticleDetailImageSkeleton variant="rectangular" width={'100%'} />;
};

export const ArticlesDetailDescImageSkeleton = () => {
  return <Skeleton variant="rectangular" width={400} height={200} />;
};

export const ArticlesCardSkeleton = () => {
  return (
    <div className="col-md-6 mb-4">
      <DetailColumn to={''}>
        <ImageHolder>
          <AutoImgBox>
            <ArticlesCardImageSkeleton />
          </AutoImgBox>
        </ImageHolder>
        <TextHolder>
          <DetailColumnH2>
            <Skeleton width={200} />
          </DetailColumnH2>
          <TextHolderP></TextHolderP>

          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} />
          ))}
          <InfoWrapper>
            <TitleDate>
              <Skeleton width={100} />
            </TitleDate>
          </InfoWrapper>
        </TextHolder>
      </DetailColumn>
    </div>
  );
};

export const ArticlesSkeleton = ({ trans }: { trans: any }) => {
  return (
    <main id="main" className="articles">
      <MainHead>
        <MainHeadContainer className="container">
          <Row>
            <ColLg12>
              <MainHeadH1>{trans.articles}</MainHeadH1>
            </ColLg12>
          </Row>
        </MainHeadContainer>
      </MainHead>
      <FeaturedDetailsPage className="container articles">
        <Row>
          {[...Array(10)].map((_, i) => (
            <ArticlesCardSkeleton key={i} />
          ))}
        </Row>
        <span className="pagination-middle">
          <Skeleton
            width={200}
            style={{
              margin: '0 auto',
            }}
          />
        </span>
      </FeaturedDetailsPage>
    </main>
  );
};

export const MoreArticlesSkeleton = ({ trans }: { trans: any }) => {
  return (
    <>
      <Row className="more-article">
        <ColLg12>
          <MoreArticleH2>{trans.more_articles}</MoreArticleH2>
        </ColLg12>
      </Row>
      <Row>
        {[...Array(4)].map((_, i) => (
          <ArticlesCardSkeleton key={i} />
        ))}
      </Row>
    </>
  );
};

export const ArticleDetailSkeleton = ({ trans }: { trans: any }) => {
  return (
    <main id="main">
      <MainHead>
        <MainHeadContainer className="container">
          <Row>
            <ColLg12>
              <MainHeadH1>{trans.articles}</MainHeadH1>
            </ColLg12>
          </Row>
        </MainHeadContainer>
      </MainHead>
      <FeaturedDetailsPage className="container articles">
        <ArticleDetailsInfo>
          <ArticleDetailsInfoH2>
            <Skeleton width={'100%'} />
            <Skeleton width={'100%'} />
          </ArticleDetailsInfoH2>
          <ArticleDateNavUl>
            <ArticleDateNavLi>{trans.by} ONE AUTOCAR</ArticleDateNavLi>
            <ArticleDateNavLi>
              <Skeleton width={100} />
            </ArticleDateNavLi>
          </ArticleDateNavUl>
          <div>
            <ArticlesDetailImageSkeleton />
          </div>
          <span className="article-description">
            {[...Array(5)].map((_, i) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Skeleton key={i} width={'100%'} />
                <Skeleton key={i} width={'100%'} />
                <Skeleton key={i} width={'100%'} />
                <ArticlesDetailDescImageSkeleton />
              </div>
            ))}
          </span>
        </ArticleDetailsInfo>
        <MoreArticlesSkeleton trans={trans} />
      </FeaturedDetailsPage>
    </main>
  );
};
