import React from 'react';

const ServicePackageIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="48"
      height="41"
      viewBox="0 0 48 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.8486 26.9593L42.7698 17.9829C42.7304 17.9435 42.7304 17.9041 42.6911 17.8648L42.6517 17.8254C42.6123 17.786 42.6123 17.786 42.573 17.7467L42.5336 17.7073C42.4942 17.6679 42.4549 17.6679 42.4155 17.6679H42.3761C42.3367 17.6679 42.258 17.6285 42.2186 17.6285L24.4627 14.5183H24.4234H24.384C24.3446 14.5183 24.3446 14.5183 24.3053 14.5183C24.2659 14.5183 24.2659 14.5183 24.2265 14.5183H24.1871H24.1478L6.39187 17.6285C6.3525 17.6285 6.27376 17.6679 6.23439 17.6679H6.19501C6.15564 17.6679 6.11628 17.7073 6.07691 17.7073C6.07691 17.7073 6.03753 17.7073 6.03753 17.7467C5.99816 17.786 5.99816 17.786 5.95879 17.8254L5.91943 17.8648C5.88005 17.9041 5.84068 17.9435 5.84068 17.9829L0.840685 26.9593C0.683205 27.1955 0.722575 27.5104 0.840685 27.7467C0.998165 27.9829 1.23439 28.1404 1.50998 28.1404H5.80131V36.3293C5.80131 36.723 6.07691 37.038 6.47061 37.1167L24.2265 40.227C24.2659 40.227 24.3053 40.227 24.3446 40.227C24.384 40.227 24.4234 40.227 24.4627 40.227L42.2186 37.1167C42.6123 37.038 42.8879 36.723 42.8879 36.3293V28.1404H47.1793C47.4549 28.1404 47.7304 27.9829 47.8486 27.7467C47.9667 27.5104 48.006 27.1955 47.8486 26.9593ZM7.10053 19.0852L23.0454 16.29L18.7147 26.5656H2.84856L7.10053 19.0852ZM29.9745 26.5656L25.6438 16.29L41.5887 19.0852L45.8407 26.5656H29.9745ZM7.37612 28.1404H19.2265C19.5415 28.1404 19.8171 27.9435 19.9352 27.6679L23.5572 19.164V38.4947L7.37612 35.66V28.1404ZM41.3131 35.66L25.132 38.4947V19.164L28.7147 27.6679C28.8328 27.9435 29.1084 28.1404 29.4234 28.1404H41.2738V35.66H41.3131Z"
        fill={color}
      />
      <path
        d="M14.2264 14.6756C14.6594 14.6756 15.0138 14.3213 15.0138 13.8882V1.80161H34.4232C34.8563 1.80161 35.2106 1.44728 35.2106 1.01421C35.2106 0.581137 34.8563 0.226807 34.4232 0.226807H14.2264C13.7933 0.226807 13.439 0.581137 13.439 1.01421V13.8882C13.439 14.3213 13.7933 14.6756 14.2264 14.6756Z"
        fill={color}
      />
      <path
        d="M28.9116 9.04575C28.9116 12.1166 31.3919 14.5969 34.4628 14.5969C36.1163 14.5969 37.573 13.8883 38.5967 12.7465L38.636 12.7072C38.636 12.7072 38.636 12.6678 38.6754 12.6678C39.5022 11.6835 40.014 10.4237 40.014 9.08512C40.014 6.01425 37.5337 3.53394 34.4628 3.53394C31.3919 3.53394 28.9116 6.01425 28.9116 9.04575ZM33.6754 5.18748V9.08512C33.6754 9.32134 33.7935 9.51819 33.951 9.67567L36.8644 12.2347C36.1951 12.7465 35.3683 13.0615 34.4628 13.0615C32.2581 13.0615 30.4864 11.2898 30.4864 9.08512C30.4864 7.15598 31.8644 5.54181 33.6754 5.18748ZM37.888 11.0142L35.2108 8.69142V5.14811C37.0219 5.50244 38.3998 7.11661 38.3998 9.04575C38.3998 9.79378 38.203 10.4237 37.888 11.0142Z"
        fill={color}
      />
      <path
        d="M24.4624 3.37646H17.3758C16.9427 3.37646 16.5884 3.7308 16.5884 4.16387C16.5884 4.59694 16.9427 4.95127 17.3758 4.95127H24.423C24.8561 4.95127 25.2104 4.59694 25.2104 4.16387C25.2104 3.7308 24.8955 3.37646 24.4624 3.37646Z"
        fill={color}
      />
      <path
        d="M24.4624 6.52612H17.3758C16.9427 6.52612 16.5884 6.88045 16.5884 7.31352C16.5884 7.7466 16.9427 8.10093 17.3758 8.10093H24.423C24.8561 8.10093 25.2104 7.7466 25.2104 7.31352C25.2104 6.88045 24.8955 6.52612 24.4624 6.52612Z"
        fill={color}
      />
      <path
        d="M24.4624 9.67578H17.3758C16.9427 9.67578 16.5884 10.0301 16.5884 10.4632C16.5884 10.8963 16.9427 11.2506 17.3758 11.2506H24.423C24.8561 11.2506 25.2104 10.8963 25.2104 10.4632C25.2104 10.0301 24.8955 9.67578 24.4624 9.67578Z"
        fill={color}
      />
    </svg>
  );
};

export default ServicePackageIcon;
