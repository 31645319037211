import { useQuery } from 'react-query';
import {
  IGetArticles,
  getArticles,
  getArticlesById,
  getArticlesBySlug,
  getLast4CreatedArticles,
} from '../utils/articlesApi';
import { queryClient } from '../../../App';
import { IArticle } from '../../../types/articles/article';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';

export const useGetArticles = ({
  searchQueryParams,
  page,
  perPage,
}: IGetArticles) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['articles', searchQueryParams, page, perPage, lang],
    queryFn: () => getArticles({ searchQueryParams, page, perPage }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};

export const useGetArticlesById = (id: number) => {
  return useQuery({
    queryKey: ['articles', id],
    queryFn: () => getArticlesById(id),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!id,
  });
};

export const useGetArticlesBySlug = (slug: string) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['articles', slug, lang],
    queryFn: () => getArticlesBySlug(slug),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!slug,
  });
};

export const useGetMoreArticles = (skip?: string) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['articles', 'more-articles', skip, lang],
    queryFn: () => getLast4CreatedArticles(skip),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!skip,
    onSuccess: data => {
      // Filter out the article with the specified Slug
      const filteredArticles = data.filter(
        (article: IArticle) => article.slug !== skip,
      );
      queryClient.setQueryData(
        ['articles', 'more-articles', skip],
        filteredArticles,
      );
    },
  });
};
