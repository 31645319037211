import { Skeleton } from '@mui/material';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import {
  LogoBox,
  LogoHold,
  WrapBox,
} from '../../Listings/brands/components/BrandCardStyledComponent';

export const BrandCardImageSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      width={80}
      height={60}
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const BrandCardSkeleton = () => {
  return (
    <article key={Math.random()} style={{ height: '100%', width: '100%' }}>
      <LogoBox>
        <WrapBox>
          <LogoHold>
            <BrandCardImageSkeleton />
          </LogoHold>
          <Skeleton />
        </WrapBox>
      </LogoBox>
    </article>
  );
};
