import authenticatedApi from '../../../instance/ApiInstance';
import { IBrand } from '../../../types/resources/cars';

const brandsEndpoint = '/v2/resources/brands';

const brandAnalytics = '/v2/analytics/new-car-analytics/brand/analytics';

export const getBrandsWithDeals = async () => {
  try {
    const response = await authenticatedApi.get(brandsEndpoint, {
      params: { type: 'new' },
    });
    const data: IBrand[] = response.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const BrandPageClick = async (id: number) => {
  try {
    const response = await authenticatedApi.post(brandAnalytics, {
      new_car_id: id,
      event: 'click',
    });

    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const BrandPageView = async (id: number) => {
  try {
    const response = await authenticatedApi.post(brandAnalytics, {
      new_car_id: id,
      event: 'view',
    });

    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};
