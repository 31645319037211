/**
 * Format time difference between a given date and the current date in a human-readable form.
 * @param {string} dateString - The date string to compare.
 * @param {Object} trans - Translations for different time units.
 * @param {string} lang - The current language.
 * @returns {string} - Human-readable time difference.
 */
export function timeSince(
  dateString: string,
  trans: Record<string, string>,
  lang: string,
): string {
  // Convert the input date string to a Date object
  const date = new Date(dateString);

  // Calculate the time difference in seconds
  const seconds: number = Math.floor((Date.now() - date.getTime()) / 1000);

  // Define intervals for different time units
  const intervals: Record<string, number> = {
    year: Math.floor(seconds / 31536000),
    month: Math.floor(seconds / 2592000),
    day: Math.floor(seconds / 86400),
    hour: Math.floor(seconds / 3600),
    minute: Math.floor(seconds / 60),
    second: Math.floor(seconds),
  };

  /**
   * Get the relative time string for a given unit.
   * @param {number} value - The value of the time unit.
   * @param {string} unit - The unit of time.
   * @param {string} singular - The translation key for the singular form.
   * @param {string} plural - The translation key for the plural form.
   * @returns {string} - Human-readable relative time string.
   */
  const getRelativeTime = (
    value: number,
    unit: string,
    singular: string,
    plural: string,
  ): string => {
    const translationKey: string = value === 1 ? singular : plural;
    if (lang === 'ar') {
      const arabicNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
      // Convert the value to an Arabic number
      const arabicValue: string = value
        .toString()
        .split('')
        .map(numb => arabicNumbers[parseInt(numb)])
        .join('');

      return `${trans[translationKey].split(' ').join(` ${arabicValue} `)}`;
    }
    return `${value} ${trans[translationKey]}`;
  };

  // Iterate through intervals and return the first non-zero value
  for (const [unit, value] of Object.entries(intervals)) {
    if (value >= 1) {
      return getRelativeTime(value, unit, unit + '_ago', unit + 's_ago');
    }
  }

  // If the time difference is less than a second, return the seconds ago string
  return getRelativeTime(seconds, 'second', 'seconds_ago', trans.seconds_ago);
}
