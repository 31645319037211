import { useContext, useMemo } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useGetArticles } from '../../Articles/hooks/useArticles';
import { IArticle } from '../../../types/articles/article';
import { ArticleCard } from '../../common/ArticleCard';
import {
  BtnViewHolderView,
  HeaderHolder,
  InfoBlock,
  InfoBlockBtnViewHolder,
  InfoBlockH2,
  InfoBlockRow,
} from '../../../common/styles/CommonStyles';

export default function HomeArticles() {
  const { lang, trans, country } = useContext(AppContext);

  const { data: articles, isLoading } = useGetArticles({
    searchQueryParams: {
      featured: 'true',
    },
  });
  const firstFourArticles = useMemo(
    () => articles?.data.slice(0, 4),
    [articles?.data],
  );

  return (
    <>
      <InfoBlock className="style">
        <HeaderHolder>
          <InfoBlockH2>{trans.articles}</InfoBlockH2>
        </HeaderHolder>
        <InfoBlockRow className="row">
          {!isLoading
            ? firstFourArticles?.map((article: IArticle) => (
                <ArticleCard
                  key={article.id}
                  article={article}
                  isLoading={isLoading}
                />
              ))
            : Array(4)
                .fill(0)
                .map((_, i) => <ArticleCard key={i} />)}
          <InfoBlockBtnViewHolder>
            <BtnViewHolderView to={`/${country}/${lang}/articles`}>
              {trans.view_all}
            </BtnViewHolderView>
          </InfoBlockBtnViewHolder>
        </InfoBlockRow>
      </InfoBlock>
    </>
  );
}
