import { VideoHolderBackground } from './CarouselBannerStyledComponent';

export interface CarouselImageProps {
  defaultSrc?: string;
  src: string | undefined;
  alt?: string;
  class?: string;
  onClick?: any;
  style?: React.CSSProperties;
  onLoad?: any;
  width?: number;
  height?: number;
}
export const CarouselImage = ({
  src,
  alt,
  class: className,
  height,
  onClick,
  onLoad,
  style,
  width,
}: CarouselImageProps) => {
  return (
    <VideoHolderBackground
      src={src}
      className={className}
      onClick={onClick}
      alt={alt}
      style={style}
      onLoad={onLoad}
      width={width}
      height={height}
      loading="lazy"
    />
  );
};
