import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppContext } from '../../../../contexts/AppContext';
import DeleteChatPopupConfirmation from '../DeleteChatPopupConfirmation';
import {
  EChatConversationType,
  IChatConversation,
} from '../../../../types/chats/chat_conversation';
import { format } from 'date-fns';
import { IUsedCar } from '../../../../types/cars/used_car';
import { User } from '../../../../types';
import { IUsedBike } from '../../../../types/bikes';
import './AccountChatCard-styles.css';

interface IAccountChatCardProps {
  chatConversation?: IChatConversation;
  openedChat: { id: number | null; type: EChatConversationType | null };
  setUserParticipantId: (newParticipantId: number) => void;
  newChatState?: {
    new: boolean;
    car: IUsedCar;
    bike: IUsedBike;
    loggedInUser: User;
    chat_conversation_type: EChatConversationType;
  };
}

const AccountChatCard = ({
  chatConversation,
  openedChat,
  setUserParticipantId,
  newChatState,
}: IAccountChatCardProps) => {
  const { type: chatConversationTypeParam, id: paramId } = useParams();
  const id = paramId && paramId !== 'new' ? parseInt(paramId) : 'new';
  const { userData, lang, trans, country } = useContext(AppContext);
  const [showDelete, setShowDelete] = useState(false);

  const otherChatParticipant = chatConversation
    ? chatConversation.chat_participants[0]?.user?.id === userData.id
      ? chatConversation.chat_participants[1]?.user ??
        chatConversation.chat_participants[1]?.dealer
      : chatConversation.chat_participants[0]?.user ??
        chatConversation.chat_participants[0]?.dealer
    : newChatState?.chat_conversation_type === EChatConversationType.Car
    ? newChatState?.car?.user
    : newChatState?.bike?.user;

  const previewMessage = useMemo(() => {
    if (chatConversation && chatConversation.last_message) {
      return chatConversation.last_message.substring(0, 20) + '...';
    }
    return '';
  }, [chatConversation]);

  const toggleDelete = () => setShowDelete(!showDelete);

  const getBrandModelNames = useCallback(() => {
    if (
      (chatConversation &&
        chatConversation.chat_conversation_type ===
          EChatConversationType.Car) ||
      (newChatState &&
        newChatState.chat_conversation_type === EChatConversationType.Car)
    ) {
      return (
        <>
          {(chatConversation && chatConversation.used_car?.model?.brand
            ? chatConversation.used_car?.model?.brand.name
            : '') || newChatState?.car?.model?.brand.name}{' '}
          {(chatConversation && chatConversation.used_car?.model
            ? chatConversation.used_car?.model.name
            : '') || newChatState?.car?.model?.name}
        </>
      );
    }
    if (
      (chatConversation &&
        chatConversation.chat_conversation_type ===
          EChatConversationType.Bike) ||
      (newChatState &&
        newChatState.chat_conversation_type === EChatConversationType.Bike)
    ) {
      return (
        <>
          {(chatConversation && chatConversation.used_bike?.bike_model?.brand
            ? chatConversation.used_bike?.bike_model?.brand.name
            : '') || newChatState?.bike?.bike_model?.brand.name}{' '}
          {(chatConversation && chatConversation.used_bike?.bike_model
            ? chatConversation.used_bike?.bike_model.name
            : '') || newChatState?.bike?.bike_model?.name}
        </>
      );
    }
  }, [chatConversation, newChatState]);

  useEffect(() => {
    var bodyTag = document.getElementsByTagName('body')[0];
    if (showDelete) {
      bodyTag.classList.add('modal-open');
    } else {
      bodyTag.classList.remove('modal-open');
    }
  }, [showDelete]);

  return (
    <>
      <Link
        to={`/${country}/${lang}/account-chat/${
          chatConversation?.chat_conversation_type ??
          newChatState?.chat_conversation_type
        }/${chatConversation?.id ?? 'new'}`}
        onClick={e =>
          chatConversation
            ? setUserParticipantId(
                chatConversation
                  ? chatConversation.chat_participants[0].user?.id ===
                    userData.id
                    ? chatConversation.chat_participants[0].id
                    : chatConversation.chat_participants[1].id
                  : newChatState!.loggedInUser.id,
              )
            : e.preventDefault()
        }
      >
        <li
          className={`${
            chatConversation
              ? (chatConversation.id === id ||
                  chatConversation.id === openedChat.id) &&
                chatConversation.chat_conversation_type ===
                  chatConversationTypeParam
                ? 'active'
                : ''
              : newChatState?.new && id === 'new'
              ? 'active'
              : ''
          }`}
          key={chatConversation?.id}
        >
          <div className="dropdown uer-options-drop">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>
            <div
              className={
                'dropdown-menu ' +
                (lang == 'ar' ? 'dropdown-menu-right' : 'dropdown-menu-left')
              }
            >
              <button
                className="dropdown-item"
                type="button"
                data-toggle="modal"
                onClick={toggleDelete}
              >
                {trans.delete}
              </button>
            </div>
          </div>
          <div className="user-chat-info">
            <h2>
              {otherChatParticipant?.first_name
                ? otherChatParticipant?.first_name
                : '' + ' ' + otherChatParticipant?.last_name
                ? otherChatParticipant?.last_name
                : ''}
            </h2>
            <h3>{getBrandModelNames()}</h3>
            {chatConversation && (
              <>
                <p>{previewMessage}</p>
                <div className="user-chatConversation-unread-date">
                  <strong className="user-chatConversation-date">
                    {chatConversation?.last_message
                      ? format(
                          new Date(chatConversation.last_message_datetime),
                          'MMM d, yyyy',
                        )
                      : ''}
                    <p className="user-chatConversation-unread-count">
                      {/* {chatConversation.unread_messages_count !== 0
                    ? chatConversation.unread_messages_count
                    : null} */}
                    </p>
                  </strong>
                </div>
              </>
            )}
          </div>
        </li>
      </Link>
      {showDelete && chatConversation && (
        <DeleteChatPopupConfirmation
          toggleDelete={toggleDelete}
          chatConversationId={chatConversation.id}
          chatConversationType={chatConversation.chat_conversation_type}
          country={country}
        />
      )}
    </>
  );
};

export default AccountChatCard;
