import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { Link } from 'react-router-dom';

export default function ReviewsRedirectionButton() {
  const { trans, lang, country } = useContext(AppContext);

  return (
    <Link className="btn-shop" to={`/${country}/${lang}/reviews`}>
      <span className="ico">
        <img
          src="/images/reviews-2.svg"
          alt="img"
          style={{
            height: '25px',
          }}
        />
      </span>
      <span className="txt">{trans.reviews}</span>
    </Link>
  );
}
