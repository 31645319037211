import styled from '@emotion/styled';

export const VisualHolder = styled('div')(() => ({
  overflow: 'hidden',
  padding: '0 0 0 455px',
  position: 'relative',
  zIndex: 0,
  '@media (max-width: 1599px)': {
    padding: '0 0 0 365px',
  },
  '@media (max-width: 991px)': {
    padding: 0,
  },
  '.lang-au &': {
    padding: '0 455px 0 0',
    '@media (max-width: 1599px)': {
      padding: '0 365px 0 0',
    },
    '@media (max-width: 991px)': {
      padding: 0,
    },
  },
}));

export const VideoHolder = styled('div')(() => ({
  background: '#20282C',
  padding: '180px 100px 100px',
  position: 'relative',
  zIndex: 3,
  '@media (max-width: 991px)': {
    margin: '0 0 25px',
    padding: '100px',
  },
  '@media (max-width: 767px)': {
    margin: '0 0 25px',
    padding: '100px',
  },
  '@media (max-width: 575px)': {
    padding: '50px 0 100px',
  },
}));

export const VideoHolderBackground = styled('img')(() => ({
  position: 'absolute',
  left: '35px',
  bottom: '0',
  content: '" "',
  height: '100%',
  opacity: '.5',
  right: '60px',
  top: '0',
  width: '80%',
  zIndex: -1,
}));

export const CarouselBox = styled('div')(() => ({
  backgroundColor: '#000001C',
  aspectRatio: '16/9',
  border: '4px solid #fff',
}));

export const VideoBox = styled('div')(() => ({
  width: '100%',
  border: '4px solid #fff',
  height: '308px',
}));
