import { useQuery } from 'react-query';
import { IGetTrims, getTrims } from '../apis/trim.apis';

export const useGetTrims = (data: IGetTrims) => {
  const { enabled, model } = data;
  return useQuery({
    queryKey: ['trims', model],
    queryFn: () => getTrims({ model }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
    placeholderData: [],
  });
};
