import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { timeSince } from '../../../helpers/timesince';
import { Link } from 'react-router-dom';
import { IUsedCar } from '../../../types/cars/used_car';
import { formatPrice } from '../../../helpers/formatHelper';
import { CarListCardSkeleton } from '../../UsedCars/UsedCarsSkeletons';
import { CarListImageCard } from './CarGridCard/CarListImageCard';
import {
  AutoImgBox,
  CarListInfo,
  CarListInfoDd,
  CarListInfoDt,
  DealsAdvantages,
  DealsAdvantagesDealTitle,
  DealsAdvantagesH3,
  DealsAdvantagesSmallLogos,
  DealsInfoRow,
  DealsLeft,
  DealsNav,
  DealsNavLi,
  DealsRight,
  DealsRightBox,
  DealsRightBtn,
  DealsRightFrame,
  DealsRightFrameQarSpan,
  DealsRightFrameStrong,
  ImgBox,
} from '../../../common/styles/CommonStyles';
import { CarGridDealerLogo } from './CarGridCard/CarGridDealerLogo';
import '../../User/AccountWishList/Card-styles.css';
import {
  useUsedCarsPageClick,
  useUsedCarsPageView,
} from '../../UsedCars/hooks';
import { useInView } from 'react-intersection-observer';

type CarListCardProps = {
  usedCar: IUsedCar;
  isFavourite: boolean;
  onFavoriteClick: (e: any, id: number) => void;
  redirectionLink: string;
  isLoading: boolean;
};

const CarListCard = ({
  usedCar,
  isFavourite,
  onFavoriteClick,
  redirectionLink,
  isLoading,
}: CarListCardProps) => {
  const { mutateAsync: usedCarsPageView } = useUsedCarsPageView();

  const { ref } = useInView({
    threshold: 0.01,
    onChange(inView) {
      if (inView) {
        usedCarsPageView(usedCar.id);
      }
    },
    triggerOnce: true,
  });

  const { mutateAsync: usedCarPageClick } = useUsedCarsPageClick();

  const { trans, darkImageLoadingPlaceholder, lang } = useContext(AppContext);

  if (isLoading) {
    return <CarListCardSkeleton />;
  }
  return (
    <Link
      style={{ color: '#000' }}
      to={redirectionLink}
      ref={ref}
      onClick={() => {
        usedCarPageClick({ id: usedCar.id, event: 'click' });
      }}
    >
      <DealsInfoRow key={usedCar.id} className="usedcars test">
        <DealsLeft className="deals-left">
          <ImgBox>
            <AutoImgBox>
              <CarListImageCard
                src={usedCar.images?.[0]}
                defaultSrc={darkImageLoadingPlaceholder}
                alt={`${usedCar.model?.brand.slug}-${usedCar.model?.slug}-image`}
              />
              <button
                className={`wishlist-icon loginpopupload ${
                  isFavourite ? 'active' : ''
                }`}
                onClick={(e: any) => {
                  e.preventDefault();
                  onFavoriteClick(e, usedCar.id);
                }}
              >
                <i className="fa fa-heart-o"></i>
                <i className="fa fa-heart"></i>
              </button>
            </AutoImgBox>
          </ImgBox>
          <DealsAdvantages className="deals-advantages">
            <DealsAdvantagesSmallLogos className="small-logos">
              <CarGridDealerLogo
                src={usedCar.model?.brand.original_logo}
                alt={`${usedCar.dealer?.slug}-logo`}
              />
            </DealsAdvantagesSmallLogos>
            <DealsAdvantagesDealTitle>
              {usedCar.model?.brand.name ?? ''}
            </DealsAdvantagesDealTitle>
            <DealsAdvantagesH3>
              {usedCar.model?.name ?? ''}{' '}
              {usedCar && usedCar.trim != null ? usedCar.trim.name : ''}
            </DealsAdvantagesH3>
            <DealsNav className="deals-nav">
              <DealsNavLi>
                {timeSince(usedCar.status_changed_at, trans, lang)}
              </DealsNavLi>
            </DealsNav>
          </DealsAdvantages>
        </DealsLeft>
        <DealsRight className="deals-right">
          <DealsRightBox className="box">
            <CarListInfo>
              <CarListInfoDt>{trans.year}:</CarListInfoDt>
              <CarListInfoDd>{usedCar.year}</CarListInfoDd>
              <CarListInfoDt>{trans.mileage}:</CarListInfoDt>
              <CarListInfoDd>
                {usedCar.mileage || 0} {trans.kms}
              </CarListInfoDd>
              <CarListInfoDt>{trans.seller}:</CarListInfoDt>
              <CarListInfoDd>
                {usedCar.dealer?.name || trans.private}
              </CarListInfoDd>
            </CarListInfo>
          </DealsRightBox>
          <DealsRightFrame>
            <DealsRightFrameStrong>
              <DealsRightFrameQarSpan>
                {usedCar?.city?.currencies?.name}
              </DealsRightFrameQarSpan>
              <span>{formatPrice(usedCar.price)}</span>
            </DealsRightFrameStrong>
            <DealsRightBtn className="btn btn-secondary" to={redirectionLink}>
              {trans.view_car}
            </DealsRightBtn>
          </DealsRightFrame>
        </DealsRight>
      </DealsInfoRow>
    </Link>
  );
};

export default CarListCard;
