import { queryClient } from '../App';
import authenticatedApi from '../instance/ApiInstance';
import { IUsedCar } from '../types/cars/used_car';

const UserEndpointV2 = '/v2/auth';

export const getDealers = (page: any = 1) =>
  authenticatedApi
    .get<any>(`/api/dealers?page=${page}`)
    .then(response => response?.data)
    .catch();

export const getFinancings = (page: any = 1) =>
  authenticatedApi
    .get<any>(`/api/financing?page=${page}`)
    .then(response => response?.data)
    .catch();

export const getInsurances = (page: any = 1) =>
  authenticatedApi
    .get<any>(`/api/insurance?page=${page}`)
    .then(response => response?.data)
    .catch();

export const getCars = (
  category: 'used' | 'new',
  dealer_id: any = null,
  brand_id: any = null,
  model_id: any = null,
  type_id: any = null,
  perpage: number | null = null,
  page: number | null = null,
  orderBy: any = null,
  order: any = null,
  featured: any = null,
) => {
  let url = `/api/v2/search-cars/${category}?`;
  if (dealer_id != null && dealer_id != '') url += `&dealer_id=${dealer_id}`;
  if (brand_id != null && brand_id != '') url += `&brand_id=${brand_id}`;
  if (model_id != null && model_id != '') url += `&model_id=${model_id}`;
  if (type_id != null && type_id != '') url += `&type_id=${type_id}`;
  if (perpage) url += `&perpage=${perpage}`;
  if (page) url += `&page=${page}`;
  if (orderBy) url += `&orderby=${orderBy}`;
  if (order) url += `&order=${order}`;
  if (featured) url += `&featured=${featured}`;
  return authenticatedApi
    .get<any>(url)
    .then(response => response?.data)
    .catch();
};

export const getCarsQuery = (query: any) => {
  return authenticatedApi
    .get<any>(`/api/search-cars${query}`)
    .then(response => response?.data)
    .catch();
};

export const searchCars = (query: any) => {
  return authenticatedApi
    .get<any>(`/v2/cars/used-cars${query}`)
    .then(response => response?.data)
    .catch();
};

// ! this is temporary function
export const getNewListings = async () => {
  return authenticatedApi
    .get<any>('/api/listings')
    .then(response => response.data)
    .catch();
};

// ! this is temporary function

export const getModelsForBrand = (
  brand_id: any,
  model_id: any = null,
  type_id: any = null,
  isNew: any = null,
  isUsed: any = null,
) => {
  // id not brand_id here refers to brand id
  let url = `/api/models-by-brand?all=1&id=${brand_id}`;
  if (model_id != null && model_id !== '') url += `&model_id=${model_id}`;
  if (type_id != null && type_id !== '') url += `&type_id=${type_id}`;
  if (isNew != null && isNew !== '') url += `&alownew=${isNew}`;
  if (isUsed != null && isUsed !== '') url += `&alowuse=${isUsed}`;

  return authenticatedApi
    .get<any>(url)
    .then(response => response?.data)
    .catch();
};

export const getModels = () =>
  authenticatedApi
    .get<any>(`/api/search-models`)
    .then(response => response?.data)
    .catch();

export const getTypes = () =>
  authenticatedApi
    .get<any>(`/api/search-types`)
    .then(response => response?.data)
    .catch();

export const getArticles = (page: any = 1) =>
  authenticatedApi
    .get<any>(`/api/articles?page=${page}`)
    .then(response => response?.data)
    .catch();

export const getArticleDetails = (id: any) =>
  authenticatedApi
    .get<any>(`/api/article-details?id=${id}`)
    .then(response => response?.data)
    .catch();

export const postInquiry = (lang: any, type: any, id: any, body: any) =>
  authenticatedApi
    .post<any>(`/api/inquiry_call/${type}/${id}`, body)
    .then(response => response?.data)
    .catch();

export const getBrands = () =>
  authenticatedApi
    .get<any>(`/api/brands?all=1`)
    .then(response => response?.data)
    .catch();

export const getCarDetails = ({ id }): Promise<IUsedCar> =>
  authenticatedApi
    .get<any>(`/v2/cars/used-cars/${id}`)
    .then(response => response?.data);

export const getFeaturedCars = (page: any = 1) =>
  authenticatedApi
    .get<any>(`/api/featured-cars?page=${page}`)
    .then(response => response?.data)
    .catch();

export const postDealInquiry = (body: any) =>
  authenticatedApi
    .post<any>(`/api/inquires`, body)
    .then(response => response?.data)
    .catch();

export const postSellCar = (body: any, carId: any = 0) =>
  authenticatedApi
    .post<any>(`/api/sell-car${carId > 0 ? `/${carId}` : ''}`, body, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => response?.data)
    .catch();

export const loginUserByEmail = async (body: any) => {
  try {
    const response = await authenticatedApi.post<any>(`v2/auth/login`, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const loginUserByMobile = async (body: any) => {
  const response = await authenticatedApi.post<any>(
    `v2/auth/login-by-mobile`,
    body,
  );
  return response?.data;
};

export const logOutUser = () =>
  authenticatedApi
    .post<any>(`/api/auth/sign-out/sessions/current`)
    .then(() => {
      localStorage.removeItem('userData');
      window.location.href = `/`;
    })
    .catch();

export const signUp = async (body: any) => {
  const response = await authenticatedApi.post<any>(`v2/auth/register`, body);
  return response?.data;
};

export const sendCode = async () => {
  try {
    const response = await authenticatedApi.get('v2/auth/otp');
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const verifyPhone = async (code: string) => {
  const response = await authenticatedApi.post(`v2/auth/otp`, {
    otp: code,
  });

  return response.data;
};

export const verifyOTPwithMobile = async (mobile: string, OTPCode: string) => {
  try {
    const response = await authenticatedApi.post(
      'v2/auth/reset-password',
      {
        otp: OTPCode,
      },
      {
        params: {
          mobile: mobile,
        },
      },
    );

    return response.data;
  } catch (e) {
    throw e;
  }
};

export const updateMobileNumber = async (mobile_number: string) => {
  try {
    const response = await authenticatedApi.put('v2/auth/update-profile', {
      mobile_number,
    });

    return response.data;
  } catch (e) {
    throw e;
  }
};

export const resetPassword = async (
  newPassword: string,
  access_token: string,
) => {
  try {
    const response = await authenticatedApi.put(
      '/v2/auth/update-profile',
      {
        password: newPassword,
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${access_token}`,
        },
      },
    );

    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getUserDetails = (userId: any) =>
  authenticatedApi
    .get<any>(`/api/userdetail/${userId}`)
    .then(response => response?.data)
    .catch();

export const getHelperList = () =>
  authenticatedApi
    .get<any>(`/api/helperlisit`)
    .then(response => response?.data)
    .catch();

export const forgotPasswordOTP = async (mobile: string) => {
  try {
    const response = await authenticatedApi.get(`v2/auth/reset-password`, {
      params: {
        mobile: mobile,
      },
    });

    return response.data;
  } catch (e) {
    throw e;
  }
};

export type TUserPayload = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  mobile_number: string;
};

export const updateProfile = async (data: TUserPayload) => {
  const response = await authenticatedApi.put<any>(`/api/v2/users`, {
    ...data,
  });

  return response.data;
};

export const updateLanguage = (body: any) =>
  authenticatedApi
    .put<any>(`/api/update-language`, body)
    .then(response => response?.data)
    .catch();

export const addFavorite = (carId: number) => {
  return authenticatedApi
    .post<any>(`/v2/favorites`, { used_car_id: carId })
    .then(response => {
      queryClient.invalidateQueries({
        queryKey: ['favoriteUsedCars'],
      });
      return response?.data;
    })
    .catch();
};

export const deleteFavorite = (carId: number) => {
  return authenticatedApi
    .delete<any>(`/v2/favorites/${carId}`)
    .then(response => {
      queryClient.invalidateQueries({
        queryKey: ['favoriteUsedCars'],
      });
      return response?.data;
    })
    .catch();
};

export const getBanners = async () => {
  const banners = await authenticatedApi.get<any>('/v2/banners');
  return banners?.data;
};

export const getMenuNumbers = async () => {
  try {
    const result = await authenticatedApi.get(`/api/meanucounts`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getChat = async (chatId?: any) => {
  try {
    const result = await authenticatedApi.get(
      `/api/v2/account-chat${chatId ? `/${chatId}` : ''}`,
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const getChats = async (chatId?: any) => {
  try {
    const result = await authenticatedApi.get('/api/get-chats');
    return result.data?.chats;
  } catch (error) {
    throw error;
  }
};

export const startChat = async (carId: number, userId: number) => {
  try {
    const result = await authenticatedApi.get(
      `/api/start-chat?user_id=${userId}&car_id=${carId}`,
    );
    return result?.data?.chat;
  } catch (error) {
    throw error;
  }
};

export const sendMessage = async (chatId: any, message: string) => {
  try {
    const result = await authenticatedApi.get(
      `/api/v2/send-msg?chat_id=${chatId}&msg=${message}`,
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const sendSupportMessage = async (request: any) => {
  try {
    const result = await authenticatedApi.post(`/api/support-msg`, {
      name: request.name,
      message: request.message,
      mobile_number: request.mobile.toString(),
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateAccountDetails = async (user: any) => {
  try {
    const result = await authenticatedApi.put(
      `${UserEndpointV2}/update-profile`,
      user,
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deactivateMe = async () => {
  try {
    const result = await authenticatedApi.post(`${UserEndpointV2}/deactivate`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const clearchat = async (chatId: number) => {
  try {
    const result = await authenticatedApi.post(`/api/clearchat/${chatId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const blockUser = async (userId: number) => {
  try {
    const result = await authenticatedApi.post(`/api/blocked/${userId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const reportUser = async (userId: number) => {
  try {
    const result = await authenticatedApi.post(`/api/reporteduser/${userId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const submitContact = (body: any) =>
  authenticatedApi
    .post<any>(`/api/support-msg`, body, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => response?.data)
    .catch();

export const markChatMessagesAsRead = async (chatId: any) => {
  try {
    const result = await authenticatedApi.post(`/api/mark-as-read/${chatId}`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getBrandsWithDeals = async () => {
  try {
    const result = await authenticatedApi.get('api/v2/get-brands-with-deals');
    return result ? result.data : [];
  } catch (error) {
    throw error;
  }
};

export const brandPageView = async (brandId: number) => {
  try {
    const result = await authenticatedApi.post(`api/view/brands`, {
      brand_id: brandId,
      source: 'website',
      event: 'page_view',
    });
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
