import { useMutation, useQuery } from 'react-query';
import { ICreateUsedCarInquiry, createUsedCarInquiry } from '../apis';
import { getCarDetails } from '../../../services/ApiService';
import {
  ICreateUsedCar,
  IGetUsedCars,
  IPriceValuation,
  IUpdateUsedCar,
  PriceValuation,
  UsedCarsPageClick,
  UsedCarsPageView,
  VinDecoder,
  createUsedCar,
  getFavoriteUsedCars,
  getUsedCars,
  updateUsedCar,
  uploadUsedCarImage,
  uploadVinDecoderIamge,
} from '../apis/usedCarsApi';
import { IUsedCar } from '../../../types/cars/used_car';
import { AppContext } from '../../../contexts/AppContext';
import { useContext } from 'react';

interface IUseGetFavoriteUsedCars {
  enabled: boolean;
  userData: any;
}

interface IUseGetUsedCars {
  query: IGetUsedCars;
  enabled: boolean;
}

export const useGetUsedCars = ({ query, enabled }: IUseGetUsedCars) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['usedCars', query, lang],
    queryFn: () => getUsedCars(query),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: enabled,
  });
};

export const useGetFavoriteUsedCars = ({
  enabled = true,
  userData,
}: IUseGetFavoriteUsedCars) => {
  return useQuery({
    queryKey: ['favoriteUsedCars', userData],
    queryFn: () => getFavoriteUsedCars(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useUploadUsedCarImage = () => {
  return useMutation({
    mutationFn: (file: File) => uploadUsedCarImage({ file }),
  });
};

export const useCreateUsedCar = () => {
  return useMutation({
    mutationFn: (data: ICreateUsedCar) => createUsedCar(data),
  });
};

export const useUpdateUsedCar = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IUpdateUsedCar }) =>
      updateUsedCar(id, data),
  });
};

export const useGetUsedCarById = (id?: string) => {
  const { lang, country } = useContext(AppContext);
  return useQuery({
    queryKey: ['usedCar', id, lang],
    queryFn: () => getCarDetails({ id }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    onError: error => {
      window.location.href = `/${country}/${lang}/404`;
    },
  });
};

export const useGetUsedDealClick = (
  setShowDealForm: (newValue: boolean) => void,
) => {
  const getDealClick = (car: IUsedCar) => {
    if (car.call_to_action) {
      window.open(
        car.call_to_action.startsWith('http')
          ? car.call_to_action
          : 'https://' + car.call_to_action,
        '_blank',
      );
    } else {
      setShowDealForm(true);
    }
  };

  return { getDealClick };
};

export const useCreateUsedCarInquiry = () => {
  return useMutation({
    mutationFn: (data: ICreateUsedCarInquiry) => createUsedCarInquiry(data),
  });
};

export const useUsedCarsPageView = () => {
  return useMutation({
    mutationFn: (id: number) => UsedCarsPageView(id),
  });
};

export const useUsedCarsPageClick = () => {
  return useMutation({
    mutationFn: ({ id, event }: { id: number; event: string }) =>
      UsedCarsPageClick(id, event),
  });
};

export const useUploadVinDecoderIamge = () => {
  return useMutation({
    mutationFn: (file: File) => uploadVinDecoderIamge(file),
  });
};

export const usePriceValuation = () => {
  return useMutation({
    mutationFn: (priceVaLuation: IPriceValuation) =>
      PriceValuation(priceVaLuation),
    mutationKey: 'priceVaLuation',
  });
};
