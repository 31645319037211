import parse from 'html-react-parser';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import {
  sendCode,
  updateMobileNumber,
  verifyPhone,
} from '../../services/ApiService';
import Banner from '../Banners/BannerComponent';
import PageLoader from '../Other/PageLoader/PageLoaderComponent';
import { successNotification } from '../Other/ToastMessage';
import PhoneInput from 'react-phone-input-2';
import OtpInput from 'react-otp-input';
import '../User/SignUp/signup-styles.css';
import '../User/LogIn/login-styles.css';

enum Screen {
  Mobile = 'mobile',
  OTP = 'OTP',
}

export default function VerifyPhone(this: any) {
  const { lang, trans, country } = useContext(AppContext);
  const [showPageLoader, setShowPageLoader] = useState(false);
  const [otp, setOtp] = useState('');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const [error, setError] = useState('');
  const [screen, setScreen] = useState<Screen>(Screen.OTP);
  const [newMobileNumber, setNewMobileNumber] = useState({
    number: '',
    isValid: true,
  });

  const isMobile = useMemo(() => {
    return window.innerWidth < 768;
  }, [window.innerWidth]);

  const resendCodeClick = async (showToast?: boolean) => {
    try {
      setShowPageLoader(true);

      const response = await sendCode();

      if (response.error) {
        successNotification(
          'There was a problem sending you an OTP',
          'danger',
          'Error',
        );
        setShowPageLoader(false);
        return;
      }

      if (showToast) {
        successNotification(
          'An OTP code has been sent to your mobile',
          'success',
          'Success',
        );
      }

      setSeconds(59);
      setMinutes(1);
      setShowPageLoader(false);
    } catch (e) {
      successNotification('Failed to send OTP', 'danger', 'Error');
      setShowPageLoader(false);
    }
  };

  const handleChangeMobile = async () => {
    try {
      await updateMobileNumber(newMobileNumber.number);

      await resendCodeClick(false);

      // Success
      successNotification(
        'Mobile number updated, a new OTP has been sent',
        'success',
        'OTP Sent',
      );

      setScreen(Screen.OTP);
      setShowPageLoader(false);
    } catch (e) {
      successNotification('Mobile number already taken', 'info', 'Error');
      setShowPageLoader(false);
    }
  };

  const handleSubmitOTP = async () => {
    try {
      if (otp.length < 6) {
        return;
      }

      setShowPageLoader(true);

      const response = await verifyPhone(otp);

      const accessToken = response.access_token;

      localStorage.setItem('accessToken', accessToken);

      if (accessToken) {
        const userData = JSON.parse(atob(accessToken.split('.')[1]));
        if (userData) {
          localStorage.setItem('userData', JSON.stringify(userData));
        }
      }
      window.location.href = `/${country}/${lang}/account-details`;
      setShowPageLoader(false);
    } catch (e) {
      successNotification('Failed to verify OTP', 'danger', 'Error');
      setShowPageLoader(false);
    }
  };

  useEffect(() => {
    sendCode();
  }, []);

  // OTP Timer
  useEffect(() => {
    if (screen == Screen.OTP) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, screen]);

  return (
    <>
      {showPageLoader && <PageLoader />}
      <main id="main">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Banner section_name="E2"></Banner>
              <div className="signup-holder authentication">
                <div className="signup-box">
                  <div className="login-logo">
                    <img src="/images/logo.jpg" />
                  </div>
                  {screen === Screen.OTP && (
                    <form className="login-form row justify-content-center">
                      <div className="form-group col-md-12 form-text">
                        <p>{trans.enter_code}</p>
                      </div>
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        containerStyle={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                        inputStyle={{
                          width: isMobile ? '35px' : '50px',
                          height: isMobile ? '35px' : '50px',
                          margin: '0 10px',
                          fontSize: '20px',
                        }}
                        shouldAutoFocus={true}
                        renderInput={props => (
                          <input
                            {...props}
                            className={props.className + ' otp-input'}
                          />
                        )}
                      />{' '}
                      <div className="form-group col-md-12 form-text">
                        <p>
                          <span className="errorMessage">{parse(error)}</span>
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '20px',
                        }}
                        className="form-group resend-txt"
                      >
                        {seconds > 0 || minutes > 0 ? (
                          <button
                            disabled={true}
                            className="btn forgot-password-secondary-button"
                            style={{
                              backgroundColor: '#ffff',
                              border: '1.5px solid #00d6bc',
                              minWidth: ' 160px',
                              textAlign: 'center',
                              color: '#00d6bc',
                              outline: 'none',
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              letterSpacing: '1px',
                            }}
                          >
                            Time Remaining:
                            {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => resendCodeClick(true)}
                            className="btn forgot-password-secondary-button"
                            style={{
                              backgroundColor: '#ffff',
                              border: '1.5px solid #00d6bc',
                              minWidth: ' 160px',
                              textAlign: 'center',
                              color: '#00d6bc',
                              outline: 'none',
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              letterSpacing: '1px',
                            }}
                          >
                            Resend Code
                          </button>
                        )}
                        <p>
                          <button
                            type="button"
                            className="btn forgot-password-secondary-button"
                            style={{
                              backgroundColor: '#ffff',
                              border: '1.5px solid #00d6bc',
                              minWidth: ' 160px',
                              textAlign: 'center',
                              color: '#00d6bc',
                              outline: 'none',
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              letterSpacing: '1px',
                            }}
                            onClick={() => setScreen(Screen.Mobile)}
                          >
                            Change Mobile Number
                          </button>
                        </p>
                      </div>
                      <div className="form-group button col-12">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSubmitOTP}
                        >
                          {trans.next}
                        </button>
                      </div>
                    </form>
                  )}
                  {screen === Screen.Mobile && (
                    <form className="login-form row justify-content-center">
                      <div className="form-group col-md-12 form-text">
                        <p>New Mobile</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '15px',
                        }}
                      >
                        <div>
                          <PhoneInput
                            country="qa"
                            isValid={(value, country) => {
                              if (
                                newMobileNumber.number.length > 4 &&
                                value.length < 8
                              )
                                return 'Invalid Mobile';
                              return true;
                            }}
                            inputClass="custom-tel"
                            value={newMobileNumber.number}
                            onChange={(e: any) =>
                              setNewMobileNumber({
                                ...newMobileNumber,
                                number: e,
                              })
                            }
                            placeholder=""
                            masks={{ qa: '........' }}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '20px',
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleChangeMobile}
                          >
                            {trans.next}
                          </button>
                          <button
                            type="button"
                            className="btn forgot-password-secondary-button"
                            style={{
                              backgroundColor: '#ffff',
                              border: '1.5px solid #00d6bc',
                              minWidth: ' 160px',
                              textAlign: 'center',
                              color: '#00d6bc',
                              outline: 'none',
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              letterSpacing: '1px',
                            }}
                            onClick={() => setScreen(Screen.OTP)}
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
