import { useContext, useState } from 'react';
import {
  ButtonStyled,
  FormControlStyled,
  MainHead,
  MainHeadH1,
  MainHeadP,
  TextFieldStyled,
} from '../../../../common/styles/CommonStyles';
import { AppContext } from '../../../../contexts/AppContext';
import { BiScan } from 'react-icons/bi';
import './SellYourCarFirstPage-styles.css';
import { useNavigate } from 'react-router-dom';
import { SellYourCarFirstPageModal } from './SellYourCarFirstPageModal';
import PageLoader from '../../../Other/PageLoader/PageLoaderComponent';
import MetaTag from '../../../../common/meta/MetaTag';
import { useUploadVinDecoderIamge } from '../../../UsedCars/hooks';

export const SellYourCarFirstPage = () => {
  const navigate = useNavigate();
  const { trans, lang, country } = useContext(AppContext);
  const [vinConfirm, setVinConfirm] = useState(false);
  const [vin, setVin] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: uploadVinDecoderIamge } = useUploadVinDecoderIamge();

  const handleConfirmClick = () => {
    if (vin.trim() === '') {
      setError(true);
      setVinConfirm(false);
    } else {
      setError(false);
      setVinConfirm(true);
    }
  };

  return (
    <>
      <MetaTag
        href={`/${country}/${lang}/sell-your-car`}
        metaKey="sellYourCar"
      />
      <main id="main" className="sell_your_car_data">
        <MainHead className="sell-car-head ">
          <MainHeadH1 className="sell-car-h1">{trans.sell_car}</MainHeadH1>
          <MainHeadP className="sell-car-p">
            {trans.sell_car} {trans.with} ONE AUTOCAR
          </MainHeadP>
        </MainHead>
        {isLoading && <PageLoader loadSectionOnly />}
        <div className="container">
          <div className="sell-car-first-page-box">
            <div className="sell-car-first-page-box-container">
              <div className="sell-car-first-page-box-content">
                <h2>
                  <strong>{trans.first},</strong>{' '}
                  {trans.Scan_your_car_id_or_type_it_manually}
                </h2>
                <p>{trans.Scan_your_car_description}</p>
              </div>
              <FormControlStyled
                required={true}
                className="sell-your-car-vin-search-bar-form"
              >
                <div className="sell-your-car-vin-search-bar-container">
                  <TextFieldStyled
                    className="sell-your-car-vin-search-bar"
                    label={trans.Type_your_vin_Number}
                    variant="filled"
                    value={vin}
                    onChange={e => setVin(e.target.value)}
                    fullWidth
                    error={error}
                    helperText={error ? trans.invalid_vin_scan_error : ''}
                    lang={lang}
                  />
                  <BiScan
                    className="scan-icon"
                    onClick={() => {
                      const input = document.createElement('input');
                      input.type = 'file';
                      input.accept = 'image/*';
                      input.onchange = async e => {
                        const file = (e.target as HTMLInputElement).files![0];
                        if (file) {
                          setIsLoading(true);
                          try {
                            const res = await uploadVinDecoderIamge(file);
                            setVin(res.vin);
                            setIsLoading(false);
                            setVinConfirm(true);
                          } catch (error) {
                            console.log(error);
                            setIsLoading(false);
                            setVinConfirm(false);
                            setVin('');
                            setError(true);
                          }
                        }
                      };
                      input.click();
                    }}
                  />
                </div>
                <ButtonStyled
                  variant="contained"
                  color="primary"
                  className="sell-your-car-vin-search-button"
                  onClick={handleConfirmClick}
                >
                  {trans.sell_confirm}
                </ButtonStyled>
              </FormControlStyled>
              <h3
                onClick={() =>
                  navigate(`/${country}/${lang}/sell-your-car/car-details-form`)
                }
              >
                {trans.Or_you_can_select_your_car_details}
              </h3>
            </div>
          </div>
        </div>
        {vinConfirm && (
          <SellYourCarFirstPageModal
            toggleSignInDialog={setVinConfirm}
            vin={vin}
            setError={setError}
            setIsLoading={setIsLoading}
          />
        )}
      </main>
    </>
  );
};
