export const USBChargerIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4201 1.82471L22.9254 9.33356H19.9219V20.4181L24.4272 18.167L24.4257 16.8424H22.9254V10.8353H28.9325V16.8424H27.4292L27.4307 20.0232L19.9219 23.7776V26.1098C21.6729 26.7286 22.9254 28.397 22.9254 30.3584C22.9254 32.8468 20.9085 34.8637 18.4201 34.8637C15.9317 34.8637 13.9148 32.8468 13.9148 30.3584C13.9148 28.5758 14.951 27.0349 16.4528 26.3036L9.40949 21.3477L9.40798 17.9417C8.51143 17.4221 7.90771 16.452 7.90771 15.3406C7.90771 13.6812 9.2518 12.3371 10.9113 12.3371C12.5707 12.3371 13.9148 13.6812 13.9148 15.3406C13.9148 16.452 13.3111 17.4236 12.413 17.9417V19.846L16.9183 22.9787V9.33356H13.9148L18.4201 1.82471ZM18.4201 28.8566C17.5911 28.8566 16.9183 29.5294 16.9183 30.3584C16.9183 31.1873 17.5911 31.8601 18.4201 31.8601C19.2491 31.8601 19.9219 31.1873 19.9219 30.3584C19.9219 29.5294 19.2491 28.8566 18.4201 28.8566Z"
        fill={color}
      />
    </svg>
  );
};
