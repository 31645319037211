import { AppConstants } from '../constants/AppConstants';
import { BannerModel } from '../models/BannerModel';
import { LocalStorageService } from './LocalStorageService';

export function getBannerInternal(groupKey: string) {
  const banners = LocalStorageService.get<{ [key: string]: BannerModel }>(
    AppConstants.LOCAL_STORAGE.BANNERS,
  );
  if (banners && banners[groupKey] && banners[groupKey] !== undefined) {
    let banner = banners[groupKey].banners.shift();
    if (banner) {
      banners[groupKey].banners.push(banner);
      LocalStorageService.set<{ [key: string]: BannerModel }>(
        AppConstants.LOCAL_STORAGE.BANNERS,
        banners,
        AppConstants.LOCAL_STORAGE.DEFAULT_TTL,
      );
      return banners[groupKey].banners.map(banner => {
        return {
          ...banner,
          content_type: banners[groupKey].content_type,
          show_default_image: banners[groupKey].show_default_image,
          banner_resource_images: banners[groupKey].banner_resource_images,
        };
      });
    } else {
      return [banners[groupKey]];
    }
  }
}
