import authenticatedApi from '../../../../instance/ApiInstance';
import {
  IChatConversation,
  EChatConversationType,
} from '../../../../types/chats/chat_conversation';
import { IChatMessage } from '../../../../types/chats/chat_message';

const chatsEndpoint = '/v2/chats';
const chatConversationsEndpoint = `${chatsEndpoint}/chat-conversations`;
const chatMessagesEndpoint = `${chatsEndpoint}/chat-messages`;

export interface IGetChatMessagesByConversationProps {
  enabled?: boolean;
  chat_conversation_id?: number;
  chat_conversation_type: EChatConversationType;
}

export interface ISendMessageProps {
  chat_conversation_id?: number;
  recipient_id: number;
  recipient_type: string;
  used_car_id?: number;
  used_bike_id?: number;
  message: string;
  chat_conversation_type: EChatConversationType;
}

export interface IMarkMessagesAsReadProps {
  chat_conversation_id: number;
  chat_conversation_type: EChatConversationType;
}

export interface IDeleteChatConversationProps {
  chat_conversation_id: number;
  chat_conversation_type: EChatConversationType;
}

export interface IGetUnreadMessagesCountProps {
  chat_conversation?: number;
  chat_conversation_type?: EChatConversationType;
}

export const getChatConversations = async (): Promise<IChatConversation[]> => {
  try {
    const response = await authenticatedApi.get(
      `${chatConversationsEndpoint}/get-for-user`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChatMessagesByConversation = async (
  params: IGetChatMessagesByConversationProps,
): Promise<{ data: IChatMessage[]; unread_messages_count: number }> => {
  const response = await authenticatedApi.get(
    `${chatMessagesEndpoint}/messages`,
    {
      params,
    },
  );
  return response.data;
};

export const sendMessage = async (data: ISendMessageProps) => {
  const response = await authenticatedApi.post(
    `${chatMessagesEndpoint}/create`,
    data,
  );
  return response.data;
};

export const markMessagesAsRead = async (data: IMarkMessagesAsReadProps) => {
  const response = await authenticatedApi.put(
    `${chatMessagesEndpoint}/mark-as-read`,
    data,
  );
  return response.data;
};

export const deleteChatConversation = async (
  data: IDeleteChatConversationProps,
) => {
  const response = await authenticatedApi.post(
    `${chatConversationsEndpoint}/delete-chat`,
    data,
  );
  return response.data;
};

export const getUnreadMessagesCount = async (
  params?: IGetUnreadMessagesCountProps,
) => {
  const response = await authenticatedApi.get(
    `${chatMessagesEndpoint}/unread-count`,
    {
      params,
    },
  );
  return response.data;
};
