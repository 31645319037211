import authenticatedApi from '../../../instance/ApiInstance';
import { IExteriorColor } from '../../../types/resources/cars';

const colorsEndpoint = '/v2/resources/bikes/colors';

export const getBikeColors = async (): Promise<
  IExteriorColor[] | undefined
> => {
  try {
    const response = await authenticatedApi.get(colorsEndpoint);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
