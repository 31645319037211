import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {
  MenuProps,
  selectStyle,
} from './ShowRoomUsedCarsComponent/ShowroomUsedCarsComponent';
import { IBrand, IModel, TType } from '../../../types/resources/cars';
import { CarsFormHolder } from './DealerStyledComponent';
import '../../../styles/css/filters-styles.css';

export const UsedBikeBrandFilterShowroomComponent = ({
  trans,
  filters,
  handleBrandChange,
  renderBrandValue,
  handleModelChange,
  brands,
  renderModelValue,
  models,
  handleTypeChange,
  renderTypeValue,
  typesToRender,
  handleSearch,
  handleClear,
}: {
  trans: any;
  filters: any;
  handleBrandChange: (event: SelectChangeEvent<number[]>) => void;
  renderBrandValue: (selected: number[]) => string;
  brands: IBrand[] | undefined;
  handleModelChange: (event: SelectChangeEvent<number[]>) => void;
  renderModelValue: (selected: number[]) => string;
  models: IModel[] | undefined;
  handleTypeChange: (event: SelectChangeEvent<number[]>) => void;
  renderTypeValue: (selected: number[]) => string;
  typesToRender: TType[] | undefined;
  handleSearch: () => void;
  handleClear: () => void;
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <CarsFormHolder className="cars-form-holder">
          <form className="row cars-selection-form showroom">
            <div className="form-group">
              <label htmlFor="brand_id" className="form-label">
                {trans.brand}
              </label>
              <Select
                id="brand_id"
                multiple
                value={filters?.brandIds || []}
                onChange={handleBrandChange}
                className="custom-select"
                renderValue={renderBrandValue}
                MenuProps={MenuProps}
                displayEmpty
                style={selectStyle}
                disabled={!brands}
              >
                {brands?.map(brand => (
                  <MenuItem key={brand.id} value={brand.id}>
                    <ListItemText primary={brand.name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="form-group">
              <label htmlFor="model_id" className="form-label">
                {trans.model}
              </label>
              <Select
                id="model_id"
                multiple
                value={filters.modelIds || []}
                onChange={handleModelChange}
                className="custom-select mui-fixed"
                renderValue={renderModelValue}
                MenuProps={MenuProps}
                displayEmpty
                disabled={!models}
                style={selectStyle}
              >
                {models?.map(model => (
                  <MenuItem key={model.id} value={model.id}>
                    <ListItemText primary={model.name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="form-group">
              <label htmlFor="type_id" className="form-label">
                {trans.type}
              </label>
              <Select
                id="type_id"
                multiple
                value={filters.types || []}
                onChange={handleTypeChange}
                className="custom-select"
                renderValue={renderTypeValue}
                disabled={!typesToRender || typesToRender.length === 0}
                MenuProps={MenuProps}
                displayEmpty
                style={selectStyle}
              >
                {typesToRender?.map(type => (
                  <MenuItem key={type.id} value={type.id}>
                    <ListItemText primary={type.name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="form-group buttons">
              <button
                type="button"
                onClick={handleSearch}
                className="btn btn-primary"
              >
                {trans.search}
              </button>
              <button
                type="button"
                onClick={handleClear}
                className="btn btn-clear"
              >
                {trans.clear}
              </button>
            </div>
          </form>
        </CarsFormHolder>
      </div>
    </div>
  );
};
