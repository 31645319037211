import { styled } from '@mui/material';

export const ReviewsContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 360px))',
  justifyContent: 'space-evenly',
}));

export const ReviewCardContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
  '.lang-au &': {
    gap: '10px',
  },
}));

export const ReviewsCardTextContainer = styled('div')(() => ({
  flexGrow: 1,
}));

export const ReviewsCardP1 = styled('p')(() => ({
  fontWeight: 'bold',
  margin: '0',
}));

export const ReviewsCardP2 = styled('p')(() => ({
  margin: '5px 0',
  color: '#666',
}));

export const ReviewCardStyled = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  marginBottom: '20px',
  cursor: 'pointer',
}));

export const ReviewBox = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
}));

export const VideopWrapper = styled('div')(() => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: '0',
  left: '0',
}));

export const ReviewerInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ReviewerInfoContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
  gap: '10px',
}));

export const ReviewerName = styled('h2')(() => ({
  fontSize: '24px',
  color: '#000',
  fontWeight: 800,
  lineHeight: 1.2,
  marginBottom: '10px',
}));

export const ReviewDescription = styled('h2')(() => ({
  fontSize: '24px',
  color: '#000',
  fontWeight: 700,
  margin: '10px 0',
}));


