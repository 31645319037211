import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { useModelsForReviews } from '../../../../common/hooks/model.hooks';
import { useBrandsReviews } from '../../../../common/hooks/brand.hooks.';
import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { TReviewsFilter } from '../../ReviewsLayout';
import './ReviewsFilter-styles.css';

interface IListingFilter {
  filters: TReviewsFilters;
  setFilters: Dispatch<SetStateAction<TReviewsFilters>>;
  getFilteredFilters: (filters: TReviewsFilters) => {};
  setReviewsQueryParams: Dispatch<SetStateAction<TReviewsFilter | undefined>>;
}

const ITEM_HEIGHT = 48;
export const MenuProps = {
  PaperProps: {
    sx: {
      color: '#4E4E4E',
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + 8,
      width: 'auto',
    },
  },
  disableScrollLock: true,
  style: {
    position: 'absolute' as 'absolute',
  },
};

const selectStyle = {
  color: '#4E4E4E',
  fontWeight: 700,
  marginTop: 10,
  marginBottom: 15,
  borderRadius: 8,
  fontFamily: 'Lato',
  width: 160,
};

export type TReviewsFilters = {
  brandIds: number[];
  modelIds: number[];
  language: string;
};

export enum Language {
  en = 'en',
  ar = 'ar',
}

export const ReviewsFilter = ({
  filters,
  setFilters,
  getFilteredFilters,
  setReviewsQueryParams,
}: IListingFilter) => {
  const { trans } = React.useContext(AppContext);

  const { data: brands } = useBrandsReviews({
    language: filters.language,
  });

  const { data: models, isFetching: isFetchingModels } = useModelsForReviews({
    brandIds: filters.brandIds,
    language: filters.language,
  });

  //#endregion

  const handleBrandChange = (
    event: SelectChangeEvent<typeof filters.brandIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      const newFilters = {
        ...filters,
        brandIds: value,
      };
      setFilters(newFilters);
      const filteredFilters = getFilteredFilters(newFilters);
      setReviewsQueryParams(filteredFilters);
    }
  };

  const handleModelChange = (
    event: SelectChangeEvent<typeof filters.modelIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      const newFilters = {
        ...filters,
        modelIds: value,
      };
      setFilters(newFilters);
      const filteredFilters = getFilteredFilters(newFilters);
      setReviewsQueryParams(filteredFilters);
    }
  };

  const hanldeLanguageChange = (
    event: SelectChangeEvent<typeof filters.language>,
  ) => {
    const {
      target: { value },
    } = event;

    const newFilters = {
      ...filters,
      language: value,
    };

    setFilters(newFilters);
    const filteredFilters = getFilteredFilters(newFilters);
    setReviewsQueryParams(filteredFilters);
  };

  const handleLanguageClickEn = () => {
    // Manually reset the language to an empty string
    const newValue = filters.language === Language.en ? '' : Language.en;
    const newFilters = {
      ...filters,
      language: newValue,
    };
    setFilters(newFilters);
    const filteredFilters = getFilteredFilters(newFilters);
    setReviewsQueryParams(filteredFilters);
  };

  const handleLanguageClickAr = () => {
    // Manually reset the language to an empty string
    const newValue = filters.language === Language.ar ? '' : Language.ar;
    const newFilters = {
      ...filters,
      language: newValue,
    };
    setFilters(newFilters);
    const filteredFilters = getFilteredFilters(newFilters);
    setReviewsQueryParams(filteredFilters);
  };

  const renderBrandValue = (selected: Array<string | number>) => {
    if (!selected || selected.length === 0) {
      return trans.brands;
    }

    return selected
      .map(id => {
        const brand = brands?.find(brand => brand.id === id);
        return brand?.name;
      })
      .join(', ');
  };

  const renderModelValue = (selected: Array<string | number>) => {
    if (isFetchingModels) return <p>Loading...</p>;
    if (selected.length === 0) {
      return trans.models;
    }

    const filteredModels = models?.filter(model => selected.includes(model.id));
    return filteredModels?.map(model => model.name).join(', ');
  };

  const renderLanguageValue = (selected: string) => {
    if (!selected || selected === '') {
      return trans.language;
    }

    return selected.valueOf() === Language.en
      ? `${trans.english}`
      : `${trans.arabic}`;
  };

  useEffect(() => {
    setFilters({
      modelIds: [],
      brandIds: [],
      language: filters.language,
    });
    const filteredFilters = getFilteredFilters(filters);
    setReviewsQueryParams(filteredFilters);
  }, [filters.language]);

  return (
    <>
      <div className="reviews-filters-bar-container">
        {/*Language select*/}
        <Select
          id="language"
          value={filters.language}
          onChange={hanldeLanguageChange}
          renderValue={renderLanguageValue}
          className="custom-select reviews-button"
          style={selectStyle}
          MenuProps={MenuProps}
          displayEmpty
        >
          <MenuItem value={Language.en}>
            <ListItemText
              primary={trans.english}
              onClick={handleLanguageClickEn}
            />
          </MenuItem>
          <MenuItem value={Language.ar} onClick={handleLanguageClickAr}>
            <ListItemText primary={trans.arabic} />
          </MenuItem>
        </Select>
        {/*Brand select*/}
        <Select
          id="brand_id"
          multiple
          value={filters?.brandIds || []}
          onChange={handleBrandChange}
          className="custom-select reviews-button"
          renderValue={renderBrandValue}
          MenuProps={MenuProps}
          displayEmpty
          style={selectStyle}
          disabled={!brands || brands.length === 0}
        >
          {brands?.map(brand => (
            <MenuItem key={brand.id} value={brand.id}>
              <ListItemText primary={brand.name} />
            </MenuItem>
          ))}
        </Select>
        {/* </div> */}
        {/*Model select*/}
        <Select
          id="model_id"
          multiple
          value={filters.modelIds || []}
          onChange={handleModelChange}
          className="custom-select mui-fixed reviews-button"
          renderValue={renderModelValue}
          MenuProps={MenuProps}
          displayEmpty
          disabled={!brands || !models}
          style={selectStyle}
        >
          {models?.map(model => (
            <MenuItem key={model.id} value={model.id}>
              <ListItemText primary={model.name} />
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  );
};
