import { useQuery } from 'react-query';
import { getBikeTypes } from '../../apis/bikes';

export const useGetBikeTypes = () => {
  return useQuery({
    queryKey: ['bike-types'],
    queryFn: () => getBikeTypes(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
