import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { IBrand } from '../../../../types/resources/cars';
import { NewBikeDealerCarBrandSkeleton } from '../../../Dealers/components';
import BrandCardImage from './BrandCardImage';
import {
  LogoBox,
  LogoBoxText,
  LogoHold,
  WrapBox,
} from './BrandCardStyledComponent';
import { useNavigate } from 'react-router-dom';
import { useBrandPageClick } from '../../../NewCars';

type BrandCardProps = {
  brand: IBrand;
  redirectionUrl: string;
  isLoading?: boolean;
};

const BrandCard = ({ brand, redirectionUrl, isLoading }: BrandCardProps) => {
  const { lightImageLoadingPlaceholder } = useContext(AppContext);
  const navigate = useNavigate();

  const { mutateAsync: brandClick } = useBrandPageClick();

  if (isLoading) {
    return <NewBikeDealerCarBrandSkeleton />;
  }
  return (
    <article key={brand?.id} style={{ height: '100%', width: '100%' }}>
      <LogoBox
        onClick={() => {
          navigate(redirectionUrl);
          brandClick(Number(brand.id));
        }}
      >
        <WrapBox>
          <LogoHold>
            <BrandCardImage
              src={brand.original_logo}
              defaultSrc={lightImageLoadingPlaceholder}
              class="lazy"
              alt={`${brand?.slug}-logo`}
            />
          </LogoHold>
          <LogoBoxText>{brand.name}</LogoBoxText>
        </WrapBox>
      </LogoBox>
    </article>
  );
};

export default BrandCard;
