import authenticatedApi from '../../../instance/ApiInstance';
import { IModel } from '../../../types/resources/cars';
import { IGetAllModels } from '../model.apis';

const bikeModelsEndpoint = '/v2/resources/bikes/models';

export interface IGetAllBikeModels {
  brand?: number | number[] | undefined;
  type?: string;
}

export const getModelsForUsedBikes = async (
  params: IGetAllBikeModels,
): Promise<IModel[]> => {
  try {
    const response = await authenticatedApi.get(`${bikeModelsEndpoint}`, {
      params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
