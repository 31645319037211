import authenticatedApi from '../../../instance/ApiInstance';
import { IDealer } from '../../../types/resources';

const DealerEndpointV2 = '/v2/dealers';

export const getDealers = async (): Promise<IDealer[]> => {
  const response = await authenticatedApi.get(`${DealerEndpointV2}`);

  return response.data;
};
