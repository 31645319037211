import authenticatedApi from '../../instance/ApiInstance';
import { IRamadanOffers } from '../../types/resources/cars/ramadan_offers';

const ramadanOffersEndpoint = '/v3/ramadan-offers';

export const getRamadanOffers = async () => {
  const response = await authenticatedApi.get(ramadanOffersEndpoint);

  return response.data;
};

export const getRamadanOffersBySlug = async (
  slug: string,
): Promise<IRamadanOffers> => {
  const response = await authenticatedApi.get(
    `${ramadanOffersEndpoint}/${slug}`,
  );

  return response.data;
};
