import { useQuery } from 'react-query';
import { getRentCarDetails } from '../apis';
import { AppContext } from '../../../contexts/AppContext';
import { useContext } from 'react';

export const useRentCarDetails = (id: number) => {
  const { lang } = useContext(AppContext);
  return useQuery({
    queryKey: ['getRentCarDetails', id, lang],
    queryFn: () => getRentCarDetails(id),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
