import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import {
  useUserDeleteBikeListings,
  useUserDeleteListings,
} from '../../../common/hooks/user.hook';
import '../AccountDetails/styles.css';

const DeleteConfirmationModal = ({
  car,
  toggleDelete,
  carId,
  callback,
}: any) => {
  const { trans } = useContext(AppContext);

  const { mutateAsync: deletedCar } = useUserDeleteListings();

  const { mutateAsync: deleteBike } = useUserDeleteBikeListings();

  const deleteCar = async (e: any) => {
    e.preventDefault();
    if (car.type === 'car') {
      const result = await deletedCar(carId);
      if (result) {
        callback(true);
      }
    } else if (car.type === 'bike') {
      const result = await deleteBike(carId);
      if (result) {
        callback(true);
      }
    } else {
      console.log('Invalid type');
    }
  };

  return (
    <>
      <div
        className="modal fade modal-center advance-search-modal close-modal show"
        id="removelisting"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="usedcar"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-center-style">
          <div className="modal-content">
            <div className="modal-body">
              <p>
                {trans.remove_listing} <br />
              </p>
            </div>
            <div className="modal-footer">
              <form
                action="#"
                method="POST"
                id="delete_car"
                onSubmit={deleteCar}
                className="modal-action-buttons d-flex"
              >
                <button className="btn btn-primary" type="submit">
                  {trans.confirm}
                </button>
                <button
                  type="button"
                  className="btn btn-primary cancel"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={toggleDelete}
                >
                  {trans.cancel}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default DeleteConfirmationModal;
