import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../../contexts/AppContext';
import SignIn from '../../../User/SignIn/SignInComponent';
import { FaCar } from 'react-icons/fa';
import { Box, Fab, SxProps } from '@mui/material';
import WhatsappIcon from '../../../Icons/WhatsappIcon';
import { SellYourVehicleModal } from '../../../User/SellYourVehicle/SellYourVehicleModal';
import './floatingbutton-styles.css';

export const FloatingButton = () => {
  const location = useLocation();
  const { userData, lang, trans } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [sellVehicleModal, setSellVehicleModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = useMemo(() => width <= 995, [width]);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const handleClick = useCallback(() => {
    if (userData) {
      setShowModal(false);
      setSellVehicleModal(true);
    } else {
      setShowModal(true);
      setSellVehicleModal(false);
    }
  }, [userData]);

  const toggleSignInDialog = () => {
    setShowModal(false);
    setSellVehicleModal(false);
  };

  const calculateBottom = (index: number) => {
    const baseBottom = 16;
    const itemHeight = 50;
    const gap = 16;

    const bottom = baseBottom + index * (itemHeight + gap);

    return bottom;
  };

  const fabStyle = useMemo(
    () => ({
      position: 'fixed',
      right: lang === 'en' ? 16 : 'unset',
      left: lang === 'ar' ? 16 : 'unset',
    }),
    [lang],
  );

  const fabArray = useMemo(
    () => [
      {
        color: 'primary' as 'primary',
        sx: fabStyle as SxProps,
        icon: <FaCar size={20} />,
        label: 'Add',
        description: isMobile ? '' : trans.sell_now,
        className: 'fab-button-color',
        boxStyles: {
          padding: isMobile ? 0 : '0 20px',
        },
        onClick: handleClick,
      },
      {
        sx: fabStyle as SxProps,
        icon: <WhatsappIcon />,
        label: 'Add',
        className: 'fab-button-standalone',
        disallowedLocations: [
          '/en/login',
          '/ar/login',
          '/en/register',
          '/ar/register',
          '/en/verify-phone',
          '/ar/verify-phone',
        ],
      },
    ],
    [fabStyle, handleClick, isMobile, trans.sell_now],
  );

  const generalDisallowedLocations = [
    '/en/sell-your-car',
    '/ar/sell-your-car',
    '/en/sell-your-car/step-two',
    '/ar/sell-your-car/step-two',
    '/en/sell-your-car/step-three',
    '/ar/sell-your-car/step-three',
    '/en/sell-your-car/car-details-form',
    '/ar/sell-your-car/car-details-form',
    '/en/sell-your-bike',
    '/ar/sell-your-bike',
    '/en/login',
    '/ar/login',
    '/en/register',
    '/ar/register',
    '/en/verify-phone',
    '/ar/verify-phone',
  ];

  const filteredFabArray = fabArray.filter(fab => {
    return fab.disallowedLocations
      ? !fab.disallowedLocations.includes(location.pathname)
      : !generalDisallowedLocations.includes(location.pathname);
  });

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      {filteredFabArray.map((fab, index) => {
        const hideFab = fab.disallowedLocations
          ? fab.disallowedLocations.includes(location.pathname)
          : generalDisallowedLocations.includes(location.pathname);

        const calculatedBottom = calculateBottom(index);

        return (
          <Fab
            key={index}
            aria-label={fab.label}
            color={fab.color}
            sx={{ ...fab.sx, bottom: calculatedBottom } as SxProps}
            className={fab.className}
            style={{
              width: isMobile ? 50 : 'fit-content',
              borderRadius: isMobile ? 25 : '5px',
              height: isMobile ? 50 : 50,
              outline: 'none',
              display: hideFab ? 'none' : 'block',
            }}
            onClick={fab.onClick}
          >
            <Box sx={fab.boxStyles}>
              {fab.icon} {fab.description}
            </Box>
          </Fab>
        );
      })}
      {showModal && (
        <SignIn
          toggleSignInDialog={toggleSignInDialog}
          showSignIn={location.pathname}
        />
      )}
      {sellVehicleModal && (
        <SellYourVehicleModal
          location={location.pathname}
          toggleSignInDialog={toggleSignInDialog}
        />
      )}
    </>
  );
};
