import { Skeleton } from '@mui/material';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import {
  AutoImgBox,
  SilderHolder,
  SlideContent,
  SliderSectionStyle,
} from '../../../common/styles/CommonStyles';
import Carousel from 'react-multi-carousel';
import { Row } from 'reactstrap';
import '../../../styles/css/common.css';
import '../../Listings/common/ListingDetailsFeaturesList/ListingDetailsFeaturesList-styles.css';
import '../../Listings/common/ListingDetailsImageGallery/ListingDetailsImageGallery-styles.css';

export const BikeDetailsPageLazyImageSkeletons = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      width={'100%'}
      height={'100%'}
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '10px',
      }}
    />
  );
};

export const BikeDealerLogoSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      width={'100%'}
      height={90}
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const BikeDetailsPageCarouselLazyImageSkeletons = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      width={'100%'}
      height={'100%'}
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '10px',
      }}
    />
  );
};

export const BikeListingFeatureListImgSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <div className="comment-dealear-image-holder">
      <AutoImgBox>
        <Skeleton
          variant="rectangular"
          width={'100%'}
          height={90}
          style={{
            backgroundImage: `url(${darkImageLoadingPlaceholder})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </AutoImgBox>
    </div>
  );
};

export const BikeListingDetailsImageGallerySkeletons = ({
  responsiveFeatured,
}: {
  responsiveFeatured: any;
}) => {
  return (
    <>
      <div className="slick-slider-big2 slick-initialized slick-slider">
        <div className="slick-list draggable">
          <div className="slick-track" style={{ width: '100%' }}>
            <div
              className="item slick-current slick-active"
              data-slick-index="0"
              aria-hidden="false"
            >
              <SlideContent>
                <AutoImgBox>
                  <BikeDetailsPageLazyImageSkeletons />
                </AutoImgBox>
              </SlideContent>
            </div>
          </div>
        </div>
      </div>
      <div className="slider-nav-thumbnails2 slick-initialized slick-slider">
        <Carousel
          responsive={responsiveFeatured}
          swipeable={true}
          draggable={true}
          infinite={true}
          customLeftArrow={
            <div className="swiper-button-prev swiper-button-prev1"></div>
          }
          customRightArrow={
            <div className="swiper-button-next swiper-button-next1"></div>
          }
        >
          {[...Array(5)].map((_, i) => (
            <div
              key={Math.random()}
              className={`item slick-active ${i === 0 ? 'slick-current' : ''}`}
            >
              <AutoImgBox>
                <BikeDetailsPageCarouselLazyImageSkeletons />
              </AutoImgBox>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export const BikeListingFeatureListSkeletons = ({ trans }: { trans: any }) => {
  return (
    <section className="deals-detail-info">
      <div className="deals-detail">
        <header className="details-head">
          <div className="box">
            <h1>
              <Skeleton variant="text" width={100} />
            </h1>
            <h2>
              <Skeleton variant="text" width={100} />
            </h2>
          </div>
          <div className="box2">
            <strong className="price-qar">
              <Skeleton variant="text" width={50} />
            </strong>
          </div>
          <div className="used-cars-buttons-container">
            <div className="used-cars-buttons">
              <Skeleton
                variant="rectangular"
                className="show-number-used-car"
                width={240}
                height={40}
              />
            </div>
            <div>
              <Skeleton
                variant="rectangular"
                className="get-deal-used-car"
                width={240}
                height={40}
              />
            </div>
          </div>
        </header>
      </div>
    </section>
  );
};

export const BikeListingDetailsSkeleton = ({
  responsiveFeatured,
  trans,
}: {
  responsiveFeatured?: any;
  trans: any;
}) => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <main id="main">
      <div className="container usedetailcar">
        <Row>
          <SliderSectionStyle className="get-deals">
            <SilderHolder className="used-detail">
              <BikeListingDetailsImageGallerySkeletons
                responsiveFeatured={responsiveFeatured}
              />

              <div className="features-holder">
                <div className="comment-header-heading">{trans.features}</div>
                <br />
                <div className="optional-features-cards-container">
                  {[...Array(4)].map((_, i) => (
                    <div key={i} className="optional-features-cards">
                      <Skeleton
                        variant="rectangular"
                        width={120}
                        height={120}
                        style={{
                          backgroundImage: `url(${darkImageLoadingPlaceholder})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="description-holder">
                <div className="comment-header-heading">
                  {trans.description}
                </div>
                <br />
                <div className="comment-box">
                  <p>
                    <Skeleton variant="text" width={300} />
                    <Skeleton variant="text" width={300} />
                    <Skeleton variant="text" width={300} />
                  </p>
                </div>
              </div>
            </SilderHolder>

            <BikeListingFeatureListSkeletons trans={trans} />
          </SliderSectionStyle>
        </Row>
      </div>
    </main>
  );
};
