import styled from '@emotion/styled';

export const ArticleDetailsInfo = styled('div')(() => ({
  color: '#3D3D3D',
  fontSize: '15px',
  lineHeight: '1.6',
  overflow: 'hidden',
  padding: '0 20px',
  position: 'relative',
  '@media (max-width: 1199px)': {
    fontSize: '20px',
    paddingInline: '10px',
  },
  '&.reviews-details-info': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
}));

export const ArticleDetailsInfoH2 = styled('h2')(() => ({
  color: '#3D3D3D',
  fontSize: '42px',
  fontWeight: 800,
  lineHeight: '1.2',
  marginBottom: '10px',
  '@media (max-width: 1199px)': {
    fontSize: '40px',
  },
  '@media (max-width: 480px)': {
    fontSize: '24px',
  },
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const ArticleDetailsInfoP = styled('p')(() => ({
  '@media (max-width: 480px)': {
    fontSize: '14px',
  },
}));

export const ArticleDateNavUl = styled('ul')(() => ({
  color: '#848484',
  display: 'flex',
  flexWrap: 'wrap',
  fontSize: '15px',
  justifyContent: 'flex-start',
  marginBottom: '20px',
}));

export const ArticleDateNavLi = styled('li')(() => ({
  marginRight: '10px',
}));
