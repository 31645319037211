import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import 'react-multi-carousel/lib/styles.css';
import LazyLoad from 'react-lazyload';
import HomeVisual from './HomeVisualComponent/HomeVisualComponent';
import HomeNewCars from './HomeNewCarsComponent';
import HomeUsedCars from './HomeUsedCarsComponent';
import HomeArticles from './HomeArticlesComponent/HomeArticlesComponent';
import SignIn from '../User/SignIn/SignInComponent';
import { HomePageRentals } from '../RentalCars/components/HomeRentalsComponent';
import { useGetCarTypes } from '../../common/hooks/type.hooks';
import { useGetBrands } from '../../common/hooks/brand.hooks.';
import {
  BannerHolder,
  BannerHolderBtnSecondary,
  BannerHolderH2,
  BannerHolderImgBox,
  BannerHolderImgBoxImg,
  BannerHolderP,
  BannerHolderTextBox,
  BannerHolderTextBoxImg,
  SellCarBanner,
  SellCarBannerBrandLogo,
  SellCarBannerBtnSecondary,
  SellCarBannerH2,
  SellCarBannerH2Strong,
  SellCarBannerH2Sup,
  SellCarBannerLeftContent,
  SellCarBannerRightContent,
  SellCarBannerRightContentImgBox,
  SellCarBannerRightContentTxt,
} from './HomeStyledComponent';
import MetaTag from '../../common/meta/MetaTag';

export default function Home() {
  const { lang, trans, userData, country } = useContext(AppContext);

  const navigate = useNavigate();
  const [showSignIn, setShowSignIn] = useState(false);

  const { data: brands, isLoading: isLoadingBrands } = useGetBrands({
    type: 'new',
  });

  const { data: carTypes } = useGetCarTypes();

  const toggleSignInDialog = show => {
    setShowSignIn(show);
  };

  const sellYourCarClick = () => {
    if (userData) {
      navigate(`/${lang}/sell-your-car`);
    } else {
      setShowSignIn(true);
    }
  };

  return (
    <>
      <>
        <MetaTag metaKey={'home'} href={`/${country}/${lang}`} />
        <LazyLoad>
          <HomeVisual />
        </LazyLoad>
        <main id="mainHome">
          <div className="container">
            <BannerHolder>
              <BannerHolderImgBox>
                <BannerHolderImgBoxImg
                  src="/images/banner-bg1.png"
                  alt="Banner image"
                />
              </BannerHolderImgBox>
              <BannerHolderTextBox>
                <BannerHolderTextBoxImg
                  src="/images/banner-logo.png"
                  alt="OneAutoCar"
                  width={60}
                  height={21}
                />
                <BannerHolderH2>{trans.find_car_you_looking}</BannerHolderH2>
                <BannerHolderP>{trans.dream_it}</BannerHolderP>
              </BannerHolderTextBox>
              <BannerHolderBtnSecondary
                className="btn btn-secondary"
                to={`/${country}/${lang}/new-cars`}
              >
                {trans.find_it}
              </BannerHolderBtnSecondary>
            </BannerHolder>
            <LazyLoad>
              <HomeNewCars brands={brands ?? []} isLoading={isLoadingBrands} />
            </LazyLoad>
            <LazyLoad>
              <SellCarBanner>
                <SellCarBannerLeftContent>
                  <SellCarBannerH2>
                    {trans.sell_car}
                    <SellCarBannerH2Strong>
                      <SellCarBannerH2Sup>{trans.with}</SellCarBannerH2Sup> ONE
                      AUTOCAR
                    </SellCarBannerH2Strong>
                  </SellCarBannerH2>
                  <SellCarBannerBtnSecondary
                    className="btn btn-secondary"
                    onClick={sellYourCarClick}
                  >
                    {trans.sell_it}
                  </SellCarBannerBtnSecondary>
                </SellCarBannerLeftContent>
                <SellCarBannerRightContent>
                  <SellCarBannerBrandLogo
                    src="/images/banner-logo.png"
                    alt="OneAutoCar"
                  />
                  <SellCarBannerRightContentTxt className="list">
                    • {trans.free_listing}
                  </SellCarBannerRightContentTxt>
                  <SellCarBannerRightContentImgBox>
                    <img src="/images/banner-bg1.png" alt="Banner image" />
                  </SellCarBannerRightContentImgBox>
                  <SellCarBannerRightContentTxt className="value">
                    • {trans.free_listing}
                  </SellCarBannerRightContentTxt>
                </SellCarBannerRightContent>
              </SellCarBanner>
            </LazyLoad>
            <LazyLoad>
              <HomeUsedCars types={carTypes ?? []} />
            </LazyLoad>
            <LazyLoad>
              <HomePageRentals types={carTypes ?? []} />
            </LazyLoad>
            <LazyLoad>
              <HomeArticles />
            </LazyLoad>
          </div>
        </main>
      </>

      {showSignIn && <SignIn toggleSignInDialog={toggleSignInDialog} />}
    </>
  );
}
