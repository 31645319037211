import { useEffect, useRef, useState } from 'react';
import Plyr from 'plyr';
import Hls from 'hls.js';
import 'plyr/dist/plyr.css';

export default function VideoPlayer({
  videoSrc,
  thumbnail,
}: {
  videoSrc: string;
  thumbnail: string;
}) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const plyrPlayerRef = useRef<Plyr | null>(null);
  const hlsRef = useRef<Hls | null>(null);

  // Use state to manage the quality
  const [selectedQuality, setSelectedQuality] = useState<number>(480);

  const initializePlayer = (video: HTMLVideoElement, quality: number) => {
    const defaultOptions: Plyr.Options = {
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'duration',
        'mute',
        'volume',
        'captions',
        'settings',
        'fullscreen',
        'quality',
      ],
      quality: {
        default: quality,
        options: [720, 480, 360],
        forced: true,
        onChange: (quality: number) => {
          setSelectedQuality(quality);
        },
      },
    };

    const player = new Plyr(video, defaultOptions);
    return player;
  };

  const loadHls = (video: HTMLVideoElement) => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
      hlsRef.current = hls;
    }
  };

  const getVideoSrcWithQuality = (src: string, quality: number): string => {
    quality = selectedQuality;
    const qualityString = quality + 'p';
    const match = src.match(/-(\d+p)\.(.+)$/);

    if (match) {
      const originalQuality = match[1];
      const fileExtension = match[2];
      const newVideoSrc = src.replace(
        `-${originalQuality}.${fileExtension}`,
        `-${qualityString}.${fileExtension}`,
      );
      return newVideoSrc;
    } else {
      console.error('Invalid video source format');
      return src;
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      // Save current playback time
      const currentPlaybackTime = videoElement.currentTime;

      // Initialize Plyr player if quality has changed
      plyrPlayerRef.current = initializePlayer(videoElement, selectedQuality);

      // Check if HLS is supported and load HLS source
      if (Hls.isSupported()) {
        loadHls(videoElement);
      }

      // Check if the video source has changed
      const newVideoSource = getVideoSrcWithQuality(videoSrc, selectedQuality);

      if (videoElement.src !== newVideoSource) {
        videoElement.src = newVideoSource;
        // Auto play the video\\
        videoElement.play().catch(error => {
          console.error('Auto play failed', error);
        });
      }
      videoElement.currentTime = currentPlaybackTime;

      if (videoElement.currentTime === 0) {
        videoElement.pause();
      }
    }
  }, [videoSrc, selectedQuality]);

  return (
    <video ref={videoRef} className="plyr" poster={thumbnail}>
      <source src={getVideoSrcWithQuality(videoSrc, selectedQuality)} />
    </video>
  );
}
