import { useLocation, useNavigate } from 'react-router-dom';
import {
  MainHead,
  MainHeadH1,
  MainHeadP,
} from '../../../../common/styles/CommonStyles';
import { useContext, useRef, useState } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import MetaTag from '../../../../common/meta/MetaTag';
import {
  AdditionalInfoContent,
  AdditionalInfoHeaderContainer,
  AdditionalInfoHeaderP,
  CancelButton,
  ConfirmCancelContainer,
  ProgressBar,
  ProgressBarContainer,
  ProgressBarFill,
  ProgressPercentage,
  ProgressPercentageSpan,
  SellYourCarButton,
  StyledDiv,
  StyledDivColumn,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledPhoneInput,
  StyledSpan,
  StyledSpan1,
  StyledSpan2,
  StyledTextField,
  StyledUl,
  UploadInProgressContainer,
  UploadInProgressContent,
  UploadInProgressLeft,
  UploadInProgressLeftContent,
  UploadInProgressLeftContentImgName,
  UploadInProgressLeftContentImgSize,
  UploadInProgressLeftImg,
  UploadInProgressRemoveImg,
  UploadInProgressRemoveImg2,
  UploadPhotoContainer,
  UploadPhotoContainer2,
  UploadPhotoContainer2Label,
  UploadPhotoImg,
  UploadedImagesContaier,
  UploadedImagesContaierImg,
  UploadedImagesContent,
} from './SellYourCarThirdPageStyledComponent';
import { StyledDivContainer } from '../SellYourCarSecondPage';
import OptionalFeaturesListItem from '../../../Listings/common/OptionalFeaturesListItem';
import { useGetOptionalFeatures } from '../../../../common/hooks/optional_feature.hooks';
import Upload from '../../../../common/images/Upload.png';
import Close from '../../../../common/images/x.png';
import { useCreateUsedCar } from '../../../UsedCars/hooks';
import {
  ICreateUsedCar,
  uploadUsedCarImage,
} from '../../../UsedCars/apis/usedCarsApi';
import { v4 as uuidv4 } from 'uuid';
import {
  errorNotification,
  successNotification,
} from '../../../Other/ToastMessage';

export const SellYourCarThirdPage = () => {
  const { trans, lang, country } = useContext(AppContext);
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();

  const addedImages = useRef<any>([]);
  const removedImages = useRef<any>([]);
  const fileInputRef = useRef<any>(null);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const [selectedOptionalFeatures, setSelectedOptionalFeatures] = useState<
    number[]
  >([]);

  const [formData, setFormData] = useState({
    price: data?.price || data?.priceValuation?.lowRetail || 0,
    mobile_number: data?.mobile_number || '',
    description: data?.description || '',
  });

  const { data: optionalFeatures, isLoading: isLoadingOptionalFeatures } =
    useGetOptionalFeatures();

  const handleOptionalFeatureChange = (id: number) => {
    const index = selectedOptionalFeatures.indexOf(id);
    if (index === -1) {
      setSelectedOptionalFeatures([...selectedOptionalFeatures, id]);
    } else {
      const updatedNumbers = [...selectedOptionalFeatures];
      updatedNumbers.splice(index, 1);
      setSelectedOptionalFeatures(updatedNumbers);
    }
  };

  const handlePriceInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, '');
    setFormData(prevData => ({
      ...prevData,
      price: numericValue === '' ? '' : Number(numericValue),
    }));
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormData(prevData => ({
      ...prevData,
      description: event.target.value,
    }));
  };

  const handleFilesChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      // Prepare files for state update
      const newFiles = filesArray.map(file => ({
        file: {
          uid: uuidv4(),
          name: file.name,
          size: file.size,
          url: URL.createObjectURL(file),
        },
        progress: 0, // Initialize progress to 0 for each file
        status: 'done', // Add status to track upload state (pending, uploading, done)
      }));
      // Update selectedFiles state with new files
      setSelectedFiles(prevSelectedFiles => [
        ...prevSelectedFiles,
        ...newFiles,
      ]);
      setIsUploading(true);
      // Upload each file
      await Promise.all(
        newFiles.map(async (newFile, index) => {
          try {
            const response = await uploadUsedCarImage({
              file: filesArray[index],
              onProgress: (progress: { percent: number }) => {
                // Update progress for the specific file
                setSelectedFiles(prevSelectedFiles =>
                  prevSelectedFiles.map(prevFile =>
                    prevFile.file.uid === newFile.file.uid
                      ? { ...prevFile, progress: Math.round(progress.percent) }
                      : prevFile,
                  ),
                );
              },
            });
            const addedImage = { ...response, file: newFile.file };
            addedImages.current = [...addedImages.current, addedImage];
            fileInputRef.current.value = null;
          } catch (error) {
            console.log(error);
            //set progress to 0
            setSelectedFiles(prevSelectedFiles =>
              prevSelectedFiles.map(prevFile =>
                prevFile.file.uid === newFile.file.uid
                  ? { ...prevFile, progress: 0 }
                  : prevFile,
              ),
            );
          }
        }),
      );
      setIsUploading(false);
    }
  };

  const removeSelectedFile = (selectedFile: any) => {
    if (selectedFile?.status === 'old') {
      removedImages.current = [
        ...removedImages.current,
        selectedFile?.file?.url,
      ];
    }
    if (selectedFile?.status === 'done') {
      addedImages.current = addedImages.current.filter((image: any) => {
        return image.file.uid !== selectedFile?.file?.uid;
      });
    }

    let _selectedFiles = [...selectedFiles];
    _selectedFiles = _selectedFiles.filter(
      (f: any) => f?.file?.uid !== selectedFile?.file?.uid,
    );
    setSelectedFiles(_selectedFiles);
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFilesChange(event);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (files) {
      handleFilesChange({
        target: { files },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const { mutateAsync: createUsedCar, isLoading: isCreating } =
    useCreateUsedCar();

  const handleSubmit = async () => {
    const optionalFeatures = selectedOptionalFeatures;
    const dataToSubmit: ICreateUsedCar = {
      model_id: data?.model?.id,
      cylinder_id: data?.cylinder?.id,
      engine_size_id: data?.engine_size?.id,
      engine_type_id: data?.engine_type?.id,
      transmission_id: data?.transmission?.id,
      type_id: data?.type?.id,
      doors: Number(data?.doors),
      year: Number(data?.year),
      mileage: data?.formData?.mileage,
      price: formData?.price,
      mobile_number:
        formData?.mobile_number !== '' ? formData?.mobile_number : null,
      description: formData?.description,
      optional_features_ids: optionalFeatures,
      add_images: addedImages.current.map(image => ({ id: image.id })),
      battery_capacity_id: data?.battery_capacity?.id,
      battery_range_id: data?.battery_range?.id,
      call_to_action: data?.call_to_action,
      city_id: data?.city?.id,
      condition_id: data?.condition?.id,
      exterior_color_id: data?.exterior_color?.id,
      interior_color_id: data?.interior_color?.id,
      seat_material_id: data?.seat_material?.id,
      variant_id: data?.variant?.id,
      whatsapp_number: data?.whatsapp_number,
    };
    const response = await createUsedCar(dataToSubmit);
    if (response) {
      successNotification(
        'created',
        'success',
        'Car has been sent to approval',
      );
      navigate(`/${country}/${lang}/used-cars`);
    }
    errorNotification('Something went wrong');
  };

  return (
    <>
      <MetaTag
        href={`/${country}/${lang}/sell-your-car/step-three`}
        metaKey="sellYourCar"
      />
      <main id="main" className="sell_your_car_data">
        <MainHead className="sell-car-head ">
          <MainHeadH1 className="sell-car-h1">{trans.sell_car}</MainHeadH1>
          <MainHeadP className="sell-car-p">
            {trans.sell_car} {trans.with} ONE AUTOCAR
          </MainHeadP>
        </MainHead>
        <div className="container">
          <StyledDivContainer>
            <div className="sell-car-first-page-box">
              <StyledH3>{trans.youCarPrice}</StyledH3>
              <StyledDiv>
                <StyledDivColumn>
                  <StyledH4>{trans.car_price}</StyledH4>
                  <StyledTextField
                    value={`${formData.price} QAR`}
                    onChange={handlePriceInputChange}
                    helperText={
                      formData?.price === data?.priceValuation?.lowRetail
                        ? trans.priceValuation
                        : ''
                    }
                  />
                </StyledDivColumn>
                <StyledDivColumn>
                  <StyledH4>{trans.phone_number}</StyledH4>
                  <StyledPhoneInput
                    country={country || ''}
                    value={formData.mobile_number}
                    onChange={mobile_number =>
                      setFormData(prevData => ({ ...prevData, mobile_number }))
                    }
                    placeholder=""
                  />
                </StyledDivColumn>
              </StyledDiv>
            </div>
            <div className="sell-car-first-page-box">
              <StyledH2>{trans.features}</StyledH2>
              <StyledUl>
                {optionalFeatures?.map(optionalFeature => {
                  const isSelected = selectedOptionalFeatures.includes(
                    optionalFeature.id,
                  );
                  return (
                    <li key={optionalFeature.id}>
                      <OptionalFeaturesListItem
                        name={optionalFeature.name}
                        onClick={() =>
                          handleOptionalFeatureChange(optionalFeature.id)
                        }
                        checked={isSelected}
                        className={`optional-features-new-list-syc-item optional-feature-new-list-item  ${
                          isSelected && 'optional-feature-new-list-item-active'
                        }`}
                        className2="optional-feature-new-list-item-name"
                        nameColor="#191E29"
                        iconColor="#191E29"
                      />
                    </li>
                  );
                })}
              </StyledUl>
            </div>
            <div className="sell-car-first-page-box">
              <StyledH2>{trans.upload_photos}</StyledH2>
              <UploadPhotoContainer
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <UploadPhotoContainer2>
                  <UploadPhotoImg src={Upload} alt="upload" />
                  <input
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                    id="fileInput"
                    ref={fileInputRef}
                    accept=".png,.jpg,.jpeg,.webp"
                  />
                  <UploadPhotoContainer2Label htmlFor="fileInput">
                    <StyledSpan>
                      {trans.dropImage}{' '}
                      <StyledSpan1>{trans.browse}</StyledSpan1>
                    </StyledSpan>
                  </UploadPhotoContainer2Label>
                  <StyledSpan2>{trans.Supports}</StyledSpan2>
                </UploadPhotoContainer2>
              </UploadPhotoContainer>
              {selectedFiles?.length !== 0 && (
                <UploadInProgressContainer>
                  {selectedFiles?.map((file: any) => (
                    <div key={file.file.uid}>
                      <UploadInProgressContent>
                        <UploadInProgressLeft>
                          <UploadInProgressLeftImg
                            src={file.file.url}
                            alt={file.file.name}
                          />
                          <UploadInProgressLeftContent>
                            <UploadInProgressLeftContentImgName>
                              {file.file.name}
                            </UploadInProgressLeftContentImgName>
                            <UploadInProgressLeftContentImgSize>
                              {file.file.size > 1000 * 1000
                                ? `${(file.file.size / (1000 * 1000)).toFixed(
                                    2,
                                  )} MB`
                                : `${Math.round(file.file.size / 1024)} KB`}
                            </UploadInProgressLeftContentImgSize>
                          </UploadInProgressLeftContent>
                        </UploadInProgressLeft>
                        <UploadInProgressRemoveImg
                          src={Close}
                          alt="close"
                          onClick={() => removeSelectedFile(file)}
                        />
                      </UploadInProgressContent>
                      <ProgressBarContainer key={file.file.uid}>
                        <ProgressBar>
                          <ProgressBarFill progress={file.progress} />
                        </ProgressBar>
                        <ProgressPercentage>
                          <ProgressPercentageSpan>
                            {file.progress}%
                          </ProgressPercentageSpan>
                        </ProgressPercentage>
                      </ProgressBarContainer>
                    </div>
                  ))}
                </UploadInProgressContainer>
              )}
              {selectedFiles?.length !== 0 && (
                <UploadedImagesContaier>
                  {selectedFiles?.map((file: any) => (
                    <UploadedImagesContent key={file.file.uid}>
                      <div>
                        <UploadInProgressRemoveImg2
                          src={Close}
                          alt="close"
                          onClick={() => removeSelectedFile(file)}
                        />
                        <UploadedImagesContaierImg
                          src={file.file.url}
                          alt={file.file.name}
                        />
                      </div>
                      <UploadInProgressLeftContent>
                        <UploadInProgressLeftContentImgName>
                          {file.file.name}
                        </UploadInProgressLeftContentImgName>
                        <UploadInProgressLeftContentImgSize>
                          {file.file.size > 1000 * 1000
                            ? `${(file.file.size / (1000 * 1000)).toFixed(
                                2,
                              )} MB`
                            : `${Math.round(file.file.size / 1024)} KB`}
                        </UploadInProgressLeftContentImgSize>
                      </UploadInProgressLeftContent>
                    </UploadedImagesContent>
                  ))}
                </UploadedImagesContaier>
              )}
            </div>
            <div className="sell-car-first-page-box">
              <AdditionalInfoHeaderContainer>
                <StyledH2>{trans.addit_info}</StyledH2>
                <AdditionalInfoHeaderP>
                  {trans.addit_info_p}
                </AdditionalInfoHeaderP>
              </AdditionalInfoHeaderContainer>
              <AdditionalInfoContent
                multiline
                rows={7}
                variant="outlined"
                fullWidth
                value={formData.description}
                onChange={handleDescriptionChange}
              />
            </div>
            <ConfirmCancelContainer>
              <SellYourCarButton
                type="submit"
                onClick={handleSubmit}
                disabled={isCreating || isUploading}
              >
                {trans.sell_car}
              </SellYourCarButton>
              <CancelButton
                onClick={() => {
                  navigate(`/${country}/${lang}/sell-your-car`);
                }}
              >
                {trans.cancel}
              </CancelButton>
            </ConfirmCancelContainer>
          </StyledDivContainer>
        </div>
      </main>
    </>
  );
};
