import { Button, Slider, TextField, styled } from '@mui/material';

export const StyledH2 = styled('h2')(() => ({
  fontSize: '24px',
  lineHeight: '28px',
  fontWeight: '600',
  height: '17px',
}));

export const StyledSlider = styled(Slider)(() => ({
  '& .MuiSlider-rail': {
    color: '#32A6F9',
  },
  '& .MuiSlider-track': {
    color: '#00D6BC',
  },
  '& .MuiSlider-mark': {
    top: '95%',
    height: '5px',
    width: '1px',
    color: ' #161616',
    '@media screen and (max-width: 992px)': {
      top: '85%',
    },
  },
  '& .MuiSlider-thumb': {
    background: 'white',
  },
  '& .MuiSlider-markLabel': {
    '@media screen and (max-width: 992px)': {
      fontSize: '10px',
    },
  },
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '713px',
  height: '52px',
  borderRadius: '8px',
  border: '1px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '8px',
    height: '52px',
    width: '100%',
  },
  '@media screen and (max-width: 992px)': {
    width: '100%',
  },
}));

export const StyledButton = styled(Button)(() => ({
  width: '162px',
  height: '48px',
  padding: '6px 18px 6px 18px',
  borderRadius: '12px',
  background: '#00D6BC',
  border: '1px solid #00A187',
  color: 'white',
  textTransform: 'uppercase',
  ':hover': {
    background: '#00A187',
  },
  '@media screen and (max-width: 992px)': {
    width: '100%',
    textTransform: 'capitalize',
  },
}));

export const StyledH4 = styled('h4')(() => ({
  fontWeight: '400',
  fontSize: '14px',
}));

export const StyledDiv2 = styled('div')(() => ({
  display: 'flex',
  gap: '24px',
  '@media (max-width: 992px)': {
    flexDirection: 'column',
  },
}));

export const StyledDiv1 = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const StyledCommonDiv = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '24px',
  '@media screen and (max-width: 992px)': {
    gridTemplateColumns: '1fr',
  },
}));

export const StyledDivContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));
