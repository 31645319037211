export const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/g;

export function timeSince(date: string, trans: any) {
  var seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + ' ' + trans.years_ago;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    const months = Math.floor(interval);
    const transMonth = months === 1 ? trans.month_ago : trans.months_ago;
    return months + ' ' + transMonth;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' ' + trans.days_ago;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' ' + trans.hours_ago;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' ' + trans.minutes_ago;
  }
  return Math.floor(seconds) + ' ' + trans.seconds_ago;
}

export const groupBy = (key: string) => (array: Array<any>) =>
  array.reduce((previousValue, currentValue) => {
    const value = currentValue[key];
    previousValue[value] = (previousValue[value] || []).concat(currentValue);
    return previousValue;
  }, {});

export function isImage(url: any) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg|jfif)$/.test(url);
}
