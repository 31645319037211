export class SelectOption {
  public id: number;
  public name: string;
  public arabicName: string;
  constructor(option: any) {
    this.id = option.id;
    this.name = option.name;
    this.arabicName = option.arabic_name;
  }
}
