import { useQuery, useQueries } from 'react-query';
import { IGetDealerBrands, getDealerBrands, getDealers } from '../apis';

interface IUseDealers {
  params?: IGetDealerBrands;
  enabled?: boolean;
}

export const useDealerBrands = ({ dealer }: IGetDealerBrands) => {
  const [dealerBrandsResponse, dealersResponse] = useQueries([
    {
      queryKey: ['dealerBrands', dealer],
      queryFn: () => getDealerBrands({ dealer }),
      refetchOnWindowFocus: false,
      enabled: true,
    },
    {
      queryKey: 'dealers',
      queryFn: () => getDealers(),
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
      retry: 3,
    },
  ]);
  const dealerBrandsData = dealerBrandsResponse.data;
  const dealersData = dealersResponse.data;
  const isLoading = dealerBrandsResponse.isLoading || dealersResponse.isLoading;

  return { dealerBrandsData, dealersData, isLoading };
};

export const useDealers = () => {
  return useQuery({
    queryKey: 'dealers',
    queryFn: () => getDealers(),
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
    retry: 3,
  });
};

export const useCarsBrands = ({ params, enabled }: IUseDealers = {}) => {
  return useQuery({
    queryKey: ['brandsPerDealer', params, enabled],
    queryFn: () => getDealerBrands(params || {}),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: enabled,
  });
};
