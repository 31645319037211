import Carousel from 'react-multi-carousel';
import { AppContext } from '../../../contexts/AppContext';
import { useContext } from 'react';
import CarGridCard from './CarGridCard/CarGridCard';
import { IUsedCar } from '../../../types/cars/used_car';
import { CarGridCarouselSkeleton } from '../../UsedCars/UsedCarsSkeletons/UsedCarsCardSkeleton';

type CarCarouselProps = {
  cars: Array<IUsedCar>;
  favoriteCars: Array<number>;
  onFavouriteClick: (e: any, carId: number) => void;
  generateRedirectLink: (car: IUsedCar, lang: string) => string;
  isLoading: boolean;
};

const CarCarousel = (props: CarCarouselProps) => {
  const { lang } = useContext(AppContext);
  const rtl = lang == 'ar' ? true : false;

  const responsiveInfo = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  if ((props.isLoading && !props.cars) || props.cars.length === 0) {
    return <CarGridCarouselSkeleton responsive={responsiveInfo} rtl={rtl} />;
  }

  return (
    <Carousel
      autoPlay
      autoPlaySpeed={4000}
      responsive={responsiveInfo}
      swipeable={true}
      draggable={true}
      infinite={true}
      rtl={rtl}
      customLeftArrow={<div className="swiper-button-prev"></div>}
      customRightArrow={<div className="swiper-button-next"></div>}
      key="used-cars-carousel"
    >
      {props.cars.map((car: any) => (
        <CarGridCard
          key={Math.random()}
          car={car}
          isFavorite={
            props.favoriteCars &&
            props.favoriteCars.find((f: any) => f === car.id) != undefined
          }
          onFavoriteClick={props.onFavouriteClick}
          redirectionLink={props.generateRedirectLink(car, lang)}
          isLoading={props.isLoading}
        />
      ))}
    </Carousel>
  );
};

export default CarCarousel;
