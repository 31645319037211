import authenticatedApi from '../../../instance/ApiInstance';

const ramadanOfferInquiriesEndPoint = 'v2/inquiries/ramadan-offer-inquiries';

export interface ICreateRamadanOfferInquiry {
  name: string;
  email: string;
  mobile_number: string;
  ramadan_offer_id: number;
  model_id: number;
}

export const createRamadanOfferInquiry = async (
  data: ICreateRamadanOfferInquiry,
) => {
  const response = await authenticatedApi.post(
    ramadanOfferInquiriesEndPoint,
    data,
  );
  return response.data;
};
