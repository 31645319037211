import { useQuery } from 'react-query';
import { getSeatMaterials } from '../apis/seat_material.apis';

export const useGetSeatMaterials = () => {
  return useQuery({
    queryKey: ['seatMaterials'],
    queryFn: () => getSeatMaterials(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
