import { useQuery } from 'react-query';
import {
  getRamadanOffers,
  getRamadanOffersBySlug,
} from '../apis/ramadan_offers';

interface IUseGetRamadanOffers {
  enabled?: boolean;
}

export const useGetRamadanOffers = ({ enabled }: IUseGetRamadanOffers) => {
  return useQuery({
    queryKey: ['ramadan-offers', enabled],
    queryFn: () => getRamadanOffers(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: enabled,
  });
};

export const useGetRamadanOffersBySlug = (slug: string) => {
  return useQuery({
    queryKey: ['ramadan-offers', slug],
    queryFn: () => getRamadanOffersBySlug(slug),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: !!slug,
  });
};
