import { useContext, useState } from 'react';
import { Skeleton } from '@mui/material';
import { AppContext } from '../../contexts/AppContext';

export const ImageBannerComponent = ({ src }: { src: string }) => {
  const [isLoaded, setIsLoaded] = useState(true);
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <div style={{ width: '100%', height: '100%' }}>
      {isLoaded && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
          style={{
            backgroundImage: `url(${darkImageLoadingPlaceholder})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      )}
      <img
        src={src}
        alt=""
        style={{ display: isLoaded ? 'none' : 'block' }}
        className="banner-image"
        onLoad={() => {
          setIsLoaded(false);
        }}
      />
    </div>
  );
};
