import { Skeleton } from '@mui/material';
import {
  ReviewCardContent,
  ReviewCardStyled,
  ReviewsCardP1,
  ReviewsCardP2,
  ReviewsCardTextContainer,
  ReviewsContainer,
} from '../ReviewsStyles';
import {
  ColLg12,
  FeaturedDetailsPage,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  Row,
} from '../../../../common/styles/CommonStyles';

export const ReviewsCardImageSkeletons = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={'100%'}
      height={200}
      style={{
        minHeight: '200px',
        maxHeight: '200px',
        objectFit: 'cover',
        borderRadius: '5px',
      }}
    />
  );
};

export const ReviewsCardReviewLogoSkeletons = () => {
  return (
    <Skeleton
      variant="circular"
      width={50}
      height={50}
      style={{
        borderRadius: '50%',
        marginRight: '10px',
      }}
    />
  );
};

export const ReviewsCardSkeletons = () => {
  return (
    <ReviewCardStyled>
      <ReviewsCardImageSkeletons />
      <ReviewCardContent>
        <ReviewsCardReviewLogoSkeletons />
        <ReviewsCardTextContainer>
          <ReviewsCardP1>
            <Skeleton variant="text" width={100} />
          </ReviewsCardP1>
          <ReviewsCardP2>
            <Skeleton variant="text" width={100} />
          </ReviewsCardP2>
        </ReviewsCardTextContainer>
      </ReviewCardContent>
    </ReviewCardStyled>
  );
};

export const ReviewsSkeletons = ({ trans }: { trans: any }) => {
  return (
    <>
      <MainHead>
        <MainHeadContainer className="container">
          <Row>
            <ColLg12>
              <MainHeadH1>{trans.reviews}</MainHeadH1>
            </ColLg12>
          </Row>
        </MainHeadContainer>
      </MainHead>
      <FeaturedDetailsPage className="container reviews">
        <ReviewsContainer>
          {[...Array(9)].map((_, i) => (
            <ReviewsCardSkeletons key={i} />
          ))}
        </ReviewsContainer>
        <span className="pagination-middle">
          <div
            style={{
              margin: '0 auto',
              width: '150px',
            }}
          />
        </span>
      </FeaturedDetailsPage>
    </>
  );
};
