import { useState, useContext, useEffect } from 'react';
import {
  Autocomplete,
  TextField,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import NewUsedCarsToggle from './HeaderSearchToggle';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { useSearch } from './hooks/useSearch';
import { animated } from 'react-spring';
import '../../styles/css/header-styles.css';

const theme = createTheme({
  components: {
    MuiTextField: {
      variants: [
        {
          props: { color: 'secondary' },
          style: {
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#00D9BB',
              },
            },
            '& .MuiInputBase-input': {
              paddingLeft: '12px',
            },
          },
        },
      ],
    },
  },
});

export default function SearchBar({
  inputSpring,
  isSearchIconVisible,
  showIcon,
}: {
  inputSpring: any;
  isSearchIconVisible: boolean;
  showIcon: boolean;
}) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isNew, setIsNew] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFocusWithin, setIsFocusWithin] = useState<boolean>(false);
  const [searchSuggestions, setSearchSuggestions] = useState<any>([]);
  const navigate = useNavigate();
  const { lang, trans, country } = useContext(AppContext);

  const {
    data: cars,
    isFetching,
    isLoading,
  } = useSearch({
    query: searchValue,
  });

  useEffect(() => {
    if (cars && cars.length > 0) {
      let _searchSuggestions = cars.map((car: any) => {
        return {
          details: car,
          label: `${car.brand.name} ${car.name}`,
        };
      });
      setSearchSuggestions(_searchSuggestions);
    } else {
      if (!isFetching) {
        setSearchSuggestions([]);
      }
    }
  }, [cars]);

  const handleSwitchChange = (newValue: boolean) => {
    setIsNew(newValue);
  };

  const goToSelectedCar = (value: any) => {
    navigate(
      `/${country}/${lang}/new-cars/${value.details.brand?.slug}/${value.details?.slug}`,
    );
  };

  const updateSearchQuery = (searchValue: string) => {
    navigate(`/${country}/${lang}/used-cars?q=${searchValue}`);
  };

  return (
    <animated.div
      className="search-wrapper "
      style={
        isSearchIconVisible
          ? {
              pointerEvents: showIcon ? 'none' : 'auto',
              ...inputSpring,
            }
          : {}
      }
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <ThemeProvider theme={theme}>
        <Autocomplete
          size="small"
          clearOnBlur={true}
          clearIcon={null}
          value={searchValue}
          onChange={(e, value, reason) => {
            if (value) goToSelectedCar(value);
          }}
          loading={isLoading}
          loadingText={trans.loading}
          open={isFocused && isNew}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter' && !isNew) {
              e.preventDefault();
              updateSearchQuery(searchValue);
            }
          }}
          inputValue={searchValue}
          onInputChange={(_, newValue) => setSearchValue(newValue)}
          options={searchSuggestions}
          filterOptions={(options, state): Array<string> => {
            return options;
          }}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              value={searchValue}
              placeholder="Search"
              color="secondary"
              variant="outlined"
              autoComplete="off"
              onChange={e => {
                setSearchValue(e.target.value);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  updateSearchQuery(searchValue);
                }
              }}
            />
          )}
          noOptionsText={trans.no_results}
          freeSolo={searchValue === ''}
          openOnFocus={true}
        />
      </ThemeProvider>
      {isFocused || isFocusWithin || (isHovering && !isFocused) ? (
        <div className="old-new-toggle-wrapper">
          <NewUsedCarsToggle
            isNew={isNew}
            onToggle={value => {
              handleSwitchChange(value);
            }}
            onFocusWithin={focusValue => {
              setIsFocusWithin(focusValue);
            }}
          />
        </div>
      ) : (
        <div></div>
      )}
    </animated.div>
  );
}
