import { useQuery } from 'react-query';
import { getInteriorColors } from '../apis/interior_color.apis';

export const useGetInteriorColors = () => {
  return useQuery({
    queryKey: ['interiorColors'],
    queryFn: () => getInteriorColors(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
