export const WirelessChargerIcon = ({ color = '#191E29' }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.22681 7.9165L8.35032 10.04C6.17575 12.2146 4.83167 15.2181 4.83167 18.5355C4.83167 21.8544 6.17575 24.858 8.35032 27.031L6.22831 29.153C3.50861 26.4363 1.82812 22.6819 1.82812 18.5355C1.82812 14.3891 3.51011 10.6347 6.22681 7.9165ZM30.4684 7.9165C33.1866 10.6347 34.8671 14.3891 34.8671 18.5355C34.8671 22.6819 33.1851 26.4378 30.4684 29.1545L28.3449 27.031C30.5195 24.8565 31.8635 21.8529 31.8635 18.5355C31.8635 15.2181 30.5195 12.2161 28.3464 10.0415L30.4669 7.91801L30.4684 7.9165ZM19.8494 8.02313V17.0338H24.3547L16.8458 29.0479V20.0373H12.3405L19.8494 8.02313ZM10.4738 12.1635L12.5988 14.2885C11.5115 15.3743 10.8387 16.8761 10.8387 18.5355C10.8387 20.195 11.5115 21.6968 12.5988 22.7825L10.4738 24.9075C8.8444 23.2766 7.83521 21.024 7.83521 18.5355C7.83521 16.0471 8.8444 13.7944 10.4738 12.1635ZM26.2229 12.165C27.8523 13.7959 28.86 16.0486 28.86 18.5355C28.86 21.024 27.8508 23.2766 26.2214 24.9075L24.0964 22.7825C25.1837 21.6968 25.8565 20.195 25.8565 18.5355C25.8565 16.8776 25.1852 15.3758 24.0994 14.29L26.2229 12.165Z"
        fill={color}
      />
    </svg>
  );
};
