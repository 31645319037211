import { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useDealers } from '../hooks/useDealersApis';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import { IDealer } from '../../../types/resources';
import { DealerCardImage } from './DealerCardImage';
import { DealerListSkeleton } from './DealerCardSkeleton';
import {
  ColLg12,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  MainHeadP,
  Row,
  SellYourCar,
} from '../../../common/styles/CommonStyles';
import {
  FinancingColumn,
  FinancingColumnBox,
  FinancingFrame,
  FinancingFrameH2,
  FinancingSection,
} from './DealerStyledComponent';

type DealersProps = {
  new_cars: number;
  used_cars: number;
  new_bikes: number;
  used_bikes: number;
};

export default function Dealers() {
  const { lang, trans, lightImageLoadingPlaceholder, country } =
    useContext(AppContext);

  const { data: dealers, isLoading: dealersIsLoading } = useDealers();

  const sortedDealers = useMemo(() => {
    return dealers
      ?.sort((a: IDealer, b: IDealer) => {
        return a.name.localeCompare(b.name);
      })
      .filter(
        (dealer: IDealer) =>
          dealer._count.used_cars > 0 ||
          dealer._count.new_cars > 0 ||
          dealer._count.new_bikes > 0 ||
          dealer._count.used_bikes > 0,
      );
  }, [dealers]);

  const redirectionLink = (counts: DealersProps) => {
    const { used_cars, new_cars, new_bikes, used_bikes } = counts;

    if (
      used_cars === 0 &&
      new_cars === 0 &&
      new_bikes === 0 &&
      used_bikes === 0
    ) {
      return 'used-cars';
    } else if (
      used_cars > 0 &&
      new_cars === 0 &&
      new_bikes === 0 &&
      used_bikes === 0
    ) {
      return 'used-cars';
    } else if (
      used_cars === 0 &&
      new_cars > 0 &&
      new_bikes === 0 &&
      used_bikes === 0
    ) {
      return 'new-cars';
    } else if (
      used_cars === 0 &&
      new_cars === 0 &&
      new_bikes > 0 &&
      used_bikes === 0
    ) {
      return 'new-bikes';
    } else if (
      used_cars === 0 &&
      new_cars === 0 &&
      new_bikes === 0 &&
      used_bikes > 0
    ) {
      return 'used-bikes';
    } else {
      return '';
    }
  };

  if (dealersIsLoading && !dealers) {
    return (
      <>
        <MetaTag
          metaKey="dealers"
          href={`/${country}/${country}/${lang}/dealers`}
        />
        <DealerListSkeleton trans={trans} />
      </>
    );
  }

  return (
    <>
      <MetaTag metaKey="dealers" href={`/${country}/${lang}/dealers`} />
      <main id="main" className="dealers">
        <MainHead>
          <MainHeadContainer className="container">
            <Row>
              <ColLg12>
                <h1>
                  <MainHeadH1>{trans.car_dealers}</MainHeadH1>
                  <MainHeadP>{trans.in_qatar}</MainHeadP>
                </h1>
              </ColLg12>
            </Row>
          </MainHeadContainer>
        </MainHead>
        <SellYourCar className="container financing-block">
          <FinancingFrame>
            <FinancingFrameH2>{trans.list_of_dealers}</FinancingFrameH2>
            <FinancingSection>
              {sortedDealers?.map((dealer: IDealer) => (
                <FinancingColumn key={dealer.id}>
                  <FinancingColumnBox
                    title={dealer.name}
                    to={`/${country}/${lang}/dealer/${
                      dealer.slug
                    }/${redirectionLink(dealer._count)}`}
                  >
                    <DealerCardImage
                      src={dealer.logo}
                      defaultSrc={lightImageLoadingPlaceholder}
                      alt={`${dealer.slug}-logo`}
                    />
                  </FinancingColumnBox>
                </FinancingColumn>
              ))}
            </FinancingSection>
          </FinancingFrame>
        </SellYourCar>
      </main>
    </>
  );
}
