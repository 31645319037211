import authenticatedApi from '../../instance/ApiInstance';

export const getRentalDealerNames = async () => {
  try {
    const response = await authenticatedApi.get('/v2/rental-dealers');

    return response.data;
  } catch (error) {
    throw error;
  }
};
