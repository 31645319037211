import { useState } from 'react';
import { RamadanSliderSkeleton } from '../RamadanSkeletons';

interface RamadanSliderImageProps {
  defaultSrc?: string;
  src: string | undefined;
  alt?: string;
  class?: string;
  onClick?: any;
  style?: React.CSSProperties;
  onLoad?: any;
  width?: number;
  height?: number;
}

export const RamadanSliderImage = (props: RamadanSliderImageProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <>
      {isImageLoading && <RamadanSliderSkeleton />}
      <img
        src={props.src}
        className={props.class}
        onClick={props.onClick}
        alt={props.alt}
        style={{
          ...props.style,
          display: isImageLoading ? 'none' : 'block',
        }}
        width={props.width}
        height={props.height}
        onLoad={() => {
          setIsImageLoading(false);
        }}
      />
    </>
  );
};
