import authenticatedApi from '../../../instance/ApiInstance';

export interface RentCarInquiryPayload {
  from: string;
  to: string;
  inquirer_name: string;
  inquirer_mobile: string;
  inquirer_email: string;
  pick_up_location: number | null;
  drop_off_location: number | null;
  rental_id: number;
}

export const postRentCarInquiry = async (data: RentCarInquiryPayload) => {
  try {
    const response = await authenticatedApi.post(
      '/v2/inquiries/rental-car-inquiries',
      data,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
