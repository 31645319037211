import { Skeleton, styled } from '@mui/material';
import {
  AutoImgBox,
  BrowseBrands,
  BrowseBrandsSection,
  BrowseBrandsCarInfoColumn,
  CarInfoColumnLogoImg,
  CarInfoColumnTextBox,
  CarInfoColumnTextHolder,
  CarInfoColumnTitleCar,
  CarInfoColumnTitleCategory,
  CarInfoColumnImgHolder,
  MainHeadingH2,
  CarInfoColumn,
  SliderSectionStyle,
  FeaturedDetailsStyled,
  FeautredDetailsHolder,
  FeaturedDetailsDetail,
  FeaturedDetailsH2,
  FeaturedDetailsP,
  FeaturedFrame,
  FeaturedFrameHolder,
  DetailsHeader,
} from '../../../../common/styles/CommonStyles';
import {
  FeaturedDetailsDealerBrandImg,
  NewCarImageSlideContent,
  NewCarImageStyled,
  NewCarsDetailsPageSliderImagesSkeletonStyled,
} from '../NewCarsStyled';
import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import '../../../Listings/common/ListingDetailsImageGallery/ListingDetailsImageGallery-styles.css';

export const NewCarModelCardImageSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      width={'100%'}
      height={184}
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const NewCarModelCardSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <BrowseBrandsCarInfoColumn>
      <span>
        <CarInfoColumnImgHolder>
          <AutoImgBox>
            <NewCarModelCardImageSkeleton />
          </AutoImgBox>
        </CarInfoColumnImgHolder>
        <CarInfoColumnTextHolder>
          <CarInfoColumnTextBox>
            <CarInfoColumnLogoImg>
              <Skeleton
                variant="rectangular"
                width={40}
                height={30}
                style={{
                  backgroundImage: `url(${darkImageLoadingPlaceholder})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
            </CarInfoColumnLogoImg>
            <CarInfoColumnTitleCar>
              <Skeleton variant="text" width={100} />
            </CarInfoColumnTitleCar>
            <CarInfoColumnTitleCategory>
              <Skeleton variant="text" width={150} />
            </CarInfoColumnTitleCategory>
          </CarInfoColumnTextBox>
        </CarInfoColumnTextHolder>
      </span>
    </BrowseBrandsCarInfoColumn>
  );
};

export const NewCarModelMoreCardSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <CarInfoColumn className="deal-page">
      <span>
        <CarInfoColumnImgHolder>
          <AutoImgBox>
            <NewCarModelCardImageSkeleton />
          </AutoImgBox>
        </CarInfoColumnImgHolder>
        <CarInfoColumnTextHolder>
          <CarInfoColumnTextBox>
            <CarInfoColumnLogoImg>
              <Skeleton
                variant="rectangular"
                width={40}
                height={30}
                style={{
                  backgroundImage: `url(${darkImageLoadingPlaceholder})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
            </CarInfoColumnLogoImg>
            <CarInfoColumnTitleCar>
              <Skeleton variant="text" width={100} />
            </CarInfoColumnTitleCar>
            <CarInfoColumnTitleCategory>
              <Skeleton variant="text" width={150} />
            </CarInfoColumnTitleCategory>
          </CarInfoColumnTextBox>
        </CarInfoColumnTextHolder>
      </span>
    </CarInfoColumn>
  );
};

export const NewCarModelListPageSkeleton = ({ trans }: { trans: any }) => {
  return (
    <main id="main">
      <div className="container" style={{ paddingTop: '32px' }}>
        <BrowseBrandsSection className="container">
          <>
            <MainHeadingH2>{trans.Car_models}</MainHeadingH2>
            <BrowseBrands>
              <>
                {[...Array(4)].map((_, i) => (
                  <NewCarModelCardSkeleton key={i} />
                ))}
              </>
            </BrowseBrands>
          </>
        </BrowseBrandsSection>
      </div>
    </main>
  );
};

export const NewCarsDetailsPageSliderDealerLogoSkeleton = () => {
  const StyledSkeleton = styled(Skeleton)({
    '@media (max-width: 767px)': {
      width: '170px !important',
    },
  });
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <StyledSkeleton
      variant="rectangular"
      width={250}
      height={60}
      style={{
        margin: 0,
        alignItems: 'end',
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const NewCarsDetailsPageSliderImageSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <Skeleton
      variant="rectangular"
      width={'100%'}
      height={300}
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100%',
        margin: 'auto',
      }}
    />
  );
};

export const NewCarsDetailsPageSliderImagesSkeleton = () => {
  const { darkImageLoadingPlaceholder } = useContext(AppContext);
  return (
    <NewCarsDetailsPageSliderImagesSkeletonStyled
      variant="rectangular"
      style={{
        backgroundImage: `url(${darkImageLoadingPlaceholder})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export const NewCarsDetailsPageHeaderSkeleton = () => {
  return (
    <DetailsHeader className="d-flex justify-content-between w-100"></DetailsHeader>
  );
};

export const NewCarsDetailsPageSilderSkeleton = () => {
  return (
    <div className="slider-holder" style={{ paddingTop: '35px' }}>
      <div className="slick-slider-big slick-initialized slick-slider">
        <div className="slick-list draggable">
          <div className="slick-track" style={{ opacity: 1 }}>
            <NewCarImageStyled>
              <NewCarImageSlideContent>
                <AutoImgBox>
                  <NewCarsDetailsPageSliderImageSkeleton />
                </AutoImgBox>
              </NewCarImageSlideContent>
            </NewCarImageStyled>
          </div>
        </div>
      </div>
      <div className="slider-nav-thumbnails slick-initialized slick-slider">
        <div className="slick-list draggable">
          <div
            className="slick-track"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            {[...Array(5)].map((_, i) => (
              <div key={i} className="item w-100">
                <AutoImgBox
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <NewCarsDetailsPageSliderImagesSkeleton />
                </AutoImgBox>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const NewCarsDetailsPageFeaturedDetailsSkeleton = () => {
  return (
    <FeaturedDetailsStyled
      className="d-flex flex-column justify-content-between align-items-end"
      style={{
        width: '60%',
      }}
    >
      <FeaturedDetailsDealerBrandImg>
        <NewCarsDetailsPageSliderDealerLogoSkeleton />
      </FeaturedDetailsDealerBrandImg>
      <FeautredDetailsHolder>
        <FeaturedDetailsH2 style={{ margin: 0 }}>
          <Skeleton variant="text" width={100} />
        </FeaturedDetailsH2>
        <FeaturedDetailsDetail>
          <FeaturedDetailsP>
            <Skeleton variant="text" width={'100%'} />
            <Skeleton variant="text" width={'100%'} />
            <Skeleton variant="text" width={'100%'} />
          </FeaturedDetailsP>
        </FeaturedDetailsDetail>
        <FeaturedFrame>
          <FeaturedFrameHolder style={{ width: '100%' }}>
            <div className="d-flex justify-content-end w-100">
              <Skeleton variant="rectangular" width={130} height={40} />
            </div>
          </FeaturedFrameHolder>
        </FeaturedFrame>
      </FeautredDetailsHolder>
    </FeaturedDetailsStyled>
  );
};

export const NewCarsDetailsPageOtherModelsSkeleton = ({
  trans,
}: {
  trans: any;
}) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
        <h1
          className="other-models-header"
          style={{
            color: '#3d3d3d',
            fontSize: '24px',
            lineHeight: '1.3',
            fontWeight: '800',
            marginBottom: '30px',
          }}
        >
          <strong>{trans.other_models}</strong>
        </h1>
      </div>
      <div
        className="d-flex justify-content-start align-items-center flex-wrap mb-4 deal-page-models-wrapper"
        style={{ gap: '20px' }}
      >
        {[...Array(4)].map((_, i) => (
          <CarInfoColumn key={i} className="deal-page">
            <NewCarModelMoreCardSkeleton />
          </CarInfoColumn>
        ))}
      </div>
    </>
  );
};

export const NewCarsDetailsPageSkeleton = ({ trans }: { trans: any }) => {
  return (
    <main id="main">
      <div className="container details">
        <div className="row d-flex">
          <NewCarsDetailsPageHeaderSkeleton />
          <SliderSectionStyle className="new-car-detail-slider-section">
            <NewCarsDetailsPageSilderSkeleton />
            <NewCarsDetailsPageFeaturedDetailsSkeleton />
          </SliderSectionStyle>
          <NewCarsDetailsPageOtherModelsSkeleton trans={trans} />
        </div>
      </div>
    </main>
  );
};

export default NewCarsDetailsPageSkeleton;
